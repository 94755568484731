import { Basket, JobPartOfferSearchResponse, PartOfferAggregation } from '../../models';
import { useMemo } from 'react';
import { createBaskets } from '../../utils';
import { offer_request_handler } from '@/sdk/reflect/reflect';

/**
 * A hook that takes offers search response and creates baskets based on recommendations
 */
export const useRecommendedBaskets = (
	offersData: JobPartOfferSearchResponse,
	partOfferAggregations: PartOfferAggregation[],
	offerRequestsData: offer_request_handler.exp.OfferRequestGetResponse
): Basket[] => {
	const baskets = useMemo(
		() =>
			offersData && partOfferAggregations.length > 0
				? createBaskets(offersData, partOfferAggregations, offerRequestsData)
				: [],
		[offersData, partOfferAggregations]
	);

	return baskets;
};
