import { SellableOffer } from '../models';
import { getOfferSellable } from './get-offer-sellable';

/**
 * Format and sort offers for display
 */
export const formatOffers = (offers: SellableOffer[]) =>
	offers
		.filter(offer => !offer.groupId)
		.map(productOffer => ({
			offer: productOffer,
			sellable: getOfferSellable(productOffer)!,
			business: productOffer.business!
		}));
