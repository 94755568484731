import { OfferRequest, OfferSelection, PartOfferAggregation, SellableOffer } from '../../models';
import SectionContainer from '../section-container';
import { useMemo, useState } from 'react';
import JobPartItem from '../job-part-item';
import { EmptyState } from '@common/components/empty-state';
import ExpandButton from '../expand-button';
import { formatOffers, getOfferSellable, getSelectedAssemblies } from '../../utils';
import { isDefined } from '@partly/js-ex';
import { JobPart } from '@/sdk/lib';
import { EXTRA_JOB_PART } from '../../constants';
import { flatten } from 'lodash';
import OfferCard from '../offer-card';

interface JobPartsOffersSectionProps {
	partOfferAggregations: PartOfferAggregation[];
	deliverBefore: Date;
	notReady?: boolean;
	pendingRequest?: OfferRequest;
	offerSelection: OfferSelection;
	onAddSuppliersClick(): void;
	onSendMessage(message: string, requestId: string): void;
	onSelectionChange: (jobPart: JobPart, offer: SellableOffer) => void;
}

const JobPartsOffersSection = ({
	partOfferAggregations,
	deliverBefore,
	pendingRequest,
	notReady,
	offerSelection,
	onAddSuppliersClick,
	onSendMessage,
	onSelectionChange
}: JobPartsOffersSectionProps) => {
	const [expandedItems, setExpandedItems] = useState<string[]>([]);

	// All selected asseblies for all job parts
	const selectedAssemblyOffers = useMemo(
		() => getSelectedAssemblies(partOfferAggregations, offerSelection),
		[offerSelection, partOfferAggregations]
	);

	const jobParts = useMemo(
		() => partOfferAggregations.filter(aggr => aggr.jobPart.description !== EXTRA_JOB_PART),
		[partOfferAggregations]
	);

	const additionalParts = useMemo(() => {
		const offers = flatten(
			partOfferAggregations
				.filter(aggr => aggr.jobPart.description === EXTRA_JOB_PART)
				.map(aggr => aggr.offers.map(o => ({ jobPart: aggr.jobPart, ...o })))
		);

		return formatOffers(offers);
	}, [partOfferAggregations]);

	const onExpandedItemChange = (itemId: string, isExpanded: boolean) => {
		if (isExpanded) {
			setExpandedItems(prev => [...prev, itemId]);
		} else {
			setExpandedItems(prev => prev.filter(id => id !== itemId));
		}
	};

	const getFulfillingAssemblies = (partOfferAggregation: PartOfferAggregation) =>
		selectedAssemblyOffers
			.filter(({ offer }) => partOfferAggregation.offers.some(o => o.id === offer.id))
			.map(matching => matching.assembly)
			.filter(isDefined);

	return (
		<div>
			<div>
				<div className="flex items-center mt-12 mb-6 justify-between">
					<h2 className="text-xl font-semibold">Parts ({jobParts.length})</h2>
					{partOfferAggregations.length > 0 && (
						<ExpandButton
							partOfferAggregations={partOfferAggregations}
							expandedItems={expandedItems}
							setExpandedItems={setExpandedItems}
						/>
					)}
				</div>
				<SectionContainer>
					{jobParts.length > 0 ? (
						<ul>
							{jobParts.map((aggregation, i, array) => {
								return (
									<li key={aggregation.jobPart.id}>
										<JobPartItem
											partOfferAggregation={aggregation}
											partOfferAggregationsList={array}
											deliverBefore={deliverBefore}
											fulfillingAssemblies={getFulfillingAssemblies(aggregation)}
											noBorder={i === array.length - 1}
											offerSelection={offerSelection}
											notReady={notReady}
											isExpanded={expandedItems.includes(aggregation.jobPart.id)}
											onExpandedChange={isExpanded =>
												onExpandedItemChange(aggregation.jobPart.id, isExpanded)
											}
											pendingRequest={pendingRequest}
											onAddSuppliersClick={onAddSuppliersClick}
											onSendMessage={onSendMessage}
											onSelectionChange={offer => onSelectionChange(aggregation.jobPart, offer)}
										/>
									</li>
								);
							})}
						</ul>
					) : (
						<EmptyState className="items-start w-full">
							<EmptyState.Title>No parts</EmptyState.Title>
							<EmptyState.Description>There are no parts to display</EmptyState.Description>
						</EmptyState>
					)}
				</SectionContainer>
			</div>
			{additionalParts.length > 0 ? (
				<div>
					<div className="flex items-center mt-12 mb-6 justify-between">
						<h2 className="text-xl font-semibold">Additional Items ({additionalParts.length})</h2>
					</div>
					<SectionContainer>
						<div className="flex gap-16 pl-4 p-6 pb-4 overflow-x-auto">
							<ul className="flex h-[calc(100%-36px)] w-full gap-4 mt-6">
								{additionalParts.map(({ offer }) => (
									<li key={offer.id}>
										<OfferCard
											sellable={getOfferSellable(offer)}
											business={offer.business}
											offerSelection={offerSelection}
											offer={offer}
											isAdditionalPart
											partOfferAggregationsList={partOfferAggregations}
											deliverBefore={deliverBefore}
											isSelected={offerSelection.has(offer.id)}
											onSendMessage={onSendMessage}
											onSelectionChange={() =>
												onSelectionChange(
													(offer as SellableOffer & { jobPart: JobPart }).jobPart,
													offer
												)
											}
										/>
									</li>
								))}
							</ul>
						</div>
					</SectionContainer>
				</div>
			) : null}
		</div>
	);
};

export default JobPartsOffersSection;
