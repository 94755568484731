import { coreSdk, repairSdk } from '@/sdk/reflect';
import {
	api_error,
	basket_handler,
	core_order,
	offer_request_handler,
	order_handler
} from '@/sdk/reflect/reflect';
import { auth, upload } from '@partly/core-server-client';
import { getConfig } from '../config';
import { mutationOptions } from '../utils/mutation-options';

export const mutations = {
	baskets: {
		update: mutationOptions<
			basket_handler.exp.BasketIngestResponse,
			api_error.RepairerServerError,
			basket_handler.BasketIngestRequest
		>({
			mutationKey: ['draft-orders', 'ingest'],
			mutationFn: async payload => {
				const sdk = await repairSdk(getConfig());
				const { data, error } = await sdk.baskets.update(payload);
				if (error) {
					throw error;
				}

				return data;
			}
		})
	},
	offer_requests: {
		insert: mutationOptions<
			offer_request_handler.exp.OfferRequestInsertResponse,
			api_error.RepairerServerError,
			offer_request_handler.OfferRequestInsertRequest
		>({
			mutationKey: ['offer_requests', 'insert'],
			mutationFn: async payload => {
				const sdk = await repairSdk(getConfig());
				const { data, error } = await sdk.offer_requests.insert(payload);
				if (error) {
					throw error;
				}

				return data;
			}
		}),
		insert_event: mutationOptions<
			offer_request_handler.exp.OfferRequestInsertEventResponse,
			api_error.RepairerServerError,
			offer_request_handler.OfferRequestEventInsertRequest
		>({
			mutationKey: ['offer_requests', 'insert_event'],
			mutationFn: async payload => {
				const sdk = await repairSdk(getConfig());
				const { data, error } = await sdk.offer_requests.insert_event(payload);
				if (error) {
					throw error;
				}

				return data;
			}
		})
	},
	orders: {
		insert: mutationOptions<
			core_order.SupplierOrder,
			core_order.OrdersInsertError,
			order_handler.JobOrdersInsertRequest
		>({
			mutationKey: ['orders', 'insert'],
			mutationFn: async payload => {
				const sdk = await repairSdk(getConfig());
				const { data, error } = await sdk.orders.insert(payload);
				if (error) {
					throw error;
				}

				return data;
			}
		}),
		cancel: mutationOptions<
			core_order.SupplierOrder,
			core_order.OrdersCancelError,
			order_handler.JobOrdersCancelRequest
		>({
			mutationKey: ['orders', 'cancel'],
			mutationFn: async payload => {
				const sdk = await repairSdk(getConfig());
				const { data, error } = await sdk.orders.cancel(payload);
				if (error) {
					throw error;
				}

				return data;
			}
		}),
		items: {
			process: mutationOptions<
				core_order.SupplierOrder,
				core_order.OrdersItemsProcessError,
				order_handler.JobOrdersItemsProcessRequest
			>({
				mutationKey: ['orders', 'items', 'process'],
				mutationFn: async payload => {
					const sdk = await repairSdk(getConfig());
					const { data, error } = await sdk.orders.items.process(payload);
					if (error) {
						throw error;
					}

					return data;
				}
			})
		}
	},
	users: {
		signin: mutationOptions<
			auth.UsersSignInResponse,
			auth.UsersSignInError,
			auth.UsersSignInRequest
		>({
			mutationKey: ['users', 'signin'],
			mutationFn: async payload => {
				const [api] = await coreSdk(getConfig());
				const result = await api.users.signin(payload, {});
				if (result.is_err()) {
					throw result.unwrap_err();
				}

				return result.unwrap_ok();
			}
		})
	},
	uploads: {
		prepare: mutationOptions<
			upload.UploadsPrepareResponse,
			upload.UploadsPrepareError,
			upload.UploadsPrepareRequest
		>({
			mutationKey: ['uploads', 'prepare'],
			mutationFn: async payload => {
				const [api, headers] = await coreSdk(getConfig());
				const result = await api.uploads.prepare(
					payload,
					headers as {
						authorization: string;
					} /** forgive me, will fix later :jeff-war-flashback: */
				);
				if (result.is_err()) {
					throw result.unwrap_err().unwrap();
				}

				return result.unwrap_ok();
			}
		})
	}
};
