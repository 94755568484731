import { OfferRequestsAggregation } from '../../models';
import { useMemo } from 'react';
import { offer_request_handler } from '@/sdk/reflect/reflect';

/**
 * A hook that takes offer requests data and returns a record with a key set to  supplierId
 */
export const useOfferRequestsAggregation = (
	offerRequestsData: offer_request_handler.exp.OfferRequestGetResponse,
	jobId: string
): OfferRequestsAggregation => {
	const data = useMemo(() => {
		const aggregation: OfferRequestsAggregation = {};
		// Filter out offer requests that already returned offers
		offerRequestsData.offer_requests.forEach(request => {
			const { store_id } = request;

			const lastEvent = request.events[request.events.length - 1];
			const status = lastEvent?.kind?.type === 'repairer' ? 'pending' : lastEvent?.kind?.status;
			const lastUpdated = new Date(lastEvent?.created_at);

			if (aggregation[store_id]) {
				if (aggregation[store_id].lastUpdated.getTime() < lastUpdated.getTime()) {
					// Shouldn't happen but in case there are multiple offer requests for the same supplier for the same job then take the most recent one
					aggregation[store_id] = {
						request,
						status,
						lastUpdated
					};
				}
			} else {
				aggregation[store_id] = {
					request,
					status,
					lastUpdated
				};
			}
		});
		return aggregation;
	}, [jobId, offerRequestsData]);

	return data;
};
