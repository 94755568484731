import { InheritableElementProps } from '@/types/utilties';
import { tlsx } from '@common/utils/tw-merge';
import { ReactNode } from 'react';
import { FallbackCategoryTree, FallbackCategoryTreeLeaf } from '../../types';

export type FallbackPartsCategoryProps = InheritableElementProps<
	'button',
	{
		category: FallbackCategoryTree;
		selected: FallbackCategoryTreeLeaf | null;
		actions: {
			category: {
				set: (category: FallbackCategoryTreeLeaf) => void;
			};
		};
	}
>;

export const FallbackPartsCategory = ({
	className,
	category,
	selected,
	actions,
	...rest
}: FallbackPartsCategoryProps): ReactNode | null => {
	if (category.kind === 'leaf') {
		return (
			<button
				type="button"
				className={tlsx(
					'group rounded-md py-2 px-4 gap-2 hover:bg-gray-100 active:bg-gray-100 flex items-center justify-around shrink-0 text-nowrap',
					{
						'bg-gray-100': selected?.id === category.id
					},
					className
				)}
				onClick={() => actions.category.set(category)}
				{...rest}
			>
				{category.description}
			</button>
		);
	}
};
