import { tlsx } from '@common/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { Skeleton } from '@mantine/core';
import { getFeatureFlags } from '@common/utils/feature-flag';

type JobTableSkeletonProps = InheritableElementProps<'table', object>;

const JobTableSkeleton = ({ className, ...rest }: JobTableSkeletonProps) => {
	const featureFlags = getFeatureFlags();

	return (
		<table className={tlsx('flex-1 w-full', className)} {...rest}>
			<tbody className="grid relative w-full">
				{Array.from({ length: 3 }).map((_, index) => (
					<tr
						key={index}
						className="border-b border-gray-200 w-full min-h-24 flex items-center justify-between py-6 px-10 bg-white"
					>
						<td className="w-1/4 text-sm text-gray-600 space-y-1 font-semibold">
							<Skeleton className="w-[14ch] h-6" />
							<Skeleton className="w-[10ch] h-5" />
						</td>
						<td className="w-[12.5%] space-y-1">
							<Skeleton className="w-[10ch] h-6" />
							<Skeleton className="w-[8ch] h-5" />
						</td>
						<td className="w-[8.33%]">
							<Skeleton className="w-[6ch] h-5" />
						</td>
						<td className="w-[6.25%] ml-10 flex items-center justify-end">
							<Skeleton className="w-[2ch] h-5" />
						</td>
						{featureFlags.showWorkProvider ? (
							<td className="w-[6.25%] flex items-center justify-end">
								<Skeleton className="w-[2ch] h-5" />
							</td>
						) : null}
						<td className="w-[6.25%] flex items-center justify-end">
							<Skeleton className="w-[2ch] h-5" />
						</td>
						<td className="w-[6.25%] flex items-center justify-center">
							<Skeleton className="w-[2ch] h-5" />
						</td>
						<td className="w-[6.25%] flex items-center justify-center">
							<Skeleton className="w-[2ch] h-5" />
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default JobTableSkeleton;
