import { session } from '@common/hooks/use-auth';
import { ApiError } from '@/sdk/lib';
import { queries } from '@/sdk/react/queries';
import { InheritableElementProps } from '@/types/utilties';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Loader, Select, Skeleton } from '@mantine/core';
import { SelectOrganisationRequest } from '@partly/bna-session';
import * as Sentry from '@sentry/browser';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

const organizationSchema = yup.object({
	organization: yup.string().required().label('Organization')
});

export type OrganizationFormData = yup.InferType<typeof organizationSchema>;

export type OrganizationFormProps = Omit<InheritableElementProps<'form', object>, 'noValidate'>;

export const OrganizationForm = (props: OrganizationFormProps) => {
	const queryClient = useQueryClient();
	const { data: orgsData, isLoading } = useQuery({
		...queries.organizations.list(),
		staleTime: Infinity,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		retry: 0
	});

	const { mutateAsync, isPending, error } = useMutation({
		mutationKey: ['auth', 'select-organization'],
		mutationFn: async (payload: SelectOrganisationRequest) => {
			const { data, error } = await session.actions.selectOrganisation(payload);
			if (!data) {
				throw error;
			}

			return data;
		},
		onSuccess: async () => {
			await queryClient.resetQueries();
		}
	});
	const { handleSubmit, reset, control } = useForm<OrganizationFormData>({
		defaultValues: {
			organization: orgsData?.items?.at(0)?.id
		},
		resolver: yupResolver(organizationSchema)
	});

	const onSubmit = async ({ organization }: OrganizationFormData) => {
		try {
			await mutateAsync({
				organisation_id: organization,
				authorization_issuer: '/api/v1/organizations.verify',
				authorization_parameters: {
					organization_id: organization
				}
			});
		} catch (error) {
			if (error instanceof ApiError && error.status >= 400 && error.status < 500) {
				// Debug capture the expected errors for login endpoint
				Sentry.captureException(error, {
					level: 'debug'
				});
			} else {
				console.warn('Unexpected error', error);
				// Catch any unhandled error and send to Sentry
				Sentry.captureException(error);
			}
		}
	};

	const onBack = async () => {
		await session.actions.signout();
		await queryClient.resetQueries();
	};

	useEffect(() => {
		if (isLoading) {
			return;
		}
		if (orgsData?.items.length === 1) {
			onSubmit({ organization: orgsData.items[0].id });
		} else {
			reset({ organization: orgsData?.items?.at(0)?.id });
		}
	}, [isLoading, orgsData]);

	if (isPending) {
		return <Skeleton className={props.className} />;
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} noValidate {...props}>
				<div className="space-y-4">
					<Controller
						control={control}
						name="organization"
						render={({ field: { onChange, value, ...controlRest } }) => (
							<Select
								data-field="organization"
								label="Organization"
								value={value ?? null}
								data={orgsData?.items.map(({ id, name }) => ({ value: id, label: name })) ?? []}
								onChange={onChange}
								placeholder={isLoading ? 'Loading organizations...' : 'Select organization'}
								disabled={isLoading}
								icon={isLoading ? <Loader className="!size-4" /> : null}
								clearable
								{...controlRest}
							/>
						)}
					/>
				</div>
				<Button className="w-full mt-8" type="submit">
					Continue
				</Button>

				<div className="flex items-center justify-center w-full mt-4">
					<button type="button" className="text-sm text-gray-500" onClick={onBack}>
						Go back to sign in
					</button>
				</div>
			</form>
			{error && (
				<Alert color="red" className="mt-4" title="Sign in error">
					Invalid organization
				</Alert>
			)}
		</>
	);
};
