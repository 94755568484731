import { offer_request_handler } from '@/sdk/reflect/reflect';
import {
	Basket,
	JobPartOfferSearchResponse,
	JobPartRecommendedOffers,
	PartOfferAggregation,
	SellableOffer,
	SupplierCondition
} from '../models';
import { formatSellableOffers } from './format-sellable-offers';
import { flatten, isEqual } from 'lodash-es';
import { v4 as generateId } from 'uuid';
import { isOfferMatching } from './is-offer-matching';
import { getOffersSummary } from './get-offers-summary';
import { formatOfferPrice } from './format-offer-price';
import { isDefined } from '@partly/js-ex';
import { EXTRA_JOB_PART } from '../constants';

/**
 * Create baskets based on recommendations
 */
export const createBaskets = (
	offerData: JobPartOfferSearchResponse,
	partOfferAggregations: PartOfferAggregation[],
	offerRequests: offer_request_handler.exp.OfferRequestGetResponse
): Basket[] => {
	const { matches } = offerData;

	const flattenOffers = formatSellableOffers(offerData.offers, offerRequests);

	const allUsersJobPartIds = partOfferAggregations
		.filter(a => a.jobPart.description !== EXTRA_JOB_PART)
		.map(a => a.jobPart.id);

	const baskets = offerData.baskets
		.map(basket => {
			const fulfilledByBasketJobPartIds: string[] = [];

			const recommendedMatches =
				basket.type === 'single_supplier'
					? basket.recommendations?.map(recommendation => ({
							quantity: recommendation.quantity,
							match: matches[recommendation.match_index]
						}))
					: flatten(
							basket.suppliers.map(sup =>
								sup.recommendations.map(recommendation => ({
									quantity: recommendation.quantity,
									match: matches[recommendation.match_index]
								}))
							)
						);

			if (!recommendedMatches) {
				return null;
			}

			const jobPartRecommendedOffers = flattenOffers
				.filter(offer => recommendedMatches.some(({ match }) => isOfferMatching(offer, match)))
				.map(offer => {
					const matched = recommendedMatches.find(({ match }) => isOfferMatching(offer, match))!;

					return {
						offer,
						quantity: matched.quantity,
						jobPartId: matched.match.job_part_id
					};
				})
				.reduce((accu, recommendedOffer) => {
					if (!recommendedOffer.offer) {
						return accu;
					}

					if (!accu[recommendedOffer.jobPartId]) {
						accu[recommendedOffer.jobPartId] = [];
					}

					accu[recommendedOffer.jobPartId].push({
						offer: recommendedOffer.offer,
						quantity: recommendedOffer.quantity
					});
					return accu;
				}, {} as JobPartRecommendedOffers);

			if (Object.keys(jobPartRecommendedOffers).length > 0) {
				const offers = Object.keys(jobPartRecommendedOffers)
					.reduce(
						(accu, jobPartId) => {
							if (
								partOfferAggregations.some(
									aggr =>
										aggr.jobPart.id === jobPartId &&
										aggr.offers.length > 0 &&
										aggr.jobPart.description !== EXTRA_JOB_PART
								)
							) {
								fulfilledByBasketJobPartIds.push(jobPartId);
							}

							jobPartRecommendedOffers[jobPartId].forEach(r =>
								accu.push({
									quantity:
										partOfferAggregations.find(aggregation => aggregation.jobPart.id)?.jobPart
											.quantity || 0,
									...r.offer
								})
							);
							return accu;
						},
						[] as (SellableOffer & { quantity: number })[]
					)
					.filter(offer => offer.quantity);

				const summary = getOffersSummary(offers);

				const request =
					basket.type === 'single_supplier'
						? offerRequests?.offer_requests?.find(
								request => request.store_id === basket.supplier?.id
							)
						: undefined;

				const b: Basket = {
					id: generateId(),
					type: basket.type,
					supplierScore: basket.type === 'single_supplier' ? basket.supplier_score : null,
					title:
						basket.type === 'single_supplier'
							? basket.supplier.name
							: basket.suppliers.reduce((accu, sup) => `${accu}, ${sup.supplier.name}`, ''),
					request,
					supplier:
						basket.type === 'single_supplier'
							? [basket.supplier]
							: basket.suppliers.map(({ supplier }) => supplier),
					offers,
					missingJobPartsCount:
						allUsersJobPartIds.length - new Set(fulfilledByBasketJobPartIds).size,
					offersPerJobPart: jobPartRecommendedOffers,
					estimatedDelivery: summary.shippingTime,
					fulfillmentStatus: basket.metadata?.fulfillment_status || null,
					shippingCost: basket.metadata?.shipping_cost
						? {
								amount: Number(basket.metadata.shipping_cost.amount),
								currency: basket.metadata.shipping_cost.currency
							}
						: null,
					totalPrice: basket.metadata
						? formatOfferPrice(basket.metadata.total_price)
						: summary.totalPrice,
					totalCount: summary.totalCount,
					conditions: Object.keys(summary.conditionsPerSupplier).reduce(
						(accu, suppId) => {
							const conditions = summary.conditionsPerSupplier[suppId];

							conditions.forEach(c => {
								if (
									c &&
									!accu.some(
										condition =>
											isEqual(condition.condition, c.condition) &&
											isEqual(condition.isOes, condition.isOes) &&
											isEqual(condition.isOem, c.isOem)
									)
								) {
									accu.push(c);
								}
							});
							return accu;
						},
						[] as Omit<SupplierCondition, 'isAssembly'>[]
					)
				};

				return b;
			} else {
				return null;
			}
		})
		.filter(isDefined);

	return baskets
		.filter(basket => basket.offers.length > 0)
		.sort((a, b) => a.title.localeCompare(b.title));
};
