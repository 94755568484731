import { formatPrice } from '@common/utils/currency';
import { Basket } from '../../models';
import CardContainer, { CardContainerProps } from '../card-container';
import { Button } from '@mantine/core';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import {
	ChatBubbleBottomCenterTextIcon,
	CubeIcon,
	ExclamationTriangleIcon
} from '@heroicons/react/24/outline';
import { ConditionBadge } from '../condition-badge';
import { FormattedShippingTime } from '../formatted-shipping-time';
import { isOutOfStock, isSellableBackorderable } from '../../utils';
import { Badge } from '@common/components/badge';
import Messenger from '../messenger';
import { useState } from 'react';
import clsx from 'clsx';

interface BasketCardProps extends Omit<CardContainerProps, 'type' | 'children'> {
	basket: Basket;
	deliverBefore: Date;
}

const BasketCard = ({
	tag,
	deliverBefore,
	basket,
	isSelected,
	onSelectionChange
}: BasketCardProps) => {
	const [openMessenger, setOpenMessanger] = useState(false);

	const showNotes = basket.request && basket.request.events?.filter(e => e.note).length > 0;

	return (
		<CardContainer
			tag={tag}
			type="radio"
			isSelected={isSelected}
			className="min-h-[350px] w-[260px] flex flex-col"
			onSelectionChange={onSelectionChange}
		>
			<div className="flex flex-col items-center flex-1 gap-3 px-4 pt-6 pb-2">
				<b className="font-medium leading-[1.3rem] text-center">{basket.title}</b>
				<strong className="text-xl font-medium">{formatPrice(basket.totalPrice)}</strong>

				<div className="flex text-sm font-medium">
					<ConditionBadge conditions={basket.conditions} />
				</div>

				<ul className="flex flex-col gap-2 text-sm text-gray-600">
					<li>
						<FormattedShippingTime
							orientation="vertical"
							className="text-gray-600 "
							outOfStock={basket.offers.some(isOutOfStock)}
							shippingTime={basket.estimatedDelivery}
							isBackorderable={basket.offers.some(isSellableBackorderable)}
							deliverBefore={deliverBefore}
						/>
					</li>
					<li className="flex items-center gap-1.5">
						<CubeIcon color="blue" className="h-4" /> {basket.totalCount}{' '}
						{basket.totalCount > 1 ? 'items' : 'item'}
					</li>
				</ul>
				<div className="flex flex-col items-center gap-2 my-2">
					{basket.missingJobPartsCount > 0 && (
						<Badge className="flex items-center gap-1" size="small" rounded variant="red">
							<ExclamationTriangleIcon width={16} />
							<span>
								No offers for {basket.missingJobPartsCount}{' '}
								{basket.missingJobPartsCount > 1 ? 'parts' : 'part'}
							</span>
						</Badge>
					)}
				</div>
				{basket.request ? (
					<>
						<Messenger
							open={openMessenger}
							supplier={basket.supplier[0]}
							events={basket.request.events}
							disabled
							onClose={() => setOpenMessanger(false)}
							onSendMessage={() => {}}
						/>
					</>
				) : null}

				<div className="flex items-center gap-1 mt-auto">
					<Button
						className={clsx('mt-auto min-h-9', basket.request && showNotes ? 'w-32' : 'w-52')}
						variant={isSelected ? 'filled' : 'outline'}
						leftIcon={isSelected && <CheckCircleIcon width={16} />}
					>
						{isSelected ? 'Selected' : 'Select'}
					</Button>
					{showNotes && (
						<Button
							variant="subtle"
							className="px-2"
							leftIcon={<ChatBubbleBottomCenterTextIcon className="-mr-1" width={20} />}
							onClick={e => {
								e.stopPropagation();
								setOpenMessanger(true);
							}}
						>
							Notes
						</Button>
					)}
				</div>
			</div>
		</CardContainer>
	);
};

export default BasketCard;
