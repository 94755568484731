import { ClientAPI } from '../../generated';
import { ApiConfig } from '../types';
import { createBmsEndpoint } from './bms';
import { createJobsEndpoints } from './jobs';
import { createSearchVehiclesEndpoint } from './vehicle';
import { getFeatureFlags } from '@common/utils/feature-flag';

export const createApi = (config: ApiConfig) => {
	const { localeSettings } = getFeatureFlags(config);
	const api = new ClientAPI({
		BASE: config.basePath,
		HEADERS: {
			'partly-storefront-key': config.webKey,
			...(localeSettings.currency && {
				'partly-accept-currency': localeSettings.currency
			}),
			...(localeSettings.uvdbRegionId && {
				'partly-uvdb-region-id': localeSettings.uvdbRegionId
			}),
			...(config.organizationId && {
				'partly-organization-id': config.organizationId
			}),
			...(config.userToken && {
				Authorization: `Bearer ${config.userToken.token}`
			})
		}
	});

	const vehicleEndpoints = createSearchVehiclesEndpoint(api.vehicles, config);
	const jobsEndpoints = createJobsEndpoints(api.jobs, config);
	const bmsEndpoints = createBmsEndpoint(api.bms, config);

	return {
		vehicle: vehicleEndpoints,
		jobs: jobsEndpoints,
		bms: bmsEndpoints
	};
};
