import { offer_request } from '@/sdk/reflect/reflect';
import { Button, Drawer, Textarea } from '@mantine/core';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useState } from 'react';

interface MessengerProps {
	supplier: { id: string; name: string } | null;
	open: boolean;
	events: offer_request.model.OfferRequestEvent[];
	disabled?: boolean;
	onSendMessage?(message: string): void;
	onClose(): void;
}

const Messenger = ({
	events,
	open,
	supplier,
	disabled,
	onSendMessage,
	onClose
}: MessengerProps) => {
	const [newMessage, setNewMessage] = useState('');

	if (!supplier) {
		return null;
	}

	return (
		<Drawer
			position="right"
			title={<span className="text-xl">{supplier.name}</span>}
			opened={open}
			closeButtonProps={{ size: 'md' }}
			onClick={e => e.stopPropagation()}
			onClose={onClose}
		>
			<div className="w-full max-w-2xl mt-8 px-5">
				<b className="font-medium">Notes</b>
				<ul className="mt-2">
					{events
						.filter(event => event.note)
						.map(event => {
							const isSupplier = event.kind.type === 'supplier';
							return (
								<li className="mb-4" key={event.id}>
									<div
										className={clsx('flex gap-2 w-full items-center', {
											'justify-start': isSupplier,
											'justify-end': !isSupplier
										})}
									>
										<div>
											<span className="text-xs font-light text-gray-500">
												{isSupplier ? `${event.subject.user.name || supplier.name} at` : 'You at'}{' '}
												{format(event.created_at, 'hh:mm a dd/MM')}
											</span>
											<div
												className={clsx(
													'shadow-md whitespace-pre-line rounded-md py-1 px-2 border text-gray-800 text-sm',
													isSupplier ? 'bg-blue-100 border-blue-200' : 'bg-gray-100 border-gray-200'
												)}
											>
												{event.note!}
											</div>
										</div>
									</div>
								</li>
							);
						})}
				</ul>
				{!disabled && (
					<div className="flex gap-4 mt-8 flex-col items-end w-full">
						<Textarea
							value={newMessage}
							className="w-full"
							onChange={e => setNewMessage(e.target.value)}
							placeholder="Your message to the supplier"
						/>

						<Button
							variant="filled"
							onClick={() => {
								onSendMessage?.(newMessage);
								setNewMessage('');
							}}
							disabled={!newMessage}
						>
							Send
						</Button>
					</div>
				)}
			</div>
		</Drawer>
	);
};

export default Messenger;
