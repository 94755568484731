import { InheritableElementProps } from '@/types/utilties';
import { tlsx } from '@common/utils/tw-merge';
import { isNil } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Control } from 'react-hook-form';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { DEFAULT_DISTANCE_Z, DISTANCE_Z_STEP, MAX_MIN_DISTANCE_Z } from '../../constants';
import { useSearchValue } from '../../hooks/use-search-value';
import {
	CategoryTreeLeaf,
	CategoryTreeNode,
	Diagram,
	DiagramAssembly,
	PartsFormData
} from '../../types';
import { PartsNavigation } from '../parts-navigation';
import { PartsToolbar } from '../parts-toolbar';
import { PartsDiagramSuspenseRenderer, PartsDiagramView } from './subcomponents';

type PartsDiagramProps = InheritableElementProps<
	'div',
	{
		cut?: CategoryTreeNode | null;
		other?: CategoryTreeLeaf | null;
		category?: CategoryTreeLeaf | null;
		diagram?: Diagram | null;
		highlighted: Set<string>;
		control: Control<PartsFormData>;
		selection: PartsFormData;
		navigating?: boolean;
		actions: {
			view: {
				open: () => void;
				close: () => void;
			};
			part: {
				add: (assemblies: DiagramAssembly[]) => void;
				remove: (assemblies: DiagramAssembly[]) => void;
				highlight: (partSlotIds: string[]) => void;
			};
			custom: {
				add: () => void;
			};
			category: {
				set: (category: CategoryTreeLeaf) => void;
			};
			diagram: {
				set: (diagram: Diagram) => void;
				jump: (cutId: string | null, categoryId: string, diagramId: string) => void;
			};
		};
	}
>;

export const PartsDiagram = ({
	className,
	cut,
	other,
	category,
	diagram,
	highlighted,
	control,
	selection,
	navigating,
	actions,
	style,
	...rest
}: PartsDiagramProps) => {
	const q = useSearchValue();
	const [hide, setHide] = useState(false);
	const [distanceZ, setDistanceZ] = useState(DEFAULT_DISTANCE_Z);
	const controls = useRef<OrbitControlsImpl | null>(null);

	const zoom = useMemo(() => 2 - distanceZ / DEFAULT_DISTANCE_Z, [distanceZ]);

	const partSlots = useMemo(
		() =>
			diagram?.partSlots.filter(partSlot => {
				const ids =
					partSlot.kind === 'assembly'
						? [partSlot.id, ...partSlot.relation.children]
						: [partSlot.id];
				return ids.some(id => highlighted.has(id));
			}) ?? [],
		[diagram, highlighted]
	);

	useEffect(() => {
		if (!diagram) {
			return;
		}
		controls.current?.reset();
		setHide(false);
	}, [diagram?.id]);

	return (
		<div
			className={tlsx('relative flex-1 grid place-items-center w-full', className)}
			style={style}
			{...rest}
		>
			{!isNil(category) ? (
				<PartsDiagramSuspenseRenderer
					className={className}
					diagram={diagram}
					hightlighted={highlighted}
					selection={selection}
					zoom={zoom}
					hide={hide}
					actions={{
						part: actions.part,
						diagram: {
							jump: actions.diagram.jump
						},
						controls: {
							start: orbitControls => {
								setDistanceZ(orbitControls.object.position.z);
								controls.current = orbitControls;
							},
							change: camera => {
								setDistanceZ(camera.position.z);
							}
						}
					}}
				/>
			) : (
				<div className="grid place-items-center w-full h-full bg-[#FCFDFD]">
					<span className="text-gray-600 text-sm font-medium">
						{q
							? cut?.assemblies.length !== 0 || !isNil(other)
								? `Select an assembly from the filtered list for "${q}" to view parts on their diagrams`
								: `No results for "${q}"`
							: 'Select an assembly from the list to view parts on their diagrams'}
					</span>
				</div>
			)}

			{diagram && partSlots.length > 0 && (
				<PartsDiagramView
					cut={cut}
					category={category}
					partSlots={partSlots}
					control={control}
					selection={selection}
					highlighted={highlighted}
					actions={{
						part: actions.part,
						diagram: actions.diagram
					}}
				/>
			)}

			{!isNil(category) && !isNil(diagram) && (
				<PartsToolbar
					category={category}
					diagram={diagram}
					zoom={zoom}
					actions={{
						diagram: actions.diagram,
						zoom: {
							in: () =>
								controls.current?.object.position.setZ(
									Math.max(distanceZ - DISTANCE_Z_STEP, DEFAULT_DISTANCE_Z - MAX_MIN_DISTANCE_Z)
								),
							out: () =>
								controls.current?.object.position.setZ(
									Math.min(distanceZ + DISTANCE_Z_STEP, DEFAULT_DISTANCE_Z + MAX_MIN_DISTANCE_Z)
								)
						},
						hide: setHide,
						menu: {
							custom: actions.custom,
							diagram: {
								recenter: () => {
									if (!controls.current) {
										return;
									}
									// maintain zoom
									const { z } = controls.current.object.position;
									controls.current.reset();
									controls.current?.object.position.setZ(z);
								},
								resetZoom: () => {
									controls.current?.object.position.setZ(DEFAULT_DISTANCE_Z);
								},
								resetAll: () => controls.current?.reset()
							}
						}
					}}
				/>
			)}

			<PartsNavigation
				cut={cut}
				other={other}
				selected={category}
				diagram={diagram}
				selection={selection}
				open={!!navigating}
				actions={{
					view: actions.view,
					category: actions.category,
					diagram: actions.diagram
				}}
			/>
		</div>
	);
};
