import { createPartSlotIds } from '@common/utils/part';
import { decodeGapcPartIdentityKey, JobPart, PartSlotIds } from '@/sdk/lib';
import { isNil } from 'lodash-es';
import { v4 } from 'uuid';
import { DiagramAssembly, DiagramAssemblyResources, PartsFormData, PartsSelection } from '../types';

export const createInitialPartsFormData = (
	parts: JobPart[],
	resources: DiagramAssemblyResources
): PartsFormData => {
	const res = parts.map((part, order) => {
		const closestAssembly = part.partIdentity
			? resources.parts.get(part.partIdentity)?.at(0)
			: null;
		const directAssembly = part.assembly
			? resources.assemblies.get(part.assembly.id)
			: closestAssembly;

		const assembly =
			directAssembly && directAssembly.part.partIdentity === part.partIdentity
				? directAssembly
				: closestAssembly;

		if (!isNil(assembly)) {
			return createSelectionFromJobPart(
				assembly.id,
				part,
				assembly.displayName ?? assembly.description,
				assembly.id,
				assembly.partSlotIds,
				assembly.hcas.map(({ description }) => description),
				order
			);
		}
		return createSelectionFromJobPart(
			v4(),
			part,
			part.description ?? 'Unknown part',
			null,
			createPartSlotIds(part.partSlot),
			part.assembly?.hcas ?? [],
			order
		);
	});

	return Object.fromEntries(res);
};

export const createFallbackInitialPartsFormData = (parts: JobPart[]): PartsFormData => {
	const res = parts.map((part, order) => {
		return createSelectionFromJobPart(
			part.ghcaId ?? v4(),
			part,
			part.description,
			null,
			createPartSlotIds(part.partSlot),
			part.assembly?.hcas ?? [],
			order
		);
	});

	return Object.fromEntries(res);
};

const createSelectionFromJobPart = (
	id: string,
	part: JobPart,
	description: string,
	assemblyId: string | null,
	partSlotIds: PartSlotIds | null,
	hcas: string[],
	order: number
): [string, PartsSelection] => {
	return [
		id,
		{
			...(part.partIdentity ? decodeGapcPartIdentityKey(part.partIdentity) : {}),
			quantity: part.quantity,
			ghcaId: part.ghcaId,
			description,
			partSlotIds,
			assemblyId,
			hcas,
			order,
			diagramUrl: part.assembly?.diagramUrl,
			diagramPnc: part.assembly?.diagramPnc,
			diagramHotspot: part.assembly?.diagramHotspot
		}
	];
};

export const createPartSelection = (
	assembly: DiagramAssembly,
	checked: boolean,
	order: number = 1
): PartsSelection => {
	return {
		mpn: assembly.part.mpn,
		gapcBrandId: assembly.part.gapcBrandId,
		partSlotIds: assembly.partSlotIds,
		quantity: checked ? 1 : 0,
		assemblyId: assembly.id,
		ghcaId: assembly.hca,
		description: assembly.displayName ?? assembly.description,
		hcas: assembly.hcas.map(({ description }) => description),
		diagramUrl: assembly.diagramUrl,
		diagramPnc: assembly.diagramPnc,
		diagramHotspot: assembly.diagramHotspot,
		order
	};
};
