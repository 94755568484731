import { useMemo } from 'react';
import { OfferRequest, OfferSelection, PartOfferAggregation, SellableOffer } from '../../models';
import {
	createJobPartName,
	getSelectionSummary,
	isSellableBackorderable,
	getOfferSellable,
	formatOffers,
	isOutOfStock,
	getLastOfferRequestEvent
} from '../../utils';
import { Accordion, Button, Tooltip } from '@mantine/core';
import { FormattedShippingTime } from '../formatted-shipping-time';
import { Badge } from '@common/components/badge';
import { formatSubtotal } from '@common/utils/currency';
import clsx from 'clsx';
import { offers_search } from '@partly/core-server-client';
import ConditionPerSupplier from '../condition-per-supplier';
import { isDefined } from '@partly/js-ex';
import { ChevronDownIcon, ClockIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import OfferCard from '../offer-card';
import CardContainer from '../card-container';
import { EmptyState } from '@common/components/empty-state';

interface JobPartItemProps {
	deliverBefore: Date;
	notReady?: boolean;
	pendingRequest?: OfferRequest;
	partOfferAggregation: PartOfferAggregation;
	partOfferAggregationsList: PartOfferAggregation[];
	noBorder?: boolean;
	offerSelection: OfferSelection;
	isExpanded: boolean;
	fulfillingAssemblies?: offers_search.Assembly[];

	onAddSuppliersClick(): void;

	onExpandedChange(isExpanded: boolean): void;

	onSendMessage(message: string, requestId: string): void;

	onSelectionChange: (offer: SellableOffer) => void;
}

const JobPartItem = ({
	partOfferAggregation,
	partOfferAggregationsList,
	pendingRequest,
	notReady,
	isExpanded,
	offerSelection,
	noBorder,
	deliverBefore,
	fulfillingAssemblies,
	onAddSuppliersClick,
	onExpandedChange,
	onSendMessage,
	onSelectionChange
}: JobPartItemProps) => {
	const jobPartName = createJobPartName(partOfferAggregation.jobPart);

	const isPendingRequest = useMemo(
		() =>
			pendingRequest
				? getLastOfferRequestEvent(pendingRequest, 'repairer').event?.kind.parts.some(
						part => part.id === partOfferAggregation.jobPart.id
					)
				: null,
		[pendingRequest, partOfferAggregation]
	);

	const summary = useMemo(
		() => getSelectionSummary(offerSelection, partOfferAggregation),
		[offerSelection, partOfferAggregation]
	);

	const offers = useMemo(
		() =>
			formatOffers([
				...partOfferAggregation.offers.sort((a, b) =>
					a.business.name.localeCompare(b.business.name)
				)
			]),

		[partOfferAggregation]
	);

	const noOffers = partOfferAggregation.offers.length === 0;

	return (
		<Accordion value={isExpanded ? partOfferAggregation.jobPart.id : ''} disableChevronRotation>
			<Accordion.Item
				onClick={() => {
					if (noOffers && !isPendingRequest) {
						onAddSuppliersClick();
					} else {
						onExpandedChange(!isExpanded);
					}
				}}
				className={clsx(noBorder ? 'border-b-0' : 'border-b-gray-200')}
				value={partOfferAggregation.jobPart.id}
			>
				<Accordion.Control
					chevron={
						partOfferAggregation.offers.length > 0 ? (
							<div
								className={clsx(
									'text-blue-600 !transition-none text-sm mr-[3.65rem] font-medium flex gap-2'
								)}
							>
								<span className="whitespace-nowrap">
									{partOfferAggregation.offers.length === 1
										? '1 option'
										: `${partOfferAggregation.offers.length} options`}
								</span>
								<ChevronDownIcon width={13} color="blue" />
							</div>
						) : undefined
					}
					className="bg-transparent"
				>
					<div className="flex items-center gap-12">
						<div className="flex flex-col text-left w-72">
							<b className="font-medium whitespace-break-spaces">
								{jobPartName}{' '}
								{partOfferAggregation.jobPart.quantity > 1
									? `x ${partOfferAggregation.jobPart.quantity}`
									: ''}
							</b>
						</div>

						{!noOffers || notReady || isPendingRequest ? (
							<ul className="flex items-center flex-1 w-full gap-8 text-sm text-gray-600">
								{notReady || (isPendingRequest && noOffers) ? (
									<li>
										<Badge className="flex gap-1" variant="blue" size="small" rounded>
											<ClockIcon color="blue" width={16} />
											Waiting on more suppliers
										</Badge>
									</li>
								) : summary.selected ? (
									<>
										<li className="w-72">
											{summary.selected.suppliers.slice(0, 1).map((supplier, _i) => {
												const suppliers = summary.selected!.suppliers;
												const conditions = summary.selected!.conditionsPerSupplier[supplier.id];

												return (
													<div className="flex items-center gap-1">
														<div className="max-w-52">
															<ConditionPerSupplier
																mode="text"
																supplier={supplier}
																conditions={conditions}
															/>
														</div>
														<b className="font-medium text-black">
															{suppliers.length > 1 ? `, ${suppliers.length - 1} more` : ''}
														</b>
													</div>
												);
											})}
										</li>
										<li className="w-60">
											<FormattedShippingTime
												className="text-gray-600 "
												shippingTime={summary.selected.shippingTime || null}
												deliverBefore={deliverBefore}
												outOfStock={summary.selected.offers.some(isOutOfStock)}
												isBackorderable={summary.selected.offers.some(isSellableBackorderable)}
											/>
										</li>
									</>
								) : (
									<li>
										<Badge variant="red" size="small" rounded>
											Select supplier
										</Badge>
									</li>
								)}

								{/* State badges */}
								<li className="flex gap-2">
									{fulfillingAssemblies && fulfillingAssemblies?.length > 0 && (
										<Tooltip
											openDelay={600}
											color="gray"
											label={
												<>
													Supplied by:{' '}
													{fulfillingAssemblies
														.map(assembly => assembly.sellable.entity.name)
														.toString()
														.replaceAll(',', ', ')}
												</>
											}
										>
											<Badge size="small" rounded variant={'blue'}>
												Supplied by bulk option
											</Badge>
										</Tooltip>
									)}
									{summary.selected && summary.selected.totalCount > 1 && (
										<Tooltip
											openDelay={600}
											color="gray"
											label={`${summary.selected.totalCount} offers selected`}
										>
											<Badge variant="amber" size="small" rounded>
												Multiple selected
											</Badge>
										</Tooltip>
									)}
									{}
									{summary.selected && summary.selected.suppliers.length > 1 && (
										<Tooltip
											openDelay={600}
											color="gray"
											label={
												<ul>
													{summary.selected.suppliers.map(supplier => (
														<ConditionPerSupplier
															mode="tooltip"
															supplier={supplier}
															conditions={summary.selected!.conditionsPerSupplier[supplier.id]}
														/>
													))}
												</ul>
											}
										>
											<Badge variant="purple" size="small" rounded>
												Multiple suppliers
											</Badge>
										</Tooltip>
									)}
								</li>
								{!noOffers && !notReady && (
									<li className="flex ml-auto mr-16">
										<div
											className={clsx(
												'text-right text-sm',
												summary.potential && partOfferAggregation.offers.length > 0
													? 'font-regular text-gray-500'
													: 'font-semibold text-black'
											)}
										>
											<span className="font-normal">
												{summary.potential &&
												partOfferAggregation.offers.length > 0 &&
												isDefined(summary.potential.minPrice)
													? 'From '
													: ''}
											</span>
											<span className="whitespace-nowrap">
												{formatSubtotal({
													price: summary.selected
														? summary.selected.totalPrice
														: summary.potential?.minPrice || 0,
													displayPrice: null
												})}
											</span>
										</div>
									</li>
								)}
							</ul>
						) : noOffers ? (
							<div className="flex gap-4 items-center">
								<Badge className="flex gap-1 items-center" variant="red" size="small" rounded>
									<ExclamationTriangleIcon color="red" width={12} /> No parts available
								</Badge>
								<Button size="small" variant="subtle" onClick={onAddSuppliersClick}>
									Send to more suppliers
								</Button>
							</div>
						) : null}
					</div>
				</Accordion.Control>
				<Accordion.Panel onClick={e => e.stopPropagation()}>
					<div className="flex w-[calc(100%+2rem)] -ml-[1rem] gap-16 pl-4 p-6 pb-4 overflow-x-auto">
						<ul className="flex h-[calc(100%-36px)] w-full gap-4 mt-6">
							{offers.length > 0 ? (
								offers.map(({ offer }) => (
									<li key={offer.id}>
										<OfferCard
											sellable={getOfferSellable(offer)}
											business={offer.business}
											offerSelection={offerSelection}
											offer={offer}
											partOfferAggregationsList={partOfferAggregationsList}
											deliverBefore={deliverBefore}
											isSelected={offerSelection.has(offer.id)}
											onSendMessage={onSendMessage}
											onSelectionChange={() => onSelectionChange(offer)}
										/>
									</li>
								))
							) : (
								<li className="h-full pb-4">
									<CardContainer className="bg-white size-[260px] flex flex-col">
										<div className="flex flex-col items-center justify-center flex-1 mb-16">
											<EmptyState className="bg-transparent">
												<EmptyState.Title>
													{notReady || isPendingRequest ? 'Waiting on suppliers' : 'No offers'}
												</EmptyState.Title>
												<EmptyState.Description>
													{notReady
														? "We are gathering responses. Feel free to leave this page, we'll email you once all options are ready."
														: isPendingRequest
															? 'An offer request has been sent for this part.'
															: 'There are no offers for this part. Send request to suppliers to receive the offers.'}
												</EmptyState.Description>
											</EmptyState>
										</div>
									</CardContainer>
								</li>
							)}
						</ul>
					</div>
				</Accordion.Panel>
			</Accordion.Item>
		</Accordion>
	);
};

export default JobPartItem;
