import { flatten } from 'lodash-es';
import { OfferSelection, PartOfferAggregation, SellableOffer } from '../models';
import { getAssembly } from './get-assembly';
import { isDefined } from '@partly/js-ex';
import { offers_search } from '@partly/core-server-client';

export const getSelectedAssemblies = (
	partOfferAggregations: PartOfferAggregation[],
	offerSelection: OfferSelection
) => {
	const uniqueOfferIds: string[] = [];

	return flatten(
		partOfferAggregations.map(({ offers }) =>
			offers
				.filter(offer => offerSelection.has(offer.id))
				.map(offer => (getAssembly(offer) ? { offer, assembly: getAssembly(offer) } : null))
				.filter(item => isDefined(item) && isDefined(item.assembly))
				.reduce(
					(accu, item) => {
						if (!uniqueOfferIds.find(id => id === item!.offer.id)) {
							uniqueOfferIds.push(item!.offer.id);

							accu.push(
								item as {
									offer: SellableOffer;
									assembly: offers_search.Assembly;
								}
							);
						}
						return accu;
					},
					[] as {
						offer: SellableOffer;
						assembly: offers_search.Assembly;
					}[]
				)
		)
	);
};
