import { StorefrontResponse, createApi } from '@sdk/lib';
import { getConfig } from '../config';
import { session } from '@common/hooks/use-auth';

export const unwrap = async <T>(request: () => Promise<StorefrontResponse<T>>): Promise<T> => {
	const { data, error } = await request();
	if (error) {
		throw error;
	}

	return data;
};

export const getApi = async () => {
	const config = getConfig();

	const tokens = session.state.getTokens();
	if (tokens?.accessToken) {
		return createApi({
			...config,
			userToken: {
				token: tokens.accessToken
			}
		});
	}

	if (!tokens?.accessToken && tokens?.refreshToken) {
		const { data } = await session.actions.refreshToken();
		if (data?.accessToken) {
			return createApi({
				...config,
				userToken: {
					token: data.accessToken
				}
			});
		}
	}

	return createApi(config);
};
