// DO NOT MODIFY THIS FILE MANUALLY
// This file was generated by reflectapi-cli
//
// Schema name: repairer-server
// Partly Repairer Server

export function client(base: string | Client): __definition.Interface {
	return __implementation.__client(base);
}
/* <----- */
export interface Client {
	request(path: string, body: string, headers: Record<string, string>): Promise<[number, string]>;
}

export type NullToEmptyObject<T> = T extends null ? {} : T;

export type AsyncResult<T, E> = Promise<Result<T, Err<E>>>;

export type FixedSizeArray<T, N extends number> = Array<T> & { length: N };

export class Result<T, E> {
	constructor(private value: { ok: T } | { err: E }) {}

	public ok(): T | undefined {
		if ('ok' in this.value) {
			return this.value.ok;
		}
		return undefined;
	}
	public err(): E | undefined {
		if ('err' in this.value) {
			return this.value.err;
		}
		return undefined;
	}

	public is_ok(): boolean {
		return 'ok' in this.value;
	}
	public is_err(): boolean {
		return 'err' in this.value;
	}

	public map<U>(f: (r: T) => U): Result<U, E> {
		if ('ok' in this.value) {
			return new Result({ ok: f(this.value.ok) });
		} else {
			return new Result({ err: this.value.err });
		}
	}
	public map_err<U>(f: (r: E) => U): Result<T, U> {
		if ('err' in this.value) {
			return new Result({ err: f(this.value.err) });
		} else {
			return new Result({ ok: this.value.ok });
		}
	}

	public unwrap_ok(): T {
		if ('ok' in this.value) {
			return this.value.ok;
		}
		throw new Error(`called \`unwrap_ok\` on an \`err\` value: ${this.value.err}`);
	}
	public unwrap_err(): E {
		if ('err' in this.value) {
			return this.value.err;
		}
		throw new Error('called `unwrap_err` on an `ok` value');
	}

	public unwrap_ok_or_default(default_: T): T {
		if ('ok' in this.value) {
			return this.value.ok;
		}
		return default_;
	}
	public unwrap_err_or_default(default_: E): E {
		if ('err' in this.value) {
			return this.value.err;
		}
		return default_;
	}

	public unwrap_ok_or_else(f: (e: E) => T): T {
		if ('ok' in this.value) {
			return this.value.ok;
		}
		return f(this.value.err);
	}
	public unwrap_err_or_else(f: (v: T) => E): E {
		if ('err' in this.value) {
			return this.value.err;
		}
		return f(this.value.ok);
	}

	public toString(): string {
		if ('ok' in this.value) {
			return `Ok { ok: ${this.value.ok} }`;
		} else {
			return `Err { err: ${this.value.err} }`;
		}
	}
}

export class Err<E> {
	constructor(private value: { application_err: E } | { other_err: any }) {}

	public err(): E | undefined {
		if ('application_err' in this.value) {
			return this.value.application_err;
		}
		return undefined;
	}
	public other_err(): any | undefined {
		if ('other_err' in this.value) {
			return this.value.other_err;
		}
		return undefined;
	}

	public is_err(): boolean {
		return 'application_err' in this.value;
	}
	public is_other_err(): boolean {
		return 'other_err' in this.value;
	}

	public map<U>(f: (r: E) => U): Err<U> {
		if ('application_err' in this.value) {
			return new Err({ application_err: f(this.value.application_err) });
		} else {
			return new Err({ other_err: this.value.other_err });
		}
	}
	public unwrap(): E {
		if ('application_err' in this.value) {
			return this.value.application_err;
		} else {
			throw this.value.other_err;
		}
	}
	public unwrap_or_default(default_: E): E {
		if ('application_err' in this.value) {
			return this.value.application_err;
		}
		return default_;
	}
	public unwrap_or_else(f: () => E): E {
		if ('application_err' in this.value) {
			return this.value.application_err;
		}
		return f();
	}

	public toString(): string {
		if ('application_err' in this.value) {
			return `Application Error: ${this.value.application_err}`;
		} else {
			return `Other Error: ${this.value.other_err}`;
		}
	}
}

export function __request<I, H, O, E>(
	client: Client,
	path: string,
	input: I | undefined,
	headers: H | undefined
): AsyncResult<O, E> {
	let hdrs: Record<string, string> = {
		'content-type': 'application/json'
	};
	if (headers) {
		for (const [k, v] of Object.entries(headers)) {
			hdrs[k?.toString()] = v?.toString() || '';
		}
	}
	return client
		.request(path, JSON.stringify(input), hdrs)
		.then(([status, response_body]) => {
			if (status < 200 || status >= 300) {
				let parsed_response_body;
				try {
					parsed_response_body = JSON.parse(response_body);
				} catch (e) {
					return new Result<O, Err<E>>({
						err: new Err({ other_err: `[${status}] ${response_body}` })
					});
				}
				return new Result<O, Err<E>>({
					err: new Err({ application_err: parsed_response_body as E })
				});
			}

			let parsed_response_body;
			try {
				parsed_response_body = JSON.parse(response_body);
			} catch (e) {
				return new Result<O, Err<E>>({
					err: new Err({
						other_err:
							'internal error: failure to parse response body as json on successful status code: ' +
							response_body
					})
				});
			}
			return new Result<O, Err<E>>({ ok: parsed_response_body as O });
		})
		.catch(e => {
			return new Result<O, Err<E>>({ err: new Err({ other_err: e }) });
		});
}

class ClientInstance {
	constructor(private base: string) {}

	public request(
		path: string,
		body: string,
		headers: Record<string, string>
	): Promise<[number, string]> {
		return (globalThis as any)
			.fetch(`${this.base}${path}`, {
				method: 'POST',
				headers: headers,
				body: body
			})
			.then((response: any) => {
				return response.text().then((text: string) => {
					return [response.status, text];
				});
			});
	}
}

/* -----> */

export namespace __definition {
	export interface Interface {
		jobs: JobsInterface;
	}

	export interface JobsInterface {
		orders: OrdersInterface;
		external: ExternalInterface;
		baskets: BasketsInterface;
		offer_requests: OfferRequestInterface;
	}

	export interface OrdersInterface {
		/**
		 * List all orders for a job
		 */
		list: (
			input: order_handler.OrdersListRequest,
			headers: context.RepairerServerHeaders
		) => AsyncResult<Array<core_order.SupplierOrder>, core_order.OrdersGetError>;
		/**
		 * Insert a new order for a job
		 */
		insert: (
			input: order_handler.JobOrdersInsertRequest,
			headers: context.RepairerServerHeaders
		) => AsyncResult<core_order.OrdersMutationResponse, core_order.OrdersInsertError>;
		/**
		 * Cancel an order for a job
		 */
		cancel: (
			input: order_handler.JobOrdersCancelRequest,
			headers: context.RepairerServerHeaders
		) => AsyncResult<core_order.OrdersMutationResponse, core_order.OrdersCancelError>;
		items: ItemsInterface;
	}

	export interface ItemsInterface {
		/**
		 * Process order items
		 */
		process: (
			input: order_handler.JobOrdersItemsProcessRequest,
			headers: context.RepairerServerHeaders
		) => AsyncResult<core_order.OrdersMutationResponse, core_order.OrdersItemsProcessError>;
	}

	export interface ExternalInterface {
		/**
		 * Insert a job from an external source
		 */
		insert: (
			input: reflectapi_ex.types_complete.InsertOrElse<
				job_integration.JobExternalIngestBatch,
				reflectapi_ex.types_actions.IfExistsUpdateOrReplaceOrElse
			>,
			headers: context.RepairerServerHeaders
		) => AsyncResult<null, api_error.RepairerServerError<null>>;
	}

	export interface BasketsInterface {
		/**
		 * Returns the active basket or creates one if there is none
		 */
		get: (
			input: basket_handler.BasketGetRequest,
			headers: context.RepairerServerHeaders
		) => AsyncResult<
			response.NormalizedResponse<response.SingularItemPayload<basket.Basket>, null>,
			api_error.RepairerServerError<null>
		>;
		/**
		 * Update a specific basket on a job
		 */
		ingest: (
			input: basket_handler.BasketIngestRequest,
			headers: context.RepairerServerHeaders
		) => AsyncResult<
			response.NormalizedResponse<response.MultipleItemsPayload<resource.BasketLink>, null>,
			api_error.RepairerServerError<null>
		>;
	}

	export interface OfferRequestInterface {
		/**
		 * Get the offer request for a job
		 */
		get: (
			input: offer_request_handler.OfferRequestGetRequest,
			headers: context.RepairerServerHeaders
		) => AsyncResult<Array<offer_request.model.OfferRequest>, api_error.RepairerServerError<null>>;
		/**
		 * Insert the offer request for a job
		 */
		insert: (
			input: offer_request_handler.OfferRequestInsertRequest,
			headers: context.RepairerServerHeaders
		) => AsyncResult<Array<string /* uuid::Uuid */>, api_error.RepairerServerError<null>>;
		events: EventsInterface;
	}

	export interface EventsInterface {
		/**
		 * Insert the offer request for a job
		 */
		insert: (
			input: offer_request_handler.OfferRequestEventInsertRequest,
			headers: context.RepairerServerHeaders
		) => AsyncResult<string /* uuid::Uuid */, api_error.RepairerServerError<null>>;
	}
}
export namespace api_error {
	export type RepairerServerError<T = {}> =
		| {
				Application: T;
		  }
		| {
				Unauthorized: string | null;
		  }
		| {
				BadRequest: string;
		  }
		| {
				NotFound: string;
		  }
		| {
				InternalServerError: string;
		  };
}

export namespace basket {
	export interface Basket {
		/**
		 *  Id is the item that references a basket
		 */
		id: string /* uuid::Uuid */;
		/**
		 *  Items are the selected offers and quantity
		 */
		items: Array<basket.BasketItem>;
		/**
		 *  Updated_at changes on each new ingest after the first Insert.
		 */
		updated_at: date.Timestamp;
	}

	/**
	 *  Actions are good to show in the basket when the reapaier looks again. Aaron said that in cases
	 *  when there is rejection, repairers can be refutant to follow if a reason is not given for the
	 *  rejection
	 */
	export type BasketAssessorAction =
		/**
		 *  Approve is when an assessor approves a part selected by the repairer
		 */
		| { approve: {} }
		/**
		 *  Rejected is when an assessor do not approve a part selected by a repairer
		 */
		| { reject: {} };

	export type BasketIngest =
		/**
		 *  Update is for any changes on the already existing event, either from repairer or
		 *  assessor
		 */
		{
			update: basket.BasketUpdate;
		};

	export interface BasketItem {
		/**
		 *  Id for the item just added to the basket
		 */
		id: string /* uuid::Uuid */;
		/**
		 *  Quantity of parts for an certain offer. E.g: 3 bumpers from Toyota Manukau offer
		 */
		quantity: number /* u32 */;
		/**
		 *  Offer id is either custom item that had a offer requested or a sellable from our know
		 *  catalog
		 */
		offer_id: string /* uuid::Uuid */;
		/**
		 *  Intent corresponds to either the reapairer or the assessor. A repairer has the intent to
		 *  repair, the assessor has the intent to assess but it can approve, reject or ask for more
		 *  info
		 */
		intent: Array<basket.BasketItemIntent>;
	}

	export type BasketItemIngest =
		| {
				insert: basket.BasketItemInsert;
		  }
		| {
				update: basket.BasketItemUpdate;
		  }
		| {
				remove: string /* uuid::Uuid */;
		  };

	export interface BasketItemInsert {
		/**
		 *  Id for the item. The reason why is also in this insert as option, is because the Assessor
		 *  can assess this part but whith a new intent
		 */
		id?: string /* uuid::Uuid */ | null;
		/**
		 *  Quantity of parts for an certain offer. E.g: 3 bumpers from Toyota Manukau offer
		 */
		quantity: number /* u32 */;
		/**
		 *  Offer id is either custom item that had a offer requested or a sellable from our know
		 *  catalog
		 */
		offer_id: string /* uuid::Uuid */;
		/**
		 *  Intent corresponds to either the reapairer or the assessor. A repairer has the intent to
		 *  repair, the assessor has the intent to assess but it can approve, reject or ask for more
		 *  info
		 */
		intent: basket.BasketItemIntent;
	}

	export type BasketItemIntent =
		/**
		 *  Repair intent exits when a basket is inserted or updated by a repair. The intent is very
		 *  straight forward, and the note is sometimes to clarify for the assessor why a part was
		 *  selected.
		 */
		| {
				repair: {
					note: string | null;
				};
		  }
		/**
		 *  Assess intent exists when a basket is inserted or updated by an assessor. The intent can
		 *  have a few actions that the assessor can take on the part, like approve and reprove.
		 */
		| {
				assess: {
					note: string | null;
					action: basket.BasketAssessorAction;
				};
		  };

	export interface BasketItemUpdate {
		/**
		 *  Id for the item just added to the basket
		 */
		id: string /* uuid::Uuid */;
		/**
		 *  Quantity of parts for an certain offer. E.g: 3 bumpers from Toyota Manukau offer
		 */
		quantity?: number /* u32 */ | null;
		/**
		 *  Offer id is either custom item that had a offer requested or a sellable from our know
		 *  catalog
		 */
		offer_id?: string /* uuid::Uuid */ | null;
		/**
		 *  Intent corresponds to either the reapairer or the assessor. A repairer has the intent to
		 *  repair, the assessor has the intent to assess but it can approve, reject or ask for more
		 *  info
		 */
		intent?: basket.BasketItemIntent | null;
	}

	export interface BasketUpdate {
		id: string /* uuid::Uuid */;
		items: Array<basket.BasketItemIngest>;
	}
}

export namespace basket_handler {
	export interface BasketGetRequest {
		job_id: string /* uuid::Uuid */;
	}

	export interface BasketIngestRequest {
		job_id: string /* uuid::Uuid */;
		data: Array<basket.BasketIngest>;
	}
}

export namespace collision_map {
	export interface CollisionArea {
		id: string;
		name: string;
		part_slots: Array<collision_map.CollisionPartSlot>;
		collision_hcas: Array<string>;
	}

	export interface CollisionHca {
		id: string;
		name: string;
		part_slots: Array<collision_map.CollisionPartSlot>;
	}

	export interface CollisionMap {
		id: string;
		image_url: listing.RelativeUrl;
		description: string;
		collision_area_ids: Array<string>;
	}

	export interface CollisionPartSlot {
		part_slot: part_slot.PartSlot;
	}

	export type CollisionSeverity = 'Light' | 'Medium' | 'Heavy';
}

export namespace context {
	export interface RepairerServerHeaders {
		authorization: string | null;
		accept_language?: string | null;
		origin?: string | null;
		'partly-organization-id': string /* uuid::Uuid */ | null;
		'partly-storefront-key': string;
		'partly-uvdb-region-id': string | null;
		'partly-accept-currency': string | null;
	}
}

export namespace core_order {
	export interface ExternalPartIdentity {
		/**
		 *  The name of the brand (e.g. Toyota). Not super relevant
		 *  for OEMs that only have one brand.
		 */
		brand: string | null;
		mpn: string;
	}

	export type OrderBuyerDetails = {
		user: {
			user_id: string /* uuid::Uuid */;
			name: string;
			email: string | null;
		};
	};

	export interface OrderCancelData {
		note?: string | null;
	}

	export type OrderId = string;

	export interface OrderImage {
		original: listing.AbsoluteUrl;
	}

	export interface OrderInsertBuyerData {
		/**
		 *  If applicable it defines where to address invoices
		 */
		billing_address: std_ex.Address | null;
		/**
		 *  If applicable it defines where to deliver the order
		 */
		shipping_address: std_ex.Address;
		/**
		 *  Free text notes to the delivery company by the buyer
		 */
		shipping_instructions?: string | null;
		/**
		 *  If known the date when the items should be delivered to a buyer
		 *  If time of a date does not matter, a buyer can specify 23:59:59,
		 *  which would mean deliver the next day after the one specified
		 */
		deliver_after?: string | null;
		/**
		 *  If known the latest date when the items should be delivered
		 *  If time of a date does not matter, a buyer can specify 00:00:00,
		 *  which would mean deliver the day before specified
		 */
		deliver_before?: string | null;
		/**
		 *  Defines the currency all of the prices are in
		 */
		purchase_currency_code: string;
		/**
		 *  If known the total shipping price for all items
		 */
		total_shipping_price?: string | null;
		/**
		 *  Items included in the order
		 */
		items: Array<core_order.OrderItemOnBuyerInsert>;
		/**
		 *  A list of images relating to the job
		 */
		images?: Array<string> | null;
		/**
		 *  Zero or more vehicles related to an order
		 */
		vehicle_context?: Array<lookup_server_model.json_types.VehicleInstanceInfo> | null;
		/**
		 *  Order metadata
		 */
		metadata?: Record<string, string> | null;
		/**
		 *  A note from the repairer to the supplier
		 */
		note?: string | null;
	}

	export type OrderInsertData =
		/**
		 *  Insert an order for a supplier as a buyer (repairer)
		 */
		{
			buyer: core_order.OrderInsertBuyerData;
		};

	export type OrderItemBuyerProcess =
		/**
		 *  The item has been received by the buyer
		 */
		| {
				received: {
					/**
					 *  The time that the item was received
					 */
					timestamp: string;
					notes: string | null;
				};
		  }
		/**
		 *  The item has been accepted by the buyer (is the right part
		 *  and in the right condition)
		 */
		| {
				accepted: {
					notes: string | null;
				};
		  }
		/**
		 *  The buyer has requested to return the item
		 */
		| {
				return_requested: {
					quantity: number /* u32 */;
					reason: lookup_server_model.json_types.OrderItemReturnReason;
					notes: string | null;
				};
		  };

	export type OrderItemCondition = 'not_damaged';

	export interface OrderItemOnBuyerInsert {
		/**
		 *  Identity of an item included in an order
		 */
		identity: string;
		buyable: lookup_server_model.json_types.OrderItemBuyable;
		/**
		 *  Quantity ordered
		 */
		quantity: number /* u32 */;
		/**
		 *  If known, item specific purchase price
		 */
		purchase_price: string;
		/**
		 *  If known, item specific shipping price
		 */
		shipping_price?: string | null;
		/**
		 *  Repairer server will use this return policy to allow order line item status’ to be moved to Returned if needed
		 */
		return_policy?: lookup_server_model.json_types.ReturnPolicy | null;
		/**
		 *  The condition of the item being ordered
		 */
		condition?: lookup_server_model.json_types.ProductCondition | null;
		/**
		 *  A list of relative images for the part
		 */
		images?: Array<string> | null;
		part_selection_contexts?: Array<lookup_server_model.json_types.PartSelectionContext> | null;
	}

	export type OrderItemProcess =
		/**
		 *  Actions that can be performed by a repairer
		 */
		| {
				buyer: core_order.OrderItemBuyerProcess;
		  }
		| {
				supplier: core_order.OrderItemSupplierProcess;
		  };

	export interface OrderItemProcessData {
		identity: string;
		data: core_order.OrderItemProcess;
	}

	export type OrderItemReturnPolicy =
		/**
		 *  Order item cannot be returned
		 */
		| 'no_returns'
		/**
		 *  Order item can be returned if it satisfies condition and return date
		 */
		| {
				allow_returns: {
					condition: core_order.OrderItemCondition | null;
					before_date: string | null;
				};
		  };

	export type OrderItemSupplierProcess =
		| {
				return_accepted: {
					notes: string | null;
				};
		  }
		| {
				return_rejected: {
					reason: lookup_server_model.json_types.OrderItemReturnRejectedReason;
					notes: string | null;
				};
		  };

	export interface OrderItemsProcessData {
		items: Array<core_order.OrderItemProcessData>;
	}

	export type OrderStatusMetadata =
		/**
		 *  We are retring from a recoverable error
		 */
		| 'Retrying'
		/**
		 *  We are unable to recover.
		 */
		| 'Failed';

	export type OrderSupplierDetails = {
		organization: {
			org_id: string /* uuid::Uuid */;
			name: string;
		};
	};

	export type OrdersCancelError =
		| {
				unauthorized: core_server_auth.service.AuthError;
		  }
		| {
				internal: string;
		  }
		| 'not_found'
		| {
				invalid_order_status: {
					expected: Array<string>;
					actual: string | null;
				};
		  };

	export type OrdersGetError =
		| {
				unauthorized: core_server_auth.service.AuthError;
		  }
		| {
				internal: string;
		  }
		| 'not_found';

	export type OrdersInsertError =
		| {
				unauthorized: core_server_auth.service.AuthError;
		  }
		| {
				internal: string;
		  }
		| {
				duplicate: {
					id: string /* uuid::Uuid */;
					external_id: string;
				};
		  }
		| {
				invalid_currency_code: string;
		  }
		| 'empty_currency_code'
		| {
				invalid_order_status: {
					expected: string;
					actual: string | null;
				};
		  };

	export type OrdersItemsProcessError =
		| {
				unauthorized: core_server_auth.service.AuthError;
		  }
		| {
				internal: string;
		  }
		| 'not_found'
		| {
				invalid_order_status: {
					expected: string;
					actual: string | null;
				};
		  }
		| {
				order_item_not_found: string;
		  }
		| {
				invalid_order_item_status: {
					expected: Array<string>;
					actual: string | null;
				};
		  }
		| {
				invalid_order_item_return_quantity: {
					available: number /* u32 */ | null;
					given: number /* u32 */;
				};
		  };

	export type OrdersMutationResponse = {} & NullToEmptyObject<core_order.SupplierOrder>;

	export interface SupplierOrder {
		/**
		 *  Id of an order at Partly system
		 */
		id: string /* uuid::Uuid */;
		/**
		 *  Id of an order at external system
		 */
		external_id?: string | null | undefined;
		/**
		 *  The current status of the order
		 */
		status: lookup_server_model.json_types.SupplierOrderStatus;
		/**
		 *  Time when the order was created
		 */
		created_at: string;
		/**
		 *  Time when the order was last updated
		 */
		updated_at: string;
		/**
		 *  Time when the order was marked as read for store users
		 *  i.e. it does not allow to track who read it, but only when it was read
		 *  This allows PIM to show what orders have not been read by store users
		 */
		read_at?: string | null | undefined;
		/**
		 *  If applicable it defines where to address invoices
		 */
		billing_address?: std_ex.Address | null | undefined;
		/**
		 *  If applicable it defines where to deliver the order
		 */
		shipping_address?: std_ex.Address | null | undefined;
		/**
		 *  Free text notes to the delivery company by the buyer
		 */
		shipping_instructions?: string | null | undefined;
		/**
		 *  If known the date when the items should be delivered to a buyer
		 *  If time of a date does not matter, a buyer can specify 23:59:59,
		 *  which would mean deliver the next day after the one specified
		 */
		deliver_after?: string | null | undefined;
		/**
		 *  If known the latest date when the items should be delivered
		 *  If time of a date does not matter, a buyer can specify 00:00:00,
		 *  which would mean deliver the day before specified
		 */
		deliver_before?: string | null | undefined;
		/**
		 *  Defines the currency all of the prices are in
		 */
		purchase_currency_code?: string | null | undefined;
		/**
		 *  If known the total purchase price for all items, discounts and negotiations included
		 */
		total_purchase_price?: string | null | undefined;
		/**
		 *  If known the total shipping price for all items
		 */
		total_shipping_price?: string | null | undefined;
		/**
		 *  Items included in the order
		 */
		items: Array<core_order.SupplierOrderItem>;
		/**
		 *  A list of images relating to the job
		 */
		images?: Array<string> | null | undefined;
		/**
		 *  Associated metadata with the order
		 */
		metadata?: Record<string, string> | null | undefined;
		/**
		 *  Zero or more vehicles related to an order
		 */
		vehicle_context?: Array<lookup_server_model.json_types.VehicleInstanceInfo> | null | undefined;
		buyer_contact?: core_order.OrderBuyerDetails | null | undefined;
		supplier_contact?: core_order.OrderSupplierDetails | null | undefined;
	}

	export interface SupplierOrderItem {
		/**
		 *  Identity of an item included in an order
		 */
		identity: string;
		/**
		 *  The item being purchased
		 */
		buyable: lookup_server_model.json_types.OrderItemBuyable;
		/**
		 *  Quantity ordered
		 */
		quantity?: number /* u32 */ | null | undefined;
		/**
		 *  If known, item specific purchase price
		 */
		purchase_price?: string | null | undefined;
		/**
		 *  If known, item specific shipping price
		 */
		shipping_price?: string | null | undefined;
		/**
		 *  Contains info about gapc part and sellable
		 */
		snapshot?: lookup_server_model.json_types.SupplierOrderItemSnapshot | null | undefined;
		/**
		 *  Order line item status, if relevant
		 */
		status: lookup_server_model.json_types.SupplierOrderItemStatus | null | undefined;
		/**
		 *  Repairer server will use this return policy to allow order line item status’ to be moved to Returned if needed
		 */
		return_policy?: lookup_server_model.json_types.ReturnPolicy | null | undefined;
		/**
		 *  The condition of the line item if known
		 */
		condition?: lookup_server_model.json_types.ProductCondition | null | undefined;
		/**
		 *  A list of relative images for the part
		 */
		images: Array<string> | null;
		part_selection_contexts?:
			| Array<lookup_server_model.json_types.PartSelectionContext>
			| null
			| undefined;
	}
}

export namespace core_server_auth {
	export namespace service {
		export type AuthError =
			| {
					custom: string;
			  }
			| {
					invalid_token: string;
			  }
			| 'invalid_email_or_password'
			| {
					revoked_token: string;
			  }
			| 'invalid_grant'
			| {
					no_authorization_details: string;
			  }
			| {
					internal: string;
			  };
	}
}

export namespace date {
	export type Timestamp = string;
}

export namespace draft_order {
	export interface AutoTransitionOrder {
		/**
		 *  Whether or not to attempt
		 */
		enabled: boolean;
		/**
		 *  The error message if the auto transition failed.
		 *  This field is deprecated.
		 */
		error: string | null;
	}

	export interface DraftOrder {
		id: core_order.OrderId;
		vendor: draft_order.DraftOrderVendor;
		status: draft_order.DraftOrderStatus;
		items: Array<draft_order.DraftOrderItem>;
		delivery_location: shipping.ShippingAddress;
		images: Array<core_order.OrderImage>;
		vendor_notes: string | null;
		estimator_notes: string | null;
		target_deliver_before_timestamp: date.Timestamp;
		attempt_auto_transition_order: draft_order.AutoTransitionOrder;
		created_at: date.Timestamp;
		updated_at: date.Timestamp | null;
	}

	export interface DraftOrderExternalItem {
		/**
		 *  The part name / description / from HCA tree
		 *  Provided by estimator.
		 */
		description: string;
		identity: draft_order.DraftOrderItemExternalIdentity | null;
	}

	export interface DraftOrderItem {
		id: string /* uuid::Uuid */;
		quantity: number /* u32 */;
		price: money.Money | null;
		grade: listing.ListingGrade | null;
		arrival_at: date.Timestamp | null;
		buyable: draft_order.DraftOrderItemBuyable;
		status: draft_order.DraftOrderItemStatus;
		context: part_slot.PartSelectionContexts | null;
		order_separately: boolean;
		return_policy: core_order.OrderItemReturnPolicy | null;
	}

	export interface DraftOrderItemApproved {
		reason: draft_order.DraftOrderItemApprovedReason;
		details: string | null;
	}

	export type DraftOrderItemApprovedReason =
		/**
		 *  Supplier can approve part for a reason, with optional extra context.
		 */
		| {
				Supplier: draft_order.DraftOrderItemSupplierApprovedReason | null;
		  }
		/**
		 *  Estimator chose to force override supplier.
		 *  This is used for incorrect fitment / not needed. Estimator
		 *  cannot override all supplier rejections.
		 */
		| 'Estimator';

	export type DraftOrderItemBuyable =
		/**
		 *  A matched listing that is in our system.
		 */
		| ({ type: 'Listing' } & draft_order.DraftOrderListingItem)

		/**
		 *  A part that is not in our system, so describe it best
		 *  we can for supplier to find and quote for it.
		 */
		| ({ type: 'External' } & draft_order.DraftOrderExternalItem)

		/**
		 *  A Sellable can be either a Product or a Assembly.
		 */
		| ({ type: 'Sellable' } & draft_order.DraftOrderSellableItem);

	export type DraftOrderItemEstimatorRejectedReason =
		/**
		 *  Estimator decided part was not wanted. This may be due
		 *  to price / availability / quality.
		 */
		'NotWanted';

	export type DraftOrderItemExternalIdentity =
		/**
		 *  The part sku (primiarly used by dismantler suppliers)
		 */
		| {
				Sku: string;
		  }
		/**
		 *  The mpn / brand id (primarily used by OEM suppliers)
		 */
		| {
				Part: core_order.ExternalPartIdentity;
		  };

	export interface DraftOrderItemRejected {
		reason: draft_order.DraftOrderItemRejectedReason;
		details: string | null;
	}

	export type DraftOrderItemRejectedReason =
		| {
				Supplier: draft_order.DraftOrderItemSupplierRejectedReason;
		  }
		| {
				Estimator: draft_order.DraftOrderItemEstimatorRejectedReason;
		  };

	export type DraftOrderItemStatus =
		/**
		 *  The part hasn\'t been sent to the supplier yet or is
		 *  waiting for the supplier to review it.
		 */
		| 'Pending'
		/**
		 *  The estimator or supplier has rejected the part.
		 */
		| {
				Rejected: draft_order.DraftOrderItemRejected;
		  }
		/**
		 *  The estimator or supplier has approved the part.
		 */
		| {
				Approved: draft_order.DraftOrderItemApproved;
		  };

	export type DraftOrderItemSupplierApprovedReason =
		/**
		 *  Part is a replacement for another part
		 */
		'Replacement';

	export type DraftOrderItemSupplierRejectedReason =
		/**
		 *  Part is not in stock
		 */
		| 'OutOfStock'
		/**
		 *  Part has never been sold by the supplier
		 */
		| 'NoSupply'
		/**
		 *  Supplier believes part is not correct for the vehicle
		 */
		| 'IncorrectFitment'
		/**
		 *  Supplier doesn\'t believe part is required. This may be because
		 *  they have added another part to replace it. todo: do we want a specific state
		 *  for this?
		 */
		| 'NotNeeded'
		/**
		 *  supplier says this part is discontinued
		 */
		| 'Discontinued';

	export interface DraftOrderListingItem {
		offer: supply.SupplyItemOffer;
	}

	export interface DraftOrderSellableItem {
		sellable_id: string /* uuid::Uuid */;
	}

	export type DraftOrderStatus =
		/**
		 *  The estimator is still working on the draft, and hasn\'t
		 *  been sent yet.
		 */
		| 'Draft'
		/**
		 *  The estimator has sent the draft to the supplier and
		 *  is awaiting a response. No changes can be made until it
		 *  has been processed.
		 */
		| {
				Processing: core_order.OrderStatusMetadata | null;
		  }
		/**
		 *  The supplier has processed the draft order, and changes
		 *  are ready for review by the estimator.
		 */
		| 'Processed'
		/**
		 *  The estimator has reviewed any changes and has promoted
		 *  the draft a finalised order.
		 */
		| 'Finalised'
		/**
		 *  The estimator cancelled the draft.
		 */
		| {
				Cancelled: core_order.OrderStatusMetadata | null;
		  };

	export type DraftOrderVendor =
		/**
		 *  Onboarded supplier with stock in our system
		 */
		{
			Partner: string /* uuid::Uuid */;
		};
}

export namespace gapc_assembly {
	export interface GapcAssembly {
		id: string /* uuid::Uuid */;
		name: string;
		part: gapc_assembly.GapcAssemblyPart | null;
		gapc_diagrams_ids: Array<string /* uuid::Uuid */>;
	}

	export interface GapcAssemblyPart {
		description: string | null;
		gapc_part_identity: gapc_part.GapcPartIdentity | null;
		part_slot: part_slot.PartSlot | null;
	}

	export interface GapcDiagram {
		id: string /* uuid::Uuid */;
		name: string;
		code: string;
		image: gapc_assembly.GapcImage;
		fitment: Array<tier1.VehicleProperty>;
		part_slots: Array<gapc_assembly.GapcDiagramPartSlot>;
		references: Array<gapc_assembly.GapcDiagramReference>;
	}

	export interface GapcDiagramHotSpot {
		x1_px: number /* i64 */;
		x2_px: number /* i64 */;
		y1_px: number /* i64 */;
		y2_px: number /* i64 */;
	}

	export interface GapcDiagramPartSlot {
		id: string /* uuid::Uuid */;
		code: string;
		hotspots: Array<gapc_assembly.GapcDiagramHotSpot>;
		parts: Array<gapc_assembly.GapcAssemblyPart>;
		assemblies: Array<string /* uuid::Uuid */>;
		sub_assemblies: Array<string /* uuid::Uuid */>;
		segments: Array<gapc_assembly.GapcDiagramSegmentPolygon>;
	}

	export interface GapcDiagramPolygonVector {
		x: number /* i64 */;
		y: number /* i64 */;
	}

	export interface GapcDiagramReference {
		id: string /* uuid::Uuid */;
		figure_id: string;
		hotspot: gapc_assembly.GapcDiagramHotSpot;
		segments: Array<gapc_assembly.GapcDiagramSegmentPolygon>;
	}

	export interface GapcDiagramSegmentPolygon {
		vectors: Array<gapc_assembly.GapcDiagramPolygonVector>;
	}

	export interface GapcImage {
		thumb: listing.AbsoluteUrl;
		large: listing.AbsoluteUrl;
	}
}

export namespace gapc_common {
	export interface GapcAttribute {
		id: string;
		name: string;
		data_type: gapc_common.GapcAttributeDataTypes;
	}

	export type GapcAttributeDataTypes = 'Boolean' | 'String' | 'Numeric' | 'NumericRange' | 'Date';

	export type GapcAttributeVal =
		| {
				String: string;
		  }
		| {
				Bool: boolean;
		  }
		| {
				Float: number /* f64 */;
		  }
		| {
				NumericRange: [number /* f64 */, number /* f64 */];
		  }
		| {
				Integer: number /* i64 */;
		  };

	export interface GapcAttributeValue {
		id: string /* uuid::Uuid */;
		gapc_attribute_id: string;
		value: gapc_common.GapcAttributeVal;
	}

	export interface GapcBrand {
		id: string;
		name: string;
		is_oem: boolean;
	}

	export interface GapcCategory {
		id: string;
		name: string;
		vehicle_type_id: string;
	}

	export interface GapcCommonProperty {
		id: string;
		name: string;
	}

	export interface GapcPartType {
		id: string;
		name: string;
		aliases: Array<uvdb_gapc_common.UvdbGapcI18nName>;
		/**
		 * @deprecated to be removed after frontend changes
		 */
		gapc_properties: Array<string>;
		categorizations: Array<gapc_common.GapcPartTypeCategorization>;
		uvdb_property_prefixes: Array<suid_rs.suid_prefix.UvdbSuidPrefix>;
	}

	export interface GapcPartTypeCategorization {
		subcategory_id: string;
		category_id: string;
	}

	export type GapcProperty =
		| ({ kind: 'Brand' } & gapc_common.GapcBrand)
		| ({ kind: 'Attribute' } & gapc_common.GapcAttribute)
		| ({ kind: 'PartType' } & gapc_common.GapcPartType)
		| ({ kind: 'Category' } & gapc_common.GapcCategory)
		| ({ kind: 'Other' } & gapc_common.GapcCommonProperty);
}

export namespace gapc_part {
	/**
	 *  shared listing info between kit/product/variant
	 */
	export type GapcPart = {
		original_mpn: string | null;
		is_oe: boolean;
		is_universal: boolean;
		gapc_parent_id: string /* uuid::Uuid */ | null;
		gapc_properties: Array<string>;
		gapc_attributes: Array<gapc_common.GapcAttributeValue>;
		measurement: gapc_part.Measurement | null;
		/**
		 *  This field store\'s every possible positions this part can fit, including the ones in custom restrictions
		 */
		available_gapc_positions: Array<string>;
	} & NullToEmptyObject<gapc_part.GapcPartIdentity>;

	/**
	 *  BrandId, PartNumber
	 */
	export interface GapcPartIdentity {
		gapc_brand_id: string;
		mpn: string;
	}

	export interface Measurement {
		width_mm: number /* u32 */ | null;
		height_mm: number /* u32 */ | null;
		length_mm: number /* u32 */ | null;
		weight_g: number /* u32 */ | null;
	}
}

export namespace i18n {
	export type WithI18n<T> = {} & NullToEmptyObject<T>;
}

export namespace job {
	export interface CollisionAreaSeverity {
		id: string;
		severity: collision_map.CollisionSeverity;
		hcas?: Array<string>;
	}

	export interface Job {
		repairer_org_id: string /* uuid::Uuid */;
		repairer_site_id: string /* uuid::Uuid */;
		id: string /* uuid::Uuid */;
		collisions: Array<job.CollisionAreaSeverity>;
		created_at: date.Timestamp | null;
		completed_at: date.Timestamp | null;
		status: job.JobStatus;
		job_number: string | null;
		claim_number: string | null;
		/**
		 *  The id of the external system
		 */
		external_id: string | null;
		/**
		 * @deprecated Use external_id instead
		 */
		bms_id: string | null;
		source: job.JobSource;
		vehicle: job.JobVehicle | null;
		added_parts_count: number /* u64 */;
		delivery_before_at: date.Timestamp | null;
		closing_reason: job.JobClosingReason | null;
		images: Array<job.JobImage>;
	}

	export interface JobClosingReason {
		reason: string;
		note: string | null;
	}

	export interface JobImage {
		original: listing.AbsoluteUrl;
	}

	export type JobModelMetadata = any /* serde_json::Value */;

	export type JobPartMetadata = any /* serde_json::Value */;

	export type JobSource = 'iBodyShop' | 'Partly' | 'Audatex';

	export type JobStatus =
		| 'Draft'
		| 'Active'
		| 'Ordering'
		| 'Completed'
		| 'Closed'
		/**
		 *  Ordered the Supplier confirmed all orders and it they are going to be delivered
		 */
		| 'Ordered'
		/**
		 *  NeedsAttention the Supplier received the order, but it did not confirm yet
		 */
		| 'NeedsAttention'
		| 'Deleted';

	export interface JobType {
		id: string;
		name: string;
	}

	export interface JobVehicle {
		plate_state: string | null;
		plate_number: string | null;
		chassis_number: string | null;
		variant: job.JobVehicleVariant | null;
		metadata: job.JobVehicleMetadata | null;
	}

	export type JobVehicleMetadata = any /* serde_json::Value */;

	export interface JobVehicleVariant {
		token: tier1.VehicleFilterToken | null;
		description: string;
		uvdb_properties: Array<string>;
	}

	export interface PartSupplySummary {
		/**
		 *  Whether there is any supply available for this part
		 */
		availability: listing.Availability;
		/**
		 *  References to matching listings
		 */
		listings: Array<resource.ListingLink>;
		/**
		 *  Types of supply available for this part
		 */
		listing_types: Array<listing.ListingKind>;
		images: Array<listing.ListingImage>;
		/**
		 *  Sample of part-numbers matching this part slot
		 */
		part_numbers: Array<string>;
		/**
		 *  Price aggregation
		 */
		price: listing.ListingPricing | null;
		/**
		 *  Shipping aggregation
		 */
		shipping: shipping.ShippingTime | null;
		/**
		 *  Grades of supply available for this part
		 */
		grades: Array<listing.ListingGrade>;
	}
}

export namespace job_integration {
	export interface JobExternalImage {
		full: listing.AbsoluteUrl;
		thumb: listing.AbsoluteUrl;
	}

	export interface JobExternalInfo {
		job_number: string | null;
		claim_number: string | null;
		metadata: job.JobModelMetadata | null;
	}

	export interface JobExternalIngestBatch {
		data: Array<
			reflectapi_ex.types_identity.IdentityData<string, job_integration.JobExternalPayload>
		>;
		options: job_integration.JobExternalSyncOptions;
	}

	export interface JobExternalPart {
		mpn: string;
		description: string;
		quantity: number /* u8 */ | null;
		price: money.Money | null;
		currency: money.Currency | null;
		deliver_before: string | null;
		metadata: job.JobPartMetadata | null;
	}

	export interface JobExternalPayload {
		repairer_site_id: string /* uuid::Uuid */;
		source: job.JobSource;
		job: job_integration.JobExternalInfo | null;
		vehicle: job_integration.JobExternalVehicle | null;
		parts: Array<job_integration.JobExternalPart> | null;
		images: Array<job_integration.JobExternalImage> | null;
	}

	export interface JobExternalSyncOptions {
		need_estimation?: boolean;
	}

	export interface JobExternalVehicle {
		plate_state: string | null;
		plate_number: string | null;
		chassis_number: string | null;
		metadata: job.JobVehicleMetadata | null;
	}
}

export namespace listing {
	export type AbsoluteUrl = string;

	export type Availability = 'InStock' | 'OutOfStock' | 'NoSupply';

	/**
	 *  shared listing info between kit/product/variant
	 */
	export interface BaseListing<Img> {
		id: string /* uuid::Uuid */;
		gapc_part_id: string /* uuid::Uuid */ | null;
		name: string;
		sku: string | null;
		images: Array<Img>;
		tags?: Array<string>;
		created_at: date.Timestamp | null;
		updated_at: date.Timestamp | null;
	}

	export type Kit = {
		products: Array<listing.Product>;
		pricing: listing.ListingPricing;
		description: string | null;
		availability: listing.Availability;
	} & NullToEmptyObject<listing.BaseListing<listing.ListingImage>> &
		gapc_part.GapcPart;

	export type Listing =
		| ({ kind: 'Kit' } & listing.Kit)
		| ({ kind: 'VariableProduct' } & listing.VariableProduct)
		| ({ kind: 'Product' } & listing.Variant);

	export type ListingGrade = '0' | 'A' | 'B' | 'C' | 'D';

	export interface ListingImage {
		thumb: listing.AbsoluteUrl;
		large: listing.AbsoluteUrl;
	}

	export type ListingKind = 'Kit' | 'VariableProduct' | 'Product';

	/**
	 *  # An aggregation of the pricing with min and max
	 *
	 *  For variant, min/max is the lowest/highest price from the supplier
	 *
	 *  For Product, min/max is the lowest/highest price of variants
	 *
	 *  For Kit, min/max is the lowest/highest of products combined, lowest exclude optional products as well
	 */
	export interface ListingPricing {
		min: money.Money;
		max: money.Money;
		display_min: money.Money | null;
		display_max: money.Money | null;
		currency: money.Currency;
	}

	export type OfferId = string;

	export type Product =
		| ({ kind: 'VariableProduct' } & listing.VariableProduct)
		| ({ kind: 'Product' } & listing.Variant);

	export type RelativeUrl = string;

	export interface SupplyInventory {
		location_id: string /* uuid::Uuid */;
		availability: listing.Availability;
	}

	export interface SupplyLocation {
		id: string /* uuid::Uuid */;
		vendor_id: string /* uuid::Uuid */;
		name: string;
	}

	export interface SupplyVendor {
		id: string /* uuid::Uuid */;
		name: string;
	}

	export type VariableProduct = {
		/**
		 *  if kit_id is not null, it means this is a variable product and links to a kit, otherwise this is the root of the listing
		 */
		kit_id?: string /* uuid::Uuid */ | null;
		variants: Array<listing.Variant>;
		pricing: listing.ListingPricing;
		description: string | null;
		availability: listing.Availability;
	} & NullToEmptyObject<listing.BaseListing<listing.ListingImage>> &
		gapc_part.GapcPart;

	/**
	 *  This represent a single variant or a non-variable product
	 */
	export type Variant = {
		/**
		 *  if kit_id is not null, this variant belongs to a kit, depending on the product_id, it means this is a variant or a non-variable product
		 */
		kit_id?: string /* uuid::Uuid */ | null;
		/**
		 *  if product_id is not null, this is a variant belong to a product, otherwise it\'s a non-variable product
		 */
		product_id?: string /* uuid::Uuid */ | null;
		/**
		 *  Aggregated pricing for this variant across all supplies
		 */
		pricing: listing.ListingPricing;
		description: string | null;
		/**
		 *  Availability for this variant from any supply
		 */
		availability: listing.Availability;
		/**
		 *  All available grades for this variant
		 */
		grades: Array<listing.ListingGrade>;
		/**
		 *  Options for supply for this variant
		 *  Normally a variant will have a single supplier/vendor but we can support multiple if needed.
		 */
		supplies: Array<listing.VariantSupply>;
	} & NullToEmptyObject<listing.BaseListing<listing.ListingImage>> &
		gapc_part.GapcPart;

	export interface VariantSupply {
		/**
		 *
		 *  Represents a distinct offer that can be purchased
		 *
		 */
		offer_id: listing.OfferId;
		vendor_id: string /* uuid::Uuid */;
		/**
		 *
		 *  A location the product can be shipped from
		 *  Useful to display shipping information to the buyer
		 *
		 */
		inventory: Array<listing.SupplyInventory>;
		price: money.Money;
		display_price: money.Money | null;
		grade: listing.ListingGrade | null;
		availability: listing.Availability;
	}
}

export namespace lookup {
	export namespace model {
		export namespace sellable {
			export type PanelUsedConditionGrade =
				/**
				 *  Like New
				 */
				| 'grade0'
				/**
				 *  Existing damage less than the size of a Credit Card
				 */
				| 'grade_a'
				/**
				 *  Existing damage less than the size of 2 Credit Cards
				 */
				| 'grade_b'
				/**
				 *  Existing damage greater than the size of 2 Credit Cards
				 */
				| 'grade_c';

			export type SellableCondition =
				| 'new'
				| {
						used: lookup.model.sellable.UsedConditionGrade;
				  };

			export type UsedConditionGrade =
				| {
						panel: lookup.model.sellable.PanelUsedConditionGrade;
				  }
				| {
						distance_traveled: number /* u32 */;
				  }
				| {
						operation_hours: number /* u32 */;
				  }
				| {
						remaining_thickness: number /* f64 */;
				  };
		}
	}
}

export namespace lookup_server_model {
	export namespace json_types {
		/**
		 *  BrandId, PartNumber
		 */
		export interface GapcPartIdentity {
			gapc_brand_id: string;
			mpn: string;
		}

		export type HcaPath = {
			text: string;
		};

		export type OrderItemBuyable =
			/**
			 *  Represents a reference to stock item,
			 *  which is tracked by Partly system
			 */
			| {
					type: 'store';
					/**
					 *  The id of the stock item
					 */
					sellable_id: string /* uuid::Uuid */;
					/**
					 *  The id of the inventory location
					 */
					store_address_id: string /* uuid::Uuid */ | null;
			  }
			/**
			 *  Represents a reference to stock item,
			 *  which is not tracked by Partly system
			 *  (i.e. a buyer / seller put one for the order to fill in gaps)
			 */
			| ({ type: 'external' } & lookup_server_model.json_types.OrderItemBuyableExternalIdentity);

		export interface OrderItemBuyableExternalIdentity {
			/**
			 *  The part name / description / from HCA tree
			 *  Provided by estimator.
			 */
			description: string;
			identity?: lookup_server_model.json_types.OrderItemBuyablePartIdentity | null;
		}

		export type OrderItemBuyablePartIdentity =
			/**
			 *  The part sku (primiarly used by dismantler suppliers)
			 */
			| string

			/**
			 *  The mpn / brand id (primarily used by OEM suppliers)
			 */
			| {
					/**
					 *  The name of the brand (e.g. Toyota). Not super relevant
					 *  for OEMs that only have one brand.
					 */
					brand: string | null;
					mpn: string;
			  };

		export type OrderItemCondition = 'not_damaged';

		export type OrderItemReturnDecision =
			/**
			 *  The return has not yet been reviewed. We will use this to
			 *  call attention to order.
			 */
			| 'undecided'
			/**
			 *  The return has been accepted
			 */
			| {
					accepted: {
						reviewer_role: lookup_server_model.json_types.OrderItemReturnDecisionRole;
						notes: string | null;
					};
			  }
			/**
			 *  The return has been rejected
			 */
			| {
					rejected: {
						reviewer_role: lookup_server_model.json_types.OrderItemReturnDecisionRole;
						reason: lookup_server_model.json_types.OrderItemReturnRejectedReason;
						notes: string | null;
					};
			  };

		export type OrderItemReturnDecisionRole = 'supplier';

		export type OrderItemReturnReason =
			/**
			 *  The part ended up not be needed for
			 *  the repair
			 */
			| 'not_required'
			/**
			 *  The part was not what was ordered
			 *  (e.g. wrong part number, color, etc)
			 */
			| 'incorrectly_supplied'
			/**
			 *  The part was correctly supplied, but the
			 *  part doesn\'t fit the vehicle
			 */
			| 'incorrect_fitment'
			/**
			 *  The part was damaged in transit
			 */
			| 'damaged'
			/**
			 *  The part was ordered with x quanity but
			 *  received less than x.
			 */
			| 'short_supplied'
			/**
			 *  Generic other reason
			 */
			| 'other';

		export type OrderItemReturnRejectedReason =
			/**
			 *  The part was returned outside of the return policy
			 *  (e.g. after specific date, or not meeting certain conditions)
			 */
			| 'out_of_return_policy'
			/**
			 *  The part was returned damaged
			 */
			| 'damaged'
			/**
			 *  The part is unable to be returned (e.g. back order part)
			 */
			| 'not_returnable'
			/**
			 *  Generic catch all for other policy violations
			 */
			| 'other';

		export type PanelUsedConditionGrade =
			/**
			 *  Like New
			 */
			| 'grade0'
			/**
			 *  Existing damage less than the size of a Credit Card
			 */
			| 'grade_a'
			/**
			 *  Existing damage less than the size of 2 Credit Cards
			 */
			| 'grade_b'
			/**
			 *  Existing damage greater than the size of 2 Credit Cards
			 */
			| 'grade_c';

		export type PartSelectionContext = {
			/**
			 * A description of the part based on the diagram or HCA tree. It is localised
			 * based on the origin language of the user.
			 */
			description?: string | null;
			/**
			 * The id of the part in the HCA tree, related to description
			 * but can be used to get more information e.g. i18n
			 */
			ghca_id?: string | null;
			/**
			 * Path to the part in the HCA tree.
			 * [deprecated] - prefer description + ghca_id
			 */
			hca_path?: lookup_server_model.json_types.HcaPath | null;
		} & Partial<lookup_server_model.json_types.GapcPartIdentity> &
			Partial<lookup_server_model.json_types.PartSlot>;

		export interface PartSlot {
			gapc_part_type_id: string;
			gapc_position_id?: string | null;
		}

		export type ProductCondition =
			| 'new'
			| {
					used: lookup_server_model.json_types.UsedConditionGrade;
			  };

		export type ReturnPolicy =
			/**
			 *  Order item cannot be returned
			 */
			| 'no_returns'
			/**
			 *  Order item can be returned if it satisfies condition and return date
			 */
			| {
					allow_returns: {
						condition: lookup_server_model.json_types.OrderItemCondition | null;
						before_date: string | null;
					};
			  };

		export interface SupplierOrderItemSnapshot {}

		export type SupplierOrderItemStatus =
			/**
			 *  The item has been received by the buyer
			 */
			| {
					received: {
						/**
						 *  The time that the item was received
						 */
						timestamp: string;
						notes: string | null;
					};
			  }
			/**
			 *  The item has been accepted by the buyer (is the right part
			 *  and in the right condition)
			 */
			| {
					accepted: {
						notes: string | null;
					};
			  }
			/**
			 *  The item has been returned by the buyer, and the supplier
			 *  has made a decision on the return
			 */
			| {
					returned: {
						quantity: number /* u32 */;
						reason: lookup_server_model.json_types.OrderItemReturnReason;
						notes: string | null;
						decision: lookup_server_model.json_types.OrderItemReturnDecision;
					};
			  };

		export type SupplierOrderStatus =
			| {
					ordered: {
						note: string | null;
					};
			  }
			| {
					confirmed: {
						note: string | null;
					};
			  }
			| {
					cancelled: {
						note: string | null;
					};
			  };

		export type UsedConditionGrade =
			| {
					panel: lookup_server_model.json_types.PanelUsedConditionGrade;
			  }
			| {
					distance_traveled: number /* u32 */;
			  }
			| {
					operation_hours: number /* u32 */;
			  }
			| {
					remaining_thickness: number /* f64 */;
			  };

		export interface VehicleInstanceInfo {
			plate_state?: string | null;
			plate_number?: string | null;
			chassis_number?: string | null;
			name: string;
		}
	}
}

export namespace money {
	export type Currency = string;

	/**
	 *  Store money as cents * 10^3 so 10.05 becomes 10050
	 */
	export type Money = number /* u64 */;
}

export namespace offer_request {
	export namespace model {
		export interface OfferRequest {
			id: string /* uuid::Uuid */;
			store_id: string /* uuid::Uuid */;
			events: Array<offer_request.model.OfferRequestEvent>;
			created_at: string;
		}

		export interface OfferRequestEvent {
			id: string /* uuid::Uuid */;
			subject: offer_request_model.model.event.Subject;
			kind: offer_request_model.model.event.OfferRequestEventKind;
			note?: string | null;
			created_at: string;
		}
	}
}

export namespace offer_request_handler {
	export interface OfferRequestEventInsertRequest {
		job_id: string /* uuid::Uuid */;
		request_id: string /* uuid::Uuid */;
		shapshot_hash: string;
		images: Array<listing.RelativeUrl> | null;
		/**
		 *  A list of offer ids that the repairer has selected to fulfill the request
		 */
		offer_ids: Array<string> | null;
		note: string | null;
	}

	export interface OfferRequestGetRequest {
		job_id: string /* uuid::Uuid */;
	}

	export interface OfferRequestInsertRequest {
		job_id: string /* uuid::Uuid */;
		store_ids: Array<string /* uuid::Uuid */>;
	}
}

export namespace offer_request_model {
	export namespace model {
		export namespace event {
			export type OfferRequestEventKind =
				| ({ type: 'repairer' } & offer_request_model.model.event.OfferRequestEventRepairer)
				| ({ type: 'supplier' } & offer_request_model.model.event.OfferRequestEventSupplier);

			export type OfferRequestEventRepairer = {
				/**
				 *  As a supplier, I can see repairer context (organisation & site & address) for the offer request,
				 *  so I can ensure pricing and shipping is correct.
				 */
				repairer_org_id: string /* uuid::Uuid */;
				repairer_site_id: string /* uuid::Uuid */;
			} & NullToEmptyObject<offer_request_model.model.event.OfferRequestEventRepairerData>;

			export type OfferRequestEventRepairerData = {
				version: 'v1';
				/**
				 *  As a repairer, I want to include job context (vehicle info, photos, crash zones, jobs parts list)
				 *  with and offer request to a supplier, so that the supplier can be more accurate in their offer response.
				 */
				vehicle: offer_request_model.model.vehicle.Vehicle;
				images: Array<offer_request_model.model.image.Image>;
				parts: Array<offer_request_model.model.event.OfferRequestRepairerPart>;
			};

			export type OfferRequestRepairerPartReturnCondition = 'not_damaged';

			export type OfferRequestRepairerPartReturnPolicy =
				| 'no_returns'
				| {
						allow_returns: {
							condition?: offer_request_model.model.event.OfferRequestRepairerPartReturnCondition | null;
							before_date?: string | null;
						};
				  };

			export type OfferRequestEventSupplier = {
				version: 'v1';
				status: offer_request_model.model.event.OfferRequestStatus;
				parts: Array<offer_request_model.model.event.OfferRequestSupplierPart>;
			};

			export interface OfferRequestPartIdentity {
				description: string;
				quantity: number /* i32 */;
				ghca_id?: string | null;
				part_identity?: offer_request_model.model.gapc.PartIdentity | null;
			}

			export type OfferRequestRepairerPart = {
				/**
				 *  The unique id for the job part.
				 */
				id: string /* uuid::Uuid */;
				/**
				 *  As a supplier, I want to see which job parts already have automated offers, so I know the full job pricing
				 *  and so I know which offers need to be completed.
				 */
				data: offer_request_model.model.event.OfferRequestRepairerPartData;
			} & NullToEmptyObject<offer_request_model.model.event.OfferRequestPartIdentity>;

			export type OfferRequestRepairerPartData = {
				version: 'v1';
			} & offer_request_model.model.event.OfferRequestRepairerPartRequired;

			export interface OfferRequestRepairerPartDataV1 {
				/**
				 *  As a supplier, I want to see the repairer specific shipping and pricing for a offer request part,
				 *  so that I can sanity check offer information is correct.
				 */
				sellable_id: string /* uuid::Uuid */;
				parent_assembly_id?: string /* uuid::Uuid */ | null;
				price?: string | null;
				condition?: lookup.model.sellable.SellableCondition | null;
				shipping_time?: string | null;
			}

			export type OfferRequestRepairerPartRequired =
				| {
						not_required: {
							data: offer_request_model.model.event.OfferRequestRepairerPartDataV1;
						};
				  }
				| { required: {} };

			export type OfferRequestStatus = 'pending' | 'accepted' | 'rejected';

			export type OfferRequestSupplierPart = {
				/**
				 *  As a supplier, I want to be able to select a parent assembly part for any offer request part,
				 *  so I can have more flexibility.
				 *
				 *  Note: the consumer of the API will need to deduplicate on ids in case an
				 *  assembly offer is used for multiple parts.
				 *
				 *  The unique id for the job part.
				 */
				id: string /* uuid::Uuid */;
				/**
				 *  Used if the suppler wants to override the repairer\'s part.
				 */
				data: offer_request_model.model.event.OfferRequestSupplierPartData;
			} & NullToEmptyObject<offer_request_model.model.event.OfferRequestPartIdentity>;

			export type OfferRequestSupplierPartData = {
				return_policy?: offer_request_model.model.event.OfferRequestRepairerPartReturnPolicy | null;
				note?: string | null;
			} & NullToEmptyObject<offer_request_model.model.event.OfferRequestSupplierPartDataKind>;

			export type OfferRequestSupplierPartDataKind = {
				type: 'sellable';
				id: string /* uuid::Uuid */;
				price: string;
				shipping_time: string;
			};

			export type Subject = {
				user: {
					id: string /* uuid::Uuid */;
					name: string;
				};
			};
		}

		export namespace gapc {
			export interface PartIdentity {
				mpn: string;
				brand_id: string;
			}
		}

		export namespace image {
			export interface Image {
				original: string;
			}
		}

		export namespace vehicle {
			export interface Vehicle {
				plate_state: string | null;
				plate_number: string | null;
				chassis_number: string | null;
				variant: offer_request_model.model.vehicle.VehicleVariant | null;
			}

			export interface VehicleVariant {
				token: string | null;
				description: string;
				uvdb_properties: Array<string>;
			}
		}
	}
}

export namespace order_handler {
	export type JobOrdersCancelRequest = {
		job_id: string /* uuid::Uuid */;
	} & NullToEmptyObject<
		reflectapi_ex.types_complete.UpdateOrElse<
			reflectapi_ex.types_identity.IdentityData<string, core_order.OrderCancelData>,
			reflectapi_ex.types_actions.IfNotExistsAbort
		>
	>;

	export type JobOrdersInsertRequest = {
		job_id: string /* uuid::Uuid */;
		business_id: string /* uuid::Uuid */;
		supply_snaphot_hash: string;
	} & NullToEmptyObject<
		reflectapi_ex.types_complete.InsertOrElse<
			reflectapi_ex.types_identity.IdentityData<string, core_order.OrderInsertData>,
			reflectapi_ex.types_actions.IfExistsAbortOrIgnore
		>
	>;

	export type JobOrdersItemsProcessRequest = {
		job_id: string /* uuid::Uuid */;
	} & NullToEmptyObject<
		reflectapi_ex.types_complete.UpdateOrElse<
			reflectapi_ex.types_identity.IdentityData<string, core_order.OrderItemsProcessData>,
			reflectapi_ex.types_actions.IfNotExistsAbort
		>
	>;

	export interface OrdersListRequest {
		job_id: string /* uuid::Uuid */;
	}
}

export namespace part_assembly {
	export interface HumanCentricAssemblyInformation {
		usage_notes: Array<string>;
		typical_shape: Array<string>;
		function_purpose: Array<string>;
		typical_material: Array<string>;
		typical_positioning: Array<string>;
	}

	export interface PartAssembly {
		id: string /* uuid::Uuid */;
		description: string | null;
		/**
		 * @deprecated Use world_tree_assemblies resource instead with hca_id
		 */
		part_slot: part_slot.PartSlot | null;
		hca_id: string | null;
		diagrams: Array<resource.GapcDiagramLink> | null;
		part_identity: resource.GapcPartIdentityKey | null;
		assembly_type: part_assembly.PartAssemblyType;
		sub_assemblies: Array<resource.PartAssemblyLink>;
		confidence: number /* u16 */ | null;
		supply: job.PartSupplySummary | null;
		tags: Array<string> | null;
		std_note: string | null;
	}

	export type PartAssemblyType = 'human_centric' | 'part';

	export interface WorldTreeAssembly {
		id: string;
		description: string;
		part_slot: part_slot.PartSlot;
		/**
		 *  Localised name
		 *  TODO:- core returns a list of names, but to be consistent with other GAPC types in repairer,
		 *  we should return the correct name based on the language.
		 *  We may want to revise this approach in the future?
		 */
		name: string;
		/**
		 *  Alternate terminology
		 */
		aliases: Array<uvdb_gapc_common.UvdbGapcI18nName>;
		information: part_assembly.HumanCentricAssemblyInformation | null;
		sub_assemblies: Array<string>;
	}
}

export namespace part_slot {
	export type PartSelectionContext = {
		description: string | null;
		hcas?: Array<string> | null;
	} & Partial<gapc_part.GapcPartIdentity> &
		Partial<part_slot.PartSlot>;

	export type PartSelectionContexts = Array<part_slot.PartSelectionContext>;

	export interface PartSlot {
		gapc_part_type_id: string;
		gapc_position_id: string | null;
	}
}

export namespace reflectapi_ex {
	export namespace types_actions {
		export type IfExistsAbortOrIgnore = 'abort' | 'ignore';

		export type IfExistsUpdateOrReplaceOrElse = 'update' | 'replace' | 'abort' | 'ignore';

		export type IfNotExistsAbort = 'abort';
	}

	export namespace types_complete {
		export type EditionMatchCondition = {
			edition: number /* u64 */;
		};

		export interface IfConflictOnInsert<C> {
			exists?: C;
			matches?: reflectapi_ex.types_complete.MatchCondition | null;
		}

		export interface IfConflictOnUpdate<C> {
			not_exists?: C;
			matches?: reflectapi_ex.types_complete.EditionMatchCondition | null;
		}

		export type InsertOrElse<T, C> = {
			if?: reflectapi_ex.types_complete.IfConflictOnInsert<C>;
		} & NullToEmptyObject<T>;

		export type MatchCondition =
			| 'exists'
			| {
					edition: number /* u64 */;
			  };

		export type UpdateOrElse<T, C> = {
			if?: reflectapi_ex.types_complete.IfConflictOnUpdate<C>;
		} & NullToEmptyObject<T>;
	}

	export namespace types_identity {
		export type IdentityData<I, D> = {
			identity: I;
		} & NullToEmptyObject<D>;
	}
}

export namespace resource {
	export type BasketLink = string;

	export type GapcDiagramLink = string;

	export type GapcPartIdentityKey = string;

	export type ListingLink = string;

	export type PartAssemblyLink = string;

	export interface ResourcesMap {
		collision_areas?: Record<string, collision_map.CollisionArea> | null;
		collision_maps?: Record<string, collision_map.CollisionMap> | null;
		collision_hcas?: Record<string, collision_map.CollisionHca> | null;
		jobs?: Record<string /* uuid::Uuid */, job.Job> | null;
		job_types?: Record<string, job.JobType> | null;
		listings?: Record<string /* uuid::Uuid */, listing.Listing> | null;
		vendors?: Record<string /* uuid::Uuid */, listing.SupplyVendor> | null;
		locations?: Record<string /* uuid::Uuid */, listing.SupplyLocation> | null;
		draft_orders?: Record<core_order.OrderId, draft_order.DraftOrder> | null;
		gapc_parts?: Record<resource.GapcPartIdentityKey, gapc_part.GapcPart> | null;
		gapc_assemblies?: Record<string /* uuid::Uuid */, gapc_assembly.GapcAssembly> | null;
		gapc_diagrams?: Record<string /* uuid::Uuid */, gapc_assembly.GapcDiagram> | null;
		gapc_properties?: Record<string, i18n.WithI18n<gapc_common.GapcProperty>> | null;
		uvdb_properties?: Record<string, i18n.WithI18n<uvdb_common.UvdbProperty>> | null;
		part_assemblies?: Record<string /* uuid::Uuid */, part_assembly.PartAssembly> | null;
		world_tree_assemblies?: Record<string, part_assembly.WorldTreeAssembly> | null;
	}
}

export namespace response {
	export interface MultipleItemsPayload<Item> {
		items: Array<Item>;
	}

	export type NormalizedResponse<Payload, Additional> = {
		payload: response.NormalizedResponsePayload<Payload, Additional>;
	} & NullToEmptyObject<resource.ResourcesMap>;

	export type NormalizedResponsePayload<Payload, Additional> = {} & NullToEmptyObject<Payload> &
		NullToEmptyObject<Additional>;

	export interface SingularItemPayload<Item> {
		item: Item;
	}
}

export namespace shipping {
	export interface GeoLocation {
		latitude: number /* f64 */;
		longitude: number /* f64 */;
	}

	export interface ShippingAddress {
		city: string | null;
		country_code: number /* i32 */ | null;
		postcode: string | null;
		state: string | null;
		street_name: string | null;
		street_number: string | null;
		suburb: string | null;
		unit: string | null;
		geo: shipping.GeoLocation | null;
	}

	export interface ShippingTime {
		eta: string | null;
		arrival_time: number /* u32 */ | null;
		business_days: number /* u32 */ | null;
		/**
		 *
		 *  The id of the location where the shipping
		 *  time is applicable.
		 *
		 */
		location_id: string /* uuid::Uuid */;
	}
}

export namespace std_ex {
	export interface Address {
		/**
		 *  An optional name for the address
		 */
		name?: string | null;
		city?: string | null;
		country_code?: number /* i32 */ | null;
		postcode?: string | null;
		state?: string | null;
		street_name?: string | null;
		street_number?: string | null;
		suburb?: string | null;
		unit?: string | null;
		geo?: std_ex.GeoLocation | null;
		time_zone?: string;
	}

	export interface GeoLocation {
		latitude: number /* f64 */;
		longitude: number /* f64 */;
	}
}

export namespace suid_rs {
	export namespace suid_prefix {
		export type UvdbSuidPrefix =
			/**
			 *  Aspiration
			 */
			| 'UASP'
			/**
			 *  BedLength
			 */
			| 'UBLN'
			/**
			 *  BedType
			 */
			| 'UBET'
			/**
			 *  BodyNumDoors
			 */
			| 'UBND'
			/**
			 *  BodyType
			 */
			| 'UBOT'
			/**
			 *  BrakeAbs
			 */
			| 'UBBS'
			/**
			 *  BrakeSystem
			 */
			| 'UBSY'
			/**
			 *  CylinderHeadType
			 */
			| 'UCHT'
			/**
			 *  DriveType
			 */
			| 'UDRT'
			/**
			 *  EngineBase
			 */
			| 'UEBA'
			/**
			 *  EngineDesignation
			 */
			| 'UEDN'
			/**
			 *  EnginePowerOutput
			 */
			| 'UEPO'
			/**
			 *  EngineVersion
			 */
			| 'UEVS'
			/**
			 *  EngineDefinition
			 */
			| 'UENG'
			/**
			 *  FuelDeliverySubtype
			 */
			| 'UFDS'
			/**
			 *  FuelDeliveryType
			 */
			| 'UFDT'
			/**
			 *  FuelSystemControlType
			 */
			| 'UFSC'
			/**
			 *  FuelSystemDesign
			 */
			| 'UFSD'
			/**
			 *  FuelType
			 */
			| 'UFTY'
			/**
			 *  IgnitionSystemType
			 */
			| 'UGST'
			/**
			 *  Make
			 */
			| 'UMAK'
			/**
			 *  MfrBodyCode
			 */
			| 'UMBC'
			/**
			 *  Model
			 */
			| 'UMOD'
			/**
			 *  Region
			 */
			| 'UREG'
			/**
			 *  Restriction
			 */
			| 'URES'
			/**
			 *  Series
			 */
			| 'UVSE'
			/**
			 *  SteeringSide
			 */
			| 'USSD'
			/**
			 *  SteeringSystem
			 */
			| 'USSY'
			/**
			 *  SteeringType
			 */
			| 'USTT'
			/**
			 *  Submodel
			 */
			| 'USUB'
			/**
			 *  TransmissionControlType
			 */
			| 'UTCT'
			/**
			 *  TransmissionElecControl
			 */
			| 'UTEC'
			/**
			 *  TransmissionMfrCode
			 */
			| 'UTMC'
			/**
			 *  TransmissionNumSpeeds
			 */
			| 'UTNS'
			/**
			 *  TransmissionType
			 */
			| 'UTTY'
			/**
			 *  Valves
			 */
			| 'UVLV'
			/**
			 *  VehicleDefinition
			 */
			| 'UDEF'
			/**
			 *  VehicleSubtype
			 */
			| 'UVES'
			/**
			 *  VehicleType
			 */
			| 'UVET'
			/**
			 *  EngineVin
			 */
			| 'UEVN'
			/**
			 *  Wheel
			 */
			| 'UWHE'
			/**
			 *  WheelBase
			 */
			| 'UWHB'
			/**
			 *  WheelConfig
			 */
			| 'UWHC'
			/**
			 *  YearFrom
			 */
			| 'UPYF'
			/**
			 *  YearTo
			 */
			| 'UPYT'
			/**
			 *  MonthFrom
			 */
			| 'UPMF'
			/**
			 *  MonthTo
			 */
			| 'UPMT'
			/**
			 *  EngineManufacturer
			 */
			| 'UEMF'
			/**
			 *  TransmissionManufacturer
			 */
			| 'UTMF'
			/**
			 *  FrontSpringType
			 */
			| 'UFST'
			/**
			 *  RearSpringType
			 */
			| 'URST'
			/**
			 *  FrontBrakeType
			 */
			| 'UFBT'
			/**
			 *  RearBrakeType
			 */
			| 'URBT'
			/**
			 *  Emission standard
			 */
			| 'UEUE'
			/**
			 *  ProductionYear
			 */
			| 'UPYE'
			/**
			 *  ProductionMonth
			 */
			| 'UPMO';
	}
}

export namespace supply {
	export type SupplyItemOffer =
		/**
		 *
		 *  Selected product offer
		 *
		 */
		| ({ type: 'Product' } & supply.SupplyItemOfferProduct)

		/**
		 *
		 *  Kit with selected variant offers
		 *
		 */
		| ({ type: 'Kit' } & supply.SupplyItemOfferKit);

	export interface SupplyItemOfferKit {
		/**
		 *  The kit id
		 */
		listing_id: string /* uuid::Uuid */;
		/**
		 *  Explicitly specifies all included variants in the kit (allow future support for full customisation)
		 *  Should include exactly one offer for each product in the kit
		 */
		offer_ids: Array<listing.OfferId>;
	}

	export interface SupplyItemOfferProduct {
		/**
		 *  The root product id
		 */
		listing_id: string /* uuid::Uuid */;
		/**
		 *  The selected variant offer
		 */
		offer_id: listing.OfferId;
	}
}

export namespace tier1 {
	export type VehicleFilterToken = string;

	export interface VehicleProperty {
		type: string;
		value: string;
	}
}

export namespace uvdb_common {
	export interface UvdbProperty {
		id: string;
		name: string;
	}
}

export namespace uvdb_gapc_common {
	export interface UvdbGapcI18nName {
		name: string;
		normalized_name: string;
	}
}

namespace __implementation {
	/* <----- */

	export function __client(base: string | Client): __definition.Interface {
		const client_instance = typeof base === 'string' ? new ClientInstance(base) : base;
		return {
			impl: {
				jobs: {
					orders: {
						list: jobs__orders__list(client_instance),
						insert: jobs__orders__insert(client_instance),
						cancel: jobs__orders__cancel(client_instance),
						items: {
							process: jobs__orders__items__process(client_instance)
						}
					},
					external: {
						insert: jobs__external__insert(client_instance)
					},
					baskets: {
						get: jobs__baskets__get(client_instance),
						ingest: jobs__baskets__ingest(client_instance)
					},
					offer_requests: {
						get: jobs__offer_request__get(client_instance),
						insert: jobs__offer_request__insert(client_instance),
						events: {
							insert: jobs__offer_request__events__insert(client_instance)
						}
					}
				}
			}
		}.impl;
	}

	/* -----> */

	function jobs__orders__list(client: Client) {
		return (input: order_handler.OrdersListRequest, headers: context.RepairerServerHeaders) =>
			__request<
				order_handler.OrdersListRequest,
				context.RepairerServerHeaders,
				Array<core_order.SupplierOrder>,
				core_order.OrdersGetError
			>(client, '/api/v1/jobs.orders.list', input, headers);
	}
	function jobs__orders__insert(client: Client) {
		return (input: order_handler.JobOrdersInsertRequest, headers: context.RepairerServerHeaders) =>
			__request<
				order_handler.JobOrdersInsertRequest,
				context.RepairerServerHeaders,
				core_order.OrdersMutationResponse,
				core_order.OrdersInsertError
			>(client, '/api/v1/jobs.orders.insert', input, headers);
	}
	function jobs__orders__cancel(client: Client) {
		return (input: order_handler.JobOrdersCancelRequest, headers: context.RepairerServerHeaders) =>
			__request<
				order_handler.JobOrdersCancelRequest,
				context.RepairerServerHeaders,
				core_order.OrdersMutationResponse,
				core_order.OrdersCancelError
			>(client, '/api/v1/jobs.orders.cancel', input, headers);
	}
	function jobs__orders__items__process(client: Client) {
		return (
			input: order_handler.JobOrdersItemsProcessRequest,
			headers: context.RepairerServerHeaders
		) =>
			__request<
				order_handler.JobOrdersItemsProcessRequest,
				context.RepairerServerHeaders,
				core_order.OrdersMutationResponse,
				core_order.OrdersItemsProcessError
			>(client, '/api/v1/jobs.orders.items.process', input, headers);
	}
	function jobs__external__insert(client: Client) {
		return (
			input: reflectapi_ex.types_complete.InsertOrElse<
				job_integration.JobExternalIngestBatch,
				reflectapi_ex.types_actions.IfExistsUpdateOrReplaceOrElse
			>,
			headers: context.RepairerServerHeaders
		) =>
			__request<
				reflectapi_ex.types_complete.InsertOrElse<
					job_integration.JobExternalIngestBatch,
					reflectapi_ex.types_actions.IfExistsUpdateOrReplaceOrElse
				>,
				context.RepairerServerHeaders,
				null,
				api_error.RepairerServerError<null>
			>(client, '/api/v1/jobs.external.insert', input, headers);
	}
	function jobs__baskets__get(client: Client) {
		return (input: basket_handler.BasketGetRequest, headers: context.RepairerServerHeaders) =>
			__request<
				basket_handler.BasketGetRequest,
				context.RepairerServerHeaders,
				response.NormalizedResponse<response.SingularItemPayload<basket.Basket>, null>,
				api_error.RepairerServerError<null>
			>(client, '/api/v1/jobs.baskets.get', input, headers);
	}
	function jobs__baskets__ingest(client: Client) {
		return (input: basket_handler.BasketIngestRequest, headers: context.RepairerServerHeaders) =>
			__request<
				basket_handler.BasketIngestRequest,
				context.RepairerServerHeaders,
				response.NormalizedResponse<response.MultipleItemsPayload<resource.BasketLink>, null>,
				api_error.RepairerServerError<null>
			>(client, '/api/v1/jobs.baskets.ingest', input, headers);
	}
	function jobs__offer_request__get(client: Client) {
		return (
			input: offer_request_handler.OfferRequestGetRequest,
			headers: context.RepairerServerHeaders
		) =>
			__request<
				offer_request_handler.OfferRequestGetRequest,
				context.RepairerServerHeaders,
				Array<offer_request.model.OfferRequest>,
				api_error.RepairerServerError<null>
			>(client, '/api/v1/jobs.offer-request.get', input, headers);
	}
	function jobs__offer_request__insert(client: Client) {
		return (
			input: offer_request_handler.OfferRequestInsertRequest,
			headers: context.RepairerServerHeaders
		) =>
			__request<
				offer_request_handler.OfferRequestInsertRequest,
				context.RepairerServerHeaders,
				Array<string /* uuid::Uuid */>,
				api_error.RepairerServerError<null>
			>(client, '/api/v1/jobs.offer-request.insert', input, headers);
	}
	function jobs__offer_request__events__insert(client: Client) {
		return (
			input: offer_request_handler.OfferRequestEventInsertRequest,
			headers: context.RepairerServerHeaders
		) =>
			__request<
				offer_request_handler.OfferRequestEventInsertRequest,
				context.RepairerServerHeaders,
				string /* uuid::Uuid */,
				api_error.RepairerServerError<null>
			>(client, '/api/v1/jobs.offer-request.events.insert', input, headers);
	}
}
