import { match } from '@/types/match';
import { memo } from 'react';
import { ConfirmedOrder } from '../confirmed-order';
import { CancelledOrder } from '../cancelled-order';
import { OrderedOrder } from '../ordered-order';
import { core_order, lookup_server_model } from '@/sdk/reflect/reflect';

type OrderRequestBuilderProps = {
	jobId: string;
	model: core_order.SupplierOrder;
	showIBody: boolean;
	syncToiBody: any;
	isSuccess: boolean;
};

export const OrderBuilder = memo(
	({ jobId, model, showIBody, syncToiBody, isSuccess }: OrderRequestBuilderProps) => {
		// We want a unique key that only updates if
		// there was a change in the model. This ensures that
		// the form default values are created.
		const key = `${model.id}.${model.updated_at}`;

		return match<lookup_server_model.json_types.SupplierOrderStatus, React.ReactNode>(
			model.status,
			{
				ordered: () => (
					<OrderedOrder
						key={key}
						jobId={jobId}
						model={model}
						showIBody={showIBody}
						syncToiBody={syncToiBody}
						isSuccess={isSuccess}
					/>
				),
				confirmed: () => (
					<ConfirmedOrder
						key={key}
						jobId={jobId}
						model={model}
						showIBody={showIBody}
						syncToiBody={syncToiBody}
						isSuccess={isSuccess}
					/>
				),
				cancelled: () => <CancelledOrder key={key} model={model} />
			},
			() => null
		);
	}
);
