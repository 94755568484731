import { coreSdk, repairSdk } from '@/sdk/reflect';
import {
	api_error,
	basket_handler,
	core_order,
	offer_request_handler,
	order_handler
} from '@/sdk/reflect/reflect';
import { auth, business_network, NonPaginatedList } from '@partly/core-server-client';
import { queryOptions } from '@tanstack/react-query';
import { getConfig } from '../config';

export const queries = {
	// just return any at this point
	basket: {
		get: (payload: basket_handler.BasketGetRequest) =>
			queryOptions<basket_handler.exp.BasketGetResponse, api_error.RepairerServerError>({
				queryKey: ['basket', 'get', payload],
				queryFn: async () => {
					const sdk = await repairSdk(getConfig());
					const { data, error } = await sdk.baskets.get(payload);
					if (error) {
						throw error;
					}

					return data;
				}
			})
	},
	offer_requests: {
		get: (payload: offer_request_handler.OfferRequestGetRequest) =>
			queryOptions<
				offer_request_handler.exp.OfferRequestGetResponse,
				api_error.RepairerServerError
			>({
				queryKey: ['offer_requests', 'get', payload],
				queryFn: async () => {
					const sdk = await repairSdk(getConfig());
					const { data, error } = await sdk.offer_requests.get(payload);
					if (error) {
						throw error;
					}

					return data;
				}
			})
	},
	orders: {
		list: (payload: order_handler.OrdersListRequest) =>
			queryOptions<core_order.SupplierOrder[], core_order.OrdersGetError>({
				queryKey: ['orders', 'list', payload],
				queryFn: async () => {
					const sdk = await repairSdk(getConfig());
					const { data, error } = await sdk.orders.list(payload);
					if (error) {
						throw error;
					}

					return data;
				}
			})
	},
	users: {
		get: (payload: auth.UsersGetRequest) =>
			queryOptions<auth.UsersGetResponse, auth.UsersGetError>({
				queryKey: ['users', 'get'],
				queryFn: async () => {
					const [api, headers] = await coreSdk(getConfig());
					const { authorization } = headers;
					const result = await api.users.get(payload, { authorization: authorization ?? '' });
					if (result.is_err()) {
						throw result.unwrap_err();
					}

					return result.unwrap_ok();
				}
			})
	},
	organizations: {
		list: (payload: object = {}) =>
			queryOptions<NonPaginatedList<auth.OrganizationModel>, auth.OrganizationsListError>({
				queryKey: ['organizations', 'list'],
				queryFn: async () => {
					const [api, headers] = await coreSdk(getConfig());
					const { authorization } = headers;
					const result = await api.organizations.list(payload, {
						authorization: authorization ?? ''
					});
					if (result.is_err()) {
						throw result.unwrap_err();
					}

					return result.unwrap_ok();
				}
			})
	},
	businesses: {
		list: (payload: business_network.BusinessesListRequest) =>
			queryOptions<business_network.BusinessesListResponse, business_network.BusinessesListError>({
				queryKey: ['businesses', 'list', payload],
				queryFn: async () => {
					const [api, headers] = await coreSdk(getConfig());
					const { authorization } = headers;
					const result = await api.businesses.list(payload, {
						authorization: authorization ?? ''
					});
					if (result.is_err()) {
						throw result.unwrap_err();
					}

					return result.unwrap_ok();
				}
			}),
		associations: {
			list: (payload: business_network.AssociationsListRequest) =>
				queryOptions<
					business_network.AssociationsListResponse,
					business_network.AssociationsListError
				>({
					queryKey: ['businesses', 'associations', 'list', payload],
					queryFn: async () => {
						const [api, headers] = await coreSdk(getConfig());
						const { authorization } = headers;
						const result = await api.businesses.associations.list(payload, {
							authorization: authorization ?? ''
						});
						if (result.is_err()) {
							throw result.unwrap_err();
						}

						return result.unwrap_ok();
					}
				})
		}
	}
};
