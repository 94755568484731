import { basket, offer_request, offer_request_model } from '@/sdk/reflect/reflect';
import { lookup, offers_search, supply_search } from '@partly/core-server-client';
import { JobPart, PartSupplyOffer, SupplyVendor } from '@sdk/lib';

export type OfferStatus = 'Draft' | 'Processing' | 'Rejected' | 'Accepted' | 'Ordered';

export type Offer = offers_search.Offer;

export type SellableOffer = offers_search.SellableOffer & {
	groupId?: string;
	/**
	 * If an offer is a response to offer request this field will be populated
	 */
	request?: offer_request.model.OfferRequest;
	isRequest?: boolean;
	id: string;
	returnPolicy?: offer_request_model.model.event.OfferRequestRepairerPartReturnPolicy;
	matchedOfferPartId?: string;
};

export type Basket = {
	id: string;
	type: 'single_supplier' | 'split';
	supplierScore: number | null;
	offers: SellableOffer[];
	offersPerJobPart: JobPartRecommendedOffers;
	missingJobPartsCount: number;
	tag?: string;
	request?: offer_request.model.OfferRequest;
	supplier: { name: string; id: string }[];
	title: string;
	totalPrice: number;
	shippingCost: {
		amount: number;
		currency: string;
	} | null;
	estimatedDelivery: Required<Omit<supply_search.ShippingTime, 'store_address_id'>> | null;
	supplierNotes?: string;
	fulfillmentStatus: offers_search.FulfillmentStatus | null;
	totalCount: number;
	conditions: Omit<SupplierCondition, 'isAssembly'>[];
};

export type OrderPartSupplyOffer = PartSupplyOffer & {
	/** The status of the offer in the most recent order if available */
	status: OfferStatus | null;
};

export type SupplierCondition = {
	condition: lookup.SellableCondition;
	isAssembly: boolean;
	isOem: boolean;
	isOes: boolean;
};

// Draft Orders
// ===========================================

export type OrderSelection = {
	repairer_site_id: string;
	/** The date that the supply page uses to work out what offers should be shown */
	deliver_before: Date;
	/** The date that the actual parts are going to be delivered */
	delivery_date: Date;
	items: Omit<basket.BasketItem, 'intent'>[];
};

// An offer id is the same as a `sellable_id`. It is what gets purchased
// in an order
export type OfferId = string;

export type EnrichedOffer = supply_search.SellableOffer & {
	id: string;
};

// This a a part id that is generated before the request to supply offers is sent.
// It corresponds to a specific part only for the duration of the request. It is
// used to associate matches with the part.
export type TransientPartId = string;

// Offer selection is a set of offer ids, these will be computed from
// the orders buyable sellable ids.
//
// This will power the 'selected' vs `unselected` state of the grid of offers
// per part.
export type OfferSelection = ReadonlySet<OfferId>;

// The part + all offers that are available for it. This powers the sidebar and
// the grid of offers.
//
// Checking selection will be done by the offer id to the `OfferSelection`.
// The job part aggregation information can be calculated from the offers + selection.
export type PartOfferAggregation = {
	alternativeForJobPartId?: string;
	jobPart: JobPart;
	offers: SellableOffer[];
};

export type JobPartOfferSearchRequest = {
	job_id: string;
	deliver_before?: Date;
	offer_request_ids: string[];
	repairerSiteId: string;
};

export type JobPartOfferSearchResponse = offers_search.SupplyOffersWithRecommendations & {
	job_parts: JobPart[];
};

export type JobPartRecommendedOffers = Record<string, { quantity: number; offer: SellableOffer }[]>;

export interface OfferRequest {
	status: offer_request_model.model.event.OfferRequestStatus;
	request: offer_request.model.OfferRequest;
	lastUpdated: Date;
}

export type OfferRequestsAggregation = Record<string, OfferRequest>;

export enum ActionType {
	AddSuppliers = 'addSuppliers',
	PlaceOrder = 'placeOrder'
}

export type OrdersBySupply = {
	supplier: SupplyVendor;
	jobParts: {
		jobPart: JobPart;
		offers: SellableOffer[];
	}[];
};
