import { InheritableElementProps, InheritableProps } from '@/types/utilties';
import { ReactComponent as AiLogo } from '@assets/parts/ai-logo.svg';
import { Badge } from '@common/components/badge';
import { useMeasurement } from '@common/hooks/use-measure';
import { tlsx } from '@common/utils/tw-merge';
import { Cog6ToothIcon } from '@heroicons/react/24/solid';
import { Divider, Loader, Popover } from '@mantine/core';
import { OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { compact, isNil, sortBy } from 'lodash-es';
import { ReactNode, Suspense, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Control } from 'react-hook-form';
import { Camera, MOUSE, TOUCH } from 'three';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { DEFAULT_DISTANCE_Z, MAX_MIN_DISTANCE_Z, MESH_SCALE } from '../../constants';
import { useImageSizes } from '../../hooks/use-image-dimensions';
import { useSearchValue } from '../../hooks/use-search-value';
import {
	CategoryTreeLeaf,
	CategoryTreeNode,
	Diagram,
	DiagramAssembly,
	DiagramPartSlot,
	PartsFormData
} from '../../types';
import { flattenPartSlots } from '../../utils/assembly';
import { vec3 } from '../../utils/geometry';
import { layoutPartSlotMeshes, meshAreaSortKey, meshKindSortKey } from '../../utils/mesh';
import { filterTokenMatch } from '../../utils/search/filter';
import { PartsDiagramPartSlot } from '../parts-display';
import {
	PartsDiagramAssemblyHotspot,
	PartsDiagramBackground,
	PartsDiagramCodeLayer,
	PartsDiagramFallback,
	PartsDiagramSuspenseSegment
} from './mesh';

const optimalMeshScale = (ratio: number) => {
	const res = MESH_SCALE / (ratio * 1.5);
	return res;
};

type PartsDiagramRendererProps = InheritableProps<
	typeof Canvas,
	{
		diagram?: Diagram | null;
		hightlighted: Set<string>;
		selection: PartsFormData;
		zoom: number;
		hide?: boolean;
		actions: {
			part: {
				add: (assemblies: DiagramAssembly[]) => void;
				remove: (assemblies: DiagramAssembly[]) => void;
				highlight: (partSlotIds: string[]) => void;
			};
			diagram: {
				jump: (cutId: string | null, categoryId: string, diagramId: string) => void;
			};
			controls: {
				start: (control: OrbitControlsImpl) => void;
				change: (control: Camera) => void;
			};
		};
		children?: ReactNode;
	}
>;

export const PartsDiagramRenderer = ({
	className,
	diagram,
	hightlighted: highlighted,
	selection,
	actions,
	hide,
	zoom,
	...rest
}: PartsDiagramRendererProps) => {
	const q = useSearchValue();
	const [hovering, setHovering] = useState<string[]>([]);
	const [errors, setErrors] = useState<Record<string, Error>>({});
	const { value: nav } = useMeasurement('navigation-bar');
	const imageSize = useImageSizes(diagram?.image?.full ?? null);

	const sizes = useMemo(() => {
		if (!imageSize || !nav) {
			return null;
		}
		return {
			width: imageSize.naturalWidth,
			height: imageSize.naturalHeight,
			ratio: imageSize.naturalHeight / imageSize.naturalWidth,
			scale: optimalMeshScale(imageSize.naturalHeight / imageSize.naturalWidth)
		};
	}, [imageSize, nav]);

	const segmented = useMemo(
		() =>
			diagram?.partSlots?.some(
				({ kind, meshes }) => kind === 'assembly' && meshes.polygons.length > 0
			) ?? false,
		[diagram]
	);

	const error = useMemo(() => (diagram ? errors?.[diagram.image.full] : null), [errors, diagram]);

	const partSlots = useMemo(() => {
		if (!diagram) {
			return [];
		}
		return flattenPartSlots(diagram.partSlots);
	}, [diagram]);

	const objects = useMemo(() => {
		if (!sizes || !diagram) {
			return { meshes: [], polygons: [] };
		}

		const { meshes, polygons } = layoutPartSlotMeshes(diagram, sizes);

		return {
			meshes: sortBy(meshes, ({ mesh }) => meshKindSortKey(mesh)),
			polygons: sortBy(polygons, ({ mesh }) => meshAreaSortKey(mesh)).filter(
				({ mesh }) => mesh.polygon.length > 0
			)
		};
	}, [diagram, sizes]);

	const related = useMemo(() => {
		const related = flattenPartSlots(
			compact([...highlighted.values()].map(id => partSlots.find(partSlot => partSlot.id === id)))
		);
		return new Set(related.map(({ id }) => id));
	}, [highlighted, partSlots]);

	useEffect(() => {
		if (hovering.length > 0) {
			document.body.style.cursor = 'pointer';
		} else {
			document.body.style.cursor = 'auto';
		}
	}, [hovering]);

	return (
		<>
			<Canvas
				className={tlsx('w-full h-full overflow-hidden', className)}
				style={{
					height: `calc(100dvh - ${nav?.height ?? 0}px - 5.5rem)`,
					maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - 5.5rem)`
				}}
				{...rest}
			>
				{diagram && sizes && (
					<>
						<ErrorBoundary
							fallbackRender={() => (
								// todo (vincent) some images are not cors configured properly
								// this is decent fallback/render image as image (only caveat no hotspots)
								<PartsDiagramFallback
									key={diagram.id}
									url={diagram.image.full}
									ratio={sizes.ratio}
									scale={sizes.scale}
									filter={
										hide || !segmented ? 'opaque' : highlighted.size > 0 ? 'dimmed' : 'background'
									}
								/>
							)}
							onError={err => setErrors(errors => ({ ...errors, [diagram.image.full]: err }))}
						>
							<PartsDiagramBackground
								key={diagram.id}
								url={diagram.image.full}
								ratio={sizes.ratio}
								scale={sizes.scale}
								filter={
									hide || !segmented ? 'opaque' : highlighted.size > 0 ? 'dimmed' : 'background'
								}
							/>
						</ErrorBoundary>
						{!hide && (
							<>
								{objects.meshes.map(({ info, mesh }, index) => {
									const assemblies = info.kind === 'assembly' ? info.assemblies : [];
									const searched = q
										? assemblies.some(({ searchables }) =>
												searchables.some(searchable => filterTokenMatch(searchable, q))
											)
										: false;
									const onClick = () => actions.part.highlight([info.id]);
									const onDoubleClick = () => {
										if (assemblies.length === 1) {
											actions.part.add([assemblies[0]]);
											actions.part.remove(
												flattenPartSlots(assemblies[0].subAssemblies ?? []).flatMap(each =>
													each.kind === 'assembly' ? each.assemblies : []
												)
											);
										}
										if (!highlighted.has(info.id)) {
											actions.part.highlight([info.id]);
										}
									};

									if (mesh.kind === 'whiteout') {
										return (
											<PartsDiagramCodeLayer
												key={`${info.id}-whiteout-${index}`}
												rect={mesh.rect}
											/>
										);
									}

									if (
										mesh.kind === 'hotspot' &&
										(info.kind === 'assembly' || info.kind === 'reference')
									) {
										return (
											<PartsDiagramAssemblyHotspot
												key={`${info.id}-hotspot-${index}`}
												code={info.code}
												kind={info.kind}
												point={mesh.point}
												checked={assemblies.some(({ id }) => (selection[id]?.quantity ?? 0) > 0)}
												highlighted={highlighted.has(info.id)}
												searched={searched}
												far={zoom < 0.9 && segmented}
												onClick={onClick}
												onDoubleClick={onDoubleClick}
											/>
										);
									}

									return null;
								})}
								{objects.polygons.map(({ mesh, infos }, index) => {
									const ids = infos.map(({ id }) => id);
									const key = `${ids.join('-')}-polygon-${index}`;

									// todo (vincent) make this a proper lookup or reverse searchable
									const parents = compact(
										infos.map(info => (info.kind === 'assembly' ? info.relation.parent : null))
									);

									const parentAssemblies = partSlots
										.filter(partSlot => {
											const ids =
												partSlot.kind === 'assembly'
													? [partSlot.id, ...partSlot.relation.children]
													: [partSlot.id];
											return ids.some(id => parents.includes(id));
										})
										.flatMap(slot => (slot.kind === 'assembly' ? slot.assemblies : []));

									const assemblies = infos.flatMap(info =>
										info.kind === 'assembly' ? info.assemblies : []
									);

									const state = ids.some(id => highlighted.has(id))
										? 'highlighted'
										: parents.some(id => related.has(id))
											? 'related'
											: [...assemblies, ...parentAssemblies].some(
														({ id }) => (selection[id]?.quantity ?? 0) > 0
												  )
												? 'added'
												: q &&
													  assemblies.some(({ searchables }) =>
															searchables.some(searchable => filterTokenMatch(searchable, q))
													  )
													? 'searched'
													: infos.every(({ kind }) => kind === 'reference')
														? 'navigable'
														: 'interactive';

									return (
										<PartsDiagramSuspenseSegment
											key={key}
											url={diagram.image.full}
											ratio={sizes.ratio}
											scale={sizes.scale}
											polygon={mesh.polygon}
											position={vec3(0, 0, 0)}
											state={state}
											renderOrder={objects.meshes.length + index}
											onClick={() => {
												return actions.part.highlight(ids);
											}}
											onDoubleClick={() => {
												if (assemblies.length === 1) {
													actions.part.add([assemblies[0]]);
													actions.part.remove(
														flattenPartSlots(assemblies[0].subAssemblies ?? []).flatMap(each =>
															each.kind === 'assembly' ? each.assemblies : []
														)
													);
												}
												if (!ids.some(id => highlighted.has(id))) {
													actions.part.highlight(ids);
												}
											}}
											onPointerOver={() => setHovering(prev => [...prev, key])}
											onPointerOut={() => setHovering(prev => prev.filter(p => p !== key))}
										/>
									);
								})}
							</>
						)}
					</>
				)}

				<OrbitControls
					position={[0, 0, 0]}
					ref={orbitControls => {
						if (!orbitControls) {
							return;
						}
						actions.controls.start(orbitControls);
					}}
					enablePan
					enableDamping
					enableRotate={false}
					zoomSpeed={1.25}
					dampingFactor={0.1}
					maxDistance={DEFAULT_DISTANCE_Z + MAX_MIN_DISTANCE_Z}
					minDistance={DEFAULT_DISTANCE_Z - MAX_MIN_DISTANCE_Z}
					mouseButtons={{
						LEFT: MOUSE.PAN,
						MIDDLE: MOUSE.PAN
					}}
					touches={{
						ONE: TOUCH.PAN,
						TWO: TOUCH.DOLLY_PAN
					}}
					onChange={event => {
						if (!event) {
							return;
						}
						actions.controls.change(event.target.object);
					}}
				/>
			</Canvas>

			{segmented && (
				<div className="absolute bottom-6 right-6 z-[5] select-none">
					<Popover width={250} withinPortal position="top-end">
						<Popover.Target>
							<Badge className="cursor-pointer" size="small" rounded variant="purple">
								<Badge.LeadingIcon as={AiLogo} />
								Interactive diagram
							</Badge>
						</Popover.Target>
						<Popover.Dropdown className="flex flex-col gap-2">
							<span className="text-sm text-gray-600 [text-wrap:pretty]">
								Part in this diagram are interactive / clickable
							</span>
							<Divider className="my-1" />
							<div className="flex items-center gap-2.5 w-full">
								<span className="size-3.5 bg-purple-100 rounded-sm border-2 border-purple-600" />
								<span className="text-sm">Interactive part</span>
							</div>
							<div className="flex items-center gap-2.5 w-full">
								<span className="size-3.5 bg-blue-100 rounded-sm border-2 border-blue-600" />
								<span className="text-sm">Highlighted part</span>
							</div>
							<div className="flex items-center gap-2.5 w-full">
								<span className="size-3.5 bg-emerald-100 rounded-sm border-2 border-emerald-600" />
								<span className="text-sm">Added part</span>
							</div>
							<div className="flex items-center gap-2.5 w-full">
								<span className="size-3.5 bg-amber-100 rounded-sm border-2 border-amber-600" />
								<span className="text-sm">Part matching search</span>
							</div>
							<div className="flex items-center gap-2.5 w-full">
								<span className="size-3.5 bg-slate-100 rounded-sm border-2 border-slate-600" />
								<span className="text-sm">Link to other diagrams</span>
							</div>
						</Popover.Dropdown>
					</Popover>
				</div>
			)}

			{!isNil(error) && (
				<div className="absolute bottom-6 right-6 z-[5]  select-none">
					<Popover width={200} withinPortal position="top-end">
						<Popover.Target>
							<Badge className="cursor-pointer" size="small" rounded variant="red">
								<Badge.LeadingIcon as={Cog6ToothIcon} />
								Fallback diagram
							</Badge>
						</Popover.Target>
						<Popover.Dropdown className="flex flex-col gap-2">
							<span className="text-sm text-gray-600 [text-wrap:pretty]">
								Encountered some issues this diagram, reverted to simple diagram
							</span>
						</Popover.Dropdown>
					</Popover>
				</div>
			)}
		</>
	);
};

export const PartsDiagramSuspenseRenderer = ({ className, ...rest }: PartsDiagramRendererProps) => (
	<Suspense
		fallback={
			<div key="parts-diagram-loader" className={tlsx('w-full grid place-items-center', className)}>
				<Loader variant="bars" />
			</div>
		}
	>
		<PartsDiagramRenderer className={className} {...rest} />
	</Suspense>
);

export type PartsDiagramViewProps = InheritableElementProps<
	'div',
	{
		cut?: CategoryTreeNode | null;
		category?: CategoryTreeLeaf | null;
		partSlots: DiagramPartSlot[];
		control: Control<PartsFormData>;
		selection: PartsFormData;
		highlighted: Set<string>;
		actions: {
			part: {
				highlight: (ids: string[]) => void;
				remove: (assemblies: DiagramAssembly[]) => void;
			};
			diagram: {
				jump: (cutId: string | null, categoryId: string, diagramId: string) => void;
			};
		};
	}
>;

export const PartsDiagramView = ({
	partSlots,
	control,
	selection,
	highlighted,
	className,
	style,
	actions,
	...rest
}: PartsDiagramViewProps) => {
	const { value: nav } = useMeasurement('navigation-bar');

	return (
		<div
			className={tlsx(
				'absolute z-10 top-4 right-4 pb-4 flex flex-col gap-2.5 w-[26rem] overflow-auto [scrollbar-gutter:stable]',
				className
			)}
			style={{
				maxHeight: `calc(100dvh - ${nav?.height}px - 6rem)`,
				...style
			}}
			{...rest}
		>
			{partSlots.map(partSlot => (
				<PartsDiagramPartSlot
					key={partSlot.id}
					partSlot={partSlot}
					control={control}
					selection={selection}
					highlighted={highlighted}
					actions={actions}
				/>
			))}
		</div>
	);
};
