import { localiseCondition, ConditionLocalisation } from '@common/utils/grade';
import { TagIcon } from '@heroicons/react/24/outline';
import { lookup } from '@partly/core-server-client';
import { ConditionFormat, getFeatureFlags } from '@common/utils/feature-flag';
import { uniqBy } from 'lodash-es';

interface Condition {
	condition?: lookup.SellableCondition | null;
	isOem?: boolean;
	isOes?: boolean;
}

interface ConditionBadgeProps {
	conditions: Condition[] | Condition;
	color?: string;
}

export const ConditionBadge = ({ conditions, color }: ConditionBadgeProps) => {
	const { conditionBadgeFormat } = getFeatureFlags();
	if (!Array.isArray(conditions)) {
		conditions = [conditions];
	}

	const showConditionLabel = (condition: ConditionLocalisation) => {
		// this will likely get unified in the future
		if (conditionBadgeFormat === ConditionFormat.MfrType) {
			if (
				(condition.mfrType === 'OEM' || condition.mfrType === 'OES') &&
				condition.grade &&
				condition.grade !== 'New'
			) {
				return condition.grade;
			} else {
				return condition.mfrType;
			}
		}
		return condition.grade;
	};

	const formattedConditions = uniqBy(
		conditions
			.map(({ condition, isOem, isOes }) => {
				const localisedCondition = localiseCondition({
					condition: condition ?? null,
					isOem,
					isOes
				});
				return {
					...localisedCondition,
					value: showConditionLabel(localisedCondition)
				};
			})
			.filter(e => !!e.value),
		e => e.value
	);

	if (formattedConditions.length === 0) {
		return null;
	}
	const firstCondition = formattedConditions[0];
	const isMultiple = formattedConditions.length > 1;

	const showLabel = () => {
		if (isMultiple) {
			return 'Multiple conditions';
		}
		return firstCondition.value;
	};

	return (
		<div className="flex items-center justify-center text-inherit gap-1">
			{firstCondition.grade === '' || firstCondition.grade === 'New' || isMultiple ? (
				<TagIcon className="-mb-0.5" color={color || 'blue'} width={17} />
			) : (
				<div className="mb-[1px]">
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M4.67261 10.9972C4.7498 11.2853 5.04591 11.4562 5.33398 11.379C5.62205 11.3018 5.793 11.0057 5.71581 10.7177L5.23533 8.92445C5.13241 8.54036 4.73761 8.31242 4.35351 8.41534L2.5603 8.89583C2.27223 8.97301 2.10127 9.26912 2.17846 9.55719C2.25565 9.84526 2.55175 10.0162 2.83982 9.93903L3.30149 9.81532L1.76291 12.4802C1.1394 13.5602 1.91873 14.9101 3.16576 14.9102L7.64998 14.9105C8.04762 14.9106 8.37 14.5882 8.37003 14.1906C8.37006 13.793 8.04773 13.4706 7.65008 13.4705L3.16586 13.4702C3.0273 13.4702 2.94071 13.3202 3.00999 13.2002L4.54877 10.535L4.67261 10.9972Z"
							fill={color === 'white' ? '#fff' : '#0E9F6E'}
						/>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M5.31717 6.3919C5.12774 6.74153 5.25761 7.17851 5.60724 7.36794C5.95687 7.55737 6.39386 7.4275 6.58328 7.07787L8.68463 3.19938C8.75167 3.07564 8.92841 3.07325 8.99878 3.19513L10.5127 5.81736L10.0506 5.69355C9.76258 5.61636 9.46648 5.78732 9.38929 6.07539C9.3121 6.36346 9.48305 6.65956 9.77113 6.73675L11.5643 7.21724C11.9484 7.32016 12.3432 7.09222 12.4462 6.70812L12.9266 4.91491C13.0038 4.62684 12.8329 4.33074 12.5448 4.25355C12.2567 4.17636 11.9606 4.34732 11.8834 4.63539L11.7597 5.09719L10.2459 2.47513C9.61253 1.37818 8.02191 1.39972 7.41852 2.51341L5.31717 6.3919Z"
							fill={color === 'white' ? '#fff' : '#0E9F6E'}
						/>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M13.8982 8.80429C13.6901 8.46543 13.2467 8.3594 12.9078 8.56748C12.569 8.77555 12.463 9.21893 12.671 9.55779L14.9792 13.3169C15.0529 13.4368 14.9666 13.591 14.8258 13.591H11.7981L12.1364 13.2528C12.3473 13.0419 12.3473 12.7 12.1364 12.4891C11.9255 12.2782 11.5836 12.2782 11.3727 12.4891L10.06 13.8018C9.77883 14.083 9.77883 14.5389 10.06 14.8201L11.3727 16.1328C11.5836 16.3437 11.9255 16.3437 12.1364 16.1328C12.3473 15.9219 12.3473 15.58 12.1364 15.3691L11.7983 15.031H14.8258C16.0925 15.031 16.8691 13.6428 16.2064 12.5634L13.8982 8.80429Z"
							fill={color === 'white' ? '#fff' : '#0E9F6E'}
						/>
					</svg>
				</div>
			)}
			<span className="mt-[1px] whitespace-nowrap">{showLabel()}</span>
		</div>
	);
};
