import { BasicTable } from '@common/components/order-table';
import { core_order } from '@/sdk/reflect/reflect';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { memo } from 'react';
import { useOrderTableItems } from '../../hooks/use-order-table-items';
import { orderColumnBuilder } from '../table-builder';
import { OrderSection } from '../order-section';

type CancelledOrderProps = {
	model: core_order.SupplierOrder;
};

const builder = orderColumnBuilder();
const tableColumns = [
	builder.quantity(),
	builder.name(),
	builder.status(),
	builder.condition(),
	builder.price()
];

export const CancelledOrder = memo(({ model }: CancelledOrderProps) => {
	const { items, subtotal } = useOrderTableItems(model.items, model.purchase_currency_code ?? null);

	const table = useReactTable({
		data: items,
		columns: tableColumns,
		getCoreRowModel: getCoreRowModel()
	});

	const cancelReason = (() => {
		if (typeof model.status === 'object' && 'cancelled' in model.status) {
			return model.status.cancelled.note;
		}

		return undefined;
	})();

	return (
		<OrderSection className="bg-white">
			<OrderSection.Content className="flex items-center justify-between border-b">
				<div className="flex items-start gap-2">
					<XCircleIcon className="text-red-500 size-5" />
					<div>
						<div className="font-semibold leading-5 text-gray-900">Order cancelled</div>
						<p className="mt-0.5 text-sm text-gray-700">
							{cancelReason ?? 'This order has been cancelled'}
						</p>
					</div>
				</div>
			</OrderSection.Content>
			<OrderSection.Content className="space-y-3">
				<OrderSection.Title>
					{model.supplier_contact?.organization.name} (#{model.external_id})
				</OrderSection.Title>
				<div className="mt-3 overflow-x-auto">
					<BasicTable table={table} />
				</div>
				<BasicTable.Subtotal
					subtotal={subtotal}
					currency={model.purchase_currency_code ?? undefined}
				/>
			</OrderSection.Content>
		</OrderSection>
	);
});
