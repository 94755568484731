import { OfferRequest, OfferRequestsAggregation } from '../models';

export const getLatestPendingOfferRequest = (offerRequestAggregation: OfferRequestsAggregation) => {
	let latest: OfferRequest | null = null;

	Object.keys(offerRequestAggregation).forEach(key => {
		const request = offerRequestAggregation[key];

		if (request.status === 'pending') {
			if (latest) {
				if (
					new Date(request.request.created_at).getTime() >
					new Date(latest.request.created_at).getTime()
				) {
					latest = request;
				}
			} else {
				latest = request;
			}
		}
	});
	return latest;
};
