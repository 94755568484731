import { UvdbProperty, VehicleConfiguration } from '@sdk/lib';
import { getYearRange } from '@common/utils/date';
import { capitalize, startsWith } from 'lodash-es';

const getValue = (property: UvdbProperty | UvdbProperty[] | undefined, format?: string) => {
	let text;
	if (Array.isArray(property)) {
		text = property.map(p => p.name).join(', ');
	} else {
		text = property?.name;
	}

	if (format && text) {
		return `${text} ${format}`;
	}
	return text;
};

export const createVehicleName = ({ properties, uvdbProperties }: VehicleConfiguration) => {
	let oem_model = properties.find(v => v.type === 'Model')?.value;

	if (oem_model) {
		const [firstWord, ...rest] = (oem_model as string).split(' ');
		oem_model = `${capitalize(firstWord)} ${rest.join(' ')}`.trim();
	}

	let make = getValue(uvdbProperties.UvdbMake);
	const model = getValue(uvdbProperties.UvdbModel) ?? oem_model;

	// Removes doubled makes from vehicle title
	// (may occur when use oem model that contains make as well)
	if (startsWith(String(model).toUpperCase(), String(make).toUpperCase())) {
		make = '';
	}

	return [
		make,
		model,
		getYearRange({
			year: getValue(uvdbProperties.UvdbYear),
			yearFrom: getValue(uvdbProperties.UvdbYearFrom),
			yearTo: getValue(uvdbProperties.UvdbYearTo),
			monthFrom: getValue(uvdbProperties.UvdbMonthFrom),
			monthTo: getValue(uvdbProperties.UvdbMonthTo)
		}),
		getValue(uvdbProperties.UvdbSeries)
	].join(' ');
};

export const createVehicleDescription = (vehicle: VehicleConfiguration) => {
	const { uvdbProperties } = vehicle;

	// note: only make sense when uvdb model exists,
	// otherwise we use oem description for vehicle name, that may already include submodel
	const submodel = getValue(uvdbProperties.UvdbModel)
		? getValue(uvdbProperties.UvdbSubmodel)
		: null;

	return [
		createVehicleName(vehicle),
		submodel,
		getValue(uvdbProperties.UvdbEngineBase),
		getValue(uvdbProperties.UvdbFuelType),
		getValue(uvdbProperties.UvdbEngineVersion),
		getValue(uvdbProperties.UvdbEngineDesignation),
		getValue(uvdbProperties.UvdbAspiration),
		getValue(uvdbProperties.UvdbMfrBodyCode),
		getValue(uvdbProperties.UvdbBodyNumDoors, 'door'),
		getValue(uvdbProperties.UvdbBodyType),
		getValue(uvdbProperties.UvdbDriveType),
		getValue(uvdbProperties.UvdbTransmissionControlType),
		getValue(uvdbProperties.UvdbRegion),
		getValue(uvdbProperties.UvdbEnginePowerOutput)
	]
		.filter(Boolean)
		.join(' ');
};
