import { useAuthenticatedUser } from '@common/hooks/use-me';
import BasketsSection from '@features/supply/components/baskets-section';
import JobPartsOffersSection from '@features/supply/components/job-parts-offers-section';
import ContextModal from '@features/supply/components/context-modal';
import { EMPTY_STATE, EXTRA_JOB_PART } from '@features/supply/constants';
import { useOfferRequestsAggregation } from '@features/supply/hooks/use-offer-requests-aggregation';
import { usePartOfferAgregations } from '@features/supply/hooks/use-part-offer-agregations';
import { useRecommendedBaskets } from '@features/supply/hooks/use-recommended-baskets';
import {
	ActionType,
	Basket,
	OrdersBySupply,
	OrderSelection,
	SellableOffer
} from '@features/supply/models';

import {
	applyOfferSelection,
	createInitialSelection,
	formatSellableOffers,
	generateOrderId,
	getDeliveryDateFromSelectedOffers,
	getLatestPendingOfferRequest,
	getMatchingBasket,
	getOfferSellable,
	isNewAcceptedOfferRequest,
	onError
} from '@features/supply/utils';
import { useOfferSelection } from '@features/supply/hooks/use-offer-selection';
import { useUnsavedChanges } from '@common/hooks/use-unsaved-changes';
import { useWatchForm } from '@common/hooks/use-watch-form';
import { createMoney, getCurrency } from '@common/utils/currency';
import { BmsUpsertPartDataRequest } from '@/sdk/generated/models/BmsUpsertPartDataRequest';
import { JobPart } from '@/sdk/lib';
import { jobsQueries } from '@/sdk/react';
import { mutations } from '@/sdk/react/mutations';
import { bmsMutations } from '@/sdk/react/mutations/bms';
import { queries } from '@/sdk/react/queries';
import { basket, order_handler } from '@/sdk/reflect/reflect';
import { usePrevious } from '@mantine/hooks';
import { isDefined } from '@partly/js-ex';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { flatten, groupBy } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { jobPartOfferSearch } from './core-client';
import { SupplySummaryToolbar } from '@features/supply/components/supply-summary-toolbar';
import { useOrderRequestAggregation } from '@features/supply/hooks/use-order-request-aggregation';
import { lookup } from '@partly/core-server-client';
import OrderMetadata from '@/app/features/supply/components/order-metadata';

type PageParams = {
	jobId: string;
};

export const SupplyPage = () => {
	const { jobId } = useParams<PageParams>();
	const shouldSetInitialState = useRef(true);
	const initialMount = useRef(true);

	const navigate = useNavigate();
	const { sites /* user */ } = useAuthenticatedUser();

	const { vendors } = useAuthenticatedUser();

	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	// Queries
	const { data: jobData, isFetching: isFetchingJob } = useSuspenseQuery({
		...jobsQueries.get({ jobId }),
		gcTime: 0,
		staleTime: 0
	});

	const { data: basketData, refetch: refetchBasket } = useSuspenseQuery(
		queries.basket.get({ job_id: jobId })
	);

	const { data: offerRequestsData, refetch: refetchOfferRequests } = useSuspenseQuery({
		...queries.offer_requests.get({ job_id: jobId }),
		refetchInterval: 30000
	});

	const previousOfferRequests = usePrevious(offerRequestsData);

	// Custom query combining job parts and offers
	const { data: offerData, refetch: refetchOffers } = useSuspenseQuery(
		jobPartOfferSearch({
			job_id: jobId,
			repairerSiteId: jobData.job.repairerSiteId,
			offer_request_ids: offerRequestsData?.offer_requests.map(r => r.id)
		})
	);

	const { data: jobConfirimationData } = useSuspenseQuery({
		...jobsQueries.getJobConfirmation({ job_id: jobId })
	});

	const { data: orderData } = useQuery(queries.orders.list({ job_id: jobId }));

	// Mutations
	const { mutateAsync: insertOrder } = useMutation(mutations.orders.insert);

	const {
		mutateAsync: upsertJobParts,
		isSuccess,
		isPending,
		reset: resetBmsSyncState
	} = useMutation({
		...bmsMutations.upsertJobParts
	});

	// const { mutateAsync: syncOrderedParts, isPending: orderPending } = useMutation({
	// 	...bmsMutations.syncorderedJobParts
	// });
	//
	const { mutateAsync: updateBasket } = useMutation({
		...mutations.baskets.update,
		onError
	});

	const { mutateAsync: insertOfferRequest } = useMutation({
		...mutations.offer_requests.insert,
		onError,
		onSuccess: () => toast.success('Offer request sent')
	});

	const { mutateAsync: insertOfferRequestEvent } = useMutation({
		...mutations.offer_requests.insert_event,
		retry: 3,
		onError
	});

	const partOfferAggregations = usePartOfferAgregations(
		offerData.job_parts,
		offerData.matches,
		offerData.offers,
		// offerData.job_readiness === 'ready',
		offerRequestsData
	);

	const offerRequestsAggregation = useOfferRequestsAggregation(offerRequestsData, jobId);

	const recommendedBaskets = useRecommendedBaskets(
		offerData,
		partOfferAggregations,
		offerRequestsData
	);

	const form = useForm<OrderSelection>({
		defaultValues: {
			// We don't want to update the basket on initial mount so we use this const to check if is initial
			items: [{ id: EMPTY_STATE, offer_id: EMPTY_STATE }],
			repairer_site_id: jobData.job.repairerSiteId,
			deliver_before: new Date(),
			delivery_date: new Date()
		}
	});

	const formSelection = useWatchForm(form);
	const offerSelection = useOfferSelection(formSelection);

	useUnsavedChanges(({ nextLocation }) => {
		const isConfirmation = nextLocation.pathname.startsWith(`/job/${jobId}/orders`);
		const isBlocking = form.formState.isDirty && !form.formState.isSubmitting && !isConfirmation;
		return isBlocking;
	});

	const allOffers = useMemo(
		() => formatSellableOffers(offerData.offers, offerRequestsData),
		[offerData.offers, offerRequestsData]
	);

	const minDeliveryDate = useMemo(() => {
		const date = getDeliveryDateFromSelectedOffers(
			allOffers.filter(o => offerSelection.has(o.id)),
			formSelection.deliver_before
		);
		if (jobConfirimationData) {
			date.setHours(new Date(jobConfirimationData.delivery_date).getHours());
		}

		return date;
	}, [allOffers, formSelection, jobConfirimationData]);

	const latestPendingOfferRequest = useMemo(
		() => getLatestPendingOfferRequest(offerRequestsAggregation) || undefined,
		[offerRequestsAggregation]
	);

	const pendingOfferRequestsSupplierIds = useMemo(
		() =>
			Object.keys(offerRequestsAggregation).filter(
				supId => offerRequestsAggregation[supId].status === 'pending'
			),
		[offerRequestsAggregation]
	);

	const ordersPerSupplier = useMemo<OrdersBySupply[]>(() => {
		const selectedOffers = allOffers.filter(offer => offerSelection.has(offer.id));

		const groupedBySupplier = groupBy(selectedOffers, 'business.id');

		return Object.keys(groupedBySupplier)
			.map(supplierId => ({
				supplier: vendors.find(sup => sup.id === supplierId),
				jobParts: partOfferAggregations
					.filter(aggr =>
						aggr.offers.some(o => groupedBySupplier[supplierId].some(offer => offer.id === o.id))
					)
					.map(aggr => ({
						jobPart: aggr.jobPart,
						offers: groupedBySupplier[supplierId].filter(offer =>
							aggr.offers.some(o => o.id === offer.id)
						)
					}))
			}))
			.filter(order => isDefined(order.supplier)) as OrdersBySupply[];
	}, [allOffers, offerSelection]);

	const orderSummary = useOrderRequestAggregation(formSelection, allOffers);

	// State
	const [selectedBasket, setSelectedBasket] = useState<Basket | null>(null);
	const [contextModalData, setContextModalData] = useState<{
		mode: ActionType;
		data: Omit<basket.BasketItem, 'intent'>[] | OrdersBySupply[];
	} | null>(null);

	// Handlers
	const onUpdateBasket = async (newState: SellableOffer[]) => {
		const basketItems = basketData.basket.items;

		const itemsToRemove = basketItems.filter(
			item => !newState.some(offer => item.offer_id === offer.id)
		);

		const itemsToInsert = newState
			.filter(offer => !basketItems.some(item => item.offer_id === offer.id))
			.map(offer => ({
				...offer,
				quantity:
					offer.payload.kind === 'assembly'
						? 1
						: partOfferAggregations.find(aggr => aggr.offers.some(o => o.id === offer.id))?.jobPart
								.quantity || 1
			}));

		if (itemsToRemove.length > 0 || itemsToInsert.length > 0) {
			await updateBasket({
				job_id: jobId,
				data: [
					{
						update: {
							id: basketData.basket.id,
							items: [
								...itemsToRemove.map<{ remove: string }>(item => ({
									remove: item.id
								})),

								...itemsToInsert.map(offerToInsert => ({
									insert: {
										offer_id: offerToInsert.id,
										quantity: offerToInsert.quantity,
										intent: { repair: { note: null } }
									}
								}))
							]
						}
					}
				]
			});
			refetchBasket();
		}
	};

	const onBasketSelect = (basket: Basket | null) => {
		setSelectedBasket(basket);

		if (basket) {
			const newSelection = createInitialSelection(
				basket?.offersPerJobPart,
				partOfferAggregations,
				jobData.job.repairerSiteId,
				formSelection.delivery_date,
				formSelection.deliver_before
			);

			form.reset(newSelection);
			resetBmsSyncState();
		}
	};

	const onOfferSelect = (jobPart: JobPart, offer: SellableOffer) => {
		applyOfferSelection(jobPart, offer, form);
		resetBmsSyncState();
	};

	// Todo: this is gross and needs a refactor
	const onPlaceOrders = async (data: OrdersBySupply[], deliveryDate?: Date, note?: string) => {
		const selectedOffers = allOffers.filter(offer => offerSelection.has(offer.id));

		onUpdateBasket(selectedOffers);

		const { job } = jobData;

		const repairSite = sites.find(site => site.id === job.repairerSiteId);
		const existingIds = orderData?.map(order => order.external_id).filter(isDefined) ?? [];
		const existingSet = new Set(existingIds);

		await data.reduce<Promise<void>>(async (prevPromise, { supplier, jobParts }) => {
			// Ensure previous order is completed before starting the next
			await prevPromise;

			const identity = generateOrderId(job.jobNumber ?? 'Order', existingSet);

			const newOrder: order_handler.JobOrdersInsertRequest = {
				job_id: jobId,
				identity,
				supply_snaphot_hash: offerData.snapshot_hash,
				business_id: supplier.id,
				buyer: {
					billing_address: {
						name: repairSite?.name,
						...repairSite?.shipping_address
					},
					shipping_address: {
						name: repairSite?.name,
						...repairSite?.shipping_address
					},
					images: jobConfirimationData?.images,
					note,
					purchase_currency_code: getCurrency(),
					deliver_before: deliveryDate
						? deliveryDate.toISOString()
						: formSelection.delivery_date.toISOString(),
					vehicle_context: [
						{
							name: job.vehicle?.variant?.description ?? 'N/A',
							chassis_number: job.vehicle?.chassisNumber,
							plate_number: job.vehicle?.plateNumber,
							plate_state: job.vehicle?.plateState
						}
					],
					items: flatten(
						jobParts.map(part => {
							return part.offers.map(offer => {
								const [return_policy, purchase_price, condition] = (() => {
									const return_policy: lookup.ReturnPolicy | undefined = (() => {
										if (!offer.returnPolicy) {
											return undefined;
										}

										if (
											typeof offer.returnPolicy === 'string' &&
											offer.returnPolicy === 'no_returns'
										) {
											return 'no_returns';
										}

										return {
											allow_returns: {
												condition: null,
												before_date: offer.returnPolicy.allow_returns.before_date ?? null
											}
										};
									})();

									switch (offer.payload.kind) {
										case 'product':
											return [
												return_policy,
												offer.payload.price || '0',
												offer.payload.entity.condition
											];
										case 'assembly':
											return [
												return_policy,
												offer.payload.sellable.price || '0',
												offer.payload.sellable.entity.condition
											];
									}
								})();

								return {
									identity: part.jobPart.id,
									buyable: {
										type: 'store', // how does external work?
										sellable_id: offer.id,
										store_address_id: null
									},
									quantity: part.jobPart.quantity,
									purchase_price,
									condition,
									return_policy,
									part_selection_contexts: [
										{
											description:
												part.jobPart.description === EXTRA_JOB_PART
													? getOfferSellable(offer).entity.name
													: part?.jobPart.description,
											gapc_brand_id: part?.jobPart.gapcBrand?.id,
											gapc_part_type_id: part?.jobPart.partSlot?.gapcPartType?.id,
											gapc_position_id: part?.jobPart.partSlot?.gapcPosition?.id,
											ghca_id: part?.jobPart.ghcaId,
											// We aren't using hca path anymore
											hca_path: null,
											mpn: part?.jobPart.mpn ?? undefined
										}
									]
								};
							});
						})
					)
				}
			};

			return insertOrder(newOrder).then(order => {
				console.log('Order inserted', order.id);
			});
		}, Promise.resolve());

		navigate(`/job/${jobId}`);
		return true;
	};

	const onSyncBms = async () => {
		if (jobData.job.externalId) {
			const upserts = partOfferAggregations
				.filter(item => item.offers.find(o => offerSelection.has(o.id)))
				.map<BmsUpsertPartDataRequest>(aggr => {
					const selectedOffers = aggr.offers.filter(o => offerSelection.has(o.id));
					const sellable = selectedOffers.length > 0 ? getOfferSellable(selectedOffers[0]) : null;
					return {
						job_part_id: aggr.jobPart.id,
						description: aggr.jobPart.description,
						grade: sellable ? (sellable.entity.condition === 'new' ? 'New' : 'Used') : 'New',
						quantity: aggr.jobPart.quantity,
						price: sellable ? createMoney(Number(sellable.price || 0)) : createMoney(0)
					};
				});

			await upsertJobParts({
				job_id: jobId,
				data: upserts
			});
		}
	};

	// const syncOrderedPartsToiBody = async () => {
	// 	console.log('Syncing Ordered Parts');
	// 	await syncOrderedParts({ job_id: jobId });
	// };

	const onSendOfferRequestEvent = async (offerRequestId: string, note?: string) => {
		await insertOfferRequestEvent({
			job_id: jobId,
			shapshot_hash: offerData.snapshot_hash,
			note: note ?? null,
			images: jobConfirimationData?.images || null,
			request_id: offerRequestId,
			offer_ids: null
		});
		refetchOfferRequests();
	};

	/**
	 * Send offer request for the entire basket
	 */
	const onAddSuppliers = async (
		supplierIds: string[],
		note?: string,
		isAuto?: boolean
	): Promise<boolean> => {
		// Prevent sending offer request for same jobId and same supplierId
		const newSupplierIds = supplierIds.filter(
			supId => !offerRequestsData.offer_requests.some(request => request.store_id === supId)
		);

		const existingOfferRequestsIds = offerRequestsData.offer_requests
			.filter(offerRequest => supplierIds.some(supId => supId === offerRequest.store_id))
			.map(offerRequest => offerRequest.id);

		const shouldSend = isAuto ? false : true;
		// TODO: if auto check the conditions below
		// user.repairerOrganizationId === PARTLY_REPAIRER_BUSINESS_ID ||
		// user.repairerOrganizationId === MOTORHUB_REPAIRER_BUSINESS_ID;

		if (newSupplierIds.length > 0 && shouldSend) {
			const response = await insertOfferRequest({ job_id: jobId, store_ids: newSupplierIds });
			if (response.items) {
				const promiseArr = response.items.map(requestId =>
					onSendOfferRequestEvent(requestId, note)
				);
				await Promise.all(promiseArr);

				refetchOfferRequests();
			}
		} else if (existingOfferRequestsIds.length > 0 && shouldSend) {
			// Update the offer requests
			const promiseArr = existingOfferRequestsIds.map(id => onSendOfferRequestEvent(id, note));
			await Promise.all(promiseArr);
		}
		return true;
	};

	// Effects
	useEffect(() => {
		if (jobData.job.needsConfirmation) {
			navigate(`/job/${jobId}/confirmation`);
			return;
		}

		// Apply initial selection from basket or recommendation
		if (
			shouldSetInitialState.current &&
			!isFetchingJob &&
			recommendedBaskets &&
			orderData &&
			jobConfirimationData
		) {
			const requestedDeliveryDate = new Date(jobConfirimationData.delivery_date);

			if (
				recommendedBaskets.length > 0 &&
				offerData.job_readiness === 'ready' &&
				orderData.length === 0
			) {
				// Auto select basket
				form.reset(
					createInitialSelection(
						recommendedBaskets[0].offersPerJobPart,
						partOfferAggregations,
						jobData.job.repairerSiteId,
						requestedDeliveryDate,
						requestedDeliveryDate
					)
				);
			} else {
				// Set delivery date to date from job confirmation
				form.setValue('deliver_before', requestedDeliveryDate);
				form.setValue('delivery_date', requestedDeliveryDate);
			}

			if (offerData.offer_requests.length > 0) {
				// Automatically send offer requests
				onAddSuppliers(
					offerData.offer_requests.map(request => request.supplier.id),
					jobConfirimationData?.note ?? undefined,
					true
				);
			}

			shouldSetInitialState.current = false;
		}
	}, [offerData, recommendedBaskets, jobData, isFetchingJob, orderData, jobConfirimationData]);

	useEffect(() => {
		// Set delivery date
		if (allOffers.length > 0 && jobConfirimationData) {
			if (minDeliveryDate > new Date(jobConfirimationData.delivery_date)) {
				form.setValue('delivery_date', minDeliveryDate);
			} else {
				form.setValue('delivery_date', new Date(jobConfirimationData.delivery_date));
			}
		}
	}, [minDeliveryDate]);

	useEffect(() => {
		if (offerRequestsData?.offer_requests.length > 0 && offerData && initialMount.current) {
			// Unlikely to happen but if for some reason an offer request is missing events we want to insert the initial one
			offerRequestsData.offer_requests
				.filter(request => request.events.length === 0)
				.forEach(request => {
					onSendOfferRequestEvent(request.id);
				});

			initialMount.current = false;
		} else if (
			offerRequestsData?.offer_requests &&
			previousOfferRequests?.offer_requests &&
			isNewAcceptedOfferRequest(previousOfferRequests, offerRequestsData)
		) {
			// If newly accepted offer request, refetch offers to get the request offers
			onUpdateBasket(allOffers.filter(o => offerSelection.has(o.id)));
			refetchOffers();
		}
	}, [offerRequestsData, offerData]);

	useEffect(() => {
		// Select matching basket from the selection (when user selects the offers instead of the whole basket)
		setSelectedBasket(getMatchingBasket(recommendedBaskets, offerSelection));
	}, [offerSelection, recommendedBaskets]);

	return (
		<div className="pb-20">
			<ContextModal
				open={Boolean(contextModalData)}
				excludedSupplierIds={pendingOfferRequestsSupplierIds}
				defaultValues={{
					note: jobConfirimationData?.note || '',
					deliveryDate:
						formSelection.deliver_before > formSelection.delivery_date
							? formSelection.deliver_before
							: formSelection.delivery_date,
					supplierIds: []
				}}
				minDeliveryDate={minDeliveryDate}
				context={
					contextModalData?.mode === ActionType.PlaceOrder ? (
						<OrderMetadata
							estimatedDeliveryDate={formSelection.delivery_date}
							requestedDeliveryDate={
								jobConfirimationData?.delivery_date
									? new Date(jobConfirimationData.delivery_date)
									: new Date()
							}
							ordersPerSupplier={ordersPerSupplier}
						/>
					) : null
				}
				mode={contextModalData?.mode}
				onClose={() => setContextModalData(null)}
				onSubmit={async (supId, note, deliveryDate) => {
					if (!contextModalData) {
						return false;
					}

					if (contextModalData.mode === ActionType.PlaceOrder) {
						// Handle place order
						return onPlaceOrders(contextModalData.data as OrdersBySupply[], deliveryDate, note);
					}

					return onAddSuppliers(supId, note, false);
				}}
			/>
			<BasketsSection
				baskets={recommendedBaskets}
				notReady={offerData.job_readiness === 'pending'}
				offerRequestsAggregation={offerRequestsAggregation}
				recommendedOfferRequests={offerData.offer_requests}
				deliverBefore={formSelection.deliver_before}
				selectedBasket={selectedBasket}
				onSelectBasket={onBasketSelect}
				showPendingNote={
					offerData.job_sensitivity === 'cost_sensitive' && offerData.job_readiness === 'pending'
				}
				onSendOfferRequest={(supplierId: string) =>
					onAddSuppliers([supplierId], jobConfirimationData?.note ?? undefined, false)
				}
				onAddSupplierClick={() => setContextModalData({ mode: ActionType.AddSuppliers, data: [] })}
				onSendMessage={(m, rId) => {
					onSendOfferRequestEvent(rId, m);
				}}
			/>
			<JobPartsOffersSection
				partOfferAggregations={partOfferAggregations}
				offerSelection={offerSelection}
				notReady={offerData.job_readiness === 'pending'}
				pendingRequest={latestPendingOfferRequest}
				deliverBefore={formSelection.deliver_before}
				onSendMessage={(m, rId) => {
					onSendOfferRequestEvent(rId, m);
				}}
				onAddSuppliersClick={() => setContextModalData({ mode: ActionType.AddSuppliers, data: [] })}
				onSelectionChange={onOfferSelect}
			/>
			<SupplySummaryToolbar
				totalCost={orderSummary.totalPrice}
				totalParts={orderSummary.totalItems}
				totalSuppliers={orderSummary.totalSuppliers}
				onIBodySync={onSyncBms}
				showIBody={jobData.job.source === 'iBodyShop'}
				disableIBody={isSuccess}
				onPlaceOrder={async () => {
					if (!isPending) await onSyncBms();
					setContextModalData({ mode: ActionType.PlaceOrder, data: ordersPerSupplier });
				}}
				bmsSource={jobData.job.source}
			/>
		</div>
	);
};
