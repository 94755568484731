import { JobStatus } from '@/sdk/lib';
import { InheritableElementProps } from '@/types/utilties';
import { Badge } from '@common/components/badge';

const statusProps: Record<JobStatus, { label: string; variant: string }> = {
	Draft: {
		label: 'Draft',
		variant: 'white'
	},
	Active: {
		label: 'In progress',
		variant: 'blue'
	},
	Ordering: {
		label: 'Ordering',
		variant: 'yellow'
	},
	Ordered: {
		label: 'Parts ordered',
		variant: 'green'
	},
	NeedsAttention: {
		label: 'Needs attention',
		variant: 'yellow'
	},
	Deleted: {
		label: 'Deleted',
		variant: 'pink'
	},
	Completed: {
		label: 'Completed',
		variant: 'green'
	},
	Closed: {
		label: 'Closed',
		variant: 'red'
	}
};

export type JobStatusBadgeProps = InheritableElementProps<
	'div',
	{
		status?: keyof typeof statusProps;
	}
>;

const JobStatusBadge = ({ status, ...rest }: JobStatusBadgeProps) => {
	if (!status) {
		return null;
	}

	const { label = 'Draft', variant = 'white' } = statusProps[status];

	return (
		<Badge className="ps-4 pe-4 py-0.5" size="small" variant={variant} rounded {...rest}>
			{label}
		</Badge>
	);
};

export default JobStatusBadge;
