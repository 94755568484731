import { tlsx } from '@common/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ReactComponent as AllIcon } from '@assets/parts/cuts/all.svg';
import { ReactComponent as FrontCutIcon } from '@assets/parts/cuts/front-cut.svg';
import { ReactComponent as MidCutIcon } from '@assets/parts/cuts/mid-cut.svg';
import { ReactComponent as OtherIcon } from '@assets/parts/cuts/other.svg';
import { ReactComponent as RearCutIcon } from '@assets/parts/cuts/rear-cut.svg';
import { ReactComponent as UnderbodyIcon } from '@assets/parts/cuts/underbody.svg';
import { CategoryTreeLeaf, CategoryTreeNode, PartsFormData } from '../../types';

type PartsCutProps = InheritableElementProps<
	'button',
	{
		cut: CategoryTreeNode;
		selected?: CategoryTreeNode | null;
		selection: PartsFormData;
		actions: {
			cut: {
				set: (cut: CategoryTreeNode) => void;
			};
		};
	}
>;

export const PartsCut = ({
	className,
	cut,
	selected,
	selection,
	actions,
	...rest
}: PartsCutProps) => {
	const icons: Record<
		string,
		React.FunctionComponent<
			React.SVGProps<SVGSVGElement> & {
				title?: string;
			}
		>
	> = {
		'Front Cut': FrontCutIcon,
		'Mid Cut': MidCutIcon,
		'Rear Cut': RearCutIcon,
		Underbody: UnderbodyIcon
	};
	const Icon = icons[cut.description] ?? OtherIcon;

	return (
		<button
			type="button"
			className={tlsx(
				'group rounded-md py-2 px-4 gap-2 hover:bg-gray-100 active:bg-gray-100 flex items-center justify-around shrink-0',
				{
					'bg-gray-100': selected?.id === cut.id
				},
				className
			)}
			onClick={() => {
				actions.cut.set(cut);
			}}
			{...rest}
		>
			<Icon className="h-8 w-14" />
			<span className="font-medium">{cut.description}</span>
		</button>
	);
};

type PartsAllDiagramProps = InheritableElementProps<
	'button',
	{
		other: CategoryTreeLeaf;
		category?: CategoryTreeLeaf | null;
		selection: PartsFormData;
		actions: {
			category: {
				set: (cut: CategoryTreeLeaf) => void;
			};
		};
	}
>;

export const PartsAllDiagram = ({
	className,
	other,
	category,
	selection,
	actions,
	...rest
}: PartsAllDiagramProps) => {
	return (
		<button
			type="button"
			className={tlsx(
				'group rounded-md py-2 px-4 gap-2 hover:bg-gray-100 active:bg-gray-100 flex items-center justify-around shrink-0',
				{
					'bg-gray-100': category?.id === other.id
				},
				className
			)}
			onClick={() => {
				actions.category.set(other);
			}}
			{...rest}
		>
			<AllIcon className="h-8 w-14" />
			<span className="font-medium">All diagrams</span>
		</button>
	);
};
