import { formatSubtotal } from '@common/utils/currency';
import { tlsx } from '@common/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { Button } from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';

type SupplySummaryToolbarProps = InheritableElementProps<
	'div',
	{
		totalParts: number;
		totalCost: number;
		totalSuppliers: number;
		disableIBody?: boolean;
		showIBody?: boolean;
		onIBodySync(): void;
		onPlaceOrder(): void;
		bmsSource: string;
	}
>;

export const SupplySummaryToolbar = forwardRef(
	(
		{
			className,
			totalCost,
			totalSuppliers,
			totalParts,
			showIBody,
			disableIBody,
			onIBodySync,
			onPlaceOrder,
			bmsSource,
			...rest
		}: SupplySummaryToolbarProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const subtotal = formatSubtotal({ price: totalCost, displayPrice: null });

		return (
			<div
				className={tlsx(
					'flex fixed  bg-white p-4 border-t-gray-200 border-t  w-full bottom-0 left-0 items-center justify-between',
					className
				)}
				{...rest}
				ref={ref}
			>
				{showIBody && (
					<div className="flex">
						<Button
							type="button"
							size="sm"
							variant="light"
							onClick={onIBodySync}
							disabled={disableIBody}
							leftIcon={<ArrowPathIcon className="size-4" />}
						>
							Sync to {bmsSource}
						</Button>
					</div>
				)}

				<div className="flex items-center gap-4"></div>
				<div className="flex items-center gap-6">
					{totalParts > 0 && (
						<dl className="flex gap-6">
							<dt>Order summary:</dt>
							<dd>{totalParts === 1 ? '1 item' : `${totalParts} items`}</dd>
							{totalSuppliers && (
								<dd>{totalSuppliers > 1 ? `Total suppliers ${totalSuppliers}` : '1 supplier'}</dd>
							)}
							<dd>{subtotal}</dd>
						</dl>
					)}
					<Button disabled={totalParts === 0} color="green" onClick={onPlaceOrder}>
						Place order
					</Button>
				</div>
			</div>
		);
	}
);
