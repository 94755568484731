import { session } from '@common/hooks/use-auth';
import { useAnalytics } from '@common/hooks/use-analytics';
import { ControlledPasswordInput } from '@features/user/components/controlled-password-input';
import { ControlledTextInput } from '@common/components/controlled-text-field';
import { ApiError } from '@/sdk/lib';
import { InheritableElementProps } from '@/types/utilties';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button } from '@mantine/core';
import { RepairApp } from '@partly/analytics';
import { EmailPasswordSigninRequest } from '@partly/bna-session';
import * as Sentry from '@sentry/browser';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const SigninSchema = yup.object({
	email: yup.string().email().required().label('Email'),
	password: yup.string().required().label('Password')
});

export type SignInFormData = yup.InferType<typeof SigninSchema>;

export type SignInFormProps = Omit<InheritableElementProps<'form', object>, 'noValidate'>;

export const SignInForm = ({ ...rest }: SignInFormProps) => {
	const queryClient = useQueryClient();
	const analytics = useAnalytics();
	const { mutateAsync, error } = useMutation({
		mutationKey: ['auth', 'signin'],
		mutationFn: async (payload: EmailPasswordSigninRequest) => {
			const { data, error } = await session.actions.emailPasswordSignin(payload);
			if (!data) {
				throw error;
			}

			return data;
		},
		onSuccess: async (result, input) => {
			if ('email' in input) {
				analytics.logEvent(
					RepairApp.auth.login({ email: input.email, user_id: result.userId ?? undefined })
				);
			}

			await queryClient.resetQueries();
		}
	});
	const { handleSubmit, control } = useForm<SignInFormData>({
		defaultValues: {
			email: '',
			password: ''
		},
		resolver: yupResolver(SigninSchema)
	});

	const onSubmit = async ({ email, password }: SignInFormData) => {
		try {
			await mutateAsync({ email, password });
		} catch (error) {
			if (error instanceof ApiError && error.status >= 400 && error.status < 500) {
				// Debug capture the expected errors for login endpoint
				Sentry.captureException(error, {
					level: 'debug'
				});
			} else {
				console.warn('Unexpected error', error);
				// Catch any unhandled error and send to Sentry
				Sentry.captureException(error);
			}
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} noValidate {...rest}>
				<div className="space-y-4">
					<ControlledTextInput
						control={control}
						name="email"
						label="Email address"
						type="email"
						placeholder="Email"
						required
					/>
					<ControlledPasswordInput
						control={control}
						name="password"
						label="Password"
						placeholder="Password"
						required
					/>
				</div>
				<Button className="w-full mt-8" type="submit">
					Sign In
				</Button>
			</form>
			{error && (
				<Alert color="red" className="mt-4" title="Sign in error">
					Invalid credentials
				</Alert>
			)}
		</>
	);
};
