import React from 'react';

interface SectionContainerProps {
	children: React.ReactNode;
}

const SectionContainer = ({ children }: SectionContainerProps) => (
	<section className="rounded-lg border border-solid border-gray-200">{children}</section>
);

export default SectionContainer;
