import { offer_request_handler, offer_request_model } from '@/sdk/reflect/reflect';
import { Offer, SellableOffer } from '../models';

/**
 * Flatten grouped offers
 */
export const formatSellableOffers = (
	offers: Offer[],
	offerRequestsData?: offer_request_handler.exp.OfferRequestGetResponse
) =>
	offers.reduce((accu, offer) => {
		if (offer.type === 'group') {
			return accu;
			// TODO: Handle groups
			// accu.push(
			// 	...offer.offers.map(o => ({
			// 		...o,
			// 		id: o.payload.kind === 'product' ? o.payload.entity.id : o.payload.sellable.entity.id,
			// 		groupId: offer.id
			// 	}))
			// );
		} else if (offer.type === 'request') {
			const request = offerRequestsData?.offer_requests?.find(
				request => request.store_id === offer.offer.business.id
			);
			const supEvents = request?.events.filter(event => event.kind.type === 'supplier');
			accu.push({
				business: offer.offer.business,
				request,
				isRequest: true,
				id: offer.id,
				returnPolicy:
					(
						supEvents?.[supEvents?.length - 1]?.kind.parts.find(
							p =>
								'type' in p.data &&
								p.data.type === 'sellable' &&
								p.data.id ===
									(offer.offer.payload.kind === 'assembly'
										? offer.offer.payload.sellable
										: offer.offer.payload
									).entity.id
						)?.data as offer_request_model.model.event.OfferRequestSupplierPartData
					)?.return_policy || undefined,
				payload: offer.offer.payload
			});
		} else {
			accu.push(offer);
		}

		return accu;
	}, [] as SellableOffer[]);
