import { useState, useEffect } from 'react';
import { RingProgress } from '@mantine/core';

interface CountdownTimerProps {
	startDate: Date;
}

const CountdownTimer = ({ startDate }: CountdownTimerProps) => {
	const [timeLeft, setTimeLeft] = useState(3600);
	const [isCounting, setIsCounting] = useState(true);
	const [isOverdue, setIsOverdue] = useState(false);
	const [overdueTime, setOverdueTime] = useState(0);

	const percentageLeft = timeLeft / 36;

	useEffect(() => {
		const currentTime = new Date().getTime();
		const difference = currentTime - startDate.getTime();

		const timeMarginMs = 3600000;
		const timePassed = Math.ceil(difference / 1000);

		if (difference > timeMarginMs) {
			setIsOverdue(true);
			setOverdueTime(Math.ceil((difference - timeMarginMs) / 1000));
			setTimeLeft(0);
		} else {
			setTimeLeft(Math.ceil(3600 - timePassed));
		}
	}, [startDate]);

	useEffect(() => {
		if (isCounting && timeLeft > 0) {
			const timer = setInterval(() => {
				setTimeLeft(prevTime => prevTime - 60);
			}, 60000);

			return () => clearInterval(timer);
		} else if (timeLeft === 0) {
			setIsCounting(false);
		}
	}, [isCounting, timeLeft]);

	if (isOverdue && overdueTime > 0) {
		return (
			<div className="text-sm flex flex-col justify-between flex-1">
				<p className="text-red-600 mt-4 font-medium">
					Overdue by {Math.ceil(overdueTime / 60)} minutes
				</p>
				<p className="text-gray-500">Still waiting on this supplier</p>
			</div>
		);
	}

	return (
		<div className="text-center flex flex-col items-center justify-between flex-1">
			{isCounting && timeLeft > 0 ? (
				<div>
					<RingProgress
						size={140}
						thickness={5}
						roundCaps
						sections={[{ value: percentageLeft, color: timeLeft < 300 ? 'red' : 'blue' }]}
						label={
							<div className="flex flex-col">
								<b className="text-lg">{Math.ceil(timeLeft / 60)}</b>
								<span className="text-sm text-gray-600">
									{Math.ceil(timeLeft / 60) === 1 ? 'minute' : 'minutes'}
								</span>
							</div>
						}
					/>
				</div>
			) : null}
			<p className="text-gray-500 text-sm">Waiting for the response</p>
		</div>
	);
};

export default CountdownTimer;
