import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { Accordion } from '@mantine/core';
import { useState } from 'react';

interface PendingJobNoteAlertProps {
	showDetails?: boolean;
}
const ACCORDION_ID = 'accordion_id';

const PendingJobNoteAlert = ({ showDetails }: PendingJobNoteAlertProps) => {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<Accordion value={isExpanded ? ACCORDION_ID : ''} disableChevronRotation>
			<Accordion.Item
				onClick={() => {
					if (!showDetails) {
						return;
					}
					setIsExpanded(!isExpanded);
				}}
				className=" border border-solid rounded !border-gray-200"
				value={ACCORDION_ID}
			>
				<Accordion.Control
					chevron={
						showDetails ? (
							<div className="text-blue-600 !transition-none text-sm mr-[3.65rem] font-medium flex gap-2">
								<span className="whitespace-nowrap">{isExpanded ? 'Close' : 'Learn more'}</span>
								{isExpanded ? (
									<ChevronUpIcon width={13} color="blue" />
								) : (
									<ChevronDownIcon width={13} color="blue" />
								)}
							</div>
						) : null
					}
					className="bg-transparent"
				>
					<div className="flex items-center gap-2">
						<InformationCircleIcon width={20} color="blue" />
						<p>
							<b className="font-semibold">Supplier options will be ready in 1 hour or less.</b>{' '}
							<span className="text-gray-700">
								Feel free to leave this page. We'll email you once all options are ready.
							</span>
						</p>
					</div>
				</Accordion.Control>
				<Accordion.Panel onClick={e => e.stopPropagation()}>
					<div className=" flex flex-col gap-2 pl-8 text-gray-700">
						<p>As per the agreement with IAG, supplier options will be ready when there is:</p>
						<ul className="list-disc pl-6">
							<li>At least 2 competitive options</li>
							<li>Of which, 1 option must contain used parts</li>
						</ul>
						<p>Any available options are revelead after a waiting time of 1 hour.</p>
					</div>
				</Accordion.Panel>
			</Accordion.Item>
		</Accordion>
	);
};

export default PendingJobNoteAlert;
