import { mutations } from '@/sdk/react/mutations';
import { queries } from '@/sdk/react/queries';
import { BasicTable } from '@common/components/order-table';
import { core_order } from '@/sdk/reflect/reflect';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ClockIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { ActionIcon, Button, Menu } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { memo } from 'react';
import { toast } from 'sonner';
import { useOrderTableItems } from '../../hooks/use-order-table-items';
import { orderColumnBuilder } from '../table-builder';
import { OrderSection } from '../order-section';
import { StatusNote } from '../status-note';

type OrderedOrderProps = {
	jobId: string;
	model: core_order.SupplierOrder;
	showIBody: boolean;
	syncToiBody: any;
	isSuccess: boolean;
};

const builder = orderColumnBuilder();
const tableColumns = [
	builder.quantity(),
	builder.name(),
	builder.condition(),
	builder.returnPolicy(),
	builder.price()
];

export const OrderedOrder = memo(
	({ jobId, model, showIBody, syncToiBody, isSuccess }: OrderedOrderProps) => {
		const client = useQueryClient();
		const { items, subtotal } = useOrderTableItems(
			model.items,
			model.purchase_currency_code ?? null
		);
		const { mutateAsync: cancelOrder } = useMutation(mutations.orders.cancel);

		const table = useReactTable({
			data: items,
			columns: tableColumns,
			getCoreRowModel: getCoreRowModel()
		});

		const onCancelOrder = async () => {
			await cancelOrder({ identity: model.id, job_id: jobId });
			client.invalidateQueries(queries.orders.list({ job_id: jobId }));
			toast.success('Order cancelled');
		};

		return (
			<OrderSection className="bg-white">
				<OrderSection.Content className="flex items-center justify-between border-b">
					<div className="flex items-start gap-2">
						<ClockIcon className="text-green-500 size-5" />
						<div>
							<div className="font-semibold leading-5 text-gray-900">Order placed</div>
							<p className="mt-0.5 text-sm text-gray-700">Waiting for supplier to confirm</p>
						</div>
					</div>
					<div className="flex flex-row space-x-3 items-center">
						{showIBody && (
							<Button variant="default" onClick={syncToiBody} disabled={isSuccess}>
								Sync orders to iBody
							</Button>
						)}
						<Menu shadow="md" width={200} position="bottom-end">
							<Menu.Target>
								<ActionIcon aria-label="Open order actions">
									<EllipsisVerticalIcon className="size-5" />
								</ActionIcon>
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item
									color="red"
									icon={<TrashIcon className="size-5" />}
									onClick={onCancelOrder}
								>
									Cancel order
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					</div>
				</OrderSection.Content>
				<OrderSection.Content className="space-y-3">
					<OrderSection.Title>
						{model.supplier_contact?.organization.name} (#{model.external_id})
					</OrderSection.Title>
					{model.deliver_before && (
						<div className="text-sm text-gray-700">
							<span className="font-semibold">Delivery date:</span>{' '}
							{formatDate(model.deliver_before, 'dd/MM, hh:mm a')}
						</div>
					)}
					<div className="mt-3 overflow-x-auto">
						<BasicTable table={table} />
					</div>
					<BasicTable.Subtotal
						subtotal={subtotal}
						currency={model.purchase_currency_code ?? undefined}
					/>
					<StatusNote className="max-w-md" status={model.status} />
				</OrderSection.Content>
			</OrderSection>
		);
	}
);
