import { InheritableElementProps } from '@/types/utilties';
import { ReactComponent as AiLogo } from '@assets/parts/ai-logo.svg';
import { InputStepper } from '@common/components/input-stepper';
import { useMeasurement } from '@common/hooks/use-measure';
import { tlsx } from '@common/utils/tw-merge';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Button } from '@mantine/core';
import { entries, sortBy, sum, uniqBy, values } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { CategoryTreeLeaf, Diagram, PartsFormData, PartsSelection } from '../../types';
import { flattenPartSlots } from '../../utils/assembly';
import { createPartSelection } from '../../utils/form';
import { usePartsContext } from '../parts-context';
import { PartsDisplayDescription } from '../parts-display';

type PartsCartProps = InheritableElementProps<
	'div',
	{
		open?: boolean;
		categories: CategoryTreeLeaf[];
		diagram?: Diagram | null;
		highlighted: Set<string>;
		control: Control<PartsFormData>;
		selection: PartsFormData;
		form: string;
		changed?: boolean;
		actions: {
			close: () => void;
			part: {
				highlight: (partSlotIds: string[]) => void;
				jump: (
					cut: string | null | undefined,
					category: string,
					diagram: string,
					partSlotId: string
				) => void;
			};
			custom: {
				add: () => void;
			};
		};
	}
>;

export const PartsCart = ({
	open,
	control,
	selection,
	categories,
	diagram,
	highlighted,
	form,
	changed,
	actions,
	className,
	...rest
}: PartsCartProps) => {
	const [showRecommendation, setShowRecommendation] = useState(true);
	const [dismissed, setDismissed] = useState<string[]>([]);
	const { value: nav } = useMeasurement('navigation-bar');
	const { parts } = usePartsContext();

	const shotcuts = useMemo(
		() =>
			categories.flatMap(({ id: category, diagrams }) =>
				diagrams
					.map(({ diagram }) => diagram)
					.flatMap(({ id: diagram, description, partSlots }) =>
						flattenPartSlots(partSlots).flatMap(({ id: partSlot, ...rest }) => ({
							category,
							diagram,
							description,
							partSlot,
							assemblies: rest.kind === 'assembly' ? rest.assemblies : []
						}))
					)
			),
		[categories]
	);

	const recommendations = useMemo(
		() =>
			uniqBy(
				parts.filter(
					({ assembly }) =>
						assembly.confidence > 0 &&
						!dismissed.includes(assembly.id) &&
						(selection[assembly.id]?.quantity ?? 0) <= 0
				),
				({ assembly }) => assembly.id
			),
		[shotcuts, selection, dismissed]
	);

	const added = useMemo(() => {
		const values = sortBy(
			entries(selection).filter(([, s]) => s && s.quantity > 0),
			([, { order }]) => order
		);

		return values.map(([id, assembly]) => {
			const other = parts.find(({ assembly: { id } }) => id === assembly.assemblyId);

			const slot = flattenPartSlots(diagram?.partSlots ?? []).find(
				partSlot =>
					partSlot.kind === 'assembly' &&
					partSlot.assemblies.some(({ id }) => id === assembly.assemblyId)
			);

			return [
				id,
				{ ...assembly, other, slot, highlighted: !!slot && highlighted.has(slot.id) }
			] as const;
		});
	}, [selection, highlighted, shotcuts]);

	const count = useMemo(() => sum(added.map(([, { quantity }]) => quantity ?? 0)), [added]);

	return (
		<div
			className={tlsx(
				'flex flex-col h-full transition-all w-0 overflow-hidden',
				{ 'w-80 border-l': open },
				className
			)}
			{...rest}
		>
			<div
				className="flex flex-col w-80 overflow-auto h-full"
				style={{
					height: `calc(100dvh - ${nav?.height}px)`
				}}
			>
				{recommendations.length > 0 && (
					<div className="flex flex-col border-b">
						<button
							type="button"
							className="flex items-center justify-between px-4 py-3.5 border-b sticky top-0 bg-white outline-hidden cursor-pointer"
							onClick={() => setShowRecommendation(prev => !prev)}
						>
							<div className="flex items-center">
								<AiLogo
									className="inline size-4 mr-2 text-blue-900"
									fill="currentColor"
									aria-hidden="true"
								/>
								<span className="font-semibold text-blue-900">
									Recommendations ({recommendations.length})
								</span>
							</div>

							<ChevronDownIcon className="size-4 text-blue-900" />
						</button>

						{showRecommendation && (
							<div className="flex flex-1 flex-col gap-3 px-2.5 py-1 empty:p-0">
								{recommendations.map(({ assembly, cut, category, diagram, partSlot }) => (
									<Controller
										key={assembly.id}
										name={assembly.id}
										control={control}
										render={({ field }) => (
											<div
												role="button"
												className="group flex flex-col items-center py-3 px-3 h-fit rounded-sm bg-blue-50 w-full gap-2 cursor-pointer"
												onClick={() => {
													const data: PartsSelection = createPartSelection(
														assembly,
														true,
														values(selection).filter(s => (s?.quantity ?? 0) > 0).length
													);
													actions.part.jump(cut ?? undefined, category, diagram.id, partSlot);
													field.onChange(data);
												}}
											>
												<div className="flex items-center justify-between w-full gap-2">
													<PartsDisplayDescription
														className="text-sm text-blue-900 group-hover:underline group-active:underline break-words"
														assembly={assembly}
													/>

													<button
														type="button"
														className="outline-hidden flex-shrink-0 min-w-4"
														onClick={e => {
															e.stopPropagation();
															setDismissed(prev => [...prev, assembly.id]);
														}}
													>
														<XMarkIcon className="size-4" />
													</button>
												</div>
											</div>
										)}
									/>
								))}
							</div>
						)}
					</div>
				)}

				<div className="flex items-center justify-between mt-4 pb-2.5 px-4 border-b border-gray-200/50">
					<span className="text-lg font-semibold text-gray-900">{count} items selected</span>

					<button
						type="button"
						className="text-sm text-blue-600 font-medium"
						onClick={actions.custom.add}
					>
						Add item
					</button>
				</div>

				{added.length === 0 && (
					<span className="text-sm text-gray-600 px-3 mt-4">
						Select a part from the diagram to view parts in the cart
					</span>
				)}
				<div className="flex flex-1 flex-col gap-2 py-0.5 px-3">
					{added.map(([id, { slot, highlighted, other, ...assembly }]) => (
						<Controller
							key={id}
							control={control}
							name={id}
							render={({ field }) => (
								<div className="flex flex-col items-center py-3 h-fit border-b w-full gap-2">
									<div className="flex items-center justify-between w-full gap-2">
										<PartsDisplayDescription
											assembly={{
												description: assembly.description,
												displayName: assembly.description
											}}
										/>
									</div>

									<div className="w-full empty:hidden">
										{!assembly.assemblyId && (
											<span className="text-xs leading-none text-gray-600">
												Manually created part
											</span>
										)}
									</div>

									<div className="flex items-center flex-wrap gap-2 w-full empty:hidden">
										{slot && (
											<>
												<button
													type="button"
													className="w-fit text-start text-xs leading-none mt-1 text-blue-600 hover:underline active:underline"
													onClick={() => actions.part.highlight([slot.id])}
												>
													Shown in current diagram
												</button>
											</>
										)}
										{!slot && other && (
											<button
												type="button"
												className="w-fit text-start text-xs leading-none mt-1 text-blue-600 hover:underline active:underline"
												onClick={() =>
													actions.part.jump(
														other.cut,
														other.category,
														other.diagram.id,
														other.partSlot
													)
												}
											>
												Shown in {other.diagram.description}
											</button>
										)}
									</div>

									<div className="flex items-center gap-4 w-full">
										<InputStepper
											className="w-28 text-xs"
											maxLength={3}
											value={field.value.quantity}
											onChange={change => {
												const quantity = change === '' ? 0 : change;
												const data: PartsSelection = {
													...field.value,
													quantity
												};
												field.onChange(data);
											}}
											min={0}
											max={999}
										/>
										<button
											type="button"
											className="text-xs font-semibold text-red-600"
											onClick={() => {
												const data: PartsSelection = {
													...field.value,
													quantity: 0
												};
												field.onChange(data);
											}}
										>
											Remove
										</button>
									</div>
								</div>
							)}
						/>
					))}
				</div>
				<div className="w-full mt-auto p-3 bg-white outline-hidden border-t bottom-0 sticky border-gray-200/50">
					<Button
						type="submit"
						form={form}
						className="w-full"
						disabled={!changed && added.length === 0}
					>
						Next
					</Button>
				</div>
			</div>
		</div>
	);
};
