import { EmptyState } from '@common/components/empty-state';
import { DefaultText, HeaderCell } from '@common/components/cell-builder';
import { JobCloseDialog } from '@features/job/components/job-close-dialog';
import { JobDetails } from '@features/job/components/job-details';
import { JobImageGrid } from '@features/job/components/job-image-grid';
import { OrderBuilder } from '@features/orders/components/order-builder';
import { withSignedIn } from '@common/hoc/with-access';
import { BasicTable } from '@common/components/order-table';
import { Section } from '@common/components/page-section';
import { tlsx } from '@common/utils/tw-merge';
import { Job, JobClosingReason, JobPart } from '@/sdk/lib';
import { jobsQueries } from '@/sdk/react';
import { queries } from '@/sdk/react/queries';
import { InheritableElementProps } from '@/types/utilties';
import { ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Button } from '@mantine/core';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { isNil } from 'lodash-es';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { JobSubActions } from '../job-detail';
import { bmsMutations } from '@/sdk/react/mutations/bms';
import { toast } from 'sonner';

type JobPageProps = {
	jobId: string;
};

const JobPage = () => {
	const { jobId } = useParams<JobPageProps>();
	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	const [closing, setClosing] = useState(false);

	const { data: jobData } = useSuspenseQuery(jobsQueries.get({ jobId }));
	const { data: partData } = useSuspenseQuery(jobsQueries.listParts({ jobId }));

	return (
		<div className="flex-1 w-full p-6 bg-gray-50">
			{jobData.job.status !== 'Closed' && (
				<JobSubActions>
					<Button variant="default" onClick={() => setClosing(true)}>
						Close job
					</Button>
				</JobSubActions>
			)}
			<div className="grid w-full grid-cols-12 gap-6 mx-auto max-w-7xl">
				<div className="col-span-12 space-y-6 md:col-span-4">
					<JobDetails job={jobData.job} />
					<JobImageGrid jobId={jobId} repairerSiteId={jobData.job.repairerSiteId} />
				</div>
				<div className="col-span-12 space-y-6 md:col-span-8">
					{jobData.job.closingReason && <ClosedWarningDetail {...jobData.job.closingReason} />}
					{jobData.job.vehicle?.variant && isNil(jobData.job.vehicle.variant.token) && (
						<FallbackWarningDetail />
					)}
					<OrdersSection
						job={jobData.job}
						canCreate={partData.parts.length > 0}
						showIBody={jobData.job.source === 'iBodyShop'}
					/>
					<PartsList job={jobData.job} parts={partData.parts} canSelect />
				</div>
			</div>
			<JobCloseDialog
				siteId={jobData.job.repairerSiteId}
				job={closing ? jobData.job : null}
				onClose={() => setClosing(false)}
			/>
		</div>
	);
};

const OrdersSection = ({
	job,
	canCreate,
	className,
	showIBody
}: InheritableElementProps<'section', { job: Job; canCreate: boolean; showIBody: boolean }>) => {
	const { data: orders } = useSuspenseQuery(
		// Refetching every 30 seconds in case orders are updated.
		{ ...queries.orders.list({ job_id: job.id }), refetchInterval: 30_000 }
	);

	const sortedOrders = orders.sort((a, b) => b.created_at.localeCompare(a.created_at));

	const {
		mutateAsync: syncOrderedParts,
		isSuccess,
		isPending
	} = useMutation({
		...bmsMutations.syncorderedJobParts,
		onSuccess: () => {
			toast.success('Ordered parts synced to iBody');
		}
	});

	const syncToiBody = async () => {
		await syncOrderedParts({ job_id: job.id });
	};

	if (!canCreate) {
		return null;
	}

	if (sortedOrders.length === 0) {
		return (
			<Section className={tlsx('bg-white px-6 pt-6 pb-4 border rounded-md', className)}>
				<Section.Title className="text-lg">Orders</Section.Title>
				<EmptyState className="bg-white">
					<EmptyState.Title>No orders</EmptyState.Title>
					<EmptyState.Description>Select supply to create orders</EmptyState.Description>
					<Button
						variant="subtle"
						color="blue"
						component={Link}
						to={job.needsConfirmation ? `/job/${job.id}/confirmation` : `/job/${job.id}/supply`}
					>
						View offers
					</Button>
				</EmptyState>
			</Section>
		);
	}

	return (
		<div className="space-y-6">
			{sortedOrders.map(order => (
				<OrderBuilder
					key={order.id}
					jobId={job.id}
					model={order}
					showIBody={showIBody}
					syncToiBody={syncToiBody}
					isSuccess={isSuccess || isPending}
				/>
			))}
		</div>
	);
};

const PartsList = ({
	job,
	parts,
	canSelect,
	className
}: InheritableElementProps<'section', { job: Job; parts: JobPart[]; canSelect: boolean }>) => {
	const link = !isNil(job.vehicle?.variant?.token)
		? `/job/${job.id}/parts`
		: `/job/${job.id}/parts/fallback`;
	const columnHelper = createColumnHelper<JobPart>();
	const columns = [
		columnHelper.accessor('quantity', {
			id: 'quantity',
			header: () => <HeaderCell>Qty.</HeaderCell>,
			cell: info => <DefaultText>{info.getValue()}</DefaultText>
		}),
		columnHelper.accessor('description', {
			id: 'description',
			header: () => <HeaderCell>Name</HeaderCell>,
			cell: info => <DefaultText>{info.getValue()}</DefaultText>
		})
	];

	const table = useReactTable({
		// todo: proper transform using part slot etc
		data: parts.sort((a, b) => (a.description ?? '--').localeCompare(b.description ?? '--')),
		columns,
		getCoreRowModel: getCoreRowModel()
	});

	// we are still going to show parts list if there's already parts in the job (coming from external system)
	if (!canSelect && parts.length === 0) {
		return null;
	}

	if (parts.length === 0) {
		return (
			<Section className={tlsx('bg-white px-6 pt-6 pb-4 border rounded-md', className)}>
				<Section.Title className="text-lg">Parts</Section.Title>
				<EmptyState className="bg-white">
					<EmptyState.Title>No parts</EmptyState.Title>
					<EmptyState.Description>Select parts to find supply</EmptyState.Description>
					<Button variant="subtle" color="blue" component={Link} to={link}>
						Add parts
					</Button>
				</EmptyState>
			</Section>
		);
	}

	return (
		<Section className={tlsx('bg-white px-6 pt-6 pb-4 border rounded-md', className)}>
			<div className="flex items-center justify-between">
				<Section.Title className="text-lg">Parts</Section.Title>
				{canSelect && (
					<Button variant="subtle" color="blue" component={Link} to={link}>
						View parts
					</Button>
				)}
			</div>
			<div className="mt-2 overflow-y-auto max-h-56">
				<BasicTable table={table} />
			</div>
		</Section>
	);
};

const ClosedWarningDetail = ({
	reason,
	note,
	className
}: InheritableElementProps<'div', JobClosingReason>) => {
	return (
		<Section className={tlsx('bg-white p-6 border rounded-md', className)}>
			<div className="flex items-center w-full gap-2">
				<ExclamationCircleIcon className="text-yellow-600 size-5" />
				<span className="text-sm font-semibold">{reason}</span>
			</div>
			<p className="mt-2 text-sm text-gray-600 empty:hidden">{note}</p>
		</Section>
	);
};

const FallbackWarningDetail = ({ className, ...rest }: InheritableElementProps<'div', object>) => {
	return (
		<div
			className={tlsx(
				'flex items-center gap-3 shrink-0 w-full rounded-md border border-orange-400 bg-orange-100 px-3.5 py-3',
				className
			)}
			{...rest}
		>
			<ExclamationTriangleIcon className="size-5 text-orange-600" />
			<span className="text-orange-900">Diagrams are not available for this vehicle</span>
		</div>
	);
};

export default withSignedIn(JobPage);
