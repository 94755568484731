import { formatPartContext } from '@common/utils/part';
import { JobPart } from '@/sdk/lib';

export const createJobPartName = (part: JobPart): string => {
	const partName = formatPartContext(
		part.partSlot?.gapcPartType ?? null,
		part.partSlot?.gapcPosition ?? null,
		part.description,
		part.ghcaId ?? null,
		part.mpn ?? null
	);

	if (!partName) {
		return 'N/A';
	}

	return partName;
};
