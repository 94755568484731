import { offers_search, supply_search } from '@partly/core-server-client';
import CardContainer, { CardContainerProps } from '../card-container';
import { Button } from '@mantine/core';
import {
	checkIfOfferIsSelectable,
	createJobPartName,
	formatOfferPrice,
	getAssembly,
	isOutOfStock,
	isSellableBackorderable
} from '../../utils';
import { FormattedShippingTime } from '../formatted-shipping-time';
import { ConditionBadge } from '../condition-badge';
import { formatPrice } from '@common/utils/currency';
import { OfferSelection, PartOfferAggregation, SellableOffer } from '../../models';
import { useEffect, useMemo, useState } from 'react';
import {
	TagIcon,
	ChatBubbleBottomCenterTextIcon,
	CheckIcon,
	ChevronUpDownIcon,
	Square3Stack3DIcon
} from '@heroicons/react/24/outline';
import { groupBy } from 'lodash-es';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import Messenger from '../messenger';
import FormatReturnPolicy from '../format-return-policy';
import { ConditionFormat, getFeatureFlags } from '@common/utils/feature-flag';

interface OfferCardProps extends Omit<CardContainerProps, 'type' | 'children' | 'tag'> {
	sellable: offers_search.Product;
	offer: SellableOffer;
	isAdditionalPart?: boolean;
	offerSelection: OfferSelection;
	partOfferAggregationsList: PartOfferAggregation[];
	business: supply_search.Business;
	isSelected: boolean;
	deliverBefore: Date;

	onSelectionChange(isSelected: boolean): void;

	onSendMessage(message: string, requestId: string): void;
}

const OfferCard = ({
	sellable,
	business,
	isSelected,
	isAdditionalPart,
	offerSelection,
	offer,
	deliverBefore,
	partOfferAggregationsList,
	onSendMessage,
	onSelectionChange
}: OfferCardProps) => {
	const [expandAll, setExpandAll] = useState(false);
	const [openMessenger, setOpenMessanger] = useState(false);
	const { conditionBadgeFormat } = getFeatureFlags();

	const assembly = useMemo(() => getAssembly(offer), [offer]);

	const showBrand =
		conditionBadgeFormat === ConditionFormat.MfrType &&
		sellable.gapc_brand &&
		!sellable.gapc_brand?.is_oem &&
		!sellable.gapc_brand.is_oes;
	const isSelectable = useMemo(
		() =>
			offerSelection
				? checkIfOfferIsSelectable(offer, partOfferAggregationsList, offerSelection)
				: true,
		[offer, offerSelection]
	);

	useEffect(() => {
		if (assembly?.children && assembly.children.length <= 3) {
			setExpandAll(true);
		}
	}, [assembly?.children]);

	const grouppedChildren = useMemo(() => {
		if (assembly) {
			return groupBy(
				assembly.children.map(child => {
					const fulfillsJobPart =
						partOfferAggregationsList.find(aggregation =>
							aggregation.offers.some(o => o.matchedOfferPartId === child.entity.id)
						)?.jobPart || null;

					return {
						group: fulfillsJobPart ? 'included' : 'extra',
						...child
					};
				}),
				'group'
			);
		}
		return null;
	}, [assembly]);

	const getFulfilledJobPart = (child: offers_search.Product) =>
		partOfferAggregationsList.find(aggr =>
			aggr.offers.some(offer => offer.matchedOfferPartId === child.entity.id)
		)?.jobPart || null;

	return (
		<CardContainer
			disabled={!isSelectable}
			tag={
				<ConditionBadge
					color={isSelected ? 'white' : 'blue'}
					conditions={{
						condition: sellable.entity.condition ?? null,
						isOem: sellable.gapc_brand?.is_oem ?? false,
						isOes: sellable.gapc_brand?.is_oes ?? false
					}}
				/>
			}
			type="checkbox"
			isSelected={isSelected}
			className="min-h-[260px] w-[260px] flex flex-col"
			onSelectionChange={onSelectionChange}
		>
			<div className="flex flex-col items-center justify-between flex-1 gap-3 px-2 pt-3 pb-2">
				<div className="flex  min-h-5 items-center justify-center  text-center text-sm text-gray-500">
					{assembly || isAdditionalPart ? (
						<>
							{assembly ? (
								<Square3Stack3DIcon color="blue" className="mr-1 mb-0.5 opacity-80" width={18} />
							) : null}
							<span className="inline-block whitespace-break-spaces">{sellable.entity.name}</span>
						</>
					) : null}
					{showBrand && (
						<>
							<TagIcon color="blue" className="mr-1 mb-0.5 opacity-80" width={18} />
							<span className="inline-block whitespace-break-spaces">
								{sellable.gapc_brand?.names?.[0]?.value}
							</span>
						</>
					)}
				</div>

				<b className="font-medium -mt-1">{business.name}</b>
				<strong className="text-xl font-medium">
					{formatPrice(formatOfferPrice(sellable.price))}
				</strong>
				<div className="flex gap-2 flex-col items-start mb-2">
					<div className="mt-1 text-sm text-gray-600">
						<FormattedShippingTime
							orientation="vertical"
							deliverBefore={deliverBefore}
							shippingTime={sellable.shipping_time || null}
							outOfStock={isOutOfStock(offer)}
							isBackorderable={isSellableBackorderable(offer)}
						/>
					</div>
					<div className="flex flex-col items-center gap-3">
						{offer.returnPolicy ? (
							<div className="text-sm text-gray-600">
								<FormatReturnPolicy returnPolicy={offer.returnPolicy} />
							</div>
						) : null}
					</div>
				</div>
				<div className="mt-auto flex items-center gap-2">
					<Button
						className={
							offer.request && offer.request.events.filter(e => e.note).length > 0 ? 'w-32' : 'w-48'
						}
						variant={isSelected ? 'filled' : 'outline'}
						leftIcon={isSelected && <CheckCircleIcon width={16} />}
					>
						{isSelected ? 'Selected' : 'Select'}
					</Button>

					{offer.request && offer.request.events.filter(e => e.note).length > 0 ? (
						<>
							<Button
								variant="subtle"
								className="px-2"
								leftIcon={<ChatBubbleBottomCenterTextIcon className="-mr-1" width={20} />}
								onClick={e => {
									e.stopPropagation();
									setOpenMessanger(true);
								}}
							>
								Notes
							</Button>

							<Messenger
								open={openMessenger}
								disabled
								supplier={offer.business}
								events={offer.request.events}
								onSendMessage={m => onSendMessage(m, offer.request!.id)}
								onClose={() => setOpenMessanger(false)}
							/>
						</>
					) : null}
				</div>

				{assembly ? (
					<div className="flex flex-col justify-between w-48 gap-2 text-sm">
						{grouppedChildren ? (
							<>
								{grouppedChildren['included'] ? (
									<div className="mt-1">
										<b className="font-medium">Included:</b>
										<ul className="flex flex-col gap-2 mt-1 text-gray-600">
											{grouppedChildren['included']
												.slice(0, expandAll ? undefined : 3)
												.map(child => {
													const fulfilledJob = getFulfilledJobPart(child)!;
													const jobPartName = createJobPartName(fulfilledJob);

													return (
														<li
															className="flex gap-0.5 flex-col border-b border-b-gray-100 pb-1"
															key={child.entity.id}
														>
															<div className="flex gap-0.5">
																<CheckIcon width={16} color="blue" />
																<b className="font-medium">{jobPartName}</b>
															</div>
														</li>
													);
												})}

											{!expandAll && grouppedChildren['included'].length > 3 ? (
												<li className="opacity-70">
													{grouppedChildren['included'].length - 3} more...
												</li>
											) : null}
										</ul>
									</div>
								) : null}

								{grouppedChildren['extra'] ? (
									<div className="mt-1">
										<b className="font-medium">Extra parts:</b>
										<ul className="text-gray-600 flex flex-col gap-0.5">
											{grouppedChildren['extra'].slice(0, expandAll ? undefined : 3).map(child => (
												<li className="pb-1 border-b border-b-gray-100" key={child.entity.id}>
													{child.entity.name}
												</li>
											))}
											{!expandAll && grouppedChildren['extra'].length > 3 ? (
												<li className="opacity-70">
													{grouppedChildren['extra'].length - 3} more...
												</li>
											) : null}
										</ul>
									</div>
								) : null}
							</>
						) : null}

						{assembly.children.length > 3 ? (
							<Button
								variant="white"
								color="blue"
								rightIcon={<ChevronUpDownIcon height={24} />}
								onClick={e => {
									e.stopPropagation();
									setExpandAll(!expandAll);
								}}
							>
								{expandAll ? 'Collapse' : 'Expand all'}
							</Button>
						) : null}
					</div>
				) : null}
			</div>
		</CardContainer>
	);
};

export default OfferCard;
