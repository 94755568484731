/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CloseJobRequest } from '../models/CloseJobRequest';
import type { DeleteJobRequest } from '../models/DeleteJobRequest';
import type { EstimationConfirmation } from '../models/EstimationConfirmation';
import type { GetJobCollisionMapRequest } from '../models/GetJobCollisionMapRequest';
import type { GetJobFallbackAssembliesTreeRequest } from '../models/GetJobFallbackAssembliesTreeRequest';
import type { GetJobPartAssembliesTreeRequest } from '../models/GetJobPartAssembliesTreeRequest';
import type { GetJobRequest } from '../models/GetJobRequest';
import type { JobsConfirmationGetRequest } from '../models/JobsConfirmationGetRequest';
import type { JobsConfirmationInsertRequest } from '../models/JobsConfirmationInsertRequest';
import type { ListJobPartsRequest } from '../models/ListJobPartsRequest';
import type { NormalizedResponse_for_MultipleItemsPayload_for_JobPart_and_Null } from '../models/NormalizedResponse_for_MultipleItemsPayload_for_JobPart_and_Null';
import type { NormalizedResponse_for_PaginatedResult_for_JobLink_and_CursorNumericPosition_and_Null } from '../models/NormalizedResponse_for_PaginatedResult_for_JobLink_and_CursorNumericPosition_and_Null';
import type { NormalizedResponse_for_PartAssembliesTree_and_Null } from '../models/NormalizedResponse_for_PartAssembliesTree_and_Null';
import type { NormalizedResponse_for_SingularItemPayload_for_CollisionMapLink_and_Null } from '../models/NormalizedResponse_for_SingularItemPayload_for_CollisionMapLink_and_Null';
import type { NormalizedResponse_for_SingularItemPayload_for_JobLink_and_Null } from '../models/NormalizedResponse_for_SingularItemPayload_for_JobLink_and_Null';
import type { NormalizedResponse_for_WorldTreeAssembliesWithRecommendations_and_Null } from '../models/NormalizedResponse_for_WorldTreeAssembliesWithRecommendations_and_Null';
import type { RemoveJobPartAssembliesTreeRequest } from '../models/RemoveJobPartAssembliesTreeRequest';
import type { SearchJobsRequest } from '../models/SearchJobsRequest';
import type { UpsertJobCollisionsRequest } from '../models/UpsertJobCollisionsRequest';
import type { UpsertJobPartsRequest } from '../models/UpsertJobPartsRequest';
import type { UpsertJobRequest } from '../models/UpsertJobRequest';
import type { UpsertJobVehicleRequest } from '../models/UpsertJobVehicleRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class JobsService {
	constructor(public readonly httpRequest: BaseHttpRequest) {}
	/**
	 * close job
	 * @param requestBody
	 * @returns null
	 * @throws ApiError
	 */
	public closeJob(requestBody: CloseJobRequest): CancelablePromise<null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.close',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * get job collision map
	 * @param requestBody
	 * @returns NormalizedResponse_for_SingularItemPayload_for_CollisionMapLink_and_Null
	 * @throws ApiError
	 */
	public getJobCollisionMap(
		requestBody: GetJobCollisionMapRequest
	): CancelablePromise<NormalizedResponse_for_SingularItemPayload_for_CollisionMapLink_and_Null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.collision-map.get',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * upsert job collisions
	 * @param requestBody
	 * @returns NormalizedResponse_for_SingularItemPayload_for_JobLink_and_Null
	 * @throws ApiError
	 */
	public upsertJobCollisions(
		requestBody: UpsertJobCollisionsRequest
	): CancelablePromise<NormalizedResponse_for_SingularItemPayload_for_JobLink_and_Null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.collisions.upsert',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * get job confirmation
	 * Returns None if confirmation is not set.
	 * @param requestBody
	 * @returns any
	 * @throws ApiError
	 */
	public getJobConfirmation(
		requestBody: JobsConfirmationGetRequest
	): CancelablePromise<EstimationConfirmation | null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.confirmation.get',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * insert job confirmation
	 * Insert job confirmation with delivery date, images and optional note.
	 * @param requestBody
	 * @returns EstimationConfirmation
	 * @throws ApiError
	 */
	public insertJobConfirmation(
		requestBody: JobsConfirmationInsertRequest
	): CancelablePromise<EstimationConfirmation> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.confirmation.insert',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * get job fallback assemblies tree
	 * @param requestBody
	 * @returns NormalizedResponse_for_WorldTreeAssembliesWithRecommendations_and_Null
	 * @throws ApiError
	 */
	public getJobFallbackAssembliesTree(
		requestBody: GetJobFallbackAssembliesTreeRequest
	): CancelablePromise<NormalizedResponse_for_WorldTreeAssembliesWithRecommendations_and_Null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.fallback-assemblies-tree.get',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * get job
	 * @param requestBody
	 * @returns NormalizedResponse_for_SingularItemPayload_for_JobLink_and_Null
	 * @throws ApiError
	 */
	public getJob(
		requestBody: GetJobRequest
	): CancelablePromise<NormalizedResponse_for_SingularItemPayload_for_JobLink_and_Null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.get',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * get job part assemblies tree
	 * @param requestBody
	 * @returns NormalizedResponse_for_PartAssembliesTree_and_Null
	 * @throws ApiError
	 */
	public getJobPartAssembliesTree(
		requestBody: GetJobPartAssembliesTreeRequest
	): CancelablePromise<NormalizedResponse_for_PartAssembliesTree_and_Null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.part-assemblies-tree.get',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * remove job part assemblies tree
	 * @param requestBody
	 * @returns null
	 * @throws ApiError
	 */
	public removeJobPartAssembliesTree(
		requestBody: RemoveJobPartAssembliesTreeRequest
	): CancelablePromise<null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.part-assemblies-tree.remove',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * list parts selected for job
	 * @param requestBody
	 * @returns NormalizedResponse_for_MultipleItemsPayload_for_JobPart_and_Null
	 * @throws ApiError
	 */
	public listJobParts(
		requestBody: ListJobPartsRequest
	): CancelablePromise<NormalizedResponse_for_MultipleItemsPayload_for_JobPart_and_Null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.parts.list',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * add parts to job, update if already added.
	 * If part is already added to job, it will be updated with new values. Setting quantity=0 will remove the part.
	 * @param requestBody
	 * @returns NormalizedResponse_for_MultipleItemsPayload_for_JobPart_and_Null
	 * @throws ApiError
	 */
	public upsertManyJobParts(
		requestBody: UpsertJobPartsRequest
	): CancelablePromise<NormalizedResponse_for_MultipleItemsPayload_for_JobPart_and_Null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.parts.upsert-many',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * delete job
	 * @param requestBody
	 * @returns null
	 * @throws ApiError
	 */
	public deleteJob(requestBody: DeleteJobRequest): CancelablePromise<null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.remove',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * search jobs
	 * @param requestBody
	 * @returns NormalizedResponse_for_PaginatedResult_for_JobLink_and_CursorNumericPosition_and_Null
	 * @throws ApiError
	 */
	public searchJobs(
		requestBody: SearchJobsRequest
	): CancelablePromise<NormalizedResponse_for_PaginatedResult_for_JobLink_and_CursorNumericPosition_and_Null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.search',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * upsert job
	 * @param requestBody
	 * @returns NormalizedResponse_for_SingularItemPayload_for_JobLink_and_Null
	 * @throws ApiError
	 */
	public upsertJob(
		requestBody: UpsertJobRequest
	): CancelablePromise<NormalizedResponse_for_SingularItemPayload_for_JobLink_and_Null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.upsert',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * upsert job vehicle
	 * @param requestBody
	 * @returns NormalizedResponse_for_SingularItemPayload_for_JobLink_and_Null
	 * @throws ApiError
	 */
	public upsertJobVehicle(
		requestBody: UpsertJobVehicleRequest
	): CancelablePromise<NormalizedResponse_for_SingularItemPayload_for_JobLink_and_Null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.vehicle.upsert',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
}
