import { InheritableElementProps } from '@/types/utilties';
import { ReactComponent as AiLogo } from '@assets/parts/ai-logo.svg';
import { InputStepper } from '@common/components/input-stepper';
import { tlsx } from '@common/utils/tw-merge';
import { ChevronDownIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Button } from '@mantine/core';
import { entries, values } from 'lodash-es';
import { Fragment, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { PartsDisplayDescription } from '../../../components/parts-display';
import { PartsFormData, PartsSelection } from '../../../types';
import { FallbackAssembly } from '../../types';

export type FallbackPartsCartProps = InheritableElementProps<
	'div',
	{
		open: boolean;
		form: string;
		control: Control<PartsFormData>;
		selection: PartsFormData;
		actions: {
			custom: {
				add: () => void;
			};
		};
		recommendations: FallbackAssembly[];
	}
>;

export const FallbackPartsCart = ({
	className,
	recommendations,
	open,
	form,
	control,
	selection,
	actions,
	...rest
}: FallbackPartsCartProps) => {
	const [showRecommendation, setShowRecommendation] = useState(true);
	const [dismissed, setDismissed] = useState<string[]>([]);

	const recommendationsToShow = recommendations.filter(
		rec => !dismissed.includes(rec.id) && (selection[rec.id]?.quantity ?? 0) <= 0
	);

	return (
		<div
			className={tlsx(
				'flex flex-col h-full transition-all w-0 overflow-hidden',
				{
					'w-80 border-l': open
				},
				className
			)}
			{...rest}
		>
			<div
				className={tlsx('flex flex-col h-full transition-all w-0 overflow-auto', {
					'w-80': open
				})}
			>
				<div className="flex items-center justify-between mt-4 pb-2.5 px-4 border-b border-gray-200/50">
					<span className="text-lg font-semibold text-gray-900">
						{values(selection).filter(each => (each?.quantity ?? 0) > 0).length} items selected
					</span>

					<button
						type="button"
						className="text-sm text-blue-600 font-medium"
						onClick={actions.custom.add}
					>
						Add item
					</button>
				</div>
				{recommendationsToShow.length > 0 && (
					<div className="flex flex-col border-b">
						<button
							type="button"
							className="flex items-center justify-between px-4 py-3.5 border-b sticky top-0 bg-white outline-hidden cursor-pointer"
							onClick={() => setShowRecommendation(prev => !prev)}
						>
							<div className="flex items-center">
								<AiLogo
									className="inline size-4 mr-2 text-blue-900"
									fill="currentColor"
									aria-hidden="true"
								/>
								<span className="font-semibold text-blue-900">
									Recommendations ({recommendationsToShow.length})
								</span>
							</div>

							<ChevronDownIcon className="size-4 text-blue-900" />
						</button>

						{showRecommendation && (
							<div className="flex flex-1 flex-col gap-3 px-2.5 py-1 empty:p-0">
								{recommendationsToShow.map(assembly => (
									<Controller
										key={assembly.id}
										name={assembly.id}
										control={control}
										render={({ field }) => {
											const checked = (selection[assembly.id]?.quantity ?? 0) > 0;
											return (
												<div
													role="button"
													className="group flex flex-col items-center py-3 px-3 h-fit rounded-sm bg-blue-50 w-full gap-2 cursor-pointer"
													onClick={() => {
														if (!assembly) {
															return;
														}
														const data: PartsSelection = {
															partSlotIds: assembly.partSlotIds,
															quantity: !checked ? 1 : 0,
															assemblyId: assembly.id,
															ghcaId: assembly.id,
															description: assembly.description,
															hcas: assembly.hcas.map(({ description }) => description),
															order: values(selection).filter(s => (s?.quantity ?? 0) > 0).length
														};
														field.onChange(data);
													}}
												>
													<div className="flex items-center justify-between w-full gap-2">
														<PartsDisplayDescription
															className="text-sm text-blue-900 group-hover:underline group-active:underline truncate"
															assembly={{ description: assembly.description }}
														/>

														<button
															type="button"
															className="outline-hidden"
															onClick={e => {
																e.stopPropagation();
																setDismissed(prev => [...prev, assembly.id]);
															}}
														>
															<XMarkIcon className="size-4" />
														</button>
													</div>
												</div>
											);
										}}
									/>
								))}
							</div>
						)}
					</div>
				)}

				{entries(selection)
					.filter(([, each]) => (each?.quantity ?? 0) !== 0)
					.map(([id, { description, hcas }]) => (
						<Controller
							control={control}
							key={id}
							name={id}
							render={({ field }) => (
								<div className="flex flex-col items-center py-3 h-fit border-b w-full gap-2 px-3">
									<div className="flex items-center justify-between w-full gap-2">
										<span className="text-sm font-normal text-gray-900 truncate">
											{description}
										</span>
									</div>

									<div className="flex items-center flex-wrap gap-1 w-full empty:hidden">
										{hcas.map((hca, index) => (
											<Fragment key={`${hca}-${index}`}>
												<span className="text-xs leading-none text-gray-600">{hca}</span>
												<ChevronRightIcon className="size-2 last:hidden" />
											</Fragment>
										))}
									</div>

									<div className="flex items-center gap-4 w-full">
										<InputStepper
											className="w-28 text-xs"
											maxLength={3}
											value={field.value.quantity}
											onChange={change => {
												const quantity = change === '' ? 0 : change;
												const data: PartsSelection = {
													...field.value,
													quantity
												};
												field.onChange(data);
											}}
											min={0}
											max={999}
										/>
										<button
											type="button"
											className="text-xs font-semibold text-red-600"
											onClick={() => {
												const data: PartsSelection = {
													...field.value,
													quantity: 0
												};
												field.onChange(data);
											}}
										>
											Remove
										</button>
									</div>
								</div>
							)}
						/>
					))}
			</div>
			<div className="w-full mt-auto p-3 bg-white outline-hidden border-t border-gray-200/50">
				<Button type="submit" form={form} className="w-full">
					Next
				</Button>
			</div>
		</div>
	);
};
