import { FallbackPartSearch } from '@/app/features/parts/fallback/components/fallback-part-search';
import { jobsQueries } from '@/sdk/react';
import { withSignedIn } from '@common/hoc/with-access';
import { useAnalytics } from '@common/hooks/use-analytics';
import { useMeasurement } from '@common/hooks/use-measure';
import { useSearchQueries } from '@common/hooks/use-search-queries';
import { useUnsavedChanges } from '@common/hooks/use-unsaved-changes';
import { tlsx } from '@common/utils/tw-merge';
import { PartsCustomPart } from '@features/parts/components/parts-custom-part';
import { FallbackPartsCart } from '@features/parts/fallback/components/fallback-parts-cart';
import { FallbackPartsCategory } from '@features/parts/fallback/components/fallback-parts-category';
import { FallbackPartsCut } from '@features/parts/fallback/components/fallback-parts-cut';
import { FallbackPartsDisplay } from '@features/parts/fallback/components/fallback-parts-display';
import { NonInteractiveWarning } from '@features/parts/fallback/components/non-interactive-warning';
import {
	categoriesAssemblies,
	fallbackCategoryLeaves,
	getDeepRecommendations
} from '@features/parts/fallback/utils';
import { usePartsForm } from '@features/parts/hooks/use-parts-form';
import { PartsFormData, PartsSelection } from '@features/parts/types';
import { createFallbackInitialPartsFormData } from '@features/parts/utils/form';
import { ShoppingBagIcon } from '@heroicons/react/24/solid';
import { Button } from '@mantine/core';
import { RepairApp } from '@partly/analytics';
import { isDefined } from '@partly/js-ex';
import { useSuspenseQueries, useSuspenseQuery } from '@tanstack/react-query';
import { isNil, values } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { JobMainAction, JobSubActions } from '../job-detail';

type PageParams = {
	jobId: string;
};

const FORM_ID = 'parts-fallback-form';

const PartsFallbackPage = () => {
	const { jobId } = useParams<PageParams>();
	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	const { value: nav } = useMeasurement('navigation-bar');
	const [queries, setQueries] = useSearchQueries(['cut', 'category', 'assembly']);
	const [customPart, setCustomPart] = useState<string | null>(null);
	const [showWarning, setShowWarning] = useState(true);
	const [cartOpen, setCartOpen] = useState(true);
	const { logEvent } = useAnalytics();
	const navigate = useNavigate();

	const [{ data: assemblyData }, { data: partsData }] = useSuspenseQueries({
		queries: [jobsQueries.getFallbackAssembliesTree({ jobId }), jobsQueries.listParts({ jobId })]
	});

	const { data: jobData } = useSuspenseQuery({
		...jobsQueries.get({ jobId }),
		refetchOnMount: false
	});

	const [searching, setSearching] = useState(false);

	const cuts = useMemo(() => categoriesAssemblies(assemblyData.tree.assemblies), [assemblyData]);

	const recommendations = useMemo(
		() =>
			getDeepRecommendations(
				cuts,
				Object.keys(assemblyData.recommendations).filter(
					rec => (assemblyData.recommendations[rec] ?? 0) > 0
				)
			),
		[assemblyData]
	);

	const { control, selection, formState, handleSubmit, submit, setValue } = usePartsForm(
		jobId,
		createFallbackInitialPartsFormData(partsData.parts)
	);

	const { isDirty, isSubmitting } = formState;

	const count = useMemo(
		() =>
			values(selection)
				.filter(isDefined)
				.filter(({ quantity }) => quantity > 0).length,
		[selection]
	);

	const cut = useMemo(
		() => cuts.find(cut => cut.id === queries.cut) ?? cuts.at(0),
		[cuts, queries]
	);
	const leaves = useMemo(() => (cut ? fallbackCategoryLeaves(cut) : []), [cut]);
	const category = useMemo(
		() => leaves.find(category => category.id === queries.category) ?? leaves.at(0) ?? null,
		[cut, queries]
	);

	const onSubmit = useCallback(
		async (data: PartsFormData) => {
			const res = await submit(data);
			if (res.kind === 'changed') {
				logEvent(
					RepairApp.part_selection.parts_finalised({
						parts_count: res.partsChanged
					})
				);
			}
			if (res.hasParts) {
				if (jobData.job.needsConfirmation) {
					navigate(`/job/${jobId}/confirmation`);
				} else {
					navigate(`/job/${jobId}/supply`);
				}
			}
		},
		[submit, logEvent, navigate]
	);

	useUnsavedChanges(isDirty && !isSubmitting);

	return (
		<form
			id={FORM_ID}
			className="flex w-full overflow-auto"
			onSubmit={handleSubmit(onSubmit)}
			style={{
				height: `calc(100dvh - ${nav?.height ?? 0}px)`,
				maxHeight: `calc(100dvh - ${nav?.height ?? 0}px)`
			}}
		>
			<JobMainAction>
				<div
					className={tlsx(
						'w-full flex items-center rounded-full bg-gray-100 max-w-lg lg:max-w-xl xl:max-w-2xl'
					)}
				>
					<FallbackPartSearch
						open={searching}
						search={''}
						cuts={cuts}
						actions={{
							view: setSearching,
							jump: {
								part: function (cutId: string, categoryId: string, hcaId: string): void {
									const cut = cuts.find(cut => cut.id === cutId);
									if (cut) {
										setQueries({ cut: cutId, category: categoryId, assembly: hcaId });
									} else {
										setQueries({ cut: 'other', category: cutId, assembly: hcaId });
									}
								}
							}
						}}
					/>
				</div>
			</JobMainAction>
			<JobSubActions>
				<Button
					key="parts-cart-button"
					className="w-fit ml-auto"
					type="button"
					onClick={() => setCartOpen(prev => !prev)}
				>
					Cart
					<ShoppingBagIcon className="size-4 mx-1.5" />
					{count}
				</Button>
			</JobSubActions>
			<div
				className="flex-1 flex flex-col w-full overflow-auto"
				style={{
					height: `calc(100dvh - ${nav?.height ?? 0}px)`,
					maxHeight: `calc(100dvh - ${nav?.height ?? 0}px)`
				}}
			>
				<div className="flex flex-col items-center">
					<div className="flex items-center w-full h-20 bg-white border-b px-6 gap-4 overflow-auto [scrollbar-gutter:stable]">
						{cuts.map(each => (
							<FallbackPartsCut
								key={each.id}
								cut={each}
								selected={cut}
								actions={{
									cut: {
										set: cut => {
											setQueries({ cut: cut.id === queries.cut ? null : cut.id, category: null });
										}
									}
								}}
							/>
						))}
					</div>
					<div className="flex items-center w-full h-20 bg-white border-b px-6 gap-4 overflow-auto [scrollbar-gutter:stable]">
						{cut?.categories.map(each => (
							<FallbackPartsCategory
								key={each.id}
								category={each}
								selected={category}
								actions={{
									category: {
										set: category => setQueries({ category: category.id })
									}
								}}
							/>
						))}
					</div>
				</div>
				<div className="flex flex-col items-center w-full flex-1">
					<NonInteractiveWarning open={showWarning} onClick={() => setShowWarning(prev => !prev)} />
					<div className="flex-1 w-full h-full flex gap-4 p-4">
						{category ? (
							// make this virtualized to prevent issues with performance
							<div
								className="flex-1 flex flex-col rounded-sm border h-full overflow-auto"
								style={{
									maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - ${showWarning ? 11.75 : 7.75}rem)`
								}}
							>
								{category?.assemblies.map(assembly => {
									return (
										<Controller
											key={assembly.id}
											control={control}
											name={assembly.id}
											render={({ field }) => {
												const checked = (selection[assembly.id]?.quantity ?? 0) > 0;
												return (
													<FallbackPartsDisplay
														key={assembly.id}
														categoryDescription={category.description}
														assembly={assembly}
														searched={assembly.id === queries.assembly}
														checked={checked}
														onChange={() => {
															if (!assembly) {
																return;
															}
															const data: PartsSelection = {
																partSlotIds: assembly.partSlotIds,
																quantity: !checked ? 1 : 0,
																assemblyId: assembly.id,
																ghcaId: assembly.id,
																description: assembly.description,
																hcas: assembly.hcas.map(({ description }) => description),
																order: values(selection).filter(s => (s?.quantity ?? 0) > 0).length
															};
															field.onChange(data);
														}}
													/>
												);
											}}
										/>
									);
								})}
							</div>
						) : (
							<div className="flex-1 grid place-items-center rounded-sm border h-full bg-[#FCFDFD]">
								<span className="text-gray-600 text-sm font-medium">
									{'Select an assembly from the list to view parts'}
								</span>
							</div>
						)}
					</div>
				</div>
			</div>

			{/* cart */}
			<FallbackPartsCart
				recommendations={recommendations}
				form={FORM_ID}
				open={cartOpen}
				control={control}
				selection={selection}
				actions={{
					custom: {
						add: () => setCustomPart('')
					}
				}}
			/>

			<PartsCustomPart
				id="parts-custom-part"
				className="z-[90]"
				gapcBrandId={assemblyData.tree.gapcBrandId}
				open={!isNil(customPart)}
				initialDescription={customPart ?? ''}
				onSubmit={({ mpn, description, id }) => {
					const data: PartsSelection = {
						gapcBrandId: assemblyData.tree.gapcBrandId,
						mpn: mpn ?? '',
						order: count,
						partSlotIds: null,
						quantity: 1,
						assemblyId: id,
						hcas: [],
						description: description ?? ''
					};

					const selectionId = id ?? v4();
					setValue(selectionId, data, { shouldDirty: true, shouldTouch: true });
					setCustomPart(null);
				}}
				onClose={() => setCustomPart(null)}
			/>
		</form>
	);
};

export default withSignedIn(PartsFallbackPage);
