import { tlsx } from '@common/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import {
	ArrowsPointingInIcon,
	ArrowUturnLeftIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	EllipsisVerticalIcon,
	EyeIcon,
	EyeSlashIcon,
	MagnifyingGlassIcon,
	MinusIcon,
	PlusIcon
} from '@heroicons/react/24/solid';
import { Divider, Menu, Switch } from '@mantine/core';
import { useMemo } from 'react';
import { CategoryTreeLeaf, Diagram } from '../../types';

type PartsToolbarProps = InheritableElementProps<
	'div',
	{
		category: CategoryTreeLeaf;
		diagram: Diagram;
		zoom: number;
		actions: {
			diagram: { set: (diagram: Diagram) => void };
			zoom: {
				in: () => void;
				out: () => void;
			};
			hide: (hide: boolean) => void;
			menu: {
				custom: {
					add: () => void;
				};
				diagram: {
					recenter: () => void;
					resetZoom: () => void;
					resetAll: () => void;
				};
			};
		};
	}
>;

export const PartsToolbar = ({
	className,
	category,
	diagram,
	zoom,
	actions,
	...rest
}: PartsToolbarProps) => {
	const diagrams = useMemo(() => category.diagrams.map(({ diagram }) => diagram), [category]);
	const [prev, page, next] = useMemo(() => {
		const idx = diagrams.findIndex(({ id }) => diagram.id === id);
		if (idx === -1) {
			return [null, 0, null];
		}
		const prev = idx - 1 >= 0 ? diagrams[idx - 1] : null;
		const next = idx + 1 < diagrams.length ? diagrams[idx + 1] : null;
		return [prev, idx + 1, next];
	}, [diagram, diagrams]);
	return (
		<div
			className={tlsx(
				'absolute bottom-6 flex items-center min-w-40 rounded-lg shadow-md border px-1 bg-white z-10',
				className
			)}
			{...rest}
		>
			<div className="flex items-center justify-center gap-2 px-1">
				<button
					type="button"
					className="flex items-center p-1 rounded-sm text-sm hover:bg-gray-50 active:bg-gray-50 select-none disabled:bg-white disabled:stroke-gray-100 disabled:cursor-not-allowed"
					disabled={!prev}
					onClick={() => {
						if (!prev) {
							return;
						}
						actions.diagram.set(prev);
					}}
				>
					<ChevronLeftIcon className="size-4" />
				</button>
				<span className="text-sm min-w-[6ch] text-center select-none">
					{page} of {category.diagrams.length}
				</span>
				<button
					type="button"
					className="flex items-center p-1 rounded-sm text-sm hover:bg-gray-50 active:bg-gray-50 select-none disabled:bg-white disabled:stroke-gray-100 disabled:cursor-not-allowed"
					disabled={!next}
					onClick={() => {
						if (!next) {
							return;
						}
						actions.diagram.set(next);
					}}
				>
					<ChevronRightIcon className="size-4" />
				</button>
			</div>
			<Divider className="opacity-40 mr-2 ml-1" orientation="vertical" />
			<div className="flex items-center py-1 px-1 gap-1.5">
				<button
					type="button"
					className="flex items-center p-1 rounded-sm text-sm hover:bg-gray-50 active:bg-gray-50 select-none"
					onClick={actions.zoom.in}
				>
					<PlusIcon className="size-4" />
				</button>
				<span className="text-sm min-w-[4ch] text-end select-none">{Math.round(zoom * 100)}%</span>
				<button
					type="button"
					className="flex items-center p-1 rounded-sm text-sm hover:bg-gray-50 active:bg-gray-50 select-none"
					onClick={actions.zoom.out}
				>
					<MinusIcon className="size-4" />
				</button>
			</div>
			<Switch
				className="mx-3"
				onChange={e => actions.hide(e.target.checked)}
				onLabel={<EyeSlashIcon className="size-4" />}
				offLabel={<EyeIcon className="size-4" />}
			/>
			<Menu shadow="md" width={200}>
				<Menu.Target>
					<button
						type="button"
						className="flex items-center p-1 rounded-sm text-sm hover:bg-gray-50 active:bg-gray-50 select-none mr-2"
					>
						<EllipsisVerticalIcon className="size-4" />
					</button>
				</Menu.Target>

				<Menu.Dropdown>
					<Menu.Label>Manual part</Menu.Label>
					<Menu.Item
						type="button"
						icon={<PlusIcon className="size-4" />}
						onClick={actions.menu.custom.add}
					>
						Add part
					</Menu.Item>
					<Menu.Divider />
					<Menu.Label>Diagram</Menu.Label>
					<Menu.Item
						type="button"
						icon={<ArrowsPointingInIcon className="size-4" />}
						onClick={actions.menu.diagram.recenter}
					>
						Re-center
					</Menu.Item>
					<Menu.Item
						type="button"
						icon={<MagnifyingGlassIcon className="size-4" />}
						onClick={actions.menu.diagram.resetZoom}
					>
						Reset Zoom
					</Menu.Item>
					<Menu.Item
						type="button"
						color="red"
						className="group"
						icon={<ArrowUturnLeftIcon className="size-4" />}
						onClick={actions.menu.diagram.resetAll}
					>
						Reset View
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>
		</div>
	);
};
