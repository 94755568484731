import { EmptyState } from '@common/components/empty-state';
import ImageWithSkeleton from '@common/components/image-with-skeleton';
import { Section } from '@common/components/page-section';
import { tlsx } from '@common/utils/tw-merge';
import { jobsQueries } from '@/sdk/react';
import { bmsMutations } from '@/sdk/react/mutations/bms';
import { InheritableElementProps } from '@/types/utilties';
import { Button } from '@mantine/core';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { JobImageUploadDialog } from '../job-image-upload-dialog';

export const JobImageGrid = ({
	jobId,
	repairerSiteId,
	className
}: InheritableElementProps<'section', { jobId: string; repairerSiteId: string }>) => {
	const [imageUpload, setImageUpload] = useState(false);
	const client = useQueryClient();

	const { data: jobData } = useSuspenseQuery({
		...jobsQueries.get({ jobId }),
		retryOnMount: false
	});

	const { mutateAsync: syncJobImages, isPending } = useMutation({
		...bmsMutations.syncJobImages,
		onSuccess: () => {
			client.invalidateQueries(jobsQueries.get({ jobId }));
		}
	});

	return (
		<Section className={tlsx('bg-white p-6 border rounded-md', className)}>
			<div className="flex items-center justify-between">
				<Section.Title className="text-lg">Images</Section.Title>
				{jobData.job.source === 'iBodyShop' ? (
					<Button variant="default" size="xs" onClick={() => syncJobImages({ jobId })}>
						Sync
					</Button>
				) : (
					<Button variant="default" size="xs" onClick={() => setImageUpload(true)}>
						Add images
					</Button>
				)}
			</div>
			{jobData.job.images.length > 0 ? (
				<div className="grid grid-cols-3 gap-2 mt-4">
					{jobData.job.images.map(image => (
						<a
							key={image.original}
							className="col-span-1 rounded-md aspect-square bg-gray-50"
							href={image.original}
							target="_blank"
							rel="noopener noreferrer"
						>
							<ImageWithSkeleton
								className="object-cover w-full rounded-sm aspect-square"
								src={image.original}
								loading="lazy"
							/>
						</a>
					))}
				</div>
			) : (
				<EmptyState className="py-4 bg-white">
					<EmptyState.Title>No images</EmptyState.Title>
					<EmptyState.Description>
						{jobData.job.source === 'iBodyShop'
							? `Sync to see images from ${jobData.job.source || 'iBodyShop'}`
							: 'No images available for this job'}
					</EmptyState.Description>
					{jobData.job.source === 'iBodyShop' && (
						<Button
							variant="subtle"
							color="blue"
							onClick={() => syncJobImages({ jobId })}
							loading={isPending}
						>
							Sync images
						</Button>
					)}
				</EmptyState>
			)}
			<JobImageUploadDialog
				open={imageUpload}
				onClose={() => setImageUpload(false)}
				jobId={jobId}
				repairerSiteId={repairerSiteId}
				existingImages={jobData.job.images.map(image => image.original)}
			/>
		</Section>
	);
};
