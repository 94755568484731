import { compact, uniqBy } from 'lodash-es';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { CategoryTreeLeaf, CategoryTreeNode } from '../../types';
import { categoryLeaves } from '../../utils';
import { flattenPartSlots } from '../../utils/assembly';
import { groupPartVariants } from '../../utils/variant';

export const createPartsContexts = (cuts: CategoryTreeNode[], other: CategoryTreeLeaf) => {
	const leaves = cuts.flatMap(cut =>
		categoryLeaves(cut).map(category => ({ category, cut: cut.id as string | null }))
	);

	leaves.push({ category: other, cut: null });

	const diagrams = uniqBy(
		leaves.flatMap(({ category: { id, diagrams }, cut }) =>
			diagrams.map(({ diagram }) => ({ cut, category: id, diagram }))
		),
		({ diagram }) => diagram.id
	);

	const parts = uniqBy(
		diagrams
			.flatMap(({ cut, category, diagram }) =>
				flattenPartSlots(diagram.partSlots).map(partSlot => ({ cut, category, diagram, partSlot }))
			)
			.flatMap(({ partSlot, ...rest }) => {
				if (partSlot.kind !== 'assembly') {
					return [];
				}
				return compact(groupPartVariants(partSlot.assemblies).map(assemblies => assemblies[0])).map(
					assembly => ({
						...rest,
						partSlot: partSlot.id,
						assembly
					})
				);
			})
			.map(({ diagram, assembly, ...rest }) => ({
				...rest,
				diagram,
				assembly: {
					...assembly,
					hcas: assembly.hcas.length > 0 ? assembly.hcas : diagram.hcas
				}
			})),
		({ assembly }) => assembly.id
	);

	return { categories: leaves, diagrams, parts };
};

export const PartsContext = createContext<ReturnType<typeof createPartsContexts>>({
	diagrams: [],
	categories: [],
	parts: []
});

export const usePartsContext = () => useContext(PartsContext);

export const PartsContextProvider = ({
	cuts,
	other,
	children
}: {
	cuts: CategoryTreeNode[];
	other: CategoryTreeLeaf;
	children: ReactNode | null;
}) => {
	const context = useMemo(() => createPartsContexts(cuts, other), [cuts, other]);
	return <PartsContext.Provider value={context}>{children}</PartsContext.Provider>;
};
