import { JobPartInput } from '@/sdk/lib';
import { compact, sortBy, sum, values } from 'lodash-es';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { PartsFormData } from '../../types';
import { useUpsertParts } from '../use-upsert-parts';

export const usePartsForm = (jobId: string, defaultValues: PartsFormData) => {
	const { mutateAsync: upsertParts } = useUpsertParts();

	const { control, handleSubmit, watch, formState, reset, setValue } = useForm<PartsFormData>({
		defaultValues
	});

	const selection = watch();

	const submit = useCallback(
		async (data: PartsFormData) => {
			const selection = sortBy(compact(values(data)), ({ order }) => order ?? 0);
			const hasParts = sum(selection.map(selection => selection?.quantity ?? 0)) > 0;

			if (formState.isDirty) {
				const parts = values(selection).map(
					(selection): JobPartInput => ({
						gapcBrandId: selection.gapcBrandId,
						mpn: selection.mpn,
						quantity: selection.quantity,
						description: selection.description,
						ghcaId: selection.ghcaId,
						partSlot: selection.partSlotIds?.gapcPartTypeId
							? {
									gapcPartTypeId: selection.partSlotIds.gapcPartTypeId,
									gapcPositionId: selection.partSlotIds.gapcPositionId
								}
							: null,
						assembly: selection.assemblyId
							? {
									id: selection.assemblyId,
									hcas: selection.hcas,
									diagramUrl: selection.diagramUrl,
									diagramPnc: selection.diagramPnc,
									diagramHotspot: selection.diagramHotspot
								}
							: null
					})
				);

				await upsertParts({
					jobId,
					parts
				});

				// retain changes (job parts won't immediately get updated, should prevent added parts from flashing to default values)
				reset(undefined, { keepValues: true });

				return {
					kind: 'changed' as const,
					partsChanged: parts.length,
					hasParts
				};
			}

			return {
				kind: 'unchanged' as const,
				hasParts
			};
		},
		[jobId, formState, upsertParts, reset]
	);

	return {
		control,
		selection,
		formState,
		reset,
		setValue,
		handleSubmit,
		submit
	};
};
