import { FileUploadDropzone } from '@features/upload/components/file-upload-dropzone';
import { FileUploadList } from '@features/upload/components/file-upload-list';
import { useFileUploader } from '@features/upload/hooks/use-file-uploader';
import StandardDialog, { StandardDialogProps } from '@common/components/standard-dialog';
import { jobsMutations, jobsQueries } from '@/sdk/react';
import { Button } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uniq } from 'lodash';

type JobImageUploadDialogProps = {
	repairerSiteId: string;
	jobId: string;
	existingImages?: string[];
	onUpload?(images: string[]): void;
} & StandardDialogProps;

export const JobImageUploadDialog = ({
	open,
	repairerSiteId,
	jobId,
	existingImages,
	onUpload,
	onClose
}: JobImageUploadDialogProps) => {
	const client = useQueryClient();
	const manager = useFileUploader();
	const { mutateAsync: upsertJob, isPending } = useMutation(jobsMutations.upsert);

	const onConfirm = async () => {
		const uploadedImages = manager.getCompleteUploads().map(upload => upload.publicUrl);
		const images = uniq([...uploadedImages, ...(existingImages ?? [])]);
		await upsertJob({
			repairerSiteId,
			jobId,
			images
		});

		onUpload?.(uploadedImages);

		client.invalidateQueries(jobsQueries.get({ jobId }));
		handleOnClose();
	};

	const handleOnClose = () => {
		manager.reset();
		onClose();
	};

	return (
		<StandardDialog open={open} onClose={handleOnClose}>
			<StandardDialog.Title>Upload images</StandardDialog.Title>
			<StandardDialog.Content>
				<div className="w-[384px] space-y-6">
					<FileUploadDropzone manager={manager} />
					<FileUploadList manager={manager} />
					<Button
						className="w-full"
						onClick={onConfirm}
						loading={isPending}
						disabled={manager.state.hasIncompleteUploads || !manager.state.hasUploads}
					>
						Confirm
					</Button>
				</div>
			</StandardDialog.Content>
		</StandardDialog>
	);
};
