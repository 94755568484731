import { useMemo } from 'react';
import { OrderSelection, SellableOffer } from '../../models';
import { getOffersFromBasketItems, getOffersSummary } from '../../utils';

export const useOrderRequestAggregation = (selection: OrderSelection, offers: SellableOffer[]) => {
	const { totalItems, totalPrice, totalSuppliers, deliveryDate } = useMemo(() => {
		const selectedOffers = getOffersFromBasketItems(selection.items, offers);

		const summary = getOffersSummary(
			selectedOffers.map(offer => ({ ...offer.offer, quantity: offer.quantity }))
		);

		return {
			totalSuppliers: summary.suppliers.length,
			totalPrice: summary.totalPrice,
			totalItems: summary.totalCount,
			deliveryDate: summary.shippingTime?.eta ? new Date(summary.shippingTime.eta) : null
		};
	}, [selection]);

	return {
		totalItems,
		totalPrice,
		totalSuppliers,
		deliveryDate
	};
};
