import { BmsUpsertPartsRequest } from '@/sdk/generated/models/BmsUpsertPartsRequest';
import {
	BmsSyncImagesRequest,
	BmsSyncImagesResponse,
	BmsSyncJobPartsRequest,
	BmsSyncJobPartsResponse,
	BmsSyncOrderedPartsResponse,
	BmsUpsertPartsResponse,
	StorefrontError
} from '@/sdk/lib';
import { getApi, unwrap } from '../utils/helpers';
import { mutationOptions } from '../utils/mutation-options';
import { BmsSyncOrderedPartsRequest } from '@/sdk/generated/models/BmsSyncOrderedPartsRequest';

export const bmsMutations = {
	syncJobParts: mutationOptions<BmsSyncJobPartsResponse, StorefrontError, BmsSyncJobPartsRequest>({
		mutationKey: ['bms', 'parts', 'sync'],
		mutationFn: payload =>
			unwrap(async () => {
				const sdk = await getApi();
				return sdk.bms.syncJobParts(payload);
			})
	}),
	syncJobImages: mutationOptions<BmsSyncImagesResponse, StorefrontError, BmsSyncImagesRequest>({
		mutationKey: ['bms', 'images', 'sync'],
		mutationFn: payload =>
			unwrap(async () => {
				const sdk = await getApi();
				return sdk.bms.syncImages(payload);
			})
	}),
	upsertJobParts: mutationOptions<BmsUpsertPartsResponse, StorefrontError, BmsUpsertPartsRequest>({
		mutationKey: ['bms', 'parts', 'upsert'],
		mutationFn: payload =>
			unwrap(async () => {
				const sdk = await getApi();
				return sdk.bms.upsertParts(payload);
			})
	}),
	syncorderedJobParts: mutationOptions<
		BmsSyncOrderedPartsResponse,
		StorefrontError,
		BmsSyncOrderedPartsRequest
	>({
		mutationKey: ['bms', 'orders', 'sync'],
		mutationFn: payload =>
			unwrap(async () => {
				const sdk = await getApi();
				return sdk.bms.syncOrderedParts(payload);
			})
	})
};
