import { api_error } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { toast } from 'sonner';

/**
 * Display toast notification for ingest errors
 */
export const onError = (e: api_error.RepairerServerError) => {
	match(
		e,
		{
			BadRequest: e => {
				toast.error(e);
			},
			InternalServerError: e => {
				toast.error(`An unexpected error occurred: ${e}`);
			},
			NotFound: e => {
				toast.error(`Resource not found: ${e}`);
			},
			Application: _ => {
				/** No-op */
			},
			Unauthorized: _ => {
				/** No-op */
			}
		},
		() => {
			toast.error('An unexpected error occurred');
		}
	);
};
