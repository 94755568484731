import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { OrdersBySupply } from '../../models';
import { Alert } from '@mantine/core';
import { differenceInDays, format } from 'date-fns';

interface OrderMetadataProps {
	ordersPerSupplier: OrdersBySupply[];
	requestedDeliveryDate: Date;
	estimatedDeliveryDate: Date;
}

const OrderMetadata = ({
	ordersPerSupplier,
	requestedDeliveryDate,
	estimatedDeliveryDate
}: OrderMetadataProps) => {
	const days = Math.abs(differenceInDays(new Date(requestedDeliveryDate), estimatedDeliveryDate));

	return (
		<div className="mb-4">
			<p className=" text-md">
				<b>Supplier{ordersPerSupplier.length > 1 ? 's' : ''}: </b>
				{ordersPerSupplier
					.map(sup => sup.supplier.name)
					.reduce((accu, sup) => `${accu}${accu ? ',' : ''} ${sup}`, '')}
			</p>
			{requestedDeliveryDate.getTime() < estimatedDeliveryDate.getTime() && days > 0 ? (
				<Alert className="mt-2" variant="light" color="orange" icon={<InformationCircleIcon />}>
					The delivery date is {days} day{days > 1 ? 's' : ''} later than your ideal delivery date
					of {format(requestedDeliveryDate, 'eee do MMM')}
				</Alert>
			) : null}
		</div>
	);
};

export default OrderMetadata;
