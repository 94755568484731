import { lookup_server_model } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';

export const getReturnRejectReason = (
	reason: lookup_server_model.json_types.OrderItemReturnRejectedReason
): string | null => {
	return match<lookup_server_model.json_types.OrderItemReturnRejectedReason, string | null>(
		reason,
		{
			damaged: () => 'Damaged',
			not_returnable: () => 'Not returnable',
			other: () => 'Other',
			out_of_return_policy: () => 'Out of return policy'
		},
		() => null
	);
};

export const getReturnReason = (
	reason: lookup_server_model.json_types.OrderItemReturnReason
): string | null => {
	return match<lookup_server_model.json_types.OrderItemReturnReason, string | null>(
		reason,
		{
			damaged: () => 'Damaged',
			incorrect_fitment: () => 'Incorrect fitment',
			incorrectly_supplied: () => 'Incorrectly supplied',
			not_required: () => 'Not required',
			short_supplied: () => 'Short supplied'
		},
		() => null
	);
};
