import { InheritableElementProps } from '@/types/utilties';
import { ControlledTextInput } from '@common/components/controlled-text-field';
import { Site } from '@common/hooks/use-me';
import { useUnsavedChanges } from '@common/hooks/use-unsaved-changes';
import { tlsx } from '@common/utils/tw-merge';
import { FileUploadDropzone } from '@features/upload/components/file-upload-dropzone';
import { FileUploadList } from '@features/upload/components/file-upload-list';
import { useFileUploader } from '@features/upload/hooks/use-file-uploader';
import { RadioGroup } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Select } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { RegoInput } from '../rego-input';

// I can't get union to work with yup :(
const schema = yup.object({
	siteId: yup.string().required().label('Site ID'),
	jobNumber: yup.string().required().label('Job number').trim(),
	searchType: yup.string().oneOf(['rego', 'chassis']).required().label('Search Type'),
	claimNumber: yup.string().required().label('Claim number').trim(),
	chassis: yup
		.string()
		.when('searchType', {
			is: (val: 'rego' | 'chassis') => val === 'chassis',
			then: schema => schema.required(),
			otherwise: schema => schema.optional()
		})
		.label('Chassis code')
		.trim(),
	plateNumber: yup
		.string()
		.when('searchType', {
			is: (val: 'rego' | 'chassis') => val === 'rego',
			then: schema => schema.required(),
			otherwise: schema => schema.optional()
		})
		.label('Plate number')
		.trim()
});

export type CreateJobFormData = yup.InferType<typeof schema> & {
	images: string[];
};

export type CreateJobFormProps = Omit<
	InheritableElementProps<
		'form',
		{
			sites: Site[];
			siteId?: string;
			initialJobNumber?: string;
			initialClaimNumber?: string;
			onSubmit: (data: CreateJobFormData) => void;
		}
	>,
	'noValidate'
>;

const CreateJobForm = ({
	onSubmit,
	sites,
	siteId,
	initialJobNumber,
	initialClaimNumber,
	...rest
}: CreateJobFormProps) => {
	const manager = useFileUploader();

	const {
		control,
		handleSubmit,
		watch,
		formState: { isDirty, isSubmitting }
	} = useForm<CreateJobFormData>({
		defaultValues: {
			siteId: siteId ?? '',
			jobNumber: initialJobNumber ?? '',
			searchType: 'rego',
			claimNumber: initialClaimNumber ?? '',
			chassis: '',
			plateNumber: '',
			images: []
		},
		resolver: yupResolver(schema)
	});

	const searchType = watch('searchType');

	useUnsavedChanges(
		({ nextLocation }) => isDirty && !isSubmitting && nextLocation.pathname === '/jobs'
	);

	const onHandleSubmit = (data: CreateJobFormData) => {
		const images = manager.getCompleteUploads().map(({ publicUrl }) => publicUrl);
		onSubmit({ ...data, images });
	};

	return (
		<>
			<form onSubmit={handleSubmit(onHandleSubmit)} noValidate {...rest}>
				<div className="space-y-4">
					<Controller
						control={control}
						name="siteId"
						rules={{ required: true }}
						render={({ field }) => (
							<Select
								label="Site"
								placeholder="Select a site"
								required
								disabled={!!siteId}
								data={sites.map(({ id, name }) => ({ label: name, value: id }))}
								{...field}
							/>
						)}
					/>
					<ControlledTextInput
						control={control}
						name="jobNumber"
						label="Job number"
						placeholder="Enter job number"
						required
					/>
					<ControlledTextInput
						control={control}
						name="claimNumber"
						label="Claim number"
						placeholder="Enter claim number"
						required
					/>
					<Controller
						control={control}
						name="searchType"
						rules={{ required: true }}
						render={({ field }) => (
							<RadioGroup className="cursor-pointer" {...field}>
								<RadioGroup.Label className="text-sm font-semibold text-gray-900">
									Vehicle
								</RadioGroup.Label>

								<div className="flex items-center justify-center overflow-hidden border rounded-lg">
									<RadioGroup.Option className="w-full" value="rego">
										{({ checked }) => (
											<div
												className={tlsx(
													'w-full py-2.5 text-sm text-center font-medium select-none',
													checked ? 'bg-blue-600 text-white' : 'text-gray-500'
												)}
											>
												License Plate
											</div>
										)}
									</RadioGroup.Option>

									<RadioGroup.Option className="w-full" value="chassis">
										{({ checked }) => (
											<div
												className={tlsx(
													'w-full py-2.5 text-sm text-center font-medium select-none',
													checked ? 'bg-blue-600 text-white' : 'text-gray-500'
												)}
											>
												Frame / VIN
											</div>
										)}
									</RadioGroup.Option>
								</div>
							</RadioGroup>
						)}
					/>
					{/* I tried using tab, but I can't enforce having either of the fields be required depending on the search type */}
					<Controller
						control={control}
						name="plateNumber"
						render={({ field, fieldState: { error } }) => (
							<RegoInput
								className={tlsx({ hidden: searchType !== 'rego' })}
								error={error?.message}
								{...field}
							/>
						)}
					/>
					<ControlledTextInput
						control={control}
						className={tlsx({ hidden: searchType !== 'chassis' })}
						name="chassis"
						label="Chassis code"
						placeholder="Enter chassis number"
					/>
					<div>
						<label className="text-sm font-semibold text-gray-900">Images</label>
						<div className="space-y-3">
							<FileUploadDropzone manager={manager} />
							<FileUploadList manager={manager} />
						</div>
					</div>
				</div>
				<Button className="w-full mt-8" type="submit" disabled={manager.state.hasIncompleteUploads}>
					Search for vehicle
				</Button>
			</form>
		</>
	);
};

export default CreateJobForm;
