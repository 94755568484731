import { getConfig } from '@/sdk/react';

// FIXME: Ideally this probably should provided by core api
export interface LocaleSettings {
	currency: 'GBP' | 'NZD';
	uvdbRegionId: string;
}

export enum ConditionFormat {
	// Show condition badge as New vs Grade ABC etc
	Grade,
	// Show condition badge as OEM/OES vs Aftermarket vs used
	MfrType
}

export interface FeatureFlags {
	showWorkProvider: boolean;
	conditionBadgeFormat: ConditionFormat;
	localeSettings: LocaleSettings;
}

const PARTLY_EU_FLAGS: Readonly<FeatureFlags> = Object.freeze({
	showWorkProvider: true,
	conditionBadgeFormat: ConditionFormat.MfrType,
	localeSettings: {
		currency: 'GBP' as const,
		uvdbRegionId: 'UREG35'
	}
});
const DEFAULT_FLAGS: Readonly<FeatureFlags> = Object.freeze({
	showWorkProvider: false,
	conditionBadgeFormat: ConditionFormat.Grade,
	localeSettings: {
		currency: 'NZD' as const,
		uvdbRegionId: 'UREG32'
	}
});

export const getFeatureFlags = (config = getConfig()): Readonly<FeatureFlags> => {
	switch (config.organizationId) {
		// partly eu
		case 'f10751c7-477f-4312-81bf-3c76a26c2ce7': {
			return PARTLY_EU_FLAGS;
		}
		default: {
			return DEFAULT_FLAGS;
		}
	}
};
