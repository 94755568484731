/**
 * Set nested value in an object based on provided keys and value
 */
export const setNestedValue = (obj: Record<string, any>, keys: string[], value: any) => {
	let current = obj;
	// Iterate over all the keys except the last one
	for (let i = 0; i < keys.length - 1; i++) {
		// If the key doesn't exist, create it as an empty object
		if (!current[keys[i]]) {
			current[keys[i]] = {};
		}
		current = current[keys[i]]; // Move to the next level
	}
	// Set the value at the deepest level
	current[keys[keys.length - 1]] = value;
};
