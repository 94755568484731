import { UseFormReturn } from 'react-hook-form';
import { JobPartRecommendedOffers, OrderSelection, PartOfferAggregation } from '../models';
import { setNestedValue } from './set-nested-value';
import { applyOfferSelection } from '../utils';

export const createInitialSelection = (
	jobPartRecommendedOffers: JobPartRecommendedOffers,
	partOfferAggregations: PartOfferAggregation[],
	repairer_site_id: string,
	deliveryDate: Date,
	deliverBefore: Date
): OrderSelection => {
	// Creating a new initial selection.
	const selection: OrderSelection = {
		delivery_date: deliveryDate,
		deliver_before: deliverBefore,
		repairer_site_id,
		items: []
	};

	// creates a draft order by auto-selecting supplies for job parts
	const newSelection = { ...selection };

	const form = {
		setValue: (key: keyof OrderSelection, value: any) => {
			const keys = key.split('.');

			setNestedValue(newSelection, keys, value);
		},

		getValues: () => {
			return { ...newSelection };
		}
	} as UseFormReturn<OrderSelection>;

	partOfferAggregations.forEach(({ jobPart }) => {
		const recommendedOffers = jobPartRecommendedOffers[jobPart.id];

		if (recommendedOffers) {
			recommendedOffers.forEach(recommendedOffer => {
				applyOfferSelection(jobPart, recommendedOffer.offer, form);
			});
		}
	});

	return newSelection;
};
