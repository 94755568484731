import { linkWithSearchParams } from '@/app/common/utils/url';
import { jobsQueries } from '@/sdk/react';
import { tlsx } from '@common/utils/tw-merge';
import { Transition } from '@headlessui/react';
import { useQueries, useSuspenseQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

type PageParams = {
	jobId: string;
};

const PartsLoadingPage = () => {
	const { jobId } = useParams<PageParams>();
	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	const {
		data: { job }
	} = useSuspenseQuery(jobsQueries.get({ jobId }));

	const [{ isFetching: isAssembliesLoading }, { isLoading: isPartsLoading }] = useQueries({
		queries: [jobsQueries.getPartAssembliesTree({ jobId }), jobsQueries.listParts({ jobId })]
	});

	const isLoading = useMemo(
		() => isAssembliesLoading || isPartsLoading,
		[isAssembliesLoading, isPartsLoading]
	);

	if (!job.vehicle || (!job.vehicle.plateNumber && !job.vehicle.chassisNumber)) {
		return (
			<Navigate
				to={linkWithSearchParams('/jobs/create', {
					siteId: job.repairerSiteId,
					jobId: job.id,
					jobNumber: job.jobNumber ?? undefined,
					claimNumber: job.claimNumber ?? undefined
				})}
			/>
		);
	}

	if (!job.vehicle.variant) {
		return (
			<Navigate
				to={linkWithSearchParams('/jobs/create/vehicles', {
					jobId: job.id,
					jobNumber: job.jobNumber ?? undefined,
					claimNumber: job.claimNumber ?? undefined,
					chassisNumber: job.vehicle.chassisNumber ?? undefined,
					plateNumber: job.vehicle.plateNumber ?? undefined,
					plateState: job.vehicle.plateState ?? undefined
				})}
			/>
		);
	}

	return (
		<>
			{!isLoading && <Outlet />}
			<Transition
				as="div"
				show={isLoading}
				className="absolute inset-0 flex flex-col items-center justify-center gap-6 z-[90] bg-gray-50"
				leave="transition-opacity duration-500 delay-150"
				leaveFrom="opacity-100"
				leaveTo="opacity-5"
			>
				<div className="w-80 h-1 rounded-xl bg-gray-300 overflow-hidden flex items-center justify-start">
					<span
						className={tlsx('h-full bg-blue-600', {
							'animate-slow-progress': isLoading,
							'animate-fast-progress': !isLoading
						})}
					/>
				</div>
				<span className="text-xs font-medium text-gray-500">
					Identifying replacement parts
					{!isNil(job.vehicle?.variant?.description) && ` for a ${job.vehicle.variant.description}`}
				</span>
			</Transition>
		</>
	);
};

export default PartsLoadingPage;
