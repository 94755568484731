import { useCallback, useRef, useState } from 'react';
import { mutations } from '@/sdk/react/mutations';
import { match } from '@/types/match';
import { auth, upload } from '@partly/core-server-client';
import {
	FileUploadPrepareError,
	NetworkPrepareResponse,
	UploadManager,
	UploadManagerState
} from '@partly/frontend-utils';
import { useMutation } from '@tanstack/react-query';

export const useFileUploader = () => {
	const { mutateAsync: prepareUpload } = useMutation(mutations.uploads.prepare);

	const [state, setState] = useState<UploadManagerState>({
		uploads: {},
		hasIncompleteUploads: false,
		hasUploads: false
	});

	const manager = useRef(
		(() => {
			const prepare = async (fileName: string): Promise<NetworkPrepareResponse> => {
				try {
					const data = await prepareUpload({
						file_name: fileName,
						upload_kind: { order_media_file_upload: 'image' }
					});

					if (!data.public_url) {
						throw { internal: 'Missing required public url' };
					}

					return { data: { uploadUrl: data.upload_url, publicUrl: data.public_url }, error: null };
				} catch (error) {
					const mappedError = mapPrepareCoreToAdapter(error as upload.UploadsPrepareError);
					return { data: null, error: mappedError };
				}
			};

			return new UploadManager(setState, { prepare });
		})()
	);

	const addFile = useCallback((file: File) => manager.current.addFile(file), []);

	const removeFile = useCallback((fileId: string) => manager.current.removeFile(fileId), []);

	const getCompleteUploads = useCallback(() => manager.current.getCompleteUploads(), []);

	const reset = useCallback(() => manager.current.reset(), []);

	return {
		state,
		addFile,
		removeFile,
		getCompleteUploads,
		reset
	};
};

export type UseFileUploader = ReturnType<typeof useFileUploader>;

const mapPrepareCoreToAdapter = (error: upload.UploadsPrepareError): FileUploadPrepareError => {
	return match<upload.UploadsPrepareError, FileUploadPrepareError>(
		error,
		{
			internal: internal => ({ internal }),
			invalid_file_extension: invalid_file_extension => ({ invalid_file_extension }),
			unauthorized: arg => {
				return match<auth.AuthError, FileUploadPrepareError>(
					arg,
					{
						custom: auth => ({ auth }),
						internal: internal => ({ internal }),
						invalid_email_or_password: () => ({ auth: 'Invalid email or password' }),
						invalid_grant: () => ({ auth: 'Invalid grant' }),
						invalid_token: () => ({ auth: 'Invalid token' }),
						revoked_token: () => ({ auth: 'Revoked token' }),
						no_authorization_details: auth => ({ auth })
					},
					() => ({ internal: 'Unknown error' })
				);
			}
		},
		() => ({ internal: 'Unknown error' })
	);
};
