import { format, isSameDay } from 'date-fns';
import { CheckIcon, ClockIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

interface FormattedShippingTimeProps {
	deliverBefore: Date;
	shippingTime: { eta?: string | Date | null } | null;
	onlyDate?: boolean;
	orientation?: 'vertical' | 'horizontal';
	isBackorderable?: boolean;
	outOfStock?: boolean;
	className?: string;
}

export const FormattedShippingTime = ({
	shippingTime,
	deliverBefore,
	className,
	onlyDate,
	outOfStock,
	isBackorderable = false,
	orientation = 'horizontal'
}: FormattedShippingTimeProps) => {
	if (!shippingTime) {
		return isBackorderable ? <span>Available via back order</span> : 'Availability on request';
	}

	const { eta } = shippingTime;

	if (
		eta &&
		deliverBefore &&
		(isSameDay(deliverBefore, new Date(eta)) || deliverBefore.getTime() >= new Date(eta).getTime())
	) {
		return (
			<div
				className={clsx(
					'flex gap-1 !text-emerald-700 ',
					{ 'flex-col !gap-0': orientation === 'vertical' },
					className
				)}
			>
				<div className="inline-flex gap-1.5">
					{!onlyDate && (
						<>
							<CheckIcon color="green" width={17} />{' '}
							<span className="">Arrives on time, by </span>{' '}
						</>
					)}
				</div>
				<span className={orientation === 'vertical' ? 'ml-5' : ''}>
					{format(deliverBefore, 'eee do MMM')}
				</span>
			</div>
		);
	}

	return eta ? (
		<div className={clsx('flex gap-1.5 items-center', className)}>
			{!onlyDate && <ClockIcon color="red" width={17} />}{' '}
			<span className="mt-[1px]">
				{isBackorderable && outOfStock ? (
					<b className="font-semibold">Backorder,</b>
				) : (
					!onlyDate && 'Arrives by'
				)}{' '}
				{format(eta, 'eee do MMM')}
			</span>
		</div>
	) : (
		'Availability on request'
	);
};
