import { CartSubtotalPrice, ListingPricing as Pricing } from '@sdk/lib';
import { getFeatureFlags } from '@common/utils/feature-flag';

export type DisplayPricing = {
	displayPrice: string;
	originalPrice?: string;
	percentOff?: number;
};

export const DEFAULT_CURRENCY = 'NZD';

export const getCurrency = (): string => {
	const { localeSettings } = getFeatureFlags();
	return localeSettings.currency || DEFAULT_CURRENCY;
};

export const formatPrice = (price: number, currency?: string) => {
	if (price === 0) {
		return 'Price on request';
	}

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: currency || getCurrency()
	});

	return formatter.format(price / 1000);
};

export const createMoney = (amount: number) => {
	return Math.round(amount * 10 ** 3);
};

/**
 * Calculate the sale price, original price and percent off for
 * product.
 *
 * Assumptions
 * + min and max are always the same
 * + displayMin and displayMax are always the same
 *
 * We make those assumations because this is a single product.
 */
export const calculateDisplayPricing = (pricing: Pricing): DisplayPricing => {
	// In future, locale will be passed in with pricing.
	const displayPrice = pricing.min;
	const originalPrice = pricing.displayMin;

	if (originalPrice === null || originalPrice === undefined) {
		return {
			displayPrice: formatPrice(displayPrice)
		};
	}

	const percentOff = Math.round(((originalPrice - displayPrice) / originalPrice) * 100);

	return {
		displayPrice: formatPrice(displayPrice),
		originalPrice: formatPrice(originalPrice),
		percentOff
	};
};

export const formatSubtotal = (subtotal: Omit<CartSubtotalPrice, 'currency'> | null): string => {
	if (!subtotal) {
		return '--';
	}

	if (subtotal.displayPrice) {
		return formatPrice(subtotal.displayPrice);
	}

	return formatPrice(subtotal.price);
};
