import { InheritableElementProps } from '@/types/utilties';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

export type NonInteractiveWarningProps = InheritableElementProps<
	'div',
	{
		open?: boolean;
		onClick: () => void;
	}
>;

export const NonInteractiveWarning = ({
	className,
	open,
	onClick,
	...rest
}: NonInteractiveWarningProps) => {
	if (!open) {
		return null;
	}

	return (
		<div className="grid place-items-center w-full p-3" {...rest}>
			<div
				className="flex items-center gap-3 shrink-0 w-full rounded-sm border border-orange-400 bg-orange-100 px-3.5 py-3"
				color="orange"
			>
				<ExclamationTriangleIcon className="size-5 text-orange-600" />
				<span className="text-orange-900">Diagrams are not available for this vehicle</span>
				<button
					type="button"
					className="text-sm text-orange-900 font-medium ml-auto cursor-pointer"
					onClick={onClick}
				>
					Dismiss
				</button>
			</div>
		</div>
	);
};
