import { JobPartRaw } from '@/sdk/generated';
import { JobPart } from '../types';
import { recursiveToCamel } from '../utils/casing';
import { Context } from '../utils/context';
import { encodeGapcPartIdentityKey } from '../utils/resources';
import { createGapcBrand, createPartSlot } from './gapc';

export const createJobPart = (jobPart: JobPartRaw, ctx: Context): JobPart | null => {
	const partIdentity =
		jobPart.mpn && jobPart.gapc_brand_id
			? encodeGapcPartIdentityKey({
					gapcBrandId: jobPart.gapc_brand_id,
					mpn: jobPart.mpn
				})
			: null;

	const partSlot = jobPart.part_slot ? createPartSlot(jobPart.part_slot, ctx) : null;
	const gapcBrand = jobPart.gapc_brand_id ? createGapcBrand(jobPart.gapc_brand_id, ctx) : null;

	const assembly = recursiveToCamel(jobPart.assembly);

	return {
		id: jobPart.id,
		ghcaId: jobPart.ghca_id,
		quantity: jobPart.quantity,
		description: jobPart.description,
		mpn: jobPart.mpn,
		gapcBrand,
		partIdentity,
		assembly,
		partSlot
	};
};

export const createJobParts = (jobParts: JobPartRaw[], ctx: Context): JobPart[] => {
	return jobParts
		.map(jobPart => createJobPart(jobPart, ctx))
		.filter((item): item is JobPart => !!item);
};
