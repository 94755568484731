import {
	JobRaw,
	NormalizedResponse_for_MultipleItemsPayload_for_JobPart_and_NullRaw,
	NormalizedResponse_for_PaginatedResult_for_JobLink_and_CursorNumericPosition_and_NullRaw,
	NormalizedResponse_for_SingularItemPayload_for_CollisionMapLink_and_NullRaw,
	NormalizedResponse_for_SingularItemPayload_for_JobLink_and_NullRaw
} from '@/sdk/generated';
import { NormalizedResponse_for_WorldTreeAssembliesWithRecommendations_and_Null } from '@/sdk/generated/models/NormalizedResponse_for_WorldTreeAssembliesWithRecommendations_and_Null';
import { NormalizedResponse_for_PartAssembliesTree_and_Null } from '@sdk/generated/models/NormalizedResponse_for_PartAssembliesTree_and_Null';
import {
	ApiConfig,
	CreateJobResult,
	GetJobCollisionMapResult,
	GetJobFallbackAssembliesTreeResult,
	GetJobPartAssembliesTreeResult,
	GetJobResult,
	Job,
	ListJobPartsResult,
	SearchJobsResult,
	SubsequentJobOrderResult,
	UpsertJobPartsResult,
	UpsertJobResult
} from '../types';
import { recursiveToCamel } from '../utils/casing';
import { Context } from '../utils/context';
import { getId } from '../utils/parsing';
import { ResourcePath } from '../utils/resources';
import { createCollisionMap } from './collisions';
import { createJobParts } from './job-parts';
import { createPagination } from './pagination';
import { createPartAssembliesTree, createWorldTreeAssembliesTree } from './part-assemblies';

type SearchJobsResponseRaw =
	NormalizedResponse_for_PaginatedResult_for_JobLink_and_CursorNumericPosition_and_NullRaw;
type CreateJobsResponseRaw = NormalizedResponse_for_SingularItemPayload_for_JobLink_and_NullRaw;
type GetJobResponseRaw = NormalizedResponse_for_SingularItemPayload_for_JobLink_and_NullRaw;
type UpdateJobResponseRaw = NormalizedResponse_for_SingularItemPayload_for_JobLink_and_NullRaw;
type GetJobCollisionMapResponseRaw =
	NormalizedResponse_for_SingularItemPayload_for_CollisionMapLink_and_NullRaw;
type GetJobPartAssembliesTreeResponseRaw = NormalizedResponse_for_PartAssembliesTree_and_Null;
type GetJobFallbackAssembliesTreeResponseRaw =
	NormalizedResponse_for_WorldTreeAssembliesWithRecommendations_and_Null;
type ListJobPartsResonseRaw = NormalizedResponse_for_MultipleItemsPayload_for_JobPart_and_NullRaw;
type UpsertJobPartsResonseRaw = NormalizedResponse_for_MultipleItemsPayload_for_JobPart_and_NullRaw;
type JobOrdersSubsequentResponseRaw =
	NormalizedResponse_for_SingularItemPayload_for_JobLink_and_NullRaw;

type JobsRaw = Record<string, JobRaw> | null | undefined;

const createJobs = (ids: string[] | undefined = [], ctx: Context) => {
	return ids
		.map((rawId: string) => {
			const path = ResourcePath.create<'jobs'>(rawId);
			if (!path) {
				return null;
			}

			return createJob(path.id, ctx);
		})
		.filter((item): item is Job => !!item);
};

const createJob = (id: string | null | undefined, ctx: Context): Job | null => {
	if (!id) {
		return null;
	}

	const job = ctx.resources.get({ path: 'jobs', id });
	if (!job) {
		return null;
	}

	return {
		repairerOrgId: job.repairer_org_id,
		repairerSiteId: job.repairer_site_id,
		id: job.id,
		jobNumber: job.job_number ?? null,
		claimNumber: job.claim_number ?? null,
		/* eslint-disable-next-line */
		createdAt: job.created_at!,
		completedAt: job.completed_at ?? null,
		deliveryBeforeAt: job.delivery_before_at ?? null,
		needsConfirmation: job.needs_confirmation,
		externalId: job.external_id,
		collisions: job.collisions?.map(collision => recursiveToCamel(collision)) ?? [],
		vehicle: job.vehicle ? recursiveToCamel(job.vehicle) : null,
		addedPartsCount: job.added_parts_count,
		status: job.status,
		source: job.source,
		closingReason: job.closing_reason ? recursiveToCamel(job.closing_reason) : null,
		images: job.images,
		workProvider: job.work_provider
	};
};

export const createSearchJobs = (
	result: SearchJobsResponseRaw,
	apiConfig: ApiConfig
): SearchJobsResult => {
	const ctx = Context.create(result, apiConfig);
	const jobs = createJobs(result.payload.items, ctx);
	const pagination = createPagination(result.payload.pagination);

	return {
		jobs,
		pagination
	};
};

export const createCreateJob = (
	result: CreateJobsResponseRaw,
	apiConfig: ApiConfig
): CreateJobResult => {
	const ctx = Context.create(result, apiConfig);
	const jobId = getId(result.payload.item);
	const job = createJob(jobId, ctx);
	if (!job) {
		throw new Error('Failed parse create job result');
	}

	return { job };
};

export const createGetJob = (result: GetJobResponseRaw, apiConfig: ApiConfig): GetJobResult => {
	const ctx = Context.create(result, apiConfig);
	const jobId = getId(result.payload.item);
	const job = createJob(jobId, ctx);
	if (!job) {
		throw new Error('Failed to parse get job result');
	}

	return { job };
};

export const createUpsertJob = (
	result: UpdateJobResponseRaw,
	apiConfig: ApiConfig
): UpsertJobResult => {
	const ctx = Context.create(result, apiConfig);
	const jobId = getId(result.payload.item);
	const job = createJob(jobId, ctx);
	if (!job) {
		throw new Error('Failed to parse update job result');
	}

	return { job };
};

export const createGetJobCollisionMap = (
	result: GetJobCollisionMapResponseRaw,
	apiConfig: ApiConfig
): GetJobCollisionMapResult => {
	const ctx = Context.create(result, apiConfig);
	const collisionMapId = getId(result.payload.item);
	const collisionMap = createCollisionMap(collisionMapId, ctx);
	if (!collisionMap) {
		throw new Error('Failed to parse get collision map result');
	}

	return { collisionMap };
};

export const createGetJobPartAssembliesTree = (
	result: GetJobPartAssembliesTreeResponseRaw,
	apiConfig: ApiConfig
): GetJobPartAssembliesTreeResult => {
	const ctx = Context.create(result, apiConfig);
	const jobId = getJobIdTemp(result.jobs);
	const job = createJob(jobId, ctx);
	if (!job) {
		throw new Error('Failed to parse get recommendations result');
	}

	const tree = createPartAssembliesTree(result.payload, ctx);

	return {
		job,
		tree
	};
};

export const createGetJobFallbackAssembliesTree = (
	result: GetJobFallbackAssembliesTreeResponseRaw,
	apiConfig: ApiConfig
): GetJobFallbackAssembliesTreeResult => {
	const ctx = Context.create(result, apiConfig);
	const jobId = getJobIdTemp(result.jobs);
	const job = createJob(jobId, ctx);
	if (!job) {
		throw new Error('Failed to parse get recommendations result');
	}

	const tree = createWorldTreeAssembliesTree(result.payload.world_tree_assemblies, ctx);
	const recommendations = result.payload.recommendations_map;

	return {
		job,
		tree,
		recommendations
	};
};

export const createListJobParts = (
	result: ListJobPartsResonseRaw,
	apiConfig: ApiConfig
): ListJobPartsResult => {
	const ctx = Context.create(result, apiConfig);
	const parts = createJobParts(result.payload.items, ctx);
	return {
		parts
	};
};

export const createUpsertParts = (
	result: UpsertJobPartsResonseRaw,
	apiConfig: ApiConfig
): UpsertJobPartsResult => {
	const ctx = Context.create(result, apiConfig);
	const jobId = getJobIdTemp(result.jobs);
	const job = createJob(jobId, ctx);
	if (!job) {
		throw new Error('Failed to parse upsert parts result');
	}

	const parts = createJobParts(result.payload.items, ctx);
	return {
		job,
		parts
	};
};

export const createJobsOrdersSubsequent = (
	result: JobOrdersSubsequentResponseRaw,
	apiConfig: ApiConfig
): SubsequentJobOrderResult => {
	const ctx = Context.create(result, apiConfig);
	/* eslint-disable-next-line */
	const job = createJob(result.payload.item, ctx)!;

	return {
		job
	};
};

// Todo: this is different from how the other endpoints work.
// I think it should come back in payload.
// Check with Matt.
const getJobIdTemp = (jobs: JobsRaw) => Object.keys(jobs ?? {})?.[0];
