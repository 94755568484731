import { useAuthenticatedUser } from '@/app/common/hooks/use-me';
import CardContainer from '../card-container';
import { Button } from '@mantine/core';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { offers_search } from '@partly/core-server-client';
import { useState } from 'react';

interface RecommendedOfferRequestCardProps {
	request: offers_search.OfferRequestGroup;
	onSendOfferRequest(supplierId: string): Promise<Boolean>;
}

const RecommendedOfferRequestCard = ({
	request,
	onSendOfferRequest
}: RecommendedOfferRequestCardProps) => {
	const { vendors } = useAuthenticatedUser();
	const [isSending, setIsSending] = useState(false);

	return (
		<CardContainer className="flex-1">
			<div className="p-2 flex-1 min-h-[260px] flex pb-10 flex-col items-center justify-center w-[240px]">
				<b className="font-medium text-center mb-16">
					{vendors.find(vendor => vendor.id === request.supplier.id)?.name || request.supplier.name}
				</b>

				<Button
					variant="outline"
					disabled={isSending}
					leftIcon={<PaperAirplaneIcon width={22} />}
					onClick={async () => {
						setIsSending(true);
						await onSendOfferRequest(request.supplier.id);
						setIsSending(false);
					}}
				>
					{isSending ? 'Adding' : 'Add'} supplier
				</Button>
			</div>
		</CardContainer>
	);
};

export default RecommendedOfferRequestCard;
