import { SupplyVendor } from '@/sdk/lib';
import { getConfig } from '@/sdk/react';
import { queries } from '@/sdk/react/queries';
import { shipping } from '@/sdk/reflect/reflect';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAuth } from '@common/hooks/use-auth';
import { business_network } from '@partly/core-server-client';

type AuthenticatedUser = {
	name: string;
	email: string;
	// todo: use reflect
	business_entities: business_network.BusinessModel[];
	repairerOrganizationId: string;
};

// todo: for some reason typescript is unhappy inferring this. don't
// have time to work out why.
export type Site = {
	id: string;
	name: string;
	shipping_address: shipping.ShippingAddress | undefined;
	user_groups: Array<string>;
};

export type Company = {
	kind: 'company';
	id: string;
	name: string;
	vendors: Record<string, SupplyVendor>;
	user_groups: Array<string>;
};

export const useAuthenticatedUser = () => {
	const { selectedBusinessId } = useAuth();
	const repairerOrganizationId = getConfig().organizationId;
	const [{ data: userData }, { data: sitesData }, { data: vendorsData }] = useSuspenseQueries({
		queries: [
			{
				...queries.users.get({ id: null }),
				// Stop refetching every time a new component uses
				// this hook. This is a network performance optimization.
				refetchOnMount: true,
				// Refetch when the window regains focus, this will
				// ensure things like permissions can be updated if needed.
				refetchOnWindowFocus: true,
				// Perform retry on mount if state is out of sync
				retryOnMount: true
			},
			{
				...queries.businesses.list({ profiles: [{ type: 'repairer_site' }] }),
				// Stop refetching every time a new component uses
				// this hook. This is a network performance optimization.
				refetchOnMount: true,
				// Refetch when the window regains focus, this will
				// ensure things like permissions can be updated if needed.
				refetchOnWindowFocus: true,
				// Perform retry on mount if state is out of sync
				retryOnMount: true
			},
			{
				...queries.businesses.associations.list({
					profiles: [{ type: 'repairer_approved_supplier' }],
					business_id: selectedBusinessId ?? ''
				}), // Stop refetching every time a new component uses
				// this hook. This is a network performance optimization.
				refetchOnMount: true,
				// Refetch when the window regains focus, this will
				// ensure things like permissions can be updated if needed.
				refetchOnWindowFocus: true,
				// Perform retry on mount if state is out of sync
				retryOnMount: true
			}
		]
	});

	if (!userData || !sitesData || !vendorsData) {
		// reload the page if the user is not found
		window.location.reload();
		throw new Error('Cannot use me hook without user data');
	}

	const user = useMemo<AuthenticatedUser>(() => {
		return {
			id: userData.id,
			name: userData.name,
			email: userData.email,
			business_entities: sitesData.items,
			repairerOrganizationId: repairerOrganizationId ?? ''
		};
	}, [userData, sitesData]);

	const vendors = useMemo(() => {
		const vendors = vendorsData.items
			.filter(({ profile }) => profile.type === 'repairer_approved_supplier')
			.map(
				({ associated_business }) =>
					({ id: associated_business.id, name: associated_business.name }) as SupplyVendor
			)
			.sort((a, b) => a.name.localeCompare(b.name));

		return vendors;
	}, [vendorsData]);

	const sites = useMemo<Site[]>(() => {
		const sites = sitesData.items
			.flatMap(business => {
				if (business.profile.type === 'repairer_site') {
					return [
						{
							id: business.id,
							name: business.name,
							user_groups: [],
							shipping_address: business.profile.shipping_address
						}
					] as Site[];
				}
				return [] as Site[];
			})
			.sort((a, b) => a.name.localeCompare(b.name));

		return sites;
	}, [sitesData]);

	return { user, vendors, sites };
};
