import { InheritableProps, InheritableThreeProps } from '@/types/utilties';
import { tlsx } from '@common/utils/tw-merge';
import { Decal, Html, Line } from '@react-three/drei';
import { Fragment, ReactNode, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import * as THREE from 'three';
import { useImageTexture } from '../../hooks/use-image-texture';
import { hexToRgb } from '../../utils/color';
import { polygonNexus, vec2to3, vec3 } from '../../utils/geometry';
import { PartsHotpot } from '../parts-hotspot';

const COLOURS = {
	interactive: {
		foreground: '#581c87',
		background: '#e9d5ff'
	},
	highlighted: {
		foreground: '#1e3a8a',
		background: '#bfdbfe'
	},
	searched: {
		foreground: '#78350f',
		background: '#fde68a'
	},
	added: {
		foreground: '#064e3b',
		background: '#a7f3d0'
	},
	navigable: {
		foreground: '#0f172a',
		background: '#e2e8f0'
	},
	related: {
		foreground: '#164e63',
		background: '#a5f3fc'
	}
} as const;

type ColorState = keyof typeof COLOURS;

type PartsDiagramFallbackProps = InheritableThreeProps<
	'mesh',
	{
		url: string;
		ratio: number;
		scale: number;
		filter?: 'dimmed' | 'background' | 'opaque';
	}
>;

export const PartsDiagramFallback = ({
	url,
	ratio,
	scale,
	filter,
	...rest
}: PartsDiagramFallbackProps) => {
	return (
		<mesh renderOrder={0} {...rest}>
			<planeGeometry args={[scale, scale * ratio]} />
			<meshBasicMaterial color={0xffffff} opacity={1} transparent toneMapped={false} />
			<Html
				wrapperClass="!z-0"
				position={[0, 0, 0]}
				transform
				occlude="blending"
				distanceFactor={10}
				className={tlsx('flex items-center justify-center pointer-events-none opacity-50', {
					'opacity-100': filter === 'opaque',
					'opacity-50': filter === 'dimmed'
				})}
				style={{
					width: `${scale * 40}px`
				}}
			>
				<img src={url} />
			</Html>
		</mesh>
	);
};

type PartsDiagramBackgroundProps = InheritableThreeProps<
	'mesh',
	{
		url: string;
		ratio: number;
		scale: number;
		filter?: 'dimmed' | 'background' | 'opaque';
		children?: ReactNode;
	}
>;

export const PartsDiagramBackground = ({
	url,
	ratio,
	scale,
	filter,
	children,
	...rest
}: PartsDiagramBackgroundProps) => {
	const texture = useImageTexture(url);
	return (
		<mesh renderOrder={0} {...rest}>
			<planeGeometry args={[scale, scale * ratio]} />
			<meshBasicMaterial
				map={texture}
				color={0xffffff}
				opacity={filter === 'opaque' ? 1 : filter == 'dimmed' ? 0.5 : 0.5}
				transparent
				toneMapped={false}
			/>
			{children}
		</mesh>
	);
};

type PartsDiagramCodeLayerProps = InheritableThreeProps<
	'mesh',
	{
		rect: THREE.Vector2[];
		color?: `#${string}`;
		opacity?: number;
	}
>;

export const PartsDiagramCodeLayer = ({
	rect,
	color,
	opacity,
	...rest
}: PartsDiagramCodeLayerProps) => {
	const shape = new THREE.Shape(rect);
	return (
		<mesh renderOrder={2} {...rest}>
			<shapeGeometry args={[shape]} />
			<meshBasicMaterial
				color={color ?? '#ffffff'}
				opacity={opacity ?? 1}
				transparent
				toneMapped={false}
			/>
		</mesh>
	);
};

type PartsDiagramHotspotProps = InheritableThreeProps<
	'mesh',
	{
		point: THREE.Vector2;
		code: string | null;
		kind: 'assembly' | 'reference';
		checked?: boolean;
		highlighted?: boolean;
		searched?: boolean;
		far?: boolean;
		className?: string;
		onClick: () => void;
		onDoubleClick: () => void;
	}
>;

export const PartsDiagramAssemblyHotspot = ({
	className,
	point,
	code,
	kind,
	checked,
	highlighted,
	searched,
	far,
	onClick,
	onDoubleClick,
	...rest
}: PartsDiagramHotspotProps) => {
	const shape = new THREE.Shape([point]);
	return (
		<mesh renderOrder={4} {...rest}>
			<shapeGeometry args={[shape]} />
			<Html
				as="div"
				wrapperClass={tlsx(
					'flex items-center justify-center !z-[5]',
					{
						'!z-[6]': searched
					},
					{
						'!z-[7]': highlighted
					},
					className
				)}
				className="bg-white/0 border-0"
				position={vec2to3(point)}
			>
				<PartsHotpot
					code={code}
					kind={kind}
					checked={checked}
					highlighted={highlighted}
					searched={searched}
					far={far}
					onClick={onClick}
					onDoubleClick={onDoubleClick}
				/>
			</Html>
			<meshBasicMaterial color="#ffffff" opacity={0} transparent toneMapped={false} />
		</mesh>
	);
};

type PartsDiagramSegmentProps = InheritableThreeProps<
	'mesh',
	{
		polygon: THREE.Vector2[];
		url: string;
		ratio: number;
		scale: number;
		state: ColorState;
		decalOrder?: number;
	}
>;

export const PartsDiagramSegment = ({
	polygon,
	ratio,
	scale,
	url,
	state,
	decalOrder,
	onClick,
	onDoubleClick,
	...rest
}: PartsDiagramSegmentProps) => {
	const texture = useImageTexture(url);
	const shape = new THREE.Shape(polygon);

	const ref = useRef<number | NodeJS.Timeout | null>(null);

	// // scaling polygon hitbox + making it a square for larger area (need to experiment more)
	// const hitbox = new THREE.Shape(scalePolygon(polygonToRect(polygon), 1.25));

	return (
		<>
			<mesh
				onClick={e => {
					if (ref.current) {
						clearTimeout(ref.current);
					}
					ref.current = setTimeout(() => {
						if (e.detail >= 2) {
							return onDoubleClick?.(e);
						}
						return onClick?.(e);
					}, 200);
				}}
				{...rest}
			>
				<shapeGeometry args={[shape]} />
				<meshBasicMaterial
					color="#ffffff"
					opacity={1}
					transparent
					toneMapped={false}
					depthWrite={false}
					depthTest={false}
				/>
				<Decal
					position={vec3(0, 0, 0)}
					rotation={[0, 0, 0]}
					scale={[scale, scale * ratio, 1]}
					renderOrder={decalOrder ?? 1}
				>
					<meshBasicMaterial
						color="#ffffff"
						opacity={1}
						transparent
						toneMapped={false}
						depthWrite={false}
						depthTest={false}
					/>
					<colorReplaceMaterial
						map={texture}
						attach="material"
						target={[0, 0, 0]}
						replacement={hexToRgb(COLOURS[state].foreground)}
						opacity={1}
						tolerance={0.8}
						transparent
						polygonOffset
						polygonOffsetFactor={-1}
						toneMapped={false}
						depthWrite={false}
						depthTest={false}
					/>
				</Decal>
				<meshBasicMaterial
					color={COLOURS[state].background}
					opacity={state === 'highlighted' ? 0.3 : 0.2}
					transparent
					toneMapped={false}
					depthWrite={false}
					depthTest={false}
				/>
			</mesh>
		</>
	);
};

export const PartsDiagramSuspenseSegment = (props: PartsDiagramSegmentProps) => {
	return (
		<ErrorBoundary fallbackRender={() => <Fragment />}>
			<PartsDiagramSegment {...props} />
		</ErrorBoundary>
	);
};

type PartsDiagramAssemblyLineProps = Omit<
	InheritableProps<
		typeof Line,
		{
			from: THREE.Vector2[];
			to: THREE.Vector2[];
			state: ColorState;
			dashed?: boolean;
			far?: boolean;
		}
	>,
	'points'
>;

export const PartsDiagramLine = ({
	from,
	to,
	state,
	dashed,
	far,
	...rest
}: PartsDiagramAssemblyLineProps) => {
	const line = polygonNexus(from, to);
	return (
		<>
			<Line
				points={line}
				color={COLOURS[state].foreground}
				lineWidth={!dashed && !far ? 3 : 2}
				dashed={dashed}
				dashScale={40}
				opacity={!dashed ? 1 : 0.25}
				transparent
				renderOrder={3}
				{...rest}
			/>
		</>
	);
};
