import { useMeasurement } from '@common/hooks/use-measure';
import { tlsx } from '@common/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';

type SplitCollisionTemplateProps = InheritableElementProps<'div', object>;
type SubProps = InheritableElementProps<'div', object>;

export const SplitCollisionTemplate = ({ className, ...rest }: SplitCollisionTemplateProps) => (
	<div className={tlsx('grid flex-1 grid-cols-1 md:grid-cols-3 gap-4', className)} {...rest} />
);

const Collision = ({ className, style, ...rest }: SubProps) => {
	const { value: nav } = useMeasurement('navigation-bar');
	return (
		<div
			className={tlsx(
				'flex flex-col items-center justify-center col-span-1 gap-6 p-6 border rounded-xl lg:sticky',
				className
			)}
			style={{
				...style,
				top: (nav?.height ?? 0) + 16,
				height: `calc(100dvh - ${nav?.height ?? 0}px - 2rem)`
			}}
			{...rest}
		/>
	);
};

const Detail = ({ className, ...rest }: SubProps) => (
	<div
		className={tlsx(
			'flex flex-col col-span-1 md:col-span-2 p-6 space-y-3 border rounded-xl',
			className
		)}
		{...rest}
	/>
);

SplitCollisionTemplate.Collision = Collision;
SplitCollisionTemplate.Detail = Detail;
