import { tlsx } from '@common/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { CategoryTreeLeaf, CategoryTreeNode, PartsFormData } from '../../types';
import { PartsAllDiagram, PartsCut } from './subcomponents';

type PartsCutsProps = InheritableElementProps<
	'div',
	{
		cuts: CategoryTreeNode[];
		other?: CategoryTreeLeaf | null;
		cut?: CategoryTreeNode | null;
		category?: CategoryTreeLeaf | null;
		selection: PartsFormData;
		actions: {
			cut: {
				set: (cut: CategoryTreeNode) => void;
			};
			category: {
				set: (category: CategoryTreeLeaf) => void;
			};
		};
	}
>;

export const PartsCuts = ({
	className,
	cuts,
	other,
	cut,
	category,
	actions,
	selection,
	...rest
}: PartsCutsProps) => {
	return (
		<div
			className={tlsx(
				'flex items-center w-full h-20 bg-white border-b px-6 gap-4 overflow-auto',
				className
			)}
			{...rest}
		>
			{cuts.map(each => (
				<PartsCut key={each.id} cut={each} selected={cut} selection={selection} actions={actions} />
			))}

			{other && (
				<PartsAllDiagram
					other={other}
					category={category}
					selection={selection}
					actions={actions}
				/>
			)}
		</div>
	);
};
