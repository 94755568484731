import { tlsx } from '@common/utils/tw-merge';
import { lookup_server_model } from '@/sdk/reflect/reflect';
import { InheritableElementProps } from '@/types/utilties';
import { match } from '@partly/js-ex';

type StatusNoteProps = InheritableElementProps<
	'div',
	{
		status: lookup_server_model.json_types.SupplierOrderStatus;
	}
>;

export const StatusNote = ({ status, className, ...rest }: StatusNoteProps) => {
	const note = getNote(status);
	if (!note) {
		return null;
	}

	return (
		<div className={tlsx('p-4 bg-gray-100 rounded-md', className)} {...rest}>
			<pre className="font-sans text-sm text-gray-700">
				<span className="font-medium">Note:</span> {note}
			</pre>
		</div>
	);
};

const getNote = (status: lookup_server_model.json_types.SupplierOrderStatus) => {
	return match<lookup_server_model.json_types.SupplierOrderStatus, string | null>(
		status,
		{
			ordered: arg => arg.note,
			confirmed: arg => arg.note,
			cancelled: arg => arg.note
		},
		() => null
	);
};
