import StandardDialog, { StandardDialogProps } from '@common/components/standard-dialog';
import { Button, Textarea } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { OrderTableItem } from '../table-builder';

const schema = yup.object({
	arrivalAt: yup.date().required().label('Arrival at'),
	details: yup.string().label('Details')
});

export type ReceiveItemFormData = yup.InferType<typeof schema>;

type ReceiveItemDialogProps = StandardDialogProps & {
	item: OrderTableItem | null; // todo: decouple
	onSubmit: (data: ReceiveItemFormData) => void;
};

const defaultValues = {
	arrivalAt: new Date(),
	details: ''
};

export const ReceiveItemDialog = ({ open, item, onSubmit, onClose }: ReceiveItemDialogProps) => {
	const form = useForm({
		defaultValues
	});

	useEffect(() => {
		if (item?.status && typeof item?.status === 'object' && 'received' in item.status) {
			form.reset({
				arrivalAt: new Date(item.status.received.timestamp),
				details: item.status.received.notes ?? ''
			});

			return;
		}

		form.reset(defaultValues);
	}, [item]);

	return (
		<StandardDialog open={open} onClose={onClose}>
			<StandardDialog.Title>Receive item(s)</StandardDialog.Title>
			<StandardDialog.Content>
				<form noValidate onSubmit={form.handleSubmit(onSubmit)}>
					<div className="space-y-2">
						<Controller
							control={form.control}
							name="arrivalAt"
							render={({ field }) => (
								<DateTimePicker
									required
									label="Arrival at"
									popoverProps={{ withinPortal: true }}
									{...field}
									// typescript being weird, don't have
									// time to investigate
									onPointerLeaveCapture={undefined}
									onPointerEnterCapture={undefined}
									placeholder={undefined}
								/>
							)}
						/>
						<Controller
							control={form.control}
							name="details"
							render={({ field }) => <Textarea label="Details" {...field} />}
						/>
					</div>
					<div className="flex items-center justify-end gap-2 mt-6">
						<Button variant="default" onClick={onClose}>
							Cancel
						</Button>
						<Button type="submit">Submit</Button>
					</div>
				</form>
			</StandardDialog.Content>
		</StandardDialog>
	);
};
