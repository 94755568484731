import { forwardRef, HTMLAttributes } from 'react';

import { tlsx } from '@common/utils/tw-merge';
import { AsProps, CompoundComponentWithRef } from '@/types/utilties';
import { getChildByType, removeChildrenByType } from 'react-nanny';
import { BadgeContext, useBadge } from './context';
import { BadgeProps, BadgeSize, BadgeVariant } from './types';

const getIconStyles = (size: BadgeSize) => {
	const sizes: Record<BadgeSize, string> = {
		small: 'size-3.5',
		medium: 'size-4'
	};

	return sizes[size];
};

const getRemoveButtonStyles = (variant: BadgeVariant) => {
	const styles: Record<BadgeVariant, string> = {
		red: tlsx('focus:bg-red-500 text-red-800 hover:bg-red-300 hover:text-red-900'),
		yellow: tlsx('focus:bg-yellow-500 text-yellow-800 hover:bg-yellow-300 hover:text-yellow-900'),
		green: tlsx('focus:bg-green-500 text-green-800 hover:bg-green-300 hover:text-green-900'),
		blue: tlsx('focus:bg-blue-500 text-blue-800 hover:bg-blue-300 hover:text-blue-900'),
		purple: tlsx('focus:bg-purple-500 text-purple-800 hover:bg-purple-300 hover:text-purple-900'),
		pink: tlsx('focus:bg-pink-500 text-pink-800 hover:bg-pink-300 hover:text-pink-900'),
		neutral: tlsx(
			'focus:bg-neutral-500 text-neutral-900 hover:bg-neutral-300 hover:text-neutral-800'
		),
		amber: tlsx('focus:bg-amber-500 text-amber-900 hover:bg-amber-300 hover:text-amber-800'),
		orange: tlsx('focus:bg-orange-500 text-orange-900 hover:bg-orange-300 hover:text-orange-800'),
		white: tlsx('focus:bg-neutral-500 text-neutral-900 hover:bg-neutral-300 hover:text-neutral-800')
	};

	return styles[variant];
};

const RemoveButton = ({
	'aria-label': ariaLabel,
	variant = 'neutral',
	onRemove
}: Pick<BadgeProps, 'variant' | 'onRemove'> &
	Pick<HTMLAttributes<HTMLButtonElement>, 'aria-label'>) => {
	return (
		<button
			type="button"
			aria-label={ariaLabel}
			className={tlsx(
				'focus:outline-hidden inline-flex size-4 shrink-0 items-center justify-center rounded-full ms-1.5 focus:text-white',
				getRemoveButtonStyles(variant)
			)}
			onClick={onRemove}
		>
			<svg
				className="size-2"
				stroke="currentColor"
				fill="none"
				viewBox="0 0 8 8"
				aria-hidden="true"
			>
				<path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
			</svg>
		</button>
	);
};

const getVariantStyles = (variant: BadgeVariant) => {
	const styles: Record<BadgeVariant, string> = {
		neutral: 'bg-neutral-50 text-neutral-900',
		red: 'bg-red-100 text-red-700',
		yellow: 'bg-yellow-100 text-yellow-900',
		green: 'bg-green-100 text-green-900',
		blue: 'bg-blue-100 text-blue-600',
		purple: 'bg-purple-100 text-purple-800',
		pink: 'bg-pink-50 text-pink-900',
		orange: 'bg-orange-200 text-orange-800',
		amber: 'bg-amber-100 text-amber-900',
		white: 'bg-white text-neutral-900 ring-1 ring-neutral-300 ring-inset'
	};

	return styles[variant];
};

const getSizeStyles = (size: BadgeSize, isRemovable: boolean) => {
	const styles: Record<BadgeSize, string> = {
		small: tlsx('ps-2 py-1 text-xs leading-4 pe-2', { 'pe-1': isRemovable }),
		medium: tlsx('ps-2.5 py-1 text-sm leading-5 pe-2.5', { 'pe-1.5': isRemovable })
	};

	return styles[size];
};

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
	(
		{
			variant = 'neutral',
			size = 'medium',
			rounded = false,
			removeButtonAriaLabel = 'Remove badge',
			className,
			onRemove,
			children,
			...props
		}: BadgeProps,
		ref
	) => {
		const leadingIcon = getChildByType(children, Badge.LeadingIcon);
		const childrenWithoutIcon = removeChildrenByType(children, Badge.LeadingIcon);

		return (
			<span
				ref={ref}
				className={tlsx(
					'inline-flex items-center whitespace-nowrap',
					rounded ? 'rounded-full' : 'rounded-md',
					getVariantStyles(variant),
					getSizeStyles(size, Boolean(onRemove)),
					className
				)}
				{...props}
			>
				<BadgeContext.Provider value={{ size }}>
					{leadingIcon}
					{childrenWithoutIcon}
				</BadgeContext.Provider>
				{onRemove && (
					<RemoveButton variant={variant} onRemove={onRemove} aria-label={removeButtonAriaLabel} />
				)}
			</span>
		);
	}
) as CompoundComponentWithRef<{ LeadingIcon: typeof LeadingIcon }, BadgeProps>;

type IconProps = Required<AsProps<any>>;
const LeadingIcon = ({ as: Component }: IconProps) => {
	const { size } = useBadge();
	return (
		<Component
			className={tlsx(getIconStyles(size), 'me-1.5')}
			fill="currentColor"
			aria-hidden="true"
		/>
	);
};

Badge.LeadingIcon = LeadingIcon;
Badge.displayName = 'Badge';
