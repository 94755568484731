import { GapcPartType, GapcPosition, PartSlot, PartSlotIds } from '@sdk/lib';

export const createPartSlotIds = (partSlot?: PartSlot | null): PartSlotIds | null => {
	if (!partSlot) {
		return null;
	}

	return {
		gapcPartTypeId: partSlot.gapcPartType?.id,
		gapcPositionId: partSlot.gapcPosition?.id
	};
};
export const formatPartContext = (
	gapcPartType: GapcPartType | null,
	gapcPosition: GapcPosition | null,
	description: string | null,
	ghcaId: string | null,
	mpn: string | null
): string | null => {
	if (description && ghcaId) {
		return description;
	}
	if (gapcPartType && gapcPosition) {
		return `${gapcPartType.name}, ${gapcPosition.name}`;
	}

	if (gapcPartType) {
		return `${gapcPartType.name}`;
	}
	if (description) {
		return description;
	}

	if (mpn) {
		return mpn;
	}

	return null;
};
