import { Skeleton } from '@mantine/core';

// todo (vincent) revise in the future
export const PartsSkeletonPage = () => {
	return (
		<div className="flex-1 flex flex-col place-items-center">
			<div className="relative flex items-center w-full h-20 bg-white border-b px-6 gap-4">
				<Skeleton className="rounded-md h-12 w-40" />
				<Skeleton className="rounded-md h-12 w-40" />
				<Skeleton className="rounded-md h-12 w-40" />
			</div>
		</div>
	);
};
