import { fuzzyIncludes } from '@common/utils/string';
import { CategorizedDiagram, CategoryTree, CategoryTreeLeaf, CategoryTreeNode } from '../../types';
import { tokenized } from './tokens';

export const filterCategoryNodes = (
	categories: CategoryTreeNode[],
	query?: string
): CategoryTreeNode[] => {
	if (!query) {
		return categories;
	}
	return categories
		.map(category => {
			if (category.searchables.some(searchable => filterTokenMatch(searchable, query))) {
				return category;
			}
			return {
				...category,
				assemblies: filterCategories(category.assemblies, query)
			};
		})
		.filter(({ searchables, ...category }) => {
			if (searchables.some(searchable => filterTokenMatch(searchable, query))) {
				return true;
			}
			return category.assemblies.length > 0;
		});
};
export const filterCategories = (categories: CategoryTree[], query?: string): CategoryTree[] => {
	if (!query) {
		return categories;
	}
	return categories
		.map(category => {
			if (category.searchables.some(searchable => filterTokenMatch(searchable, query))) {
				return category;
			}

			if (category.kind === 'leaf') {
				return {
					...category,
					diagrams: filterDiagrams(category.diagrams, query)
				};
			}
			return {
				...category,
				assemblies: filterCategories(category.assemblies, query)
			};
		})
		.filter(({ searchables, ...category }) => {
			if (searchables.some(searchable => filterTokenMatch(searchable, query))) {
				return true;
			}

			if (category.kind === 'leaf') {
				return category.diagrams.length > 0;
			}
			return category.assemblies.length > 0;
		});
};

export const filterOtherCategory = (
	category: CategoryTreeLeaf,
	query?: string
): CategoryTreeLeaf | null => {
	if (!query) {
		return category;
	}

	const diagrams = filterDiagrams(category.diagrams, query);

	if (diagrams.length === 0) {
		return null;
	}

	return {
		...category,
		diagrams
	};
};

export const filterDiagrams = (
	diagrams: CategorizedDiagram[],
	query: string
): CategorizedDiagram[] => {
	return diagrams.filter(({ diagram: { searchables, partSlots } }) => {
		const isDiagramMatching = searchables.some(searchable => filterTokenMatch(searchable, query));
		const isAnyPartMatching = partSlots
			.flatMap(partSlot => (partSlot.kind === 'assembly' ? partSlot.assemblies : []))
			.flatMap(({ searchables }) => searchables)
			.some(searchable => filterTokenMatch(searchable, query));

		return isAnyPartMatching || isDiagramMatching;
	});
};

export const filterTokenMatch = (src: string | null | undefined, query: string) => {
	if (query.length === 0) {
		return true;
	}
	if (!src) {
		return false;
	}
	const l = tokenized(src);
	const r = tokenized(query);

	if (r.isSubsetOf(l)) {
		return true;
	}

	return fuzzyIncludes(src, query);
};
