import { InheritableElementProps } from '@/types/utilties';
import ImageWithSkeleton from '@common/components/image-with-skeleton';
import { tlsx } from '@common/utils/tw-merge';
import {
	ChevronRightIcon,
	InformationCircleIcon,
	QuestionMarkCircleIcon
} from '@heroicons/react/24/outline';
import { Checkbox, CheckboxProps, Popover, Tooltip } from '@mantine/core';
import { isNil, sortBy, values } from 'lodash-es';
import { ForwardedRef, forwardRef, Fragment, ReactNode, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import {
	CategoryTreeLeaf,
	CategoryTreeNode,
	DiagramAssembly,
	DiagramPartSlot,
	DiagramPartSlotAssemblyInfo,
	DiagramPartSlotReferenceInfo,
	PartsFormData,
	PartsSelection
} from '../../types';
import { flattenPartSlots } from '../../utils/assembly';
import { createPartSelection } from '../../utils/form';
import { groupPartVariants } from '../../utils/variant';
import { usePartsContext } from '../parts-context';
import { PartsHotpot } from '../parts-hotspot';

export type PartsDiagramPartSlotProps = InheritableElementProps<
	'div',
	{
		cut?: CategoryTreeNode | null;
		category?: CategoryTreeLeaf | null;
		partSlot: DiagramPartSlot;
		control: Control<PartsFormData>;
		selection: PartsFormData;
		highlighted: Set<string>;
		preselected?: boolean;
		child?: boolean;
		actions: {
			part: {
				highlight: (ids: string[]) => void;
				remove: (assemblies: DiagramAssembly[]) => void;
			};
			diagram: {
				jump: (cutId: string | null, categoryId: string, diagramId: string) => void;
			};
		};
	}
>;

export const PartsDiagramPartSlot = ({
	partSlot,
	control,
	selection,
	highlighted,
	actions,
	className,
	...rest
}: PartsDiagramPartSlotProps) => {
	switch (partSlot.kind) {
		case 'assembly': {
			return (
				<PartsDiagramPartSlotAssembly
					partSlot={partSlot}
					control={control}
					selection={selection}
					highlighted={highlighted}
					actions={actions}
					{...rest}
				/>
			);
		}
		case 'reference': {
			return (
				<PartsDiagramPartSlotReference
					partSlot={partSlot}
					selection={selection}
					actions={actions}
					{...rest}
				/>
			);
		}
	}
};

export type PartsDiagramPartSlotAssemblyProps = InheritableElementProps<
	'div',
	{
		partSlot: DiagramPartSlotAssemblyInfo;
		control: Control<PartsFormData>;
		selection: PartsFormData;
		highlighted: Set<string>;
		preselected?: boolean;
		child?: boolean;
		actions: {
			part: {
				highlight: (ids: string[]) => void;
				remove: (assemblies: DiagramAssembly[]) => void;
			};
		};
	}
>;

export const PartsDiagramPartSlotAssembly = ({
	partSlot,
	control,
	selection,
	highlighted,
	actions,
	className,
	preselected,
	onClick: _,
	...rest
}: PartsDiagramPartSlotAssemblyProps) => {
	const variants = groupPartVariants(partSlot.assemblies);
	return variants.map(variant => {
		if (variant.length === 1) {
			const assembly = variant[0];
			return (
				<Controller
					key={assembly.id}
					control={control}
					name={assembly.id}
					render={({ field }) => {
						const checked = (selection[assembly.id]?.quantity ?? field?.value?.quantity ?? 0) > 0;
						return (
							<PartsAssemblyDisplay
								className={tlsx('w-full', className)}
								control={control}
								selection={selection}
								assembly={assembly}
								highlighted={highlighted}
								preselected={preselected}
								checkbox={{
									checked,
									indeterminate: !checked && preselected,
									disabled: !checked && preselected,
									onChange: e => {
										if (!assembly) {
											return;
										}
										const data: PartsSelection = createPartSelection(
											assembly,
											e.target.checked,
											values(selection).filter(s => (s?.quantity ?? 0) > 0).length
										);
										field.onChange(data);
										actions.part.remove(
											flattenPartSlots(assembly.subAssemblies ?? []).flatMap(each =>
												each.kind === 'assembly' ? each.assemblies : []
											)
										);
									}
								}}
								actions={actions}
								{...rest}
							/>
						);
					}}
				/>
			);
		}

		return (
			<PartsVariantsDisplay
				className={tlsx('w-full', className)}
				variants={variant}
				control={control}
				selection={selection}
				highlighted={highlighted}
				actions={actions}
				preselected={variant
					.map(v => selection[v.id]?.quantity ?? 0)
					.some(quantity => quantity > 0)}
				render={variant => (
					<Controller
						key={variant.id}
						control={control}
						name={variant.id}
						render={({ field }) => {
							const checked = (selection[variant.id]?.quantity ?? field?.value?.quantity ?? 0) > 0;
							return (
								<PartsVariantDisplay
									variant={variant}
									checkbox={{
										checked,
										indeterminate: !checked && preselected,
										disabled: !checked && preselected,
										onChange: e => {
											if (!variant) {
												return;
											}
											const data: PartsSelection = createPartSelection(
												variant,
												e.target.checked,
												values(selection).filter(s => (s?.quantity ?? 0) > 0).length
											);
											field.onChange(data);
											actions.part.remove(
												flattenPartSlots(variant.subAssemblies ?? []).flatMap(each =>
													each.kind === 'assembly' ? each.assemblies : []
												)
											);
										}
									}}
								/>
							);
						}}
					/>
				)}
				{...rest}
			/>
		);
	});
};

export type PartsDiagramPartSlotReferenceProps = InheritableElementProps<
	'div',
	{
		cut?: CategoryTreeNode | null;
		category?: CategoryTreeLeaf | null;
		partSlot: DiagramPartSlotReferenceInfo;
		selection: PartsFormData;
		child?: boolean;
		actions: {
			diagram: {
				jump: (cutId: string | null, categoryId: string, diagramId: string) => void;
			};
		};
	}
>;

export const PartsDiagramPartSlotReference = ({
	cut,
	category,
	partSlot,
	selection,
	actions,
	className,
	onClick: _,
	...rest
}: PartsDiagramPartSlotReferenceProps) => {
	const context = usePartsContext();
	const diagrams = useMemo(
		() =>
			sortBy(
				context.diagrams.filter(({ diagram }) =>
					partSlot.diagrams.some(({ id }) => id === diagram.id)
				),
				d => d.category === category?.id,
				d => d.cut === cut?.id
			),
		[context]
	);

	return (
		<div
			className={tlsx('flex flex-col p-4 rounded-lg shadow-xs border bg-white', className)}
			{...rest}
		>
			<span className="font-medium px-1 mb-3">Link to other diagrams</span>
			{diagrams.map(({ cut, category, diagram }) => (
				<button
					type="button"
					key={diagram.id}
					className="flex items-center w-full gap-3 mb-2 last:mb-0"
					onClick={() => actions.diagram.jump(cut, category, diagram.id)}
				>
					<ImageWithSkeleton className="size-20 rounded-sm border" src={diagram.image.thumb} />
					<div className="flex flex-col items-start justify-center gap-2 h-20">
						<div className="relative flex items-center justify-center p-0.5 h-[3ch] min-w-[3ch] text-sm text-center select-none rounded-full bg-gray-800 text-white">
							{partSlot.code}
						</div>
						<span className="text-sm font-medium text-start">{diagram.description}</span>
					</div>
				</button>
			))}
		</div>
	);
};

type PartsAssemblyDisplayProps = InheritableElementProps<
	'div',
	{
		assembly: DiagramAssembly;
		control: Control<PartsFormData>;
		selection: PartsFormData;
		highlighted: Set<string>;
		checkbox?: Omit<CheckboxProps, 'id' | 'radius' | 'size'>;
		preselected?: boolean;
		child?: boolean;
		actions: {
			part: {
				highlight: (ids: string[]) => void;
				remove: (assemblies: DiagramAssembly[]) => void;
			};
		};
	}
>;

export const PartsAssemblyDisplay = ({
	className,
	assembly,
	control,
	selection,
	highlighted,
	checkbox,
	preselected,
	child,
	actions,
	...rest
}: PartsAssemblyDisplayProps): ReactNode | null => {
	const isHighlighted = useMemo(
		() => !!assembly.partSlotId && highlighted.has(assembly.partSlotId),
		[assembly, highlighted]
	);

	return (
		<div
			className={tlsx(
				'flex flex-col items-center px-6 py-5 rounded-lg shadow-xs border bg-white',
				{ 'border-none shadow-none px-0 py-0 mt-3': child },
				className
			)}
			{...rest}
		>
			<div className="flex items-center gap-4 w-full">
				<Checkbox radius="100%" size="lg" {...checkbox} />
				<PartsHotpot
					className="p-1 h-[3ch] min-w-[3ch] text-sm"
					code={assembly.code}
					kind="assembly"
					highlighted={isHighlighted}
					onClick={() => {
						if (!assembly.partSlotId) {
							return;
						}
						return actions.part.highlight([assembly.partSlotId]);
					}}
				/>

				<PartsDisplayDescription className="text-base" assembly={assembly} />

				{!isNil(assembly.note) && (
					<Popover withinPortal position="bottom-end">
						<Popover.Target>
							<button type="button" className="outline-hidden ml-auto">
								<InformationCircleIcon className="size-4" />
							</button>
						</Popover.Target>
						<Popover.Dropdown className="flex flex-col gap-1">
							<span className="text-sm font-medium">Note</span>
							<span className="text-sm text-gray-600">{assembly.note}</span>
							<span className="text-xs text-gray-500 font-medium mt-4">
								Classified under {assembly.hca}
							</span>
						</Popover.Dropdown>
					</Popover>
				)}
			</div>

			{!!assembly.subAssemblies?.length && (
				<div className="flex flex-col gap-1 w-[calc(100%-1.5rem)] mt-1 ml-3 pl-4 border-l empty:hidden">
					{assembly.subAssemblies?.map(partSlot => (
						<PartsDiagramPartSlotAssembly
							key={partSlot.id}
							partSlot={partSlot}
							control={control}
							selection={selection}
							highlighted={highlighted}
							actions={actions}
							preselected={preselected || checkbox?.checked}
							child
						/>
					))}
				</div>
			)}
		</div>
	);
};

type PartsVariantsDisplayProps = InheritableElementProps<
	'div',
	{
		variants: DiagramAssembly[];
		control: Control<PartsFormData>;
		selection: PartsFormData;
		highlighted: Set<string>;
		preselected?: boolean;
		child?: boolean;
		actions: {
			part: {
				highlight: (ids: string[]) => void;
				remove: (assemblies: DiagramAssembly[]) => void;
			};
		};
		render: (variant: DiagramAssembly) => ReactNode | null;
	}
>;

export const PartsVariantsDisplay = ({
	variants,
	control,
	highlighted,
	selection,
	actions,
	render,
	className,
	preselected,
	child,
	...rest
}: PartsVariantsDisplayProps) => {
	return (
		<div
			className={tlsx(
				'flex flex-col items-center px-6 py-5 rounded-lg shadow-xs border bg-white',
				{ 'border-none shadow-none px-0 py-0': child },
				className
			)}
			{...rest}
		>
			<div className="flex items-center gap-4 w-full">
				<PartsHotpot
					className="p-1 h-[3ch] min-w-[3ch] text-sm"
					code={variants[0].code}
					kind="assembly"
					highlighted
					onClick={() => {
						if (!variants[0].partSlotId) {
							return;
						}
						return actions.part.highlight([variants[0].partSlotId]);
					}}
				/>

				<PartsDisplayDescription className="text-base" assembly={variants[0]} />
			</div>

			<div className="flex items-center w-full mt-3">
				<span className="text-sm text-gray-600 font-medium">Select variant</span>
			</div>

			<div className="flex flex-col w-full gap-6 mt-3">{variants.map(render)}</div>

			{!!variants[0].subAssemblies?.length && (
				<div className="flex flex-col gap-1 w-[calc(100%-1.5rem)] mt-1 ml-3 pl-4 border-l empty:hidden">
					{variants[0].subAssemblies?.map(partSlot => (
						<PartsDiagramPartSlotAssembly
							key={partSlot.id}
							partSlot={partSlot}
							control={control}
							selection={selection}
							highlighted={highlighted}
							actions={actions}
							preselected={preselected}
							child
						/>
					))}
				</div>
			)}
		</div>
	);
};

type PartsVariantDisplayProps = InheritableElementProps<
	'div',
	{
		variant: DiagramAssembly;
		checkbox?: Omit<CheckboxProps, 'id' | 'radius' | 'size'>;
	}
>;

export const PartsVariantDisplay = ({
	className,
	variant,
	checkbox,
	...rest
}: PartsVariantDisplayProps) => {
	return (
		<div className={tlsx('flex flex-col items-center gap-2 rounded-lg ', className)} {...rest}>
			<div className={tlsx('flex gap-3 w-full items-center')}>
				<Checkbox radius="100%" size="lg" {...checkbox} />
				{variant.note ? (
					<span className="text-sm">{variant.note}</span>
				) : (
					<span className="text-sm">{variant.description}</span>
				)}
			</div>
		</div>
	);
};

type PartsDisplayDescriptionProps = InheritableElementProps<
	'span',
	{
		assembly: {
			description: string;
			displayName?: string | null;
			information?: {
				usageNotes: string[];
				typicalShape: string[];
				functionPurpose: string[];
				typicalMaterial: string[];
				typicalPositioning: string[];
			} | null;
		};
		showHint?: boolean;
		/* Optionally hide the category name to avoid duplicate information */
		trimCategory?: string;
	}
>;

export const PartsDisplayDescription = ({
	className,
	trimCategory,
	assembly,
	showHint = false,
	...rest
}: PartsDisplayDescriptionProps) => {
	const displayName =
		trimCategory && assembly.displayName?.startsWith(trimCategory)
			? assembly.displayName?.split(`${trimCategory} >`)[1]
			: assembly.displayName;
	return (
		<>
			{displayName ? (
				<div className="flex flex-row items-center gap-1">
					<div className="flex items-center flex-wrap gap-1 w-full empty:hidden">
						{displayName.split(' > ').map(description => (
							<Fragment key={description}>
								<span
									className={tlsx('text-sm flex flex-row gap-1 items-center', className)}
									{...rest}
								>
									{description}
								</span>
								<ChevronRightIcon className="size-3 last:hidden" />
							</Fragment>
						))}
					</div>
					{(assembly.information?.usageNotes.length ?? 0) > 0 && showHint && (
						<Tooltip
							className="cursor-default"
							children={<QuestionMarkCircleIcon className="size-5" />}
							label={<PartsInformationTip assembly={assembly} />}
							width={400}
							events={{ hover: true, touch: true, focus: true }}
						/>
					)}
				</div>
			) : (
				<span className={tlsx('flex flex-row text-sm items-center gap-1', className)} {...rest}>
					{assembly.description}
					{(assembly.information?.usageNotes.length ?? 0) > 0 && showHint && (
						<Tooltip
							className="cursor-default"
							children={<QuestionMarkCircleIcon className="size-5" />}
							label={<PartsInformationTip assembly={assembly} />}
							width={400}
							events={{ hover: true, touch: true, focus: true }}
						/>
					)}
				</span>
			)}
		</>
	);
};

const PartsInformationTip = forwardRef(
	({ assembly }: PartsDisplayDescriptionProps, ref: ForwardedRef<HTMLDivElement>) => {
		return (
			<div ref={ref} className="flex flex-col gap-2 p-4 text-sm text-wrap w-[390px]">
				<div className="flex flex-col items-start justify-centre">
					<p className="font-bold text-base ">{assembly.displayName ?? assembly.description}</p>
				</div>
				<div>
					<p>{assembly.information?.usageNotes}</p>
					<br />
					{!!assembly.information?.typicalPositioning.length && (
						<p>
							<span className="font-bold">{'Typical positions: '}</span>
							<span>{assembly.information.typicalPositioning.join(', ').toLowerCase()}</span>
						</p>
					)}
					{!!assembly.information?.functionPurpose.length && (
						<p>
							<span className="font-bold">{'Function: '}</span>
							<span>{assembly.information?.functionPurpose.join(', ').toLowerCase()}</span>
						</p>
					)}
					{!!assembly.information?.typicalShape.length && (
						<p>Usually {assembly.information?.typicalShape.join(` or `).toLowerCase()}</p>
					)}
					{!!assembly.information?.typicalMaterial.length && (
						<p>Usually {assembly.information?.typicalMaterial.join(` or `).toLowerCase()}</p>
					)}
				</div>
			</div>
		);
	}
);
