import { DefaultText, HeaderCell } from '@common/components/cell-builder';
import { localiseCondition } from '@common/utils/grade';
import { lookup_server_model } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { Checkbox } from '@mantine/core';
import { isDefined } from '@partly/js-ex';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { getReturnReason, getReturnRejectReason } from '../utils';
import { createMoney, formatPrice } from '@common/utils/currency';

export type OrderTableItem = {
	id: string;
	name: string;
	hcaPath: string | null;
	quantity: number | null;
	status: lookup_server_model.json_types.SupplierOrderItemStatus | null;
	condition: lookup_server_model.json_types.ProductCondition | null;
	purchase_price: {
		amount: string | null;
		currency: string | null;
	};
	return_policy: lookup_server_model.json_types.ReturnPolicy | null;
};

export const orderColumnBuilder = () => {
	const columnHelper = createColumnHelper<OrderTableItem>();

	const select = (hasHeader = true) =>
		columnHelper.display({
			id: 'select',
			header: hasHeader
				? ({ table }) => (
						<Checkbox
							aria-label="Check all rows"
							className="inline-block"
							size="xs"
							{...{
								checked: table.getIsAllRowsSelected(),
								indeterminate: table.getIsSomeRowsSelected(),
								onChange: table.getToggleAllRowsSelectedHandler()
							}}
						/>
					)
				: undefined,
			cell: ({ row }) => (
				<Checkbox
					aria-label="Check row"
					className="inline-block"
					size="xs"
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler()
					}}
				/>
			)
		});

	const quantity = () =>
		columnHelper.accessor('quantity', {
			id: 'quantity',
			header: () => <HeaderCell>Qty.</HeaderCell>,
			cell: info => {
				const quantity = info.getValue();
				if (!isDefined(quantity) || quantity === 0) {
					return <DefaultText>--</DefaultText>;
				}

				const { status } = info.row.original;
				if (status && typeof status === 'object' && 'returned' in status) {
					const newQuantity = quantity - status.returned.quantity;
					return (
						<DefaultText className="space-x-2">
							<span className="line-through">{info.getValue()}</span>
							<span>{newQuantity}</span>
						</DefaultText>
					);
				}

				return <DefaultText>{info.getValue()}</DefaultText>;
			}
		});

	const name = () =>
		columnHelper.accessor('name', {
			id: 'name',
			header: () => <HeaderCell className="min-w-[200px]">Items</HeaderCell>,
			cell: info => (
				<>
					<DefaultText>{info.getValue()}</DefaultText>
					{info.row.original.hcaPath && (
						<DefaultText className="text-gray-500 text-xs">{info.row.original.hcaPath}</DefaultText>
					)}
				</>
			)
		});

	const condition = () =>
		columnHelper.accessor('condition', {
			id: 'condition',
			header: () => <HeaderCell>Condition</HeaderCell>,
			cell: info => {
				const formatted = localiseCondition({
					condition: info.getValue()
				});
				return <DefaultText>{formatted.grade}</DefaultText>;
			}
		});

	const status = () =>
		columnHelper.accessor('status', {
			id: 'status',
			header: () => <HeaderCell>Status</HeaderCell>,
			cell: info => {
				const status = info.getValue();
				if (!status) {
					return <DefaultText>--</DefaultText>;
				}

				const contexts: { title: string; details: string | null }[] = [];
				match<lookup_server_model.json_types.SupplierOrderItemStatus, void>(
					status,
					{
						accepted: arg =>
							contexts.push({
								title: 'Accepted',
								details: arg.notes
							}),
						received: arg =>
							contexts.push({
								title: 'Received',
								details: [formatDate(arg.timestamp, 'dd/MM, hh:mm'), arg.notes]
									.filter(Boolean)
									.join(' - ')
							}),
						returned: arg => {
							contexts.push({
								title: 'Return requested',
								details: [getReturnReason(arg.reason), arg.notes].filter(Boolean).join(' - ')
							});

							if (typeof arg.decision === 'object' && 'accepted' in arg.decision) {
								contexts.push({
									title: 'Supplier accepted',
									details: arg.decision.accepted.notes
								});
							}

							if (typeof arg.decision === 'object' && 'rejected' in arg.decision) {
								contexts.push({
									title: 'Supplier accepted',
									details: [
										getReturnRejectReason(arg.decision.rejected.reason),
										arg.decision.rejected.notes
									]
										.filter(Boolean)
										.join(' - ')
								});
							}
						}
					},
					() => {}
				);

				return (
					<div className="space-y-1.5">
						{contexts.map(({ title, details }) => (
							<div>
								<DefaultText className="min-w-24">{title}</DefaultText>
								{details && <DefaultText className="text-xs text-gray-500">{details}</DefaultText>}
							</div>
						))}
					</div>
				);
			}
		});

	const returnPolicy = () =>
		columnHelper.accessor('return_policy', {
			id: 'return_policy',
			header: () => <HeaderCell>Returns</HeaderCell>,
			cell: info => {
				const returnPolicy = info.getValue();
				if (!returnPolicy) {
					return <DefaultText>Unknown</DefaultText>;
				}

				return match(returnPolicy, {
					allow_returns: ({ before_date }) => {
						if (before_date) {
							return <DefaultText>Before {formatDate(before_date, 'dd/MM')}</DefaultText>;
						}

						return <DefaultText>Yes</DefaultText>;
					},
					no_returns: () => {
						return <DefaultText>No</DefaultText>;
					}
				});
			}
		});

	const price = () =>
		columnHelper.accessor('purchase_price', {
			id: 'price',
			header: () => <HeaderCell className="text-right">Price</HeaderCell>,
			cell: info => {
				const price = info.getValue();
				if (!isDefined(price.amount)) {
					return <DefaultText className="text-right">--</DefaultText>;
				}

				const amount = createMoney(Number(price.amount));
				return (
					<DefaultText className="text-right">
						{formatPrice(amount, price.currency ?? undefined)}
					</DefaultText>
				);
			}
		});

	return {
		select,
		quantity,
		name,
		status,
		returnPolicy,
		condition,
		price
	};
};
