import { supply_search } from '@partly/core-server-client';
import { OfferSelection, PartOfferAggregation, SellableOffer, SupplierCondition } from '../models';
import { getSelectedJobPartOffers } from './get-selected-job-part-offers';
import { formatOfferPrice } from './format-offer-price';
import { getOfferSellable } from './get-offer-sellable';
import { getOffersSummary } from './get-offers-summary';

/**
 * Returns aggregated values for selected offers such as totalPrice etc.
 */
export const getSelectionSummary = (
	offerSelection: OfferSelection,
	partOfferAggregation: PartOfferAggregation
) => {
	let selectionAggregation: {
		totalPrice: number;
		totalCount: number;
		offers: SellableOffer[];
		suppliers: supply_search.Business[];
		conditionsPerSupplier: Record<string, SupplierCondition[]>;
		shippingTime: Required<Omit<supply_search.ShippingTime, 'store_address_id'>> | null;
	} = {
		totalCount: 0,
		totalPrice: 0,
		offers: [],
		suppliers: [],
		conditionsPerSupplier: {},
		shippingTime: null
	};

	const potentialAggregation: {
		minPrice: number;
		supplyOptionsCount: number;
	} = {
		minPrice: Number.MAX_VALUE,
		supplyOptionsCount: 0
	};

	const { quantity } = partOfferAggregation.jobPart;

	const selectedOffers = getSelectedJobPartOffers(offerSelection, partOfferAggregation);
	if (selectedOffers.length === 0) {
		// No offers selected
		partOfferAggregation.offers.forEach(offer => {
			const q = offer.payload.kind === 'assembly' ? 1 : quantity;
			const sellable = getOfferSellable(offer);

			const { price } = sellable;

			if (formatOfferPrice(price) && formatOfferPrice(price) < potentialAggregation.minPrice / q) {
				potentialAggregation.minPrice = formatOfferPrice(price) * q;
			}
			potentialAggregation.supplyOptionsCount += 1;
		});
		if (potentialAggregation.minPrice === Number.MAX_VALUE) {
			// None of the options have a price
			potentialAggregation.minPrice = 0;
		}
	} else {
		// Offers selected

		selectionAggregation = getOffersSummary(
			selectedOffers.map(selectedOffer => ({
				...selectedOffer,
				quantity: selectedOffer.payload.kind === 'assembly' ? 1 : quantity
			}))
		);
	}

	return {
		selected: selectedOffers.length > 0 ? selectionAggregation : null,
		potential:
			selectedOffers.length === 0 && partOfferAggregation.offers.length > 0
				? potentialAggregation
				: null
	};
};
