import { isNil } from 'lodash-es';
import { HCA_TOP_LAYERS_ID } from '../constants';
import { DiagramAssemblyInfo } from '../types';

export const relevantHcaGroupingSubset = (hcas: DiagramAssemblyInfo[]) =>
	hcas.filter(
		({ hca, partSlot }) =>
			!isNil(partSlot?.gapcPosition) ||
			(hca && HCA_TOP_LAYERS_ID.map(hca => hca.toUpperCase()).includes(hca.toUpperCase()))
	);

export const relevantHcaDisplaySubset = (hcas: DiagramAssemblyInfo[]) => {
	return hcas.filter(
		({ hca }) => hca && !HCA_TOP_LAYERS_ID.map(hca => hca.toUpperCase()).includes(hca.toUpperCase())
	);
};
