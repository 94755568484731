import { ApiError } from '@/sdk/lib';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Button } from '@mantine/core';
import { Err } from '@partly/core-server-client';
import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { tlsx } from '../../utils/tw-merge';

type ErrorStateProps = {
	error?: unknown;
};

const ErrorState = ({ error: raw }: ErrorStateProps) => {
	const navigate = useNavigate();

	const error = useMemo(() => {
		if (raw instanceof ApiError) {
			if (raw.status === 404) {
				return { severity: 'warning' as const, title: 'Page not found' };
			}
			if (raw.status === 408) {
				return { severity: 'warning' as const, title: 'Operation is taking too long' };
			}
			if (raw.status === 401) {
				return { severity: 'error' as const, title: 'Unauthorized' };
			}
			return {
				severity: 'error' as const,
				title: raw.body['message'] ?? raw.message ?? 'Something went wrong'
			};
		}
		if (raw instanceof Error) {
			return { severity: 'error' as const, title: raw.message };
		}
		if (raw instanceof Err) {
			return { severity: 'error' as const, title: raw.toString() };
		}
		return { severity: 'error' as const, title: 'Something went wrong' };
	}, [raw]);

	return (
		<div className="flex flex-col justify-center items-center flex-1 min-h-full py-12 px-4 bg-gray-50">
			<div className="flex flex-col justify-center items-center gap-4 max-w-md">
				<ExclamationCircleIcon
					className={tlsx('w-12 h-12', {
						'text-amber-600': error.severity === 'warning',
						'text-red-600': error.severity === 'error'
					})}
				/>

				<h1 className="text-2xl font-semibold text-gray-800 text-center">{error.title}</h1>
				<p className="text-gray-500 text-lg text-center">
					Refresh the page to try again, if the problem still persists, contact us.
				</p>
				<Button className="my-2" onClick={() => navigate(0)}>
					Try Again
				</Button>
				<Button component={Link} variant="subtle" to="/jobs">
					Return Home
				</Button>
			</div>
		</div>
	);
};

export default ErrorState;
