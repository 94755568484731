import { tlsx } from '@common/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ReactComponent as FrontCutIcon } from '@assets/parts/cuts/front-cut.svg';
import { ReactComponent as MidCutIcon } from '@assets/parts/cuts/mid-cut.svg';
import { ReactComponent as OtherIcon } from '@assets/parts/cuts/other.svg';
import { ReactComponent as RearCutIcon } from '@assets/parts/cuts/rear-cut.svg';
import { ReactComponent as UnderbodyIcon } from '@assets/parts/cuts/underbody.svg';
import { ReactComponent as NoseCutIcon } from '@assets/parts/cuts/nose-cut.svg';
import { FallbackCategoryTreeNode } from '../../types';

const icons: Record<
	string,
	React.FunctionComponent<
		React.SVGProps<SVGSVGElement> & {
			title?: string;
		}
	>
> = {
	'Front Cut': FrontCutIcon,
	'Mid Cut': MidCutIcon,
	'Rear Cut': RearCutIcon,
	'Nose Cut': NoseCutIcon,
	Underbody: UnderbodyIcon
};

export type FallbackPartsCutProps = InheritableElementProps<
	'button',
	{
		cut: FallbackCategoryTreeNode;
		selected?: FallbackCategoryTreeNode | null;
		actions: {
			cut: {
				set: (cut: FallbackCategoryTreeNode) => void;
			};
		};
	}
>;

export const FallbackPartsCut = ({
	className,
	cut,
	selected,
	actions,
	...rest
}: FallbackPartsCutProps) => {
	const Icon = icons[cut.description] ?? OtherIcon;
	return (
		<button
			type="button"
			className={tlsx(
				'group rounded-md py-2 px-4 gap-2 hover:bg-gray-100 active:bg-gray-100 flex items-center justify-around shrink-0',
				{
					'bg-gray-100': selected?.id === cut.id
				},
				className
			)}
			onClick={() => actions.cut.set(cut)}
			{...rest}
		>
			<Icon className="h-8 w-14" />
			<span className="font-medium">{cut.description}</span>
		</button>
	);
};
