import { compact, isNil, sortBy } from 'lodash-es';
import { DiagramPartSlot, DiagramPartSlotAssembly } from '../types';
import { relevantHcaGroupingSubset } from './context';

export const combinePartSlots = (partSlots: DiagramPartSlotAssembly[]) => {
	const lookup = new Map(partSlots.map(each => [each.id, each]));

	const results = new Map<string, DiagramPartSlotAssembly>();
	for (const partSlot of sortBy(partSlots, ({ relation }) => relation.children.length)) {
		if (partSlot.relation.children.length > 0) {
			const combined = combinePartSlot(partSlot, lookup);
			combined.relation.children.forEach(id => results.delete(id));
			results.set(combined.id, combined);
		} else {
			results.set(partSlot.id, partSlot);
		}
	}

	return [...results.values()];
};

export const combinePartSlot = (
	partSlot: DiagramPartSlotAssembly,
	lookup: Map<string, DiagramPartSlotAssembly>,
	seen: Set<string> = new Set()
): DiagramPartSlotAssembly => {
	// goes through all children (sort by potential assemblies last)
	const allLinks = sortBy(
		compact(
			partSlot.relation.children
				.filter(id => !seen.has(id) && partSlot.id !== id)
				.map(id => lookup.get(id))
		),
		({ relation }) => relation.children.length
	);

	const results = new Map<string, DiagramPartSlotAssembly>();
	for (const subAssembly of allLinks) {
		if (subAssembly.relation.children.length > 0) {
			const combined = combinePartSlot(subAssembly, lookup, seen.add(partSlot.id));
			combined.relation.children.forEach(id => results.delete(id));
			results.set(combined.id, combined);
		} else {
			results.set(subAssembly.id, subAssembly);
		}
	}

	const directLinks = [...results.values()];

	const assemblies = partSlot.assemblies.map(assembly => {
		const hcas = new Set(
			compact(relevantHcaGroupingSubset([...assembly.hcas, assembly]).map(({ hca }) => hca))
		);

		const subAssemblies = sortBy(
			directLinks
				.map(({ assemblies, relation, ...rest }) => ({
					...rest,
					assemblies: assemblies.filter(
						subAssembly =>
							isNil(subAssembly.hca) ||
							new Set(compact(subAssembly.hcas.map(({ hca }) => hca))).isSupersetOf(hcas)
					),
					relation: {
						...relation,
						parent: partSlot.id
					}
				}))
				.filter(({ assemblies }) => assemblies.length > 0),
			({ assemblies }) => -compact(assemblies.map(({ hca }) => hca)).length
		);

		return {
			...assembly,
			subAssemblies
		};
	});

	return {
		...partSlot,
		relation: {
			...partSlot.relation,
			children: flattenPartSlotAssemblies(
				assemblies.flatMap(({ subAssemblies }) => subAssemblies ?? [])
			).map(({ id }) => id)
		},
		assemblies
	};
};

const flattenPartSlotAssemblies = (
	partSlots: DiagramPartSlotAssembly[]
): DiagramPartSlotAssembly[] => {
	const res = [] as DiagramPartSlotAssembly[];

	for (const partSlot of partSlots) {
		res.push(partSlot);

		const subPartSlots = partSlot.assemblies.flatMap(assembly => assembly.subAssemblies ?? []);
		res.push(...flattenPartSlotAssemblies(subPartSlots));
	}

	return res;
};

export const flattenPartSlots = (partSlots: DiagramPartSlot[]): DiagramPartSlot[] => {
	const res = [] as DiagramPartSlot[];

	for (const partSlot of partSlots) {
		res.push(partSlot);

		if (partSlot.kind === 'assembly') {
			const subPartSlots = partSlot.assemblies.flatMap(assembly => assembly.subAssemblies ?? []);
			res.push(...flattenPartSlots(subPartSlots));
		}
	}

	return res;
};
