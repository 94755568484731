import clsx from 'clsx';
import { ConditionBadge } from '../condition-badge';
import { SupplierCondition } from '@features/supply/models.ts';

interface ConditionPerSupplierProps {
	supplier: { id: string; name: string };
	conditions: SupplierCondition[];
	mode: 'tooltip' | 'text';
}

const ConditionPerSupplier = ({ supplier, conditions, mode }: ConditionPerSupplierProps) => {
	return (
		<li key={supplier.id} className="flex gap-2 items-center">
			<b
				className={clsx(
					'text-sm font-medium truncate',
					mode === 'tooltip' ? 'text-inherit' : 'text-black'
				)}
			>
				{supplier.name}
			</b>
			<div>
				<div className="flex">
					{conditions.length > 0 ? (
						<ConditionBadge color={mode === 'tooltip' ? 'white' : 'blue'} conditions={conditions} />
					) : null}
				</div>
			</div>
		</li>
	);
};

export default ConditionPerSupplier;
