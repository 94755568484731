import { JobsService } from '../../generated';
import {
	createGetJob,
	createGetJobCollisionMap,
	createGetJobFallbackAssembliesTree,
	createGetJobPartAssembliesTree,
	createListJobParts,
	createSearchJobs,
	createUpsertJob,
	createUpsertParts
} from '../transformers/jobs';
import {
	ApiConfig,
	CloseJobRequest,
	CloseJobResult,
	GetJobCollisionMapRequest,
	GetJobCollisionMapResult,
	GetJobConfirmationRequest,
	GetJobFallbackAssembliesTreeRequest,
	GetJobFallbackAssembliesTreeResult,
	GetJobPartAssembliesTreeRequest,
	GetJobPartAssembliesTreeResult,
	GetJobRequest,
	GetJobResult,
	GetJobsConfirmationResult,
	JobsConfirmationInsertRequest,
	JobsConfirmationInsertResult,
	ListJobPartsRequest,
	ListJobPartsResult,
	RemoveJobPartAssembliesTreeRequest,
	RemoveJobPartAssembliesTreeResult,
	RemoveJobRequest,
	RemoveJobResult,
	SearchJobsRequest,
	SearchJobsResult,
	StorefrontResponse,
	UpsertJobCollisionsRequest,
	UpsertJobCollisionsResult,
	UpsertJobPartsRequest,
	UpsertJobPartsResult,
	UpsertJobRequest,
	UpsertJobResult,
	UpsertJobVehicleRequest,
	UpsertJobVehicleResult
} from '../types';
import { recursiveToSnake } from '../utils/casing';
import { execute } from '../utils/execute';

export const createJobsEndpoints = (jobsService: JobsService, apiConfig: ApiConfig) => {
	const search = (payload: SearchJobsRequest): Promise<StorefrontResponse<SearchJobsResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await jobsService.searchJobs(snakePayload);
			return createSearchJobs(result, apiConfig);
		});

	const getJobConfirmation = (
		payload: GetJobConfirmationRequest
	): Promise<StorefrontResponse<GetJobsConfirmationResult>> =>
		execute(async () => {
			const result = await jobsService.getJobConfirmation(payload);
			return result;
		});

	const get = (payload: GetJobRequest): Promise<StorefrontResponse<GetJobResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await jobsService.getJob(snakePayload);
			return createGetJob(result, apiConfig);
		});

	const remove = (payload: RemoveJobRequest): Promise<StorefrontResponse<RemoveJobResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			await jobsService.deleteJob(snakePayload);
			return { success: true };
		});

	const close = (payload: CloseJobRequest): Promise<StorefrontResponse<CloseJobResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			await jobsService.closeJob(snakePayload);
			return { success: true };
		});

	const insertConfirmation = (
		payload: JobsConfirmationInsertRequest
	): Promise<StorefrontResponse<JobsConfirmationInsertResult>> =>
		execute(async () => {
			const result = await jobsService.insertJobConfirmation(payload);
			return result;
		});

	const upsert = (payload: UpsertJobRequest): Promise<StorefrontResponse<UpsertJobResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await jobsService.upsertJob(snakePayload);
			return createUpsertJob(result, apiConfig);
		});

	const upsertVehicle = (
		payload: UpsertJobVehicleRequest
	): Promise<StorefrontResponse<UpsertJobVehicleResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await jobsService.upsertJobVehicle(snakePayload);
			return createUpsertJob(result, apiConfig);
		});

	const upsertCollisions = (
		payload: UpsertJobCollisionsRequest
	): Promise<StorefrontResponse<UpsertJobCollisionsResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await jobsService.upsertJobCollisions(snakePayload);
			return createUpsertJob(result, apiConfig);
		});

	const getCollisionMap = (
		payload: GetJobCollisionMapRequest
	): Promise<StorefrontResponse<GetJobCollisionMapResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await jobsService.getJobCollisionMap(snakePayload);
			return createGetJobCollisionMap(result, apiConfig);
		});

	const removePartAssembliesTree = (
		payload: RemoveJobPartAssembliesTreeRequest
	): Promise<StorefrontResponse<RemoveJobPartAssembliesTreeResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			await jobsService.removeJobPartAssembliesTree(snakePayload);
			return { success: true };
		});

	const getPartAssembliesTree = (
		payload: GetJobPartAssembliesTreeRequest
	): Promise<StorefrontResponse<GetJobPartAssembliesTreeResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await jobsService.getJobPartAssembliesTree(snakePayload);
			return createGetJobPartAssembliesTree(result, apiConfig);
		});

	const getFallbackAssembliesTree = (
		payload: GetJobFallbackAssembliesTreeRequest
	): Promise<StorefrontResponse<GetJobFallbackAssembliesTreeResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await jobsService.getJobFallbackAssembliesTree(snakePayload);
			return createGetJobFallbackAssembliesTree(result, apiConfig);
		});

	const listParts = (
		payload: ListJobPartsRequest
	): Promise<StorefrontResponse<ListJobPartsResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await jobsService.listJobParts(snakePayload);
			return createListJobParts(result, apiConfig);
		});

	const upsertParts = (
		payload: UpsertJobPartsRequest
	): Promise<StorefrontResponse<UpsertJobPartsResult>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await jobsService.upsertManyJobParts(snakePayload);
			return createUpsertParts(result, apiConfig);
		});

	return {
		search,
		get,
		remove,
		close,
		upsert,
		insertConfirmation,
		upsertVehicle,
		upsertCollisions,
		getJobConfirmation,
		getCollisionMap,
		getPartAssembliesTree,
		getFallbackAssembliesTree,
		removePartAssembliesTree,
		listParts,
		upsertParts
	};
};
