import { Card } from '@mantine/core';
import clsx from 'clsx';
import React from 'react';

export interface CardContainerProps {
	children: React.ReactNode;
	isSelected?: boolean;
	type?: 'radio' | 'checkbox';
	onSelectionChange?(isSelected: boolean): void;
	className?: string;
	tag?: React.ReactNode;
	disabled?: boolean;
}

const CardContainer = ({
	isSelected,
	onSelectionChange,
	tag,
	children,
	className,
	type,
	disabled
}: CardContainerProps) => (
	<Card
		aria-checked={isSelected}
		role={type}
		className={clsx(
			'rounded-md cursor-pointer shadow-lg relative overflow-visible',
			className,
			isSelected ? ' ring-blue-600 ring-2' : 'ring-gray-200 ring-1',
			{ 'pointer-events-none opacity-60 grayscale': disabled }
		)}
		onClick={() => {
			if (disabled) {
				return;
			}
			if (type === 'radio') {
				if (!isSelected) {
					onSelectionChange?.(true);
				}
			} else {
				onSelectionChange?.(!isSelected);
			}
		}}
	>
		{tag ? (
			<div
				className={clsx(
					'rounded-full whitespace-nowrap text-xs py-2 px-4 flex items-center absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 font-semibold',
					isSelected ? 'bg-blue-600 text-white' : 'bg-gray-200 text-black'
				)}
			>
				{tag}
			</div>
		) : null}
		{children}
	</Card>
);

export default CardContainer;
