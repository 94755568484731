import { basket } from '@/sdk/reflect/reflect';
import { SellableOffer } from '../models';
import { isDefined } from '@partly/js-ex';

export const getOffersFromBasketItems = (
	items: Omit<basket.BasketItem, 'id' | 'intent'>[],
	offers: SellableOffer[]
) =>
	items
		.map(item => {
			const foundOffer = offers.find(offer =>
				offer.groupId ? offer.groupId === item.offer_id : offer.id === item.offer_id
			);
			return foundOffer ? { offer: foundOffer, quantity: item.quantity } : null;
		})
		.filter(isDefined);
