import ReactDOM from 'react-dom/client';
import App from './app/index.tsx';
import './instrument';

// load shaders in
import '@features/parts/3d/shader';

import { setConfig } from '@sdk/react';
import { toast } from 'sonner';
import './index.css';
import { registerSW } from 'virtual:pwa-register';

// This component fixes buggy behavior on mobile
// (particularly safari) devices where the 100vh
// doesn't work.
import Div100vh from 'react-div-100vh';
import { getBaseConfig } from './config.ts';

const config = getBaseConfig();
setConfig(config);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<Div100vh>
		<App />
	</Div100vh>
);

const updateSW = registerSW({
	onNeedRefresh: () => {
		toast('App update required', {
			classNames: {
				description: 'text-balance'
			},
			description: 'We have added new features and fixed some bugs to make your experience better.',
			action: {
				label: 'Update now',
				onClick: () => updateSW(true)
			},
			closeButton: true,
			duration: Infinity // Don't dismiss until manually dismiss since the prompt won't show up again for a while
		});
	}
});
