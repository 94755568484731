/**
 * Aggregation of parts recommended by supplier that are not part of the user selection. Could be returned as part of the response to the offer request
 */
export const EXTRA_JOB_PART = 'EXTRA_JOB_PART';

export const EMPTY_STATE = 'EMPTY_STATE';

export const PARTLY_QA_BUSINESS_ID = '4b26e366-45ee-4db0-9724-3edf9009ef73';

export const PARTLY_REPAIRER_BUSINESS_ID = 'eb7cb97b-1468-4305-8525-fcb033673dd0';

export const MOTORHUB_REPAIRER_BUSINESS_ID = 'e44680d1-b289-4e6f-a086-850547eeaab1';
