import { OrderSelection, OfferSelection } from '@features/supply/models';
import { EMPTY_STATE } from '@features/supply/constants';
import { useMemo } from 'react';

export const useOfferSelection = (selection: OrderSelection): OfferSelection => {
	const offerSelection = useMemo<OfferSelection>(
		() =>
			selection
				? new Set(
						selection.items
							// Filter out the initial state item but only after selection has changed
							.filter((item, _i, array) => (array.length > 1 ? item.id !== EMPTY_STATE : true))
							.map(item => item.offer_id)
					)
				: new Set([]),
		[selection]
	);

	return offerSelection;
};
