import { offer_request_model } from '@/sdk/reflect/reflect';
import { ArrowUturnLeftIcon, NoSymbolIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';

interface FormatReturnPolicyProps {
	hideIcon?: boolean;
	returnPolicy: offer_request_model.model.event.OfferRequestRepairerPartReturnPolicy | null;
}

const FormatReturnPolicy = ({ returnPolicy, hideIcon }: FormatReturnPolicyProps) => {
	const getLabel = () => {
		if (!returnPolicy) {
			return 'Unknown';
		}
		if (returnPolicy === 'no_returns') {
			return 'No returns';
		}
		if (returnPolicy.allow_returns.before_date) {
			return `Returns before ${format(returnPolicy.allow_returns.before_date, 'eee do MMM')} `;
		}
		if (returnPolicy.allow_returns.condition === 'not_damaged') {
			return 'Returns allowed';
		}
	};

	return (
		<div className="flex gap-1.5">
			{!hideIcon && returnPolicy ? (
				returnPolicy === 'no_returns' ? (
					<NoSymbolIcon color="red" width={16} />
				) : (
					<ArrowUturnLeftIcon color="blue" width={16} />
				)
			) : null}
			<span>{getLabel()}</span>
		</div>
	);
};

export default FormatReturnPolicy;
