import { SellableOffer } from '../models';
import { getOffersSummary } from './get-offers-summary';

export const getDeliveryDateFromSelectedOffers = (
	offers: SellableOffer[],
	defaultDeliveryDate: Date
) => {
	const { shippingTime } = getOffersSummary(offers.map(offer => ({ ...offer, quantity: 1 })));

	return shippingTime?.eta ? new Date(shippingTime.eta) : defaultDeliveryDate;
};
