import { OfferSelection, PartOfferAggregation, SellableOffer } from '../models';

export const getSelectedJobPartOffers = (
	offerSelection: OfferSelection,
	partOfferAggregation: PartOfferAggregation
) => {
	const selectedOffers: SellableOffer[] = [];

	for (const offerId of offerSelection) {
		const offer = partOfferAggregation.offers.find(o => o.id === offerId);

		if (offer) {
			selectedOffers.push(offer);
		}
	}
	return selectedOffers;
};
