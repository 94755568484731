import { OfferSelection, PartOfferAggregation, SellableOffer } from '../models';
import { getOfferSellable } from './get-offer-sellable';

/**
 * Checks if offer is part of already selected parent assembly or its child is selected
 */
export const checkIfOfferIsSelectable = (
	offer: SellableOffer,
	partOfferAggregations: PartOfferAggregation[],
	offerSelection: OfferSelection
) => {
	const parentOfferIds: string[] = [];
	const childrenOfferIds: string[] = [];

	// Find parent offers
	const iterateUp = (offer: SellableOffer) => {
		const sellable = getOfferSellable(offer);
		const parentAssemblyId = sellable.parent_assembly_id;

		if (parentAssemblyId) {
			let matchingOffer: SellableOffer | null = null;

			partOfferAggregations.forEach(aggr => {
				const matching = aggr.offers.find(o => {
					const offerSellable = getOfferSellable(o);
					return offerSellable.entity.id === parentAssemblyId;
				});

				if (matching) {
					matchingOffer = matching;
				}
			});

			if (matchingOffer) {
				parentOfferIds.push((matchingOffer as SellableOffer).id);
				const nestedParentAssemblyId = getOfferSellable(matchingOffer).parent_assembly_id;

				if (nestedParentAssemblyId) {
					iterateUp(matchingOffer);
				}
			}
		}
	};

	// Find child offers
	const iterateDown = (offer: SellableOffer) => {
		if (offer.payload.kind === 'assembly') {
			let matchingOffer: SellableOffer | null = null;

			partOfferAggregations.forEach(aggr => {
				const matching = aggr.offers.find(o => {
					const offerSellable = getOfferSellable(o);
					return offerSellable.parent_assembly_id === getOfferSellable(offer).entity.id;
				});

				if (matching) {
					matchingOffer = matching;
				}
			});

			if (matchingOffer) {
				childrenOfferIds.push((matchingOffer as SellableOffer).id);
				iterateDown(matchingOffer);
			}
		}
	};

	iterateUp(offer);
	iterateDown(offer);

	return ![...new Set([...parentOfferIds, ...childrenOfferIds])].some(id => offerSelection.has(id));
};
