import { Badge } from '@common/components/badge';
import ImageWithSkeleton from '@common/components/image-with-skeleton';
import { useMeasurement } from '@common/hooks/use-measure';
import { tlsx } from '@common/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Collapse, Highlight, Indicator } from '@mantine/core';
import { groupBy, mapValues, sortBy, sum, uniqBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useSearchValue } from '../../hooks/use-search-value';
import {
	CategoryTree,
	CategoryTreeLeaf,
	CategoryTreeNode,
	Diagram,
	PartsFormData
} from '../../types';
import { categoryLeaves } from '../../utils';
import { flattenPartSlots } from '../../utils/assembly';
import { normalized, tokenized } from '../../utils/search/tokens';

type PartsNavigationProps = InheritableElementProps<
	'div',
	{
		cut?: CategoryTreeNode | null;
		other?: CategoryTreeLeaf | null;
		selected?: CategoryTreeLeaf | null;
		diagram?: Diagram | null;
		selection: PartsFormData;
		open: boolean;
		actions: {
			view: {
				open: () => void;
				close: () => void;
			};
			category: {
				set: (category: CategoryTreeLeaf) => void;
			};
			diagram: { set: (diagram: Diagram) => void };
		};
	}
>;

export const PartsNavigation = ({
	className,
	cut,
	other,
	selected: selectedCategory,
	diagram: selectedDiagram,
	selection,
	open,
	actions,
	...rest
}: PartsNavigationProps) => {
	const { value: nav } = useMeasurement('navigation-bar');

	const categories = useMemo(() => cut?.assemblies ?? [], [cut]);

	const showingAll = useMemo(
		() => selectedCategory && other && selectedCategory.id === other.id,
		[selectedCategory, other]
	);

	useEffect(() => {
		if (!selectedCategory || !selectedDiagram) {
			actions.view.open();
		}
	}, [selectedCategory, selectedDiagram]);

	return (
		<>
			<div
				className={tlsx('absolute top-4 left-4 flex gap-4 z-20', {
					'inset-4 bottom-0': !selectedCategory || !selectedDiagram
				})}
			>
				{!showingAll && (
					<div
						className={tlsx(
							'h-fit flex flex-col rounded-lg shadow-sm border bg-white p-2 shrink-0 transition-all duration-300 overflow-auto text-base font-semibold',
							{ '!w-80 shadow-none': open },
							className
						)}
						style={{
							width: selectedCategory
								? `${Math.min(20, selectedCategory.description.length * 0.75 + 3)}rem`
								: '18rem',
							maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - 7rem)`
						}}
						{...rest}
					>
						<button
							type="button"
							className="group flex items-center justify-between py-1.5 px-3 text-start"
							onClick={() => {
								if (!selectedCategory || !selectedDiagram) {
									return;
								}

								if (open) {
									actions.view.close();
								} else {
									actions.view.open();
								}
							}}
						>
							<h2 className="text-gray-900 text-base font-semibold">
								{!open && selectedCategory ? selectedCategory.description : cut?.description}
							</h2>
							<div className="flex items-center p-1 rounded-sm text-sm group-hover:bg-gray-50 group-active:bg-gray-50 select-none">
								<ChevronRightIcon
									className={tlsx('size-4 transition-all', { 'rotate-180': open })}
								/>
							</div>
						</button>

						{open && (
							<div className="w-full p-2 empty:hidden">
								{categories.map(c => (
									<PartsCategoryTree
										key={c.id}
										category={c}
										selected={selectedCategory}
										selection={selection}
										onClick={actions.category.set}
									/>
								))}
							</div>
						)}
					</div>
				)}

				{selectedCategory && !selectedDiagram && (
					<PartsCategoryDiagram
						key={selectedCategory.id}
						category={selectedCategory}
						selection={selection}
						actions={actions}
					/>
				)}
			</div>

			{!!selectedCategory?.diagrams?.length && !selectedDiagram && (
				<div className="absolute inset-0 z-[15] backdrop-blur-xs bg-[#FCFDFD]" />
			)}
		</>
	);
};

type PartsCategoryDiagramsProps = InheritableElementProps<
	'div',
	{
		category: CategoryTreeLeaf;
		selection: PartsFormData;
		actions: {
			diagram: { set: (diagram: Diagram) => void };
		};
	}
>;

export const PartsCategoryDiagram = ({
	className,
	actions,
	category,
	selection,
	...rest
}: PartsCategoryDiagramsProps) => {
	const { value: nav } = useMeasurement('navigation-bar');
	const [open2, setOpen2] = useState(false);
	const [open3, setOpen3] = useState(false);

	const diagrams = useMemo(() => {
		return mapValues(
			groupBy(category.diagrams, ({ level }) => level),
			diagrams =>
				sortBy(
					diagrams.map(({ diagram }) => diagram),
					({ code }) => code
				)
		);
	}, [category]);

	return (
		<div
			className={tlsx('flex flex-col flex-1 w-full h-full gap-6 overflow-auto pb-6', className)}
			style={{
				maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - 5.5rem)`
			}}
			{...rest}
		>
			{!!diagrams['1']?.length && (
				<div className="flex flex-col w-full gap-1">
					<div className="flex flex-wrap gap-3 overflow-auto">
						{diagrams['1'].map(diagram => (
							<PartsDiagramPreview
								key={diagram.id}
								diagram={diagram}
								selection={selection}
								actions={actions}
							/>
						))}
					</div>
				</div>
			)}
			{!!diagrams['2']?.length && (
				<div className="flex flex-col w-full gap-1">
					<div className="flex items-center gap-2 mx-2">
						<span className="font-medium">Other diagrams ({diagrams['2'].length})</span>
						<button
							type="button"
							className="text-blue-600 cursor-pointer text-sm rounded-sm px-2 py-0.5 outline-hidden hover:bg-blue-50"
							onClick={() => setOpen2(prev => !prev)}
						>
							{open2 ? 'Hide' : 'Show all'}
						</button>
					</div>
					<Collapse className="flex w-full" in={open2}>
						<div className="flex flex-wrap gap-3 overflow-auto">
							{diagrams['2'].map(diagram => (
								<PartsDiagramPreview
									key={diagram.id}
									diagram={diagram}
									selection={selection}
									actions={actions}
								/>
							))}
						</div>
					</Collapse>
				</div>
			)}

			{!!diagrams['3']?.length && (
				<div className="flex flex-col w-full gap-1">
					<div className="flex items-center gap-2 mx-2">
						<span className="font-medium">Related diagrams ({diagrams['3'].length})</span>
						<button
							type="button"
							className="text-blue-600 cursor-pointer text-sm rounded-sm px-2 py-0.5 outline-hidden hover:bg-blue-50"
							onClick={() => setOpen3(prev => !prev)}
						>
							{open2 ? 'Hide' : 'Show all'}
						</button>
					</div>
					<Collapse className="flex w-full" in={open3}>
						<div className="flex flex-wrap gap-3 overflow-auto">
							{diagrams['3'].map(diagram => (
								<PartsDiagramPreview
									key={diagram.id}
									diagram={diagram}
									selection={selection}
									actions={actions}
								/>
							))}
						</div>
					</Collapse>
				</div>
			)}
		</div>
	);
};

type PartsCategoryTreeProps = InheritableElementProps<
	'button',
	{
		category: CategoryTree;
		selected?: CategoryTreeLeaf | null;
		selection: PartsFormData;
		onClick: (category: CategoryTreeLeaf) => void;
	}
>;

export const PartsCategoryTree = ({
	className,
	category,
	selected,
	selection,
	onClick,
	...rest
}: PartsCategoryTreeProps) => {
	const q = useSearchValue();
	const [open, setOpen] = useState(true);
	const leaves = useMemo(() => categoryLeaves(category), [category]);
	const count = useMemo(() => {
		const assemblies = uniqBy(
			leaves
				.flatMap(({ diagrams }) => diagrams)
				.map(({ diagram }) => diagram)
				.flatMap(({ partSlots }) => partSlots)
				.flatMap(partSlot => (partSlot.kind === 'assembly' ? partSlot.assemblies : [])),
			({ id }) => id
		);
		return sum(assemblies.map(({ id }) => ((selection[id]?.quantity ?? 0) > 0 ? 1 : 0)));
	}, [leaves, selection]);

	if (category.kind === 'leaf') {
		return (
			<button
				type="button"
				className={tlsx(
					'flex items-center text-base w-full font-medium text-start text-gray-800 ml-3 px-3 py-1 rounded-md hover:bg-gray-50 active:bg-gray-50 2 mt-3 first:mt-0',
					{
						'bg-blue-600 text-white hover:bg-blue-600 active:bg-blue-600':
							selected?.id === category.id
					},
					className
				)}
				onClick={() => onClick(category)}
			>
				<Highlight
					highlight={q ? [...normalized(q)] : ''}
					highlightStyles={{
						'--tw-text-opacity': 1,
						color: 'rgb(0 0 0 / var(--tw-text-opacity))'
					}}
				>
					{category.description}
				</Highlight>
				{count > 0 && (
					<span
						className={tlsx('ml-3 text-sm leading-none font-medium text-blue-600 min-w-[2ch]', {
							'text-white': selected?.id === category.id
						})}
					>
						{count}
					</span>
				)}
			</button>
		);
	}
	return (
		<div className="mt-3 first:mt-0">
			<button
				type="button"
				className={tlsx(
					'group flex w-full items-center gap-2 text-start hover:bg-gray-50 active:bg-gray-50 py-1 px-2',
					className
				)}
				onClick={() => setOpen(prev => !prev)}
				{...rest}
			>
				<ChevronRightIcon className="size-3 group-data-[open]:rotate-90" />
				<Highlight
					component="span"
					className="text-base text-start font-medium text-gray-800"
					highlight={q ? [...tokenized(q)] : ''}
				>
					{category.description}
				</Highlight>
				{count > 0 && (
					<span className="ml-1 text-sm leading-none font-medium text-blue-600 min-w-[2ch]">
						{count}
					</span>
				)}
			</button>
			<div className={tlsx('mt-3 pl-4', { hidden: !open })}>
				{category.assemblies.map(sub => (
					<PartsCategoryTree
						key={sub.id}
						category={sub}
						selected={selected}
						selection={selection}
						onClick={onClick}
					/>
				))}
			</div>
		</div>
	);
};

type PartsDiagramPreviewProps = InheritableElementProps<
	'button',
	{
		diagram: Diagram;
		selection: PartsFormData;
		actions: {
			diagram: { set: (diagram: Diagram) => void };
		};
	}
>;

export const PartsDiagramPreview = ({
	className,
	diagram,
	selection,
	actions,
	...rest
}: PartsDiagramPreviewProps) => {
	const q = useSearchValue();
	const count = useMemo(() => {
		const assemblies = uniqBy(
			flattenPartSlots(diagram.partSlots).flatMap(partSlot =>
				partSlot.kind === 'assembly' ? partSlot.assemblies : []
			),
			({ id }) => id
		);
		return sum(assemblies.map(({ id }) => ((selection[id]?.quantity ?? 0) > 0 ? 1 : 0)));
	}, [diagram, selection]);
	return (
		<Indicator
			className="text-sm"
			zIndex={90}
			disabled={count === 0}
			offset={16}
			size="1.5rem"
			label={`${count}`}
		>
			<button
				type="button"
				className={tlsx('flex flex-col justify-between gap-2 py-2 px-2 w-48 h-56', className)}
				onClick={() => actions.diagram.set(diagram)}
				{...rest}
			>
				<ImageWithSkeleton
					className="size-44 border border-gray-300 rounded-sm object-scale-down"
					src={diagram.image.thumb}
					loading="lazy"
				/>
				<div className="flex items-center w-44">
					<Highlight
						component="span"
						className="text-xs text-start font-medium text-gray-900"
						highlight={q ? [...tokenized(q)] : ''}
					>
						{diagram.description}
					</Highlight>
				</div>
			</button>
		</Indicator>
	);
};

type PartsDiagramVariantsPreviewProps = InheritableElementProps<
	'button',
	{
		diagrams: Diagram[];
		selection: PartsFormData;
		actions: {
			diagram: { set: (diagram: Diagram) => void };
		};
	}
>;

export const PartsDiagramVariantsPreview = ({
	className,
	diagrams,
	selection,
	actions,
	...rest
}: PartsDiagramVariantsPreviewProps) => {
	const q = useSearchValue();
	const diagramsWithCount = useMemo(() => {
		return diagrams.map(diagram => {
			const assemblies = uniqBy(
				flattenPartSlots(diagram.partSlots).flatMap(partSlot =>
					partSlot.kind === 'assembly' ? partSlot.assemblies : []
				),
				({ id }) => id
			);
			return [
				diagram,
				sum(assemblies.map(({ id }) => ((selection[id]?.quantity ?? 0) > 0 ? 1 : 0)))
			] as const;
		});
	}, [diagrams, selection]);
	return (
		<div
			className={tlsx(
				'flex flex-col items-center justify-between gap-2 py-2 px-3 h-56 max-w-full',
				{
					'w-[24.5rem]': diagrams.length === 2,
					'w-[36rem]': diagrams.length === 3
				}
			)}
		>
			<div className="flex items-center w-full gap-2 overflow-auto">
				{diagramsWithCount.map(([diagram, count]) => (
					<Indicator
						className="text-sm shrink-0"
						zIndex={90}
						disabled={count === 0}
						offset={16}
						size="1.5rem"
						label={`${count}`}
					>
						<button
							type="button"
							className={tlsx(
								'flex-1 grid place-items-center border border-gray-300 rounded-sm',
								className
							)}
							onClick={() => actions.diagram.set(diagram)}
							{...rest}
						>
							<ImageWithSkeleton
								className="size-44 object-scale-down shrink-0"
								src={diagram.image.thumb}
								loading="lazy"
							/>
						</button>
					</Indicator>
				))}
			</div>
			<div className="flex items-center w-full gap-2">
				<Highlight
					component="span"
					className="text-xs text-start font-medium text-gray-900"
					highlight={q ? [...tokenized(q)] : ''}
				>
					{diagrams[0].description}
				</Highlight>
				<Badge className="py-0.5 px-2.5" size="small" variant="blue" rounded>
					{diagrams.length} diagrams
				</Badge>
			</div>
		</div>
	);
};
