export const PageLoader = () => {
	return (
		<div className="flex flex-col h-full">
			<div className="w-full border-b pb-safe">
				<div className="flex p-4">
					<div className="flex-1 bg-gray-200 rounded-sm h-7 animate-pulse" />
				</div>
			</div>
			<div className="p-4">
				<div className="w-4/5 h-5 bg-gray-200 rounded-sm animate-pulse" />
				<div className="w-1/2 h-5 mt-2 bg-gray-200 rounded-sm animate-pulse" />
			</div>
		</div>
	);
};
