import { reasonablySearchableFormat } from '@common/utils/string';
import { HCA_NOSE_CUT_ID, HCA_TOP_LAYERS_ID } from '../../constants';
import { Diagram, DiagramAssembly } from '../../types';
import { scoreByTokens } from './score';

export const scoreAssembly = (assembly: DiagramAssembly, query: string) => {
	const context = assembly.hcas
		.filter(
			({ partSlot, hca }) =>
				(hca &&
					HCA_TOP_LAYERS_ID.map(each => each.toUpperCase()).includes(hca.toUpperCase()) &&
					hca !== HCA_NOSE_CUT_ID) ||
				(partSlot &&
					partSlot.gapcPartType &&
					partSlot.gapcPartType.name !== 'N/A' &&
					partSlot.gapcPosition &&
					partSlot.gapcPosition.name !== 'N/A')
		)
		.map(({ description }) => description);

	const scores = [
		assembly.description,
		...(assembly.partSlot?.gapcPartType?.aliases.map(alias =>
			assembly.partSlot?.gapcPosition && assembly.partSlot.gapcPosition.name !== 'N/A'
				? `${alias}, ${assembly.partSlot.gapcPosition.name}`
				: alias
		) ?? []),
		...assembly.aliases
	].map(description => {
		const directScore = scoreByTokens(description, query);
		const fullScore = scoreByTokens(`${description} ${context.join(' ')}`, query);

		// best case matchign HCA description search using all of the HCA context
		if (directScore > 0) {
			return fullScore;
		}

		return 0;
	});

	const normalizedMpn = assembly.part.mpn.replaceAll('-', '').toLowerCase();
	const normalizedQuery = query.replaceAll('-', '').toLowerCase();
	if (normalizedMpn.startsWith(normalizedQuery)) {
		scores.push(normalizedQuery.length / normalizedMpn.length / 2);
	}

	const formattedDescription = reasonablySearchableFormat(
		[assembly.description, context].join(' ')
	);
	const formattedQuery = reasonablySearchableFormat(query);
	if (formattedDescription.includes(formattedQuery)) {
		scores.push(formattedQuery.length / formattedDescription.length / 2);
	}

	return Math.max(...scores);
};

export const scoreDiagrams = (diagram: Diagram, query: string) => {
	const context = diagram.hcas
		.filter(
			({ partSlot, hca }) =>
				(hca &&
					HCA_TOP_LAYERS_ID.map(each => each.toUpperCase()).includes(hca.toUpperCase()) &&
					hca !== HCA_NOSE_CUT_ID) ||
				(partSlot &&
					partSlot.gapcPartType &&
					partSlot.gapcPartType.name !== 'N/A' &&
					partSlot.gapcPosition &&
					partSlot.gapcPosition.name !== 'N/A')
		)
		.map(({ description }) => description);

	const scores = [] as number[];

	const directScore = scoreByTokens(diagram.description, query);
	const fullScore = scoreByTokens([diagram.description, ...context].join(' '), query);
	if (directScore > 0) {
		scores.push(fullScore);
	}

	const normalizedCode = reasonablySearchableFormat(diagram.code).replaceAll('-', '');
	const normalizedQuery = reasonablySearchableFormat(query).replaceAll('-', '');
	if (normalizedCode.startsWith(normalizedQuery)) {
		scores.push(normalizedQuery.length / normalizedCode.length / 2);
	}

	const formattedDescription = reasonablySearchableFormat(
		[diagram.description, ...context].join(' ')
	);
	const formattedQuery = reasonablySearchableFormat(query);
	if (formattedDescription.startsWith(formattedQuery)) {
		scores.push(formattedQuery.length / formattedDescription.length / 2);
	}

	return Math.max(...scores);
};
