import { SearchVehiclePayload, SearchVehiclesResult, StorefrontError } from '@sdk/lib';
import { queryOptions } from '@tanstack/react-query';
import { getApi, unwrap } from '../utils/helpers';

export const vehicleQueries = {
	searchVehicles: (payload: SearchVehiclePayload) =>
		queryOptions<SearchVehiclesResult, StorefrontError>({
			queryKey: ['vehicle', 'searchVehicles', payload],
			queryFn: () =>
				unwrap(async () => {
					const sdk = await getApi();
					return sdk.vehicle.searchVehicles(payload);
				})
		})
};
