import { lookup, order } from '@partly/core-server-client';
import { toNumber } from 'lodash';
import { useMemo } from 'react';
import { OrderSelection } from '../../models';
import { OrderTableItem } from '../../components/table-builder';
import { core_order, lookup_server_model } from '@/sdk/reflect/reflect';
import { match } from '@partly/js-ex';

export const useOrderTableItems = (
	modelItems: order.SupplierOrderItem[],
	currency: string | null,
	selection: OrderSelection = {},
	// todo: fix
	entities: Record<string, any> = {}
) => {
	const items = useMemo<OrderTableItem[]>(() => {
		const newItems = modelItems.map(item => {
			const name = (() => {
				if (item.buyable.type === 'external') {
					return item.buyable.description;
				}

				return formatSelectionContext(item.part_selection_contexts, entities);
			})();

			const hcaPath = item.part_selection_contexts?.map(c => c.hca_path?.text).join(', ') ?? null;
			const status = mapBuyerStatus(selection[item.identity]) ?? item.status ?? null;

			return {
				id: item.identity,
				name,
				status,
				hcaPath,
				quantity: item.quantity ?? null,
				condition: item.condition ?? null,
				return_policy: item.return_policy ?? null,
				purchase_price: {
					amount: item.purchase_price ?? null,
					currency
				}
			};
		});

		return newItems.sort((a, b) => a.name.localeCompare(b.name));
	}, [modelItems, selection]);

	const subtotal = useMemo(() => {
		return modelItems.reduce((acc, item) => {
			const purchasePrice = item.purchase_price ?? '0.0';
			const purchaseAmount = toNumber(purchasePrice);
			let quantity = item.quantity ?? 0;
			if (isNaN(purchaseAmount)) {
				return acc;
			}

			// Check if the item has been requested for return.
			if (item.status && typeof item.status === 'object' && 'returned' in item.status) {
				// Check if the decision has been made on the return request, and if so decrement the quantity.
				if (
					typeof item.status.returned.decision === 'object' &&
					'accepted' in item.status.returned.decision
				) {
					quantity -= item.status.returned.quantity;
				}
			}

			const incrementAmount = purchaseAmount * quantity;
			return acc + incrementAmount;
		}, 0);
	}, [modelItems]);

	return { items, subtotal };
};

const mapBuyerStatus = (
	status: core_order.OrderItemBuyerProcess | null
): lookup_server_model.json_types.SupplierOrderItemStatus | null => {
	if (!status) {
		return null;
	}

	return match<
		core_order.OrderItemBuyerProcess,
		lookup_server_model.json_types.SupplierOrderItemStatus | null
	>(
		status,
		{
			accepted: arg => ({ accepted: arg }),
			received: arg => ({ received: arg }),
			return_requested: arg => ({
				returned: {
					...arg,
					decision: 'undecided'
				}
			})
		},
		() => null
	);
};

const formatPartContext = (
	// gapcPartType: any | null | undefined = {},
	// gapcPosition: any | null | undefined = {},
	description: string | null | undefined,
	mpn: string | null | undefined
): string | null => {
	// todo: fix
	// if (gapcPartType && gapcPosition) {
	// 	return `${gapcPartType.name}, ${gapcPosition.name}`;
	// }

	// if (gapcPartType) {
	// 	return `${gapcPartType.name}`;
	// }

	if (description) {
		return description;
	}

	if (mpn) {
		return mpn;
	}

	return null;
};

const formatSelectionContext = (
	context: lookup.PartSelectionContext[] | null | undefined,
	gapcEntites: Record<string, any> = {} // todo: fix
) => {
	const partNames = context
		?.map(c => {
			/// @ts-ignore
			let gapcPartType = null;
			if (c.gapc_part_type_id) {
				gapcPartType = gapcEntites[c.gapc_part_type_id];
			}

			/// @ts-ignore
			let gapcPosition = null;
			if (c.gapc_position_id) {
				gapcPosition = gapcEntites[c.gapc_position_id];
			}

			return formatPartContext(c.description, c.mpn);
		})
		.filter(Boolean)
		.join(', ');

	return partNames || '';
};
