import { omit } from 'lodash-es';
import * as reflect from './reflect';
import { ResourceMap } from './resource';

/* eslint-disable @typescript-eslint/no-namespace */
export namespace transform {
	export namespace listing {
		export type ListingTransformError =
			| { NotFound: string }
			| {
					LocationNotFound: string;
			  }
			| {
					VendorNotFound: string;
			  }
			| { ListingKindMismatch: string };

		export function listing(
			payload: reflect.listing.Listing,
			resources: ResourceMap
		): reflect.Result<reflect.listing.exp.Listing, ListingTransformError> {
			switch (payload.kind) {
				case 'Kit':
					return transform.listing.kit(payload, resources).map(kit => ({ kind: 'Kit', ...kit }));
				case 'Product':
					return transform.listing
						.variant(payload, resources)
						.map(varint => ({ kind: 'Product', ...varint }));
				case 'VariableProduct':
					return transform.listing
						.variable_product(payload, resources)
						.map(varint => ({ kind: 'VariableProduct', ...varint }));
			}
		}

		export function product(
			payload: reflect.listing.Product,
			resources: ResourceMap
		): reflect.Result<reflect.listing.exp.Product, ListingTransformError> {
			switch (payload.kind) {
				case 'VariableProduct':
					return transform.listing
						.variable_product(payload, resources)
						.map(variable_product => ({ kind: 'VariableProduct', ...variable_product }));
				case 'Product':
					return transform.listing
						.variant(payload, resources)
						.map(variant => ({ kind: 'Product', ...variant }));
				default:
					return new reflect.Result({
						err: {
							ListingKindMismatch: 'Expected Product or VariableProduct'
						}
					});
			}
		}

		export function kit(
			payload: reflect.listing.Kit,
			resources: ResourceMap
		): reflect.Result<reflect.listing.exp.Kit, ListingTransformError> {
			let gapc_brand: reflect.gapc_common.GapcBrand | null = null;
			if (payload.gapc_brand_id) {
				gapc_brand = gapc_common.gapc_brand(payload.gapc_brand_id, resources).ok_or(null);
			}

			const products = collectValues(
				payload.products.map(product => transform.listing.product(product, resources))
			);

			return new reflect.Result({
				ok: {
					...omit(payload, ['gapc_brand_id', 'products']),
					gapc_brand,
					products
				}
			});
		}

		export function variable_product(
			payload: reflect.listing.VariableProduct,
			resources: ResourceMap
		): reflect.Result<reflect.listing.exp.VariableProduct, ListingTransformError> {
			let gapc_brand: reflect.gapc_common.GapcBrand | null = null;
			if (payload.gapc_brand_id) {
				gapc_brand = gapc_common.gapc_brand(payload.gapc_brand_id, resources).ok_or(null);
			}

			const variable_products = omit(payload, ['gapc_brand_id', 'variants']);
			const variants = collectValues(
				payload.variants.map(variant => transform.listing.variant(variant, resources))
			);

			return new reflect.Result({ ok: { ...variable_products, variants, gapc_brand } });
		}

		export function variant(
			payload: reflect.listing.Variant,
			resources: ResourceMap
		): reflect.Result<reflect.listing.exp.Variant, ListingTransformError> {
			let gapc_brand: reflect.gapc_common.GapcBrand | null = null;
			if (payload.gapc_brand_id) {
				gapc_brand = gapc_common.gapc_brand(payload.gapc_brand_id, resources).ok_or(null);
			}

			const supplies = transform.listing.supplies(payload.supplies, resources).ok_or([]);
			const variant = omit(payload, ['gapc_brand_id', 'supplies']);

			return new reflect.Result({
				ok: {
					...variant,
					gapc_brand,
					supplies
				}
			});
		}

		export function supplies(
			supplies: reflect.listing.VariantSupply[],
			resources: ResourceMap
		): reflect.Result<reflect.listing.exp.VariantSupply[], never> {
			const vendor_supplies: reflect.listing.exp.VariantSupply[] = supplies.map(supply => {
				const vendor_supply = omit(supply, ['vendor_id', 'inventory']);
				const vendor = transform.listing.vendor(supply.vendor_id, resources).ok_or(null);
				const inventory = collectValues(
					supply.inventory.map(inv => transform.listing.inventory(inv, resources))
				);

				return {
					...vendor_supply,
					inventory,
					vendor
				};
			});

			return new reflect.Result({ ok: vendor_supplies });
		}

		export function inventory(
			inventory: reflect.listing.SupplyInventory,
			resources: ResourceMap
		): reflect.Result<reflect.listing.exp.SupplyInventory, never> {
			const location = transform.listing.location(inventory.location_id, resources).ok_or(null);
			return new reflect.Result({
				ok: {
					...omit(inventory, ['location_id']),
					location
				}
			});
		}

		export function location(
			id: string,
			resources: ResourceMap
		): reflect.Result<reflect.listing.exp.SupplyLocation, ListingTransformError> {
			const location = resources.get({ path: 'locations', id });
			if (!location) {
				return new reflect.Result({ err: { LocationNotFound: id } });
			}

			const vendor = transform.listing.vendor(location.vendor_id, resources).ok_or(null);

			return new reflect.Result({
				ok: {
					id: location.id,
					name: location.name,
					vendor
				}
			});
		}

		export function vendor(
			id: string,
			resources: ResourceMap
		): reflect.Result<reflect.listing.SupplyVendor, ListingTransformError> {
			const vendor = resources.get({ path: 'vendors', id });
			if (!vendor) {
				return new reflect.Result({ err: { VendorNotFound: id } });
			}

			return new reflect.Result({
				ok: {
					id: vendor.id,
					name: vendor.name
				}
			});
		}
	}

	export namespace gapc_part {
		export type GapcPartTransformError = {
			GapcPartNotFound: string;
		};

		export function gapc_part(
			id: string,
			resources: ResourceMap
		): reflect.Result<reflect.gapc_part.exp.GapcPart, gapc_part.GapcPartTransformError> {
			const part = resources.get({ path: 'gapc_parts', id });
			if (!part) {
				return new reflect.Result({ err: { GapcPartNotFound: id } });
			}

			const gapc_brand = gapc_common.gapc_brand(part.gapc_brand_id, resources).ok_or(null);
			const gapc_part = omit(part, ['gapc_brand_id']);

			return new reflect.Result({
				ok: {
					...gapc_part,
					gapc_brand
				}
			});
		}
	}

	export namespace gapc_common {
		export type GapcCommonTransformError =
			| {
					GapcCommonNotFound: string;
			  }
			| { GapcKindMismatch: string };

		export function gapc_brand(
			id: string,
			resources: ResourceMap
		): reflect.Result<reflect.gapc_common.GapcBrand, gapc_common.GapcCommonTransformError> {
			const brand = resources.get({ path: 'gapc_properties', id });
			if (!brand) {
				return new reflect.Result({ err: { GapcCommonNotFound: id } });
			}

			if (brand.kind !== 'Brand') {
				return new reflect.Result({
					err: { GapcKindMismatch: `Expected Brand but got ${brand.kind}` }
				});
			}

			return new reflect.Result({
				ok: {
					id: brand.id,
					name: brand.name,
					is_oem: brand.is_oem
				}
			});
		}

		export function gapc_part_type(
			id: string,
			resources: ResourceMap
		): reflect.Result<reflect.gapc_common.GapcPartType, gapc_common.GapcCommonTransformError> {
			const gapc_part_type = resources.get({ path: 'gapc_properties', id });
			if (!gapc_part_type) {
				return new reflect.Result({ err: { GapcCommonNotFound: id } });
			}

			if (gapc_part_type.kind !== 'PartType') {
				return new reflect.Result({
					err: { GapcKindMismatch: `Expected PartType but got ${gapc_part_type.kind}` }
				});
			}

			return new reflect.Result({
				ok: gapc_part_type
			});
		}

		export function gapc_common_property(
			id: string,
			resources: ResourceMap
		): reflect.Result<
			reflect.gapc_common.GapcCommonProperty,
			gapc_common.GapcCommonTransformError
		> {
			const brand = resources.get({ path: 'gapc_properties', id });
			if (!brand) {
				return new reflect.Result({ err: { GapcCommonNotFound: id } });
			}

			if (brand.kind !== 'Other') {
				return new reflect.Result({
					err: { GapcKindMismatch: `Expected Other but got ${brand.kind}` }
				});
			}

			return new reflect.Result({
				ok: {
					id: brand.id,
					name: brand.name
				}
			});
		}
	}

	export namespace part_slot {
		export function part_selection_context(
			payload: reflect.part_slot.PartSelectionContext,
			resources: ResourceMap
		): reflect.Result<reflect.part_slot.exp.PartSelectionContext, never> {
			let gapc_brand = null;
			if (payload.gapc_brand_id) {
				gapc_brand = gapc_common.gapc_brand(payload.gapc_brand_id, resources).ok_or(null);
			}

			let gapc_position = null;
			if (payload.gapc_position_id) {
				gapc_position = gapc_common
					.gapc_common_property(payload.gapc_position_id, resources)
					.ok_or(null);
			}

			let gapc_part_type = null;
			if (payload.gapc_part_type_id) {
				gapc_part_type = gapc_common
					.gapc_part_type(payload.gapc_part_type_id, resources)
					.ok_or(null);
			}

			return new reflect.Result({
				ok: {
					description: payload.description,
					mpn: payload.mpn ?? null,
					gapc_brand,
					gapc_part_type,
					gapc_position,
					hcas: payload.hcas ?? null
				}
			});
		}
	}

	export namespace basket {
		export interface BasketTransformError {}

		export function get(
			basket: reflect.basket.Basket
			// resources: ResourceMap
		): reflect.Result<reflect.basket_handler.exp.BasketGetResponse, never> {
			return new reflect.Result({ ok: { basket } });
		}

		export function update(
			items: string[]
			// resources: ResourceMap
		): reflect.Result<reflect.basket_handler.exp.BasketIngestResponse, never> {
			return new reflect.Result({ ok: { items } });
		}
	}

	export namespace offer_request {
		export interface OfferRequestTransformError {}

		export function get(
			offer_requests: reflect.offer_request.model.OfferRequest[]
		): reflect.Result<reflect.offer_request_handler.exp.OfferRequestGetResponse, never> {
			return new reflect.Result({ ok: { offer_requests } });
		}

		export function insert(
			items: string[]
		): reflect.Result<reflect.offer_request_handler.exp.OfferRequestInsertResponse, never> {
			return new reflect.Result({ ok: { items } });
		}
		export function insert_event(
			item: string
		): reflect.Result<reflect.offer_request_handler.exp.OfferRequestInsertEventResponse, never> {
			return new reflect.Result({ ok: { item } });
		}
	}
}

export function collectValues<T, E>(values: reflect.Result<T, E>[]): T[] {
	return values
		.map(value => {
			if (value.is_err()) {
				console.warn('Error in collection ignored', value.unwrap_err());
			}

			return value.ok();
		})
		.filter((value): value is T => !!value);
}
