import { InheritableElementProps } from '@/types/utilties';
import { ReactComponent as DoorIcon } from '@assets/parts/door-icon.svg';
import { tlsx } from '@common/utils/tw-merge';
import { CloseButton, Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { FocusTrap, Loader } from '@mantine/core';
import { Command } from 'cmdk';
import { ElementRef, Fragment, SetStateAction, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { PartsDisplayDescription } from '../../../components/parts-display';
import { FallbackCategoryTreeNode } from '../../types';
import { scoreAssemblies } from '../../utils/search';

type FallbackPartSearchProps = InheritableElementProps<
	'div',
	{
		open: boolean;
		search: string;
		actions: {
			view: (action: SetStateAction<boolean>) => void;
			jump: {
				part: (cutId: string, categoryId: string, hcaId: string) => void;
			};
		};
		cuts: FallbackCategoryTreeNode[];
	}
>;

export const FallbackPartSearch = ({ open, search, actions, cuts }: FallbackPartSearchProps) => {
	const ref = useRef<ElementRef<'input'>>(null);
	const [value, setValue] = useState('');
	const [query, setQuery] = useState('');
	const [debouncedQuery, { isPending }] = useDebounce(query, 150);

	const results = useMemo(() => {
		if (!debouncedQuery) {
			return [];
		}

		const res = scoreAssemblies(cuts, debouncedQuery, 6);
		return res.map(each => each.assembly);
	}, [debouncedQuery]);

	const empty = useMemo(() => results.length === 0, [results]);

	return (
		<>
			<div
				role="button"
				className={tlsx(
					'w-full flex items-center px-4 py-2 rounded-full bg-gray-100 max-w-lg lg:max-w-xl xl:max-w-2xl cursor-pointer',
					{
						'bg-white ring-1 ring-gray-200': search.length > 0
					}
				)}
				onClick={() => {
					actions.view(true);
					setQuery(search);
				}}
			>
				<MagnifyingGlassIcon className="size-4 mr-2" />
				{search ? (
					<>
						<span className="flex-1 text-start text-sm font-medium text-gray-900 truncate">
							Search: {search}
						</span>
						<button
							type="button"
							className="text-sm font-medium text-red-600"
							onClick={e => {
								e.stopPropagation();
							}}
						>
							Remove
						</button>
					</>
				) : (
					<>
						<span className="flex-1 text-start text-sm font-medium text-gray-500 truncate">
							Search for parts
						</span>
					</>
				)}
			</div>

			{/* Spotlight search */}
			<Transition appear show={open} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-50"
					initialFocus={ref}
					onClose={() => {
						actions.view(false);
						setQuery(search);
					}}
				>
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-100"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black/25 backdrop-blur-[1px]" />
					</TransitionChild>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex justify-center p-4 text-center">
							<TransitionChild
								as={Fragment}
								enter="ease-out duration-100"
								enterFrom="opacity-0 scale-95 -translate-y-5"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-100"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95 -translate-y-5"
							>
								<DialogPanel className="flex flex-col w-full max-w-lg lg:max-w-xl xl:max-w-2xl transform overflow-hidden rounded-3xl bg-white p-4 text-left align-middle shadow-xl transition-all">
									<FocusTrap active={open}>
										<Command
											shouldFilter={false}
											defaultValue=""
											value={value}
											onValueChange={setValue}
										>
											<div className="w-full flex items-center gap-4 px-2">
												<div className="flex items-center gap-3 flex-1 w-full py-2 px-4 rounded-full border">
													<MagnifyingGlassIcon className="size-4 text-gray-600" />
													<Command.Input
														ref={ref}
														className="outline-hidden w-full text-sm"
														data-sentry-unmask
														data-autofocus
														placeholder="Search for parts"
														value={query}
														onValueChange={setQuery}
													/>
													{isPending() ? (
														<Loader variant="dots" size="xs" />
													) : (
														query.length > 0 && (
															<button
																type="button"
																className="text-sm font-medium text-gray-500"
																onClick={() => setQuery('')}
															>
																Clear
															</button>
														)
													)}
												</div>
												<CloseButton className="p-1 rounded-sm hover:bg-gray-100 active:bg-gray-100">
													<XMarkIcon className="size-4" />
												</CloseButton>
											</div>

											<Command.List>
												{/* Parts */}
												<Command.Group className={tlsx('mt-6', { hidden: results.length === 0 })}>
													{results.map(assembly => (
														<Command.Item
															key={assembly.id}
															value={`part:${assembly.id}`}
															role="button"
															className="flex items-center w-full p-2 gap-3 rounded-lg cursor-pointer active:bg-gray-100 data-[selected=true]:bg-gray-100"
															onPointerOver={() => setValue(`part:${assembly.id}`)}
															onSelect={() => {
																console.log(assembly);
																actions.jump.part(
																	assembly.hcas?.[0]?.id,
																	assembly.hcas?.[1]?.id,
																	assembly.id
																);
																actions.view(false);
																setQuery('');
															}}
														>
															<div className="grid place-items-center p-2 rounded-lg bg-gray-100 active:bg-gray-200 data-[selected=true]:bg-gray-200">
																<DoorIcon className="size-6" />
															</div>
															<div className="flex flex-col items-start gap-1 flex-1">
																<PartsDisplayDescription assembly={assembly} />

																<div className="flex items-center flex-wrap gap-0.5 w-full mt-0.5 empty:hidden">
																	<span className="text-xs text-gray-700">
																		{assembly.description}
																	</span>
																</div>
															</div>
														</Command.Item>
													))}
												</Command.Group>

												{empty && debouncedQuery.length > 0 && (
													<div className="w-full p-10 mt-6 flex flex-col gap-1.5 items-center justify-center">
														<span className="font-medium text-gray-600 text-sm">
															No results found
														</span>
														<p className="text-gray-400 text-sm">
															We couldn't find any result matching{' '}
															<span className="text-gray-600">{debouncedQuery}</span>
														</p>
													</div>
												)}
											</Command.List>
										</Command>
									</FocusTrap>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};
