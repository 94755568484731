import { isDefined } from '@partly/js-ex';
import { SellableOffer } from '../models';
import { getOfferSellable } from './get-offer-sellable';
import { offers_search } from '@partly/core-server-client';

export const isSellableBackorderable = (offer: SellableOffer) => {
	const sellable = getOfferSellable(offer);
	return (
		isInventoryBackorderable(sellable.inventories) ||
		sellable.tags?.some(tag => tag.name === 'BACKORDERABLE')
	);
};
export const isInventoryBackorderable = (
	inventory: offers_search.SellableInventory[] | undefined
) => {
	if (!inventory) {
		return false;
	}

	return inventory.some(
		inventory =>
			inventory.is_backorderable &&
			(isDefined(inventory.estimated_quantity) ? inventory.estimated_quantity <= 0 : true)
	);
};
