import { JobPart } from '@/sdk/lib';
import { UseFormReturn } from 'react-hook-form';
import { OrderSelection, SellableOffer } from '../models';
import { getOfferSellable } from './get-offer-sellable';

export const applyOfferSelection = (
	jobPart: JobPart,
	offer: SellableOffer,
	form: Pick<UseFormReturn<OrderSelection>, 'setValue' | 'getValues'>
) => {
	let isSelected = true;
	const selection = form.getValues();

	const sellable = getOfferSellable(offer);
	const business = offer.business;

	if (!sellable || !business) {
		return;
	}

	const existingIndex = selection.items.findIndex(item => item.offer_id === offer.id);

	let items = [...selection.items];

	if (existingIndex >= 0) {
		items = items.filter(item => item.offer_id !== offer.id);
		isSelected = false;
	} else {
		items.push({ offer_id: offer.groupId || offer.id, quantity: jobPart.quantity, id: '' });
	}

	form.setValue('items', items);
	return isSelected;
};
