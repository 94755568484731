import { useAuth } from '@common/hooks/use-auth';
import { BusinessForm } from '@features/user/components/business-form';
import { OrganizationForm } from '@features/user/components/organization-form';
import { SignInForm } from '@features/user/components/sign-in-form';
import Logo from '@assets/logo.png';
import { withRedirectIfSignedIn } from '@common/hoc/with-access';

const SignInPage = () => {
	const { isAuthenticated, selectedOrganisationId } = useAuth();

	return (
		<>
			<div className="flex flex-col items-center justify-center flex-1 min-h-full px-4 py-12 bg-gray-50">
				<div className="w-full max-w-md px-4 py-8 m-4 bg-white border rounded-md shadow-sm">
					<div>
						<img className="h-auto mx-auto w-44" src={Logo} alt="Your Company" />
						<h2 className="mt-6 text-2xl font-bold leading-9 tracking-tight text-center text-gray-900">
							Sign in to your account
						</h2>
					</div>

					{isAuthenticated === false ? (
						<SignInForm className="mt-10" />
					) : selectedOrganisationId === null ? (
						<OrganizationForm className="mt-10" />
					) : (
						<BusinessForm className="mt-10" />
					)}
				</div>
			</div>
		</>
	);
};

export default withRedirectIfSignedIn(SignInPage, {
	requireBusiness: true,
	requireOrganisation: true
});
