export const generateOrderId = (prefix: string, existing: Set<string>): string => {
	const letters = 'ABCDEFGHJKMNPQRSTUVWXYZ';
	let suffix;

	do {
		suffix =
			letters[Math.floor(Math.random() * letters.length)] +
			letters[Math.floor(Math.random() * letters.length)];
	} while (existing.has(`${prefix}-${suffix}`));

	return `${prefix}-${suffix}`;
};
