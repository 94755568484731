import { offer_request, offer_request_handler } from '@/sdk/reflect/reflect';
import { flatten } from 'lodash-es';
import { Basket, OfferSelection } from '../models';

export const isNewAcceptedOfferRequest = (
	previousOfferRequests: offer_request_handler.exp.OfferRequestGetResponse,
	offerRequestsData: offer_request_handler.exp.OfferRequestGetResponse
) => {
	const oldEvents = flatten(previousOfferRequests.offer_requests.map(r => r.events));

	const newEvents = offerRequestsData.offer_requests.reduce((accu, item) => {
		const { events } = item;

		accu.push(...events.filter(event => !oldEvents.some(oldEvent => oldEvent.id === event.id)));

		return accu;
	}, [] as offer_request.model.OfferRequestEvent[]);

	if (
		newEvents.length > 0 &&
		newEvents.some(event => event.kind.type === 'supplier' && event.kind.status === 'accepted')
	) {
		return true;
	}
	return false;
};

export const getMatchingBasket = (baskets: Basket[], offerSelection: OfferSelection) => {
	return (
		baskets.find(
			basket =>
				[...offerSelection].every(offerId => basket.offers.some(offer => offer.id === offerId)) &&
				basket.offers.every(offer => [...offerSelection].some(offerId => offerId === offer.id))
		) || null
	);
};
