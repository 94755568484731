import { Section } from '@common/components/page-section';
import { tlsx } from '@common/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';

export const OrderSection = ({
	className,
	...rest
}: InheritableElementProps<'section', object>) => (
	<Section className={tlsx('border rounded-md', className)} {...rest} />
);

OrderSection.Title = ({ className, ...rest }: InheritableElementProps<'h2', object>) => (
	<Section.Title
		className={tlsx('text-gray-900 leading-8 font-semibold text-lg', className)}
		{...rest}
	/>
);

OrderSection.Content = ({ className, ...rest }: InheritableElementProps<'div', object>) => {
	return <div className={tlsx('p-6', className)} {...rest} />;
};
