import { splitCamelCaseAndAcronyms } from '@common/utils/string';
import { sortBy } from 'lodash-es';
import { Diagram, DiagramAssembly, DiagramAssemblyTag } from '../types';

export const groupDiagramVariants = (diagrams: Diagram[]) => {
	const variants = [] as Diagram[][];
	for (const diagram of diagrams) {
		let hasGroup = false;
		for (const variant of variants) {
			const { description, code } = variant[0];
			if (code && code === diagram.code && diagram.description === description) {
				variant.push(diagram);
				hasGroup = true;
				break;
			}
		}
		if (!hasGroup) {
			variants.push([diagram]);
		}
	}
	return variants;
};

export const groupPartVariants = (assemblies: DiagramAssembly[]) => {
	const variants = [] as DiagramAssembly[][];
	for (const assembly of assemblies) {
		let hasGroup = false;
		for (const variant of variants) {
			const hca = variant[0].hca;
			if (hca && hca === assembly.hca) {
				variant.push(assembly);
				hasGroup = true;
				break;
			}
		}
		if (!hasGroup) {
			variants.push([assembly]);
		}
	}
	return variants.map(variant =>
		sortBy(
			variant,
			v => -v.confidence,
			v => v.hcas.length
		)
	);
};

export const transformTag = (tag: string): DiagramAssemblyTag | null => {
	// false variants
	if (tag.replaceAll('_', '').toLowerCase() === 'falsevariants') {
		return null;
	}
	const components = tag.split('_');
	// bad tag format
	if (components.length < 2) {
		return null;
	}
	const [description, value, ...rest] = components;
	if (['yes', 'with'].includes(value.toLowerCase())) {
		return {
			kind: 'yes',
			description: splitCamelCaseAndAcronyms(description)
		};
	}
	if (['no', 'without'].includes(value.toLowerCase())) {
		return {
			kind: 'no',
			description: splitCamelCaseAndAcronyms(description)
		};
	}
	return {
		kind: 'other',
		description: `${splitCamelCaseAndAcronyms(description)} ${value} ${rest.join(' ')}`
	};
};
