import { tlsx } from '@common/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ElementType, HTMLAttributes, createContext, useContext, useId, useMemo } from 'react';

type SectionContext = {
	titleId: string;
	descriptionId: string;
};
const SectionProvider = createContext<SectionContext | null>(null);

type SectionProps = InheritableElementProps<'section', object>;
export const Section = ({ children, ...rest }: SectionProps) => {
	const titleId = useId();
	const descriptionId = useId();

	const value = useMemo(() => ({ titleId, descriptionId }), [titleId, descriptionId]);

	return (
		<SectionProvider.Provider value={value}>
			<section {...rest} aria-labelledby={titleId} aria-describedby={descriptionId}>
				{children}
			</section>
		</SectionProvider.Provider>
	);
};

type ChildProps = {
	as?: ElementType;
} & Omit<HTMLAttributes<HTMLElement>, 'id'>;

export const Title = ({ as: Component = 'h2', className, ...rest }: ChildProps) => {
	const context = useContext(SectionProvider);
	return (
		<Component
			id={context?.titleId}
			className={tlsx('text-gray-900 font-medium', className)}
			{...rest}
		/>
	);
};

export const Description = ({ as: Component = 'p', className, ...rest }: ChildProps) => {
	const context = useContext(SectionProvider);
	return (
		<Component
			id={context?.descriptionId}
			className={tlsx('text-gray-500 text-sm mt-2', className)}
			{...rest}
		/>
	);
};

Section.Title = Title;
Section.Description = Description;
