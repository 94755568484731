import { session } from '@common/hooks/use-auth';
import { ApiError } from '@sdk/lib';

export const shouldRetry = (failureCount: number, error: Error | any) => {
	const allowedAttempts = isCore401(error) ? 1 : 3;
	return failureCount < allowedAttempts;
};

const isCore401 = (error: Error | any) => {
	const isApi4xx = error instanceof ApiError && error.status === 401;
	const isCore4xx =
		typeof error === 'object' &&
		'value' in error &&
		'application_err' in error.value &&
		'unauthorized' in error.value.application_err;

	return isApi4xx || isCore4xx;
};

export const onError = async (error: Error | any) => {
	if (error instanceof ApiError) {
		if (error.status === 401) {
			await session.actions.signout();
		}
	}

	if (isCore401(error)) {
		await session.actions.signout();
	}
};
