import { ApiError } from '@/sdk/lib';
import { jobsQueries } from '@/sdk/react';
import { withSignedIn } from '@common/hoc/with-access';
import { useAnalytics } from '@common/hooks/use-analytics';
import { useMeasurement } from '@common/hooks/use-measure';
import { useSearchQueries } from '@common/hooks/use-search-queries';
import { useUnsavedChanges } from '@common/hooks/use-unsaved-changes';
import { equivalent } from '@common/utils/common';
import { tlsx } from '@common/utils/tw-merge';
import { PartsCart } from '@features/parts/components/parts-cart';
import { PartsContextProvider } from '@features/parts/components/parts-context';
import { PartsCustomPart } from '@features/parts/components/parts-custom-part';
import { PartsCuts } from '@features/parts/components/parts-cuts';
import { PartsDiagram } from '@features/parts/components/parts-diagram';
import { PartsSpotlight } from '@features/parts/components/parts-spotlight';
import { usePartsForm } from '@features/parts/hooks/use-parts-form';
import { PartsFormData, PartsSelection } from '@features/parts/types';
import { categoriesDiagrams, categoryLeaves } from '@features/parts/utils';
import { createInitialPartsFormData, createPartSelection } from '@features/parts/utils/form';
import { filterCategoryNodes, filterOtherCategory } from '@features/parts/utils/search/filter';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { Button } from '@mantine/core';
import { RepairApp } from '@partly/analytics';
import { isDefined } from '@partly/js-ex';
import { useSuspenseQueries, useSuspenseQuery } from '@tanstack/react-query';
import { isNil, values } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { JobMainAction, JobSubActions } from '../job-detail';

type PageParams = {
	jobId: string;
};

const PartsPage = () => {
	const { jobId } = useParams<PageParams>();
	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	const { value: nav } = useMeasurement('navigation-bar');
	const navigate = useNavigate();

	const [queries, setQueries] = useSearchQueries([
		'q',
		'diagram',
		'cut',
		'category',
		{ name: 'highlighted', separator: ',' }
	]);

	const { data: jobData } = useSuspenseQuery({
		...jobsQueries.get({ jobId }),
		refetchOnMount: false
	});

	const [customPart, setCustomPart] = useState<string | null>(null);
	const [cart, setCart] = useState(true);
	const [navigating, setNavigating] = useState(false);
	const [searching, setSearching] = useState(false);

	const { logEvent } = useAnalytics();
	const [{ data: assembliesData }, { data: partsData }] = useSuspenseQueries({
		queries: [
			{
				...jobsQueries.getPartAssembliesTree({ jobId }),
				retry: (failureCount: number, error: Error) => {
					if (!(error instanceof ApiError)) {
						return false;
					}
					if (error.status === 408) {
						return failureCount < 4;
					}
					return failureCount < 3;
				}
			},
			jobsQueries.listParts({ jobId })
		]
	});

	const {
		categories: cuts,
		other,
		resources
	} = useMemo(() => categoriesDiagrams(assembliesData.tree.assemblies), [assembliesData]);

	const { control, handleSubmit, selection, formState, setValue, submit } = usePartsForm(
		jobId,
		createInitialPartsFormData(partsData.parts, resources)
	);

	const { isDirty, isSubmitting } = formState;

	const highlighted = useMemo(() => new Set(queries.highlighted ?? []), [queries.highlighted]);

	const filtered = useMemo(
		() => ({
			cuts: filterCategoryNodes(cuts, queries.q),
			other: filterOtherCategory(other, queries.q)
		}),
		[cuts, queries.q]
	);

	const cut = useMemo(() => {
		const cut = filtered.cuts.find(({ id }) => queries.cut === id);
		if (cut) {
			return cut;
		}
		if (filtered.other && queries.category && queries.category === filtered.other.id) {
			return null;
		}
		return filtered.cuts[0];
	}, [filtered, queries]);

	const leaves = useMemo(() => {
		const leaves = cut?.assemblies.flatMap(categoryLeaves) ?? [];
		if (filtered.other) {
			leaves.push(filtered.other);
		}
		return leaves;
	}, [cut, other]);

	const category = useMemo(() => {
		const leaf = leaves.find(({ id }) => id === queries.category);
		if (leaf || leaves.length > 1) {
			return leaf;
		}
		return leaves[0];
	}, [leaves, queries]);

	const diagram = useMemo(
		() => category?.diagrams.find(({ diagram }) => diagram.id === queries.diagram)?.diagram,
		[category, queries]
	);

	const count = useMemo(
		() =>
			values(selection)
				.filter(isDefined)
				.filter(({ quantity }) => quantity > 0).length,
		[selection]
	);

	const gapcBrandId = useMemo(() => {
		return [...resources.assemblies.values()][0]?.part?.gapcBrandId;
	}, [resources]);

	const onSubmit = useCallback(
		async (data: PartsFormData) => {
			const res = await submit(data);
			if (res.kind === 'changed') {
				logEvent(
					RepairApp.part_selection.parts_finalised({
						parts_count: res.partsChanged
					})
				);
			}

			if (res.hasParts) {
				if (jobData.job.needsConfirmation) {
					navigate(`/job/${jobId}/confirmation`);
				} else {
					navigate(`/job/${jobId}/supply`);
				}
			}
		},
		[submit, logEvent, navigate]
	);

	useUnsavedChanges(isDirty && !isSubmitting);

	return (
		<PartsContextProvider cuts={cuts} other={other}>
			<form
				id="parts-form"
				noValidate
				className="flex items-center place-items-center"
				onSubmit={handleSubmit(onSubmit)}
				data-testid="parts-section"
				style={{
					height: `calc(100dvh - ${nav?.height ?? 0}px)`,
					maxHeight: `calc(100dvh - ${nav?.height ?? 0}px)`
				}}
			>
				<JobMainAction>
					<PartsSpotlight
						open={searching}
						search={queries.q ?? ''}
						actions={{
							view: setSearching,
							filter: (q: string) => {
								if (!q) {
									setQueries({ q });
									return;
								}
								setQueries({
									q,
									category: null,
									diagram: null,
									highlighted: null
								});
							},
							jump: {
								category: (cut, category) => {
									setNavigating(false);
									setQueries({ cut, category, diagram: null, highlighted: null });
								},
								diagram: (cut, category, diagram) => {
									setNavigating(false);
									setQueries({ cut, category, diagram, highlighted: null });
								},
								part: (cut, category, diagram, partSlot) => {
									setNavigating(false);
									setQueries({ cut, category, diagram, highlighted: [partSlot] });
								}
							},
							custom: {
								add: description => setCustomPart(description)
							},
							add: assembly => {
								const data: PartsSelection = {
									mpn: assembly.part.mpn,
									gapcBrandId: assembly.part.gapcBrandId,
									partSlotIds: assembly.partSlotIds,
									quantity: 1,
									assemblyId: assembly.id,
									description: assembly.description,
									ghcaId: assembly.hca,
									hcas: assembly.hcas.map(({ description }) => description),
									order: values(selection).filter(s => (s?.quantity ?? 0) > 0).length,
									diagramUrl: assembly.diagramUrl,
									diagramPnc: assembly.diagramPnc,
									diagramHotspot: assembly.diagramHotspot
								};

								setValue(assembly.id, data);
							}
						}}
					/>
				</JobMainAction>
				<JobSubActions>
					<Button
						key="parts-cart-button"
						className="w-fit ml-auto"
						type="button"
						onClick={() => setCart(prev => !prev)}
					>
						Cart
						<ShoppingBagIcon className="size-4 mx-1.5" />
						{count}
					</Button>
				</JobSubActions>
				<div
					className="relative w-full"
					style={{
						height: `calc(100dvh - ${nav?.height ?? 0}px)`,
						maxHeight: `calc(100dvh - ${nav?.height ?? 0}px)`
					}}
				>
					<PartsCuts
						className={tlsx('w-full transition-all', {
							'max-w-[calc(100vw-20rem)]': cart
						})}
						cuts={filtered.cuts}
						other={filtered.other}
						cut={cut}
						selection={selection}
						category={category}
						actions={{
							cut: {
								set: ({ id }) => {
									if (id !== cut?.id) {
										setQueries({ cut: id, category: null, diagram: null });
									} else {
										setNavigating(true);
									}
								}
							},
							category: {
								set: category =>
									setQueries({ cut: null, category: category.id, diagram: null, highlighted: null })
							}
						}}
					/>

					<PartsDiagram
						className="shrink-0"
						style={{
							height: `calc(100dvh - ${nav?.height ?? 0}px - 5rem)`,
							maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - 5rem)`
						}}
						cut={cut}
						other={filtered.other}
						category={category}
						diagram={diagram}
						control={control}
						highlighted={highlighted}
						selection={selection}
						navigating={navigating}
						actions={{
							view: {
								open: () => setNavigating(true),
								close: () => setNavigating(false)
							},
							part: {
								add: assemblies => {
									for (const assembly of assemblies) {
										const data: PartsSelection = createPartSelection(
											assembly,
											(selection[assembly.id]?.quantity ?? 0) === 0,
											values(selection).filter(s => (s?.quantity ?? 0) > 0).length
										);

										setValue(assembly?.id, data, { shouldDirty: true });
									}
								},
								remove: assemblies => {
									for (const assembly of assemblies) {
										const data: PartsSelection = {
											gapcBrandId: assembly.part.gapcBrandId,
											mpn: assembly.part.mpn,
											order: count,
											partSlotIds: assembly.partSlotIds,
											quantity: 0,
											assemblyId: assembly.id,
											hcas: assembly.hcas?.map(({ description }) => description) ?? [],
											description: assembly.description,
											diagramUrl: assembly.diagramUrl,
											diagramPnc: assembly.diagramPnc,
											diagramHotspot: assembly.diagramHotspot
										};

										setValue(assembly?.id, data, { shouldDirty: true });
									}
								},
								highlight: ids => {
									if (equivalent(highlighted, new Set(ids))) {
										setQueries({ highlighted: null });
									} else {
										setQueries({ highlighted: ids });
									}
								}
							},
							category: {
								set: category =>
									setQueries({ category: category.id, diagram: null, highlighted: null })
							},
							diagram: {
								set: diagram => setQueries({ diagram: diagram.id, highlighted: null }),
								// jumping may differ from setting in the future (this is an action that will cause massive jump to a completely different view)
								jump: (cut, category, diagram) => {
									setQueries({ cut, category, diagram, highlighted: null });
								}
							},
							custom: {
								add: () => setCustomPart('')
							}
						}}
					/>
				</div>

				<PartsCart
					form="parts-form"
					className="shrink-0"
					control={control}
					selection={selection}
					categories={leaves}
					diagram={diagram}
					highlighted={highlighted}
					open={cart}
					changed={isDirty}
					actions={{
						close: () => setCart(false),
						custom: {
							add: () => setCustomPart('')
						},
						part: {
							highlight: ids => {
								if (equivalent(highlighted, new Set(ids))) {
									setQueries({ highlighted: null });
								} else {
									setQueries({ highlighted: ids });
								}
							},
							jump: (cut, category, diagram, partSlot) => {
								setQueries({ cut, category, diagram, highlighted: [partSlot] });
							}
						}
					}}
				/>

				<PartsCustomPart
					id="parts-custom-part"
					className="z-[90]"
					gapcBrandId={gapcBrandId}
					open={!isNil(customPart)}
					initialDescription={customPart ?? ''}
					resources={resources}
					onSubmit={({ mpn, description, id, assembly }) => {
						const data: PartsSelection = {
							gapcBrandId,
							mpn: mpn ?? '',
							order: count,
							partSlotIds: assembly?.partSlotIds ?? null,
							quantity: 1,
							assemblyId: assembly?.id ?? id,
							hcas: assembly?.hcas?.map(({ description }) => description) ?? [],
							description: assembly?.description ?? description ?? '',
							diagramUrl: assembly?.diagramUrl,
							diagramPnc: assembly?.diagramPnc,
							diagramHotspot: assembly?.diagramHotspot
						};

						const selectionId = assembly?.id ?? id ?? v4();
						setValue(selectionId, data, { shouldDirty: true });
						setCustomPart(null);
					}}
					onClose={() => setCustomPart(null)}
				/>
			</form>
		</PartsContextProvider>
	);
};

export default withSignedIn(PartsPage);
