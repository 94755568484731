import { session } from '@common/hooks/use-auth';
import { ApiError } from '@/sdk/lib';
import { queries } from '@/sdk/react/queries';
import { InheritableElementProps } from '@/types/utilties';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Loader, Select, Skeleton } from '@mantine/core';
import { SelectBusinessRequest } from '@partly/bna-session';
import * as Sentry from '@sentry/browser';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

const businessSchema = yup.object({
	business: yup.string().required().label('Business')
});

export type BusinessFormData = yup.InferType<typeof businessSchema>;

export type BusinessFormProps = Omit<InheritableElementProps<'form', object>, 'noValidate'>;

export const BusinessForm = ({ ...rest }: BusinessFormProps) => {
	const queryClient = useQueryClient();
	const { data: businessesData, isLoading } = useQuery({
		...queries.businesses.list({ profiles: [{ type: 'repairer' }] }),
		staleTime: Infinity,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		retry: 0
	});

	const { mutateAsync, isPending, error } = useMutation({
		mutationKey: ['auth', 'select-business'],
		mutationFn: async (payload: SelectBusinessRequest) => {
			const { data, error } = await session.actions.selectBusiness(payload);
			if (!data) {
				throw error;
			}

			return data;
		},
		onSuccess: async () => {
			await queryClient.resetQueries();
		}
	});
	const { handleSubmit, reset, control } = useForm<BusinessFormData>({
		defaultValues: {
			business: businessesData?.items.at(0)?.id
		},
		resolver: yupResolver(businessSchema)
	});

	const onSubmit = async ({ business }: BusinessFormData) => {
		try {
			await mutateAsync({
				business_id: business,
				authorization_issuer: '/api/v1/repairers.verify',
				authorization_parameters: {
					repairer_id: business
				}
			});
		} catch (error) {
			if (error instanceof ApiError && error.status >= 400 && error.status < 500) {
				// Debug capture the expected errors for login endpoint
				Sentry.captureException(error, {
					level: 'debug'
				});
			} else {
				console.warn('Unexpected error', error);
				// Catch any unhandled error and send to Sentry
				Sentry.captureException(error);
			}
		}
	};

	const onBack = async () => {
		await session.actions.signout();
		await queryClient.resetQueries();
	};

	useEffect(() => {
		if (isLoading) {
			return;
		}
		if (businessesData?.items.length === 1) {
			onSubmit({ business: businessesData.items[0].id });
		} else {
			reset({ business: businessesData?.items?.at(0)?.id });
		}
	}, [businessesData, isLoading]);

	if (isPending) {
		return <Skeleton className={rest.className} />;
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} noValidate {...rest}>
				<div className="space-y-4">
					<Controller
						control={control}
						name="business"
						render={({ field: { onChange, value, ...controlRest } }) => (
							<Select
								data-field="business"
								label="Business"
								value={value ?? null}
								data={
									businessesData?.items.map(({ id, name }) => ({ value: id, label: name })) ?? []
								}
								onChange={onChange}
								placeholder={isLoading ? 'Loading businesses...' : 'Select business'}
								disabled={isLoading}
								icon={isLoading ? <Loader className="!size-4" /> : null}
								clearable
								{...controlRest}
							/>
						)}
					/>
				</div>
				<Button className="w-full mt-8" type="submit">
					Continue
				</Button>

				<div className="flex items-center justify-center w-full mt-4">
					<button type="button" className="text-sm text-gray-500" onClick={onBack}>
						Go back to sign in
					</button>
				</div>
			</form>
			{error && (
				<Alert color="red" className="mt-4" title="Sign in error">
					Invalid business
				</Alert>
			)}
		</>
	);
};
