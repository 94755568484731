import { BmsService } from '@/sdk/generated';
import { createGetJob } from '../transformers/jobs';
import {
	ApiConfig,
	BmsSyncImagesRequest,
	BmsSyncImagesResponse,
	BmsSyncJobPartsRequest,
	BmsSyncJobPartsResponse,
	BmsSyncOrderedPartsResponse,
	BmsUpsertPartsResponse,
	StorefrontResponse
} from '../types';
import { recursiveToSnake } from '../utils/casing';
import { execute } from '../utils/execute';
import { BmsUpsertPartsRequest } from '@/sdk/generated/models/BmsUpsertPartsRequest';
import { BmsSyncOrderedPartsRequest } from '@/sdk/generated/models/BmsSyncOrderedPartsRequest';

export const createBmsEndpoint = (bmsService: BmsService, config: ApiConfig) => {
	const syncJobParts = (
		payload: BmsSyncJobPartsRequest
	): Promise<StorefrontResponse<BmsSyncJobPartsResponse>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await bmsService.bmsSyncParts(snakePayload);
			return createGetJob(result, config);
		});

	const syncImages = (
		payload: BmsSyncImagesRequest
	): Promise<StorefrontResponse<BmsSyncImagesResponse>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			await bmsService.bmsSyncImages(snakePayload);
			return {
				success: true
			};
		});

	const upsertParts = (
		payload: BmsUpsertPartsRequest
	): Promise<StorefrontResponse<BmsUpsertPartsResponse>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			const result = await bmsService.bmsUpsertParts(snakePayload);
			return createGetJob(result, config);
		});

	const syncOrderedParts = (
		payload: BmsSyncOrderedPartsRequest
	): Promise<StorefrontResponse<BmsSyncOrderedPartsResponse>> =>
		execute(async () => {
			const snakePayload = recursiveToSnake(payload);
			await bmsService.bmsSyncOrderedParts(snakePayload);
			return {
				success: true
			};
		});

	return { syncJobParts, syncImages, upsertParts, syncOrderedParts };
};
