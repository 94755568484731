import { Skeleton } from '@mantine/core';
import CollisionMapPage from '@pages/collision-map';
import CreateJobPage from '@pages/create-job';
import JobPage from '@pages/job';
import { JobConfirmationPage } from '@pages/job-confirmation';
import JobDetailPage from '@pages/job-detail';
import JobExternalPage from '@pages/job/external';
import JobsPage from '@pages/jobs';
import PartsPage from '@pages/parts';
import PartsFallbackPage from '@pages/parts/fallback';
import PartsLoadingPage from '@pages/parts/loading';
import { PartsSkeletonPage } from '@pages/parts/skeleton';
import SignInPage from '@pages/sign-in';
import { SupplyPage } from '@pages/supply';
import VehicleResultsPage from '@pages/vehicle-results';
import VehicleSearchPage from '@pages/vehicle-search';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, RouteObject } from 'react-router-dom';

import ErrorState from '@common/components/error-state';
import ErrorStateBoundary from '@common/components/error-state-boundary';
import { RootTemplate } from '@common/components/root-template';
import { WithSuspense } from '@common/hoc/with-suspense';
import { SplitCollisionSkeleton } from '@features/collisions/components/split-collision-skeleton';

export const routes: RouteObject[] = [
	{
		path: '/sign-in',
		element: <SignInPage />
	},
	{
		path: '/',
		element: <RootTemplate />,
		children: [
			{
				index: true,
				element: <Navigate to="/jobs" replace />
			},
			{
				path: '/jobs',
				element: <JobsPage />
			},
			{
				path: '/jobs/create',
				element: <VehicleSearchPage />,
				children: [
					{
						index: true,
						element: <CreateJobPage />
					},
					{
						path: 'vehicles',
						element: <VehicleResultsPage />
					},
					{
						path: '*',
						element: <Navigate to="/jobs/create" />
					}
				]
			},
			{
				path: '/external/job/:jobExternalId/:page?',
				element: (
					<ErrorBoundary FallbackComponent={ErrorState}>
						<JobExternalPage />
					</ErrorBoundary>
				)
			},
			{
				path: '/',
				element: (
					<ErrorBoundary FallbackComponent={ErrorState}>
						<JobDetailPage />
					</ErrorBoundary>
				),
				children: [
					{
						path: '/job/:jobId',
						element: (
							<ErrorBoundary FallbackComponent={ErrorState}>
								<JobPage />
							</ErrorBoundary>
						)
					},
					{
						path: '/job/:jobId/collision',
						element: (
							<ErrorBoundary FallbackComponent={ErrorState}>
								<WithSuspense Component={CollisionMapPage} Fallback={SplitCollisionSkeleton} />
							</ErrorBoundary>
						)
					},
					{
						path: '/job/:jobId/parts',
						element: (
							<ErrorBoundary FallbackComponent={ErrorState}>
								<PartsLoadingPage />
							</ErrorBoundary>
						),
						children: [
							{
								path: '/job/:jobId/parts',
								element: (
									<ErrorBoundary FallbackComponent={ErrorState}>
										<WithSuspense Component={PartsPage} Fallback={Skeleton} />
									</ErrorBoundary>
								)
							}
						]
					},
					{
						path: '/job/:jobId/parts/fallback',
						element: <WithSuspense Component={PartsFallbackPage} Fallback={PartsSkeletonPage} />
					},

					{
						path: 'job/:jobId/confirmation',
						element: (
							<ErrorBoundary FallbackComponent={ErrorState}>
								<JobConfirmationPage />
							</ErrorBoundary>
						)
					},
					{
						path: '/job/:jobId/supply',
						element: (
							<ErrorBoundary FallbackComponent={ErrorState}>
								<SupplyPage />
							</ErrorBoundary>
						)
					}
				]
			}
		],
		ErrorBoundary: ErrorStateBoundary
	},
	{
		path: '*',
		element: <Navigate to="/" />
	}
];
