import { OfferRequest } from '../models';

export const getLastOfferRequestEvent = (request: OfferRequest, type: 'supplier' | 'repairer') => {
	const events = request.request.events.filter(e => e.kind.type === type);

	const event = events[events.length - 1];

	if (event) {
		return { event, index: request.request.events.findIndex(e => e.id === event.id) };
	}

	return { event: null, index: -1 };
};
