import CardContainer from '../card-container';
import { OfferRequest } from '../../models';
import { useAuthenticatedUser } from '@common/hooks/use-me';
import { useMemo, useState } from 'react';
import CountdownTimer from '../countdown-timer';
import {
	ChatBubbleBottomCenterTextIcon,
	ClipboardDocumentCheckIcon
} from '@heroicons/react/24/outline';
import Messenger from '../messenger';
import { getLastOfferRequestEvent } from '../../utils';
import { offer_request_model } from '@/sdk/reflect/reflect';
import { Button } from '@mantine/core';

interface OfferRequestCardProps {
	offerRequest: OfferRequest;
	notReady?: boolean;
	onSendMessage(message: string): void;
}

const OfferRequestCard = ({ offerRequest, notReady, onSendMessage }: OfferRequestCardProps) => {
	const { vendors } = useAuthenticatedUser();
	const [openMessenger, setOpenMessanger] = useState(false);

	const requestSentAt = useMemo(() => {
		const lastSup = getLastOfferRequestEvent(offerRequest, 'supplier');
		const eventsAfterLastSup = offerRequest.request.events.slice(lastSup.index + 1);

		const nextRepairerEvent =
			eventsAfterLastSup[0]?.kind.type === 'repairer' ? eventsAfterLastSup[0] : null;

		return (lastSup.event?.kind as offer_request_model.model.event.OfferRequestEventSupplier)
			?.status === 'accepted' && nextRepairerEvent
			? nextRepairerEvent.created_at
			: offerRequest.request.created_at;
	}, [offerRequest]);

	const vendor = useMemo(
		() => vendors.find(v => v.id === offerRequest.request.store_id),
		[vendors, offerRequest]
	);

	if (!notReady && offerRequest.status === 'accepted') {
		return null;
	}

	return (
		<CardContainer className="min-h-[350px] w-[260px] flex flex-col">
			<div className="pt-6 pb-4 flex flex-col flex-1 items-center">
				<b className="font-medium text-center mb-5">{vendor?.name}</b>

				{notReady && offerRequest.status === 'accepted' ? (
					<>
						<div className="flex flex-col justify-between flex-1 items-center">
							<div className="flex flex-col gap-2 items-center text-blue-500 mt-14">
								<ClipboardDocumentCheckIcon width={22} color="blue" />
								Response received
							</div>

							<p className="text-gray-500 text-sm">Waiting for more suppliers</p>
						</div>
					</>
				) : null}

				{offerRequest.status == 'rejected' ? (
					<div className="flex flex-col items-center justify-center flex-1">
						<b className="font-medium">Parts not available</b>
						<p className="text-gray-500 text-sm mt-1 text-center">
							{vendor?.name} does not have these parts in stock at the moment
						</p>
					</div>
				) : null}
				{offerRequest.status === 'pending' && (
					<div className="mt-4 flex-1 flex flex-col justify-between">
						<CountdownTimer startDate={new Date(requestSentAt)} />
					</div>
				)}
				<Messenger
					open={openMessenger}
					supplier={vendor || null}
					events={offerRequest.request.events}
					disabled={offerRequest.status !== 'pending'}
					onClose={() => setOpenMessanger(false)}
					onSendMessage={onSendMessage}
				/>

				<Button
					variant="subtle"
					className="px-2 mt-2"
					leftIcon={<ChatBubbleBottomCenterTextIcon className="-mr-1" width={20} />}
					onClick={() => setOpenMessanger(true)}
				>
					Notes
				</Button>
			</div>
		</CardContainer>
	);
};

export default OfferRequestCard;
