import { useAuthenticatedUser } from '@common/hooks/use-me';
import StandardDialog, { StandardDialogProps } from '@common/components/standard-dialog';
import { InheritableElementProps } from '@/types/utilties';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, MultiSelect, Textarea } from '@mantine/core';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ActionType } from '../../models';
import clsx from 'clsx';
import { DatePickerInput } from '@mantine/dates';

export type ContextModalData = { supplierIds: []; note?: string; deliveryDate: Date };

export type ContextModalProps = Omit<
	InheritableElementProps<
		'form',
		{
			defaultValues?: Partial<ContextModalData>;
			onSubmit: (supplierIds: string[], note?: string, deliveryDate?: Date) => Promise<boolean>;
		}
	>,
	'noValidate'
> &
	StandardDialogProps & {
		excludedSupplierIds: string[];
		mode?: ActionType;
		context?: React.ReactNode;
		minDeliveryDate?: Date;
	};

export const ContextModal = ({
	open,
	mode = ActionType.AddSuppliers,
	defaultValues,
	excludedSupplierIds,
	context,
	minDeliveryDate,
	onClose,
	onSubmit,
	...rest
}: ContextModalProps) => {
	const { vendors } = useAuthenticatedUser();

	const schema = useMemo(() => {
		if (mode === ActionType.AddSuppliers) {
			return yup.object({
				supplierIds: yup.array().of(yup.string()).required('Please select suppliers')
			});
		}
		if (mode === ActionType.PlaceOrder) {
			return yup.object({ deliveryDate: yup.date().required('Please specify delivery date') });
		}
		return yup.object({});
	}, [mode]);

	const form = useForm<ContextModalData>({
		defaultValues,
		resolver: yupResolver(schema)
	});

	const values = form.watch();

	useEffect(() => {
		if (open) {
			form.reset(defaultValues);
		}
	}, [open]);

	const onResolve = async (data: ContextModalData) => {
		if (mode === ActionType.AddSuppliers && data.supplierIds.length === 0) {
			return;
		}

		const isSuccess = await onSubmit(
			data.supplierIds || [],
			data.note ? data.note.trim() : undefined,
			data.deliveryDate
		);

		if (isSuccess) {
			onClose();
		}
	};

	const getDeliveryWithHours = (hour: number, date: Date) => {
		const currentDelivery = new Date(date);
		const newDelivery = currentDelivery.setHours(hour, 0, 0, 0);

		return new Date(newDelivery);
	};

	return (
		<StandardDialog open={open} onClose={onClose}>
			<StandardDialog.Title>
				{mode === ActionType.AddSuppliers ? 'Send requests to suppliers' : 'Place order'}
			</StandardDialog.Title>
			<StandardDialog.Content className="w-[880px] max-w-[90vw] min-h-[480px] flex flex-col">
				{context}
				{mode === ActionType.AddSuppliers && (
					<p className="text-sm mb-4 text-gray-600">
						Select suppliers to request offers from them. They will response as quickly as possible.
					</p>
				)}
				<form
					className="flex flex-col gap-6 h-full flex-1"
					onSubmit={form.handleSubmit(onResolve)}
					noValidate
					{...rest}
				>
					{mode === ActionType.AddSuppliers ? (
						<Controller
							control={form.control}
							name="supplierIds"
							render={({ field, fieldState }) => (
								<MultiSelect
									withinPortal
									required
									className="w-full"
									multiple
									searchable
									error={fieldState?.error?.message}
									label="Select suppliers"
									placeholder="Select suppliers to add"
									data={vendors
										.filter(v => !excludedSupplierIds.includes(v.id))
										.map(vendor => ({ value: vendor.id, label: vendor.name }))}
									{...field}
								/>
							)}
						/>
					) : (
						<div className="flex gap-12 items-end">
							<Controller
								control={form.control}
								name="deliveryDate"
								render={({ field, fieldState }) => {
									return (
										<DatePickerInput
											required
											classNames={{ input: 'w-48', root: 'flex-col !items-start' }}
											minDate={minDeliveryDate}
											valueFormat="ddd D MMM"
											className="flex gap-2 items-center"
											label="Delivery date"
											error={fieldState?.error?.message}
											{...field}
											onChange={d => {
												if (d) {
													form.setValue(
														'deliveryDate',
														getDeliveryWithHours(values.deliveryDate.getHours(), d)
													);
												}
											}}
										/>
									);
								}}
							/>
							<div>
								<Input.Label required>Delivery time</Input.Label>
								<div className="flex gap-4 mb-0.5 mt-2.5">
									<button
										onClick={() =>
											form.setValue('deliveryDate', getDeliveryWithHours(9, values.deliveryDate))
										}
										type="button"
										className={clsx(
											'px-4 h-8 items-center justify-center ring-1 rounded-md hover:bg-gray-50',
											values.deliveryDate?.getHours() === 9
												? 'ring-blue-500 bg-blue-100 hover:bg-blue-200'
												: 'ring-gray-200'
										)}
										role="radio"
									>
										9 am
									</button>
									<button
										onClick={() =>
											form.setValue('deliveryDate', getDeliveryWithHours(14, values.deliveryDate))
										}
										type="button"
										role="radio"
										className={clsx(
											'px-4 h-8 flex items-center justify-center ring-1 rounded-md',
											values.deliveryDate.getHours() === 14
												? 'ring-blue-500 bg-blue-100 hover:bg-blue-200'
												: 'ring-gray-200'
										)}
									>
										2 pm
									</button>
								</div>
							</div>
						</div>
					)}
					<Controller
						control={form.control}
						name="note"
						render={({ field }) => (
							<Textarea
								className="w-full"
								label="Notes"
								placeholder="Type any notes about the order (optional)."
								{...field}
							/>
						)}
					/>
					<div className="flex items-center justify-end gap-3 mt-auto">
						<Button onClick={onClose} variant="default" type="button">
							Cancel
						</Button>
						<Button disabled={form.formState.isSubmitting} type="submit">
							{form.formState.isSubmitting ? 'Sending' : 'Send'}
						</Button>
					</div>
				</form>
			</StandardDialog.Content>
		</StandardDialog>
	);
};

export default ContextModal;
