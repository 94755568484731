import { JobPart } from '@/sdk/lib';
import { isNil } from 'lodash-es';

export const createOfferItemsRequest = (jobParts: JobPart[]) => {
	return jobParts.flatMap(part => {
		if (isNil(part.gapcBrand) || isNil(part.mpn)) {
			return [];
		}

		return {
			job_part_id: part.id,
			job_part_item: {
				single: {
					gapc_part_identity: {
						brand_id: part.gapcBrand.id,
						mpn: part.mpn
					},
					quantity: part.quantity,
					ghca_id: part.ghcaId,
					hcas: part.assembly?.hcas ?? []
				}
			}
		};
	});
};
