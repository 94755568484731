import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { Button } from '@mantine/core';
import { PartOfferAggregation } from '../../models';

interface ExpandButtonProps {
	expandedItems: string[];
	partOfferAggregations: PartOfferAggregation[];
	setExpandedItems(items: string[]): void;
}

const ExpandButton = ({
	expandedItems,
	partOfferAggregations,
	setExpandedItems
}: ExpandButtonProps) => (
	<Button
		variant="white"
		color="blue"
		rightIcon={<ChevronUpDownIcon height={24} />}
		onClick={() => {
			if (expandedItems.length !== partOfferAggregations.length) {
				setExpandedItems(partOfferAggregations.map(aggr => aggr.jobPart.id));
			} else {
				setExpandedItems([]);
			}
		}}
	>
		{expandedItems.length == partOfferAggregations.length ? 'Collapse all' : 'Expand all'}
	</Button>
);

export default ExpandButton;
