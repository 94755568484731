import { Button } from '@mantine/core';
import { Basket, OfferRequestsAggregation } from '../../models';
import BasketCard from '../basket-card';
import SectionContainer from '../section-container';
import { PlusIcon } from '@heroicons/react/24/outline';
import OfferRequestCard from '../offer-request-card';
import CardContainer from '../card-container';
import { offers_search } from '@partly/core-server-client';
import { useMemo } from 'react';
import RecommendedOfferRequestCard from '../recommended-offer-request-card';
import PendingJobNoteAlert from '../pending-job-note-alert';

interface BasketsSectionProps {
	baskets: Basket[];
	offerRequestsAggregation: OfferRequestsAggregation;
	recommendedOfferRequests: offers_search.OfferRequestGroup[];
	selectedBasket: Basket | null;
	deliverBefore: Date;
	showPendingNote: boolean;
	notReady?: boolean;
	onSelectBasket(basket: Basket): void;
	onAddSupplierClick(): void;
	onSendOfferRequest(supplierId: string): Promise<Boolean>;
	onSendMessage(message: string, requestId: string): void;
}

const BasketsSection = ({
	baskets,
	offerRequestsAggregation,
	recommendedOfferRequests,
	selectedBasket,
	deliverBefore,
	notReady,
	showPendingNote,
	onSendOfferRequest,
	onSelectBasket,
	onSendMessage,
	onAddSupplierClick
}: BasketsSectionProps) => {
	const recommendedOfferRequestsToSend = useMemo(
		() =>
			recommendedOfferRequests.filter(
				request =>
					!Boolean(offerRequestsAggregation[request.supplier.id]) &&
					!baskets.some(basket => basket.supplier.some(sup => sup.id === request.supplier.id))
			),
		[offerRequestsAggregation, recommendedOfferRequests]
	);

	return (
		<SectionContainer>
			{showPendingNote && (
				<div className="p-4">
					<PendingJobNoteAlert showDetails />
				</div>
			)}
			<div className="pt-8 pb-6">
				<div className="mb-2 px-6"></div>
				<div className="flex overflow-x-auto gap-16  items-stretch">
					<div className="pl-4 ">
						<b className="text-xl font-medium">
							{baskets.length === 0 && Object.keys(offerRequestsAggregation).length > 0
								? 'Checking with suppliers'
								: 'Choose supplier'}
						</b>
						{notReady ? (
							<p className="text-gray-500 text-sm mt-1">
								We are gathering responses. Feel free to leave this page, we'll email you once all
								options are ready.
							</p>
						) : null}
						<ul className="flex gap-4 pt-10 pb-4">
							{baskets.map(basket => (
								<li key={basket.id}>
									<BasketCard
										tag={basket.tag}
										basket={basket}
										deliverBefore={deliverBefore}
										isSelected={basket.id === selectedBasket?.id}
										onSelectionChange={() => onSelectBasket(basket)}
									/>
								</li>
							))}
							{Object.keys(offerRequestsAggregation).map(supplierId => {
								const request = offerRequestsAggregation[supplierId];
								if (!request.status) {
									return null;
								}

								return (
									<li key={supplierId}>
										<OfferRequestCard
											offerRequest={request}
											notReady={notReady}
											onSendMessage={m => onSendMessage(m, request.request.id)}
										/>
									</li>
								);
							})}
							{
								<CardContainer>
									<div className="p-2 min-h-[260px] flex pb-10 flex-col items-center justify-center w-[240px]">
										<b className="font-medium mb-2">
											Request {baskets.length > 0 ? 'more' : ''} baskets
										</b>
										<p className="text-sm text-gray-500 text-center mb-4">
											Request prices from additional suppliers.
										</p>
										<Button
											variant="outline"
											leftIcon={<PlusIcon width={22} />}
											onClick={onAddSupplierClick}
										>
											Add supplier
										</Button>
									</div>
								</CardContainer>
							}
						</ul>
					</div>
					{recommendedOfferRequestsToSend.length > 0 && (
						<div className="pl-8 pt-7 flex-1  flex flex-col">
							<b className="text-md font-normal text-gray-500">Recommended suppliers</b>
							<ul className="flex gap-4 pb-4 mt-4 flex-1">
								{recommendedOfferRequestsToSend.map(request => (
									<li className="h-full flex flex-col " key={request.supplier.id}>
										<RecommendedOfferRequestCard
											request={request}
											onSendOfferRequest={onSendOfferRequest}
										/>
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			</div>
		</SectionContainer>
	);
};

export default BasketsSection;
