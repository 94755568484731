import { getEnvironment } from '@/environment';
import { updateConfig } from '@/sdk/react';
import { AuthenticationStateV1, sessionManager } from '@partly/bna-session';
import * as Sentry from '@sentry/browser';
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext<AuthenticationStateV1>({} as AuthenticationStateV1);

export const session = sessionManager({
	basePath: getEnvironment().coreBasePath,
	log: {
		debug: (message: string) => {
			Sentry.addBreadcrumb({ message, category: 'auth', level: 'debug' });
		},
		info: (message: string) => {
			Sentry.addBreadcrumb({ message, category: 'auth', level: 'info' });
		},
		warn: (message: string) => {
			Sentry.addBreadcrumb({ message, category: 'auth', level: 'warning' });
		},
		error: (message: string) => {
			Sentry.addBreadcrumb({ message, category: 'auth', level: 'error' });
		}
	}
});

export const useAuth = () => useContext(AuthContext);

const initialise = async () => {
	await session.init();
	return session.actions.authenticate();
};

/*
 * 1. Resolve the current users authentication status.
 *
 * 2. Emulate Reacts `<Suspense />` api, waiting for authentication
 *    to resolve before rendering any children.
 *
 * 3. Subscribe to updates to authentication.
 */
export const createAuthProvider = () =>
	// 1
	initialise().then((initialState: AuthenticationStateV1) => {
		// todo: we need to to remove this as it is for old sdk system
		updateConfig({
			organizationId: initialState.selectedBusinessId ?? undefined
		});

		return {
			// 2
			default: (props => {
				const [state, setState] = useState<AuthenticationStateV1>(initialState);

				// 3
				useEffect(() => {
					const unsubscribe = session.state.onChange(state => {
						setState(state);
						updateConfig({ organizationId: state.selectedBusinessId ?? undefined });
					});

					return () => unsubscribe();
				}, []);

				return <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>;
			}) as React.FC<PropsWithChildren<unknown>>
		};
	});
