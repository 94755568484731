import { supply_search } from '@partly/core-server-client';
import { SellableOffer, SupplierCondition } from '../models';
import { offer_request_model } from '@/sdk/reflect/reflect';
import { formatOfferPrice } from './format-offer-price';
import { getOfferSellable } from './get-offer-sellable';
import { getAssembly } from './get-assembly';
import { isEqual } from 'lodash-es';

export const getOffersSummary = (offers: (SellableOffer & { quantity: number })[]) => {
	const summary: {
		totalPrice: number;
		totalCount: number;
		suppliers: supply_search.Business[];
		returnPolicy:
			| offer_request_model.model.event.OfferRequestRepairerPartReturnPolicy
			| 'multiple'
			| null;
		offers: SellableOffer[];
		conditionsPerSupplier: Record<string, SupplierCondition[]>;
		shippingTime: Required<Omit<supply_search.ShippingTime, 'store_address_id'>> | null;
	} = {
		totalCount: 0,
		totalPrice: 0,
		offers: [],
		suppliers: [],
		returnPolicy: null,
		conditionsPerSupplier: {},
		shippingTime: null
	};

	const uniqueSupplierIds: string[] = [];

	offers.forEach(offer => {
		summary.totalCount += 1;
		const sellable = getOfferSellable(offer);
		const business = offer.business;

		if (!sellable || !business) {
			// TODO: handle groups
			return null;
		}

		const { price, shipping_time, entity, gapc_brand } = sellable;

		summary.totalPrice += formatOfferPrice(price) * offer.quantity;

		summary.offers.push(offer);

		if (!summary.shippingTime) {
			summary.shippingTime = shipping_time || null;
		} else if (
			(shipping_time &&
				shipping_time.eta &&
				new Date(shipping_time.eta).getTime() > new Date(summary.shippingTime!.eta!).getTime()) ||
			(shipping_time?.business_days &&
				shipping_time.business_days > summary.shippingTime!.business_days!)
		) {
			summary.shippingTime = shipping_time;
		}

		if (!uniqueSupplierIds.includes(business.id)) {
			uniqueSupplierIds.push(business.id);
			summary.suppliers.push(business);
		}

		if (!summary.conditionsPerSupplier[business.id]) {
			summary.conditionsPerSupplier[business.id] = [];
		}

		if (entity.condition) {
			summary.conditionsPerSupplier[business.id].push({
				condition: entity.condition,
				isAssembly: Boolean(getAssembly(offer)),
				isOem: gapc_brand?.is_oem ?? false,
				isOes: gapc_brand?.is_oes ?? false
			});
		}
		if (offer.returnPolicy) {
			if (summary.returnPolicy) {
				if (!isEqual(summary.returnPolicy, offer.returnPolicy)) {
					summary.returnPolicy = 'multiple';
				} else {
					summary.returnPolicy = offer.returnPolicy;
				}
			} else {
				summary.returnPolicy = offer.returnPolicy || null;
			}
		}
	});

	if (
		offers.some(offer => {
			const sellable = getOfferSellable(offer);
			return !sellable.shipping_time;
		})
	) {
		summary.shippingTime = null;
	}
	if (offers.some(offer => !getOfferSellable(offer).price)) {
		summary.totalPrice = 0;
	}

	// Deduplicate conditions per supplier
	summary.conditionsPerSupplier = Object.keys(summary.conditionsPerSupplier).reduce(
		(accu, supplierId) => {
			const uniqueConditionsPerSupplier: string[] = [];
			const conditionsPerSupplier = summary.conditionsPerSupplier[supplierId];

			if (!accu[supplierId]) {
				accu[supplierId] = [];
			}

			conditionsPerSupplier.forEach(({ condition, isAssembly, isOem, isOes }) => {
				const key = `${JSON.stringify(condition)}${isAssembly}${isOem}${isOes}`;

				if (!uniqueConditionsPerSupplier.includes(key)) {
					uniqueConditionsPerSupplier.push(key);

					accu[supplierId].push({ condition, isAssembly, isOem, isOes });
				}
			});
			return accu;
		},
		{} as Record<string, SupplierCondition[]>
	);

	return summary;
};
