import { Section } from '@common/components/page-section';
import { tlsx } from '@common/utils/tw-merge';
import { Job } from '@/sdk/lib';
import { InheritableElementProps } from '@/types/utilties';

export const JobVehicleDetails = ({
	job,
	className
}: InheritableElementProps<'section', { job: Job }>) => {
	return (
		<Section className={tlsx('bg-white px-6 py-8 border rounded-md', className)}>
			<div className="flex items-center justify-between w-full">
				<Section.Title className="text-2xl font-semibold">Vehicle</Section.Title>
			</div>
			<ul className="mt-5 flex flex-col gap-3">
				<li className="font-semibold">{job.vehicle?.variant?.description}</li>
				{job.vehicle?.plateNumber ? (
					<li className="flex gap-2">
						<b className="font-semibold">Rego</b>
						<span>{job.vehicle?.plateNumber?.toUpperCase()}</span>
					</li>
				) : null}
				<li className="flex gap-2">
					<b className="font-semibold">Chassis number</b>
					<span>{job.vehicle?.chassisNumber}</span>
				</li>
			</ul>
		</Section>
	);
};
