import { offers_search } from '@partly/core-server-client';
import { SellableOffer } from '../models';

export const isOfferMatching = (offer: SellableOffer, match: offers_search.JobPartMatch) => {
	const matches: boolean[] = [];

	if (offer.groupId) {
		matches.push(offer.groupId === match.offer_id);
		matches.push(offer.id === match.offer_part_id);
	} else {
		matches.push(offer.id === match?.offer_id);
	}

	return matches.every(m => m);
};
