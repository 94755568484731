import { Texture } from 'three';
import { Slice } from '../../types';
import { shaderMaterial } from '../../utils/shader';

export type ColorReplaceMaterialProps = {
	map: Texture;
	target: Slice<number, 3>;
	replacement: Slice<number, 3>;
	tolerance: number;
};

// vertex shader stuff for webgl to compile and use for the shader, this is just updating position normally
const VERTEX_SHADER_GLSL = `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

// Perform the color replacement for any image color data close enough to
// target color (given the tolerance) into replacement color
const FRAGMENT_SHADER_GLSL = `
  uniform sampler2D map;
  uniform vec3 target;
  uniform vec3 replacement;
  uniform float tolerance;
  varying vec2 vUv;

  void main() {
    vec4 texColor = texture2D(map, vUv);
    
    float darkness = 1.0 - dot(texColor.rgb, vec3(0.333));
    
    // Use a higher exponent to create more dramatic darkness scaling
    float scaledDarkness = 1.0 - pow(1.0 - darkness, 1.0);
    
    // Interpolate between white and replacement color based on scaled darkness
    vec3 finalColor = mix(vec3(1.0), replacement, scaledDarkness);
    
    gl_FragColor = vec4(finalColor, texColor.a);;
  }
`;

export const ColorReplaceMaterial = shaderMaterial<ColorReplaceMaterialProps>(
	{
		map: new Texture(),
		target: [0, 0, 0],
		replacement: [0, 0, 0],
		tolerance: 0.1
	},
	VERTEX_SHADER_GLSL,
	FRAGMENT_SHADER_GLSL
);
