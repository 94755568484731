import { useWatchForm } from '@common/hooks/use-watch-form';
import { BasicTable } from '@common/components/order-table';
import { mutations } from '@/sdk/react/mutations';
import { lookup_server_model } from '@/sdk/reflect/reflect';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Button } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { memo, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { match } from '@/types/match';
import { queries } from '@/sdk/react/queries';
import { useOrderTableItems } from '../../hooks/use-order-table-items';
import { orderColumnBuilder, OrderTableItem } from '../table-builder';
import { ReceiveItemDialog, ReceiveItemFormData } from '../receive-item-modal';
import { OrderSection } from '../order-section';
import { RejectItemDialog, RejectItemDialogData } from '../return-item-modal';
import { OrderSelection } from '../../models';
import { core_order } from '@/sdk/reflect/reflect';
import { AcceptItemFormData, AcceptItemModal } from '../accept-item-modal';
import { StatusNote } from '../status-note';

type ConfirmedOrderProps = {
	jobId: string;
	model: core_order.SupplierOrder;
	showIBody: boolean;
	syncToiBody: any;
	isSuccess: boolean;
};

const builder = orderColumnBuilder();
const getTableColumns = () => {
	const columns = [
		builder.select(),
		builder.quantity(),
		builder.name(),
		builder.status(),
		builder.condition(),
		builder.returnPolicy(),
		builder.price()
	];

	return columns;
};

// TODO: this whole component needs a cleanup but
// I don't have time to.

export const ConfirmedOrder = memo(
	({ jobId, model, showIBody, syncToiBody, isSuccess }: ConfirmedOrderProps) => {
		const client = useQueryClient();
		const { mutateAsync: processItems } = useMutation({
			...mutations.orders.items.process,
			onSuccess: () => {
				client.invalidateQueries(queries.orders.list({ job_id: jobId }));
			}
		});

		const form = useForm<OrderSelection>({
			defaultValues: {} as OrderSelection
		});

		const selection = useWatchForm(form);
		const [receiveItems, setReceiveItems] = useState(false);
		const [acceptItems, setAcceptItems] = useState(false);
		const [returnItems, setReturnItems] = useState(false);
		const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});
		const { items, subtotal } = useOrderTableItems(
			model.items,
			model.purchase_currency_code ?? null,
			selection
		);
		const tableColumns = useMemo(() => getTableColumns(), [selection]);

		const onSubmit = async (data: OrderSelection) => {
			const items: core_order.OrderItemProcessData[] = Object.entries(data).map(([id, status]) => {
				return {
					identity: id,
					data: {
						buyer: status
					}
				};
			});

			await processItems({
				job_id: jobId,
				identity: model.id,
				items
			});

			toast.success('Order updated');
		};

		const table = useReactTable({
			data: items,
			columns: tableColumns,
			state: {
				rowSelection
			},
			enableRowSelection: true,
			onRowSelectionChange: setRowSelection,
			getCoreRowModel: getCoreRowModel()
		});

		const onReceiveItems = (data: ReceiveItemFormData) => {
			const selectedItems = Object.keys(rowSelection).map(Number);
			const itemIds = selectedItems.map(index => items[index].id);
			if (!itemIds.length) {
				return;
			}

			const newSelection = itemIds.reduce((acc, id) => {
				acc[id] = {
					received: {
						timestamp: data.arrivalAt.toISOString(),
						notes: data.details ?? null
					}
				};

				return acc;
			}, {} as OrderSelection);

			form.reset({ ...selection, ...newSelection }, { keepDirty: true });
			setReceiveItems(false);
		};

		const onAcceptItems = (data: AcceptItemFormData) => {
			const selectedItems = Object.keys(rowSelection).map(Number);
			const itemIds = selectedItems.map(index => items[index].id);
			if (!itemIds.length) {
				return;
			}

			const newSelection = itemIds.reduce((acc, id) => {
				acc[id] = {
					accepted: {
						notes: data.details ?? null
					}
				};

				return acc;
			}, {} as OrderSelection);

			form.reset({ ...selection, ...newSelection }, { keepDirty: true });
			setAcceptItems(false);
		};

		const onReturnItem = (data: RejectItemDialogData) => {
			const selectedItem = Number(Object.keys(rowSelection)[0]);
			if (isNaN(selectedItem)) {
				return;
			}

			const itemId = items[selectedItem].id;
			form.setValue(itemId, {
				return_requested: {
					reason: data.reason as lookup_server_model.json_types.OrderItemReturnReason,
					quantity: data.quantity,
					notes: data.details
				}
			});

			setReturnItems(false);
		};

		const hasSelection = Object.keys(rowSelection).length > 0;
		const targetIndex = Number(Object.keys(rowSelection)[0]);
		const targetItem = isNaN(targetIndex) ? null : items[targetIndex];

		const { receive, accept, returns } = useAllowedActions(rowSelection, items);

		return (
			<OrderSection className="bg-white">
				<form noValidate onSubmit={form.handleSubmit(onSubmit)}>
					<OrderSection.Content className="flex items-center justify-between border-b">
						<div className="flex items-start gap-2">
							<CheckCircleIcon className="text-green-500 size-5" />
							<div>
								<div className="font-semibold leading-5 text-gray-900">Order confirmed</div>
								{model.deliver_before && (
									<p className="mt-0.5 text-sm text-gray-700">
										Parts should arrive on {formatDate(model.deliver_before, 'dd/MM, hh:mm a')}{' '}
									</p>
								)}
							</div>
						</div>
						<div className="flex flex-row space-x-3">
							{showIBody && (
								<Button variant="default" onClick={syncToiBody} disabled={isSuccess}>
									Sync orders to iBody
								</Button>
							)}
							<Button variant="default" type="submit">
								Save
							</Button>
						</div>
					</OrderSection.Content>
					<OrderSection.Content className="space-y-3">
						<OrderSection.Title>
							{model.supplier_contact?.organization.name} (#{model.external_id})
						</OrderSection.Title>
						<div className="flex items-center justify-between mt-3">
							{model.deliver_before && (
								<div className="text-sm text-gray-700">
									<span className="font-semibold leading-8">Delivery date:</span>{' '}
									{formatDate(model.deliver_before, 'dd/MM, hh:mm a')}
								</div>
							)}
							{hasSelection && (
								<div className="flex items-center gap-2">
									{receive.visible && (
										<Button variant="default" size="xs" onClick={() => setReceiveItems(true)}>
											{receive.label}
										</Button>
									)}
									{accept.visible && (
										<Button variant="default" size="xs" onClick={() => setAcceptItems(true)}>
											{accept.label}
										</Button>
									)}
									{returns.visible && (
										<Button
											variant="outline"
											size="xs"
											color="red"
											onClick={() => setReturnItems(true)}
										>
											{returns.label}
										</Button>
									)}
								</div>
							)}
						</div>
						<div className="mt-3 overflow-x-auto">
							<BasicTable table={table} />
						</div>
						<BasicTable.Subtotal
							subtotal={subtotal}
							currency={model.purchase_currency_code ?? undefined}
						/>
						<StatusNote className="max-w-md" status={model.status} />
					</OrderSection.Content>
				</form>
				<ReceiveItemDialog
					open={receiveItems}
					item={targetItem}
					onClose={() => setReceiveItems(false)}
					afterLeave={() => setRowSelection({})}
					onSubmit={onReceiveItems}
				/>
				<AcceptItemModal
					open={acceptItems}
					item={targetItem}
					onClose={() => setAcceptItems(false)}
					afterLeave={() => setRowSelection({})}
					onSubmit={onAcceptItems}
				/>
				<RejectItemDialog
					open={returnItems}
					item={targetItem}
					afterLeave={() => setRowSelection({})}
					onClose={() => setReturnItems(false)}
					onSubmit={onReturnItem}
				/>
			</OrderSection>
		);
	}
);

type ItemActions = {
	returns: { visible: boolean; label: string };
	accept: { visible: boolean; label: string };
	receive: { visible: boolean; label: string };
};

const useAllowedActions = (
	selection: Record<number, boolean>,
	items: OrderTableItem[]
): ItemActions => {
	const defaultActions: ItemActions = {
		receive: { visible: false, label: '' },
		accept: { visible: false, label: '' },
		returns: { visible: false, label: '' }
	};

	// todo decouple
	const actions = useMemo<ItemActions>(() => {
		const selectedKeys = Object.keys(selection).map(key => Number(key));
		if (selectedKeys.length === 0) {
			return defaultActions;
		}

		const selectedItems = selectedKeys.map(key => items[key]);
		if (selectedItems.length === 1) {
			const item = selectedItems[0];
			const allowReturn = item.return_policy !== 'no_returns';

			if (!item.status) {
				return {
					receive: { visible: true, label: 'Receive item' },
					accept: { visible: false, label: 'Accept item' },
					returns: { visible: allowReturn, label: 'Return item' }
				};
			}

			return match(item.status, {
				received: () => {
					return {
						receive: { visible: true, label: 'Update receive' },
						accept: { visible: true, label: 'Accept item' },
						returns: { visible: allowReturn, label: 'Return item' }
					};
				},
				accepted: () => {
					return {
						receive: { visible: true, label: 'Receive item' },
						accept: { visible: true, label: 'Update accept' },
						returns: { visible: allowReturn, label: 'Return item' }
					};
				},
				returned: () => {
					return {
						receive: { visible: true, label: 'Receive item' },
						accept: { visible: true, label: 'Accept item' },
						returns: { visible: allowReturn, label: 'Update return' }
					};
				}
			});
		}

		const hasReturns = selectedItems.some(
			item => item.status && typeof item.status === 'object' && 'returned' in item.status
		);

		if (hasReturns) {
			// You cannot return multiple items at once
			return defaultActions;
		}

		const hasAllReceived = selectedItems.every(
			item => item.status && typeof item.status === 'object' && 'received' in item.status
		);

		return {
			receive: { visible: !hasAllReceived, label: 'Receive items' },
			accept: { visible: hasAllReceived, label: 'Accept items' },
			returns: { visible: false, label: '' }
		};
	}, [selection, items]);

	return actions;
};
