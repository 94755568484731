import { client as coreClient } from '@partly/core-server-client';
import { ApiConfig } from '../lib';
import {
	basket_handler,
	context,
	core_order,
	Err,
	offer_request_handler,
	order_handler,
	client as repairClient,
	Result
} from './reflect';
import { transform } from './transform';
import { RepairerResponse } from './types';
import { session } from '@common/hooks/use-auth';
import { getFeatureFlags } from '@common/utils/feature-flag';

function createRepairerHeaders(apiConfig: ApiConfig): context.RepairerServerHeaders {
	const { localeSettings } = getFeatureFlags(apiConfig);
	const authorization = apiConfig.userToken ? `Bearer ${apiConfig.userToken.token}` : null;

	return {
		'partly-storefront-key': apiConfig.webKey,
		'partly-accept-currency': localeSettings.currency ?? null,
		'partly-uvdb-region-id': localeSettings.uvdbRegionId ?? null,
		'partly-organization-id': apiConfig.organizationId ?? null,
		authorization
	};
}

function createRepairClient(config: ApiConfig) {
	const headers = createRepairerHeaders(config);
	const api = repairClient(config.basePath);
	return [api, headers] as const;
}

function createCoreClient(config: ApiConfig) {
	const { authorization } = createRepairerHeaders(config);
	const api = coreClient(config.corePath);
	return [api, { authorization }] as const;
}

export async function repairSdk(config: ApiConfig) {
	const [client, headers] = createRepairClient(config);

	const tokens = session.state.getTokens();
	if (tokens?.accessToken) {
		headers.authorization = `Bearer ${tokens.accessToken}`;
	}

	if (!tokens?.accessToken && tokens?.refreshToken) {
		const { data } = await session.actions.refreshToken();
		if (data?.accessToken) {
			headers.authorization = `Bearer ${data.accessToken}`;
		}
	}

	return {
		orders: {
			list: async (
				payload: order_handler.OrdersListRequest
			): Promise<RepairerResponse<core_order.SupplierOrder[], core_order.OrdersGetError>> => {
				const response = await client.jobs.orders.list(payload, headers);
				return to_response(response, data => new Result({ ok: data }));
			},
			insert: async (
				payload: order_handler.JobOrdersInsertRequest
			): Promise<RepairerResponse<core_order.SupplierOrder, core_order.OrdersInsertError>> => {
				const response = await client.jobs.orders.insert(payload, headers);
				return to_response(response, data => new Result({ ok: data }));
			},
			cancel: async (
				payload: order_handler.JobOrdersCancelRequest
			): Promise<RepairerResponse<core_order.SupplierOrder, core_order.OrdersCancelError>> => {
				const response = await client.jobs.orders.cancel(payload, headers);
				return to_response(response, data => new Result({ ok: data }));
			},
			items: {
				process: async (
					payload: order_handler.JobOrdersItemsProcessRequest
				): Promise<
					RepairerResponse<core_order.SupplierOrder, core_order.OrdersItemsProcessError>
				> => {
					const response = await client.jobs.orders.items.process(payload, headers);
					return to_response(response, data => new Result({ ok: data }));
				}
			}
		},
		baskets: {
			get: async (
				payload: basket_handler.BasketGetRequest
			): Promise<
				RepairerResponse<
					basket_handler.exp.BasketGetResponse,
					transform.basket.BasketTransformError
				>
			> => {
				const response = await client.jobs.baskets.get(payload, headers);
				return to_response(response, data => {
					return transform.basket.get(data.payload.item);
				});
			},
			update: async (
				payload: basket_handler.BasketIngestRequest
			): Promise<
				RepairerResponse<
					basket_handler.exp.BasketIngestResponse,
					transform.basket.BasketTransformError
				>
			> => {
				const response = await client.jobs.baskets.ingest(payload, headers);
				return to_response(response, data => {
					return transform.basket.update(data.payload.items);
				});
			}
		},
		offer_requests: {
			get: async (
				payload: offer_request_handler.OfferRequestGetRequest
			): Promise<
				RepairerResponse<
					offer_request_handler.exp.OfferRequestGetResponse,
					transform.offer_request.OfferRequestTransformError
				>
			> => {
				const response = await client.jobs.offer_requests.get(payload, headers);
				return to_response(response, data => {
					return transform.offer_request.get(data);
				});
			},
			insert: async (
				payload: offer_request_handler.OfferRequestInsertRequest
			): Promise<
				RepairerResponse<
					offer_request_handler.exp.OfferRequestInsertResponse,
					transform.offer_request.OfferRequestTransformError
				>
			> => {
				const response = await client.jobs.offer_requests.insert(payload, headers);
				return to_response(response, data => {
					return transform.offer_request.insert(data);
				});
			},
			insert_event: async (
				payload: offer_request_handler.OfferRequestEventInsertRequest
			): Promise<
				RepairerResponse<
					offer_request_handler.exp.OfferRequestInsertEventResponse,
					transform.offer_request.OfferRequestTransformError
				>
			> => {
				const response = await client.jobs.offer_requests.events.insert(payload, headers);
				return to_response(response, data => {
					return transform.offer_request.insert_event(data);
				});
			}
		}
	};
}

// todo: need to refactor this and remove all the stuff
// that was for the old client, it is a bit of a mess now.
export async function coreSdk(config: ApiConfig) {
	const tokens = session.state.getTokens();

	if (tokens?.accessToken) {
		return createCoreClient({
			...config,
			userToken: {
				token: tokens.accessToken
			}
		});
	}

	if (!tokens?.accessToken && tokens?.refreshToken) {
		const { data } = await session.actions.refreshToken();
		if (data?.accessToken) {
			return createCoreClient({
				...config,
				userToken: {
					token: data.accessToken
				}
			});
		}
	}

	return createCoreClient(config);
}

/**
 * R: Response type
 * T: Transformed response type
 * N: Network Error type
 * A: Transform Error type
 */
function to_response<R, T, N, A>(
	response: Result<R, Err<N>>,
	callback: (data: R) => Result<T, A>
): RepairerResponse<T, A | N> {
	if (response.is_err()) {
		const error = response.unwrap_err().unwrap();
		return { data: null, error };
	}

	const data = callback(response.unwrap_ok());
	if (data.is_err()) {
		const error = data.unwrap_err();
		return { data: null, error };
	}

	return {
		data: data.unwrap_ok(),
		error: null
	};
}
