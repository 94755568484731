// make it less expensive
const CACHE_TOKENS = new Map<string, Set<string>>();
export const tokenized = (src: string) => {
	const existing = CACHE_TOKENS.get(src);
	if (existing) {
		return existing;
	}
	const tokens = new Set(normalized(src));
	CACHE_TOKENS.set(src, tokens);
	return tokens;
};

export const normalized = (src: string) =>
	src
		.toLowerCase()
		.replaceAll('\n', ' ')
		.replaceAll('\t', ' ')
		.replaceAll(', ', ' ')
		.replaceAll(',', ' ')
		.replaceAll('-', ' ')
		.replaceAll('  ', ' ')
		.split(' ')
		.filter(each => each.trim().length > 0);
