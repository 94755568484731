import { reasonablySearchableFormat } from '@common/utils/string';
import { compact, sortBy } from 'lodash-es';
import { HCA_TOP_LAYERS_ID } from '../../constants';
import { scoreByTokens } from '../../utils/search/score';
import { FallbackAssembly, FallbackCategoryTreeLeaf, FallbackCategoryTreeNode } from '../types';

const scoreFallbackAssembly = (assembly: FallbackAssembly, query: string) => {
	const context = [
		assembly.hcas
			.filter(
				({ partSlot, id }) =>
					HCA_TOP_LAYERS_ID.map(each => each.toUpperCase()).includes(id.toUpperCase()) ||
					(partSlot &&
						partSlot.gapcPartType &&
						partSlot.gapcPartType.name !== 'N/A' &&
						partSlot.gapcPosition &&
						partSlot.gapcPosition.name !== 'N/A')
			)
			.map(({ description }) => description)
	];

	const scores = [
		...assembly.searchables,
		...(assembly.partSlot?.gapcPartType?.aliases.map(alias =>
			assembly.partSlot?.gapcPosition && assembly.partSlot.gapcPosition.name !== 'N/A'
				? `${alias}, ${assembly.partSlot.gapcPosition.name}`
				: alias
		) ?? [])
	].map(description => {
		const directScore = scoreByTokens(description, query);
		const fullScore = scoreByTokens(`${description} ${context.join(' ')}`, query);

		// best case matching HCA description search using all of the HCA context
		if (directScore === 1) {
			return directScore;
		}
		if (directScore > 0) {
			return fullScore;
		}

		return 0;
	});

	const formattedDescription = reasonablySearchableFormat(
		[assembly.description, context].join(' ')
	);
	const formattedQuery = reasonablySearchableFormat(query);
	if (formattedDescription.includes(formattedQuery)) {
		scores.push(formattedQuery.length / formattedDescription.length / 2);
	}

	return Math.max(...scores);
};

export const reorganizeAssembliesBySearch = (cuts: FallbackCategoryTreeNode[], query: string) => {
	if (query.length < 2) {
		return cuts;
	}
	const organizedNodes = compact(cuts.map(cut => reorganizeCategoryNodeBySearch(cut, query)));
	return organizedNodes.sort((a, b) => (b.highestSearchScore ?? 0) - (a.highestSearchScore ?? 0));
};

export const scoreAssemblies = (
	cuts: FallbackCategoryTreeNode[],
	query: string,
	resultCount: number
) => {
	if (query.length < 1) {
		return [];
	}
	const flattenedAssemblies = flattenAssemblies(cuts);
	const scoredAssemblies = flattenedAssemblies
		.map(asm => {
			return {
				assembly: asm,
				score: scoreFallbackAssembly(asm, query)
			};
		})
		.filter(score => score.score > 0.2)
		.slice(0, resultCount);
	return scoredAssemblies.sort((a, b) => (b.score ?? 0) - (a.score ?? 0));
};

const flattenAssemblies = (cuts: FallbackCategoryTreeNode[]): FallbackAssembly[] => {
	let assemblies: FallbackAssembly[] = [];
	cuts?.forEach(cut => {
		if (cut.categories?.length) {
			cut.categories?.forEach(category => {
				if (category.kind === 'leaf' && category.assemblies?.length) {
					category.assemblies.forEach(asm => assemblies.push(asm));
				}
			});
		}
	});
	return assemblies;
};

const reorganizeCategoryNodeBySearch = (
	node: FallbackCategoryTreeNode,
	query: string
): FallbackCategoryTreeNode | null => {
	const categories = compact(
		node.categories.map(each => {
			if (each.kind === 'leaf') {
				return reorganizeCategoryLeafBySearch(each, query);
			}
			return reorganizeCategoryNodeBySearch(each, query);
		})
	);
	if (categories.length === 0) {
		return null;
	}

	return {
		...node,
		categories,
		highestSearchScore: categories.sort(
			(a, b) => (b.highestSearchScore ?? 0) - (a.highestSearchScore ?? 0)
		)[0].highestSearchScore
	};
};

const reorganizeCategoryLeafBySearch = (
	leaf: FallbackCategoryTreeLeaf,
	query: string
): FallbackCategoryTreeLeaf | null => {
	const assemblies = sortBy(
		leaf.assemblies
			.map(assembly => ({ assembly, score: scoreFallbackAssembly(assembly, query) }))
			.filter(({ score }) => score > 0.2),
		({ score }) => -score
	).map(({ assembly, score }) => {
		return { ...assembly, score };
	});

	if (assemblies.length === 0) {
		return null;
	}
	return {
		...leaf,
		assemblies,
		highestSearchScore: assemblies.sort((a, b) => (b.score ?? 0) - (a.score ?? 0))[0].score
	};
};
