import { SplitCollisionTemplate } from '../split-collision-template';

export const SplitCollisionSkeleton = () => {
	return (
		<SplitCollisionTemplate>
			<SplitCollisionTemplate.Collision>
				<div className="w-1/2 h-24 bg-gray-100 rounded-sm animate-pulse" />
			</SplitCollisionTemplate.Collision>
			<SplitCollisionTemplate.Detail>
				<div className="w-1/2 bg-gray-100 rounded-sm h-7 animate-pulse" />
				<div className="w-1/4 h-6 mt-3 bg-gray-100 rounded-sm animate-pulse" />
				<div className="flex items-center w-full h-12 gap-4 mt-3">
					<div className="size-6 bg-gray-100 rounded-full" />
					<div className="size-12 bg-gray-100 rounded-sm" />
					<div className="flex-1 h-6 bg-gray-100 rounded-sm animate-pulse" />
					<div className="w-32 h-6 bg-gray-100 rounded-full animate-pulse" />
				</div>
				<div className="flex items-center w-full h-12 gap-4 mt-3">
					<div className="size-6 bg-gray-100 rounded-full" />
					<div className="size-12 bg-gray-100 rounded-sm" />
					<div className="flex-1 h-6 bg-gray-100 rounded-sm animate-pulse" />
					<div className="w-32 h-6 bg-gray-100 rounded-full animate-pulse" />
				</div>
				<div className="flex items-center w-full h-12 gap-4 mt-3">
					<div className="size-6 bg-gray-100 rounded-full" />
					<div className="size-12 bg-gray-100 rounded-sm" />
					<div className="flex-1 h-6 bg-gray-100 rounded-sm animate-pulse" />
					<div className="w-32 h-6 bg-gray-100 rounded-full animate-pulse" />
				</div>
			</SplitCollisionTemplate.Detail>
		</SplitCollisionTemplate>
	);
};
