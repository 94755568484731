import { InheritableElementProps } from '@/types/utilties';
import { ReactComponent as AmiMotorhubLogo } from '@assets/business-logo/ami-motorhub.svg';
import { ReactComponent as PartlyLogo } from '@assets/business-logo/partly.svg';
import { ReactComponent as SteerLogo } from '@assets/business-logo/steer-automotive-group.svg';
import { ReactNode } from 'react';

export type BusinessEntity = 'partly' | 'steer' | 'ami-motorhub';

type SvgComponent = (
	props: React.SVGProps<SVGSVGElement> & {
		title?: string | undefined;
	}
) => ReactNode;

type BusinessLogoProps = InheritableElementProps<
	'svg',
	{
		business: BusinessEntity;
	}
>;

const mapping: Record<BusinessEntity, SvgComponent> = {
	partly: PartlyLogo,
	steer: SteerLogo,
	'ami-motorhub': AmiMotorhubLogo
};

export const BusinessLogo = ({ business, ...rest }: BusinessLogoProps) => {
	const Component = mapping[business];

	if (!Component) {
		return null;
	}

	return <Component {...rest} />;
};
