import { tokenized } from './tokens';

export const scoreByTokens = (src: string | null | undefined, query: string) => {
	if (!src) {
		return 0;
	}
	const l = tokenized(src);
	const r = tokenized(query);

	const matches = [...r.values()].filter(each => l.has(each)).length;

	if (matches === 0) {
		return 0;
	}

	return matches / Math.max(r.size, l.size);
};
