import { PageLoader } from '@common/components/page-loader';
import { useMeasurement } from '@common/hooks/use-measure';
import { UnsavedChangesProvider } from '@common/hooks/use-unsaved-changes';
import { PropsWithChildren, Suspense, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Outlet, ScrollRestoration } from 'react-router-dom';

const PORTAL_ID = 'global-header';

export const RootTemplate = () => {
	const { trackRef } = useMeasurement('navigation-bar');
	return (
		<div className="flex flex-col min-h-full">
			<header className="sticky top-0 z-20 empty:hidden" id={PORTAL_ID} ref={trackRef} />
			<main className="relative flex flex-col flex-1">
				<Suspense fallback={<PageLoader />}>
					<UnsavedChangesProvider>
						<ScrollRestoration />
						<Outlet />
					</UnsavedChangesProvider>
				</Suspense>
			</main>
		</div>
	);
};

const Header = ({ children }: PropsWithChildren) => {
	const [element, setElement] = useState<HTMLElement | null>(null);

	// Despite the fact that by the time this component renders, the
	// element will exist, we need to use useLayoutEffect to ensure
	// that the DOM is ready.
	useLayoutEffect(() => {
		setElement(document.getElementById(PORTAL_ID));
	}, []);

	if (!element) {
		return null;
	}

	return createPortal(children, element);
};

RootTemplate.Header = Header;
