import { InheritableElementProps } from '@/types/utilties';
import { tlsx } from '@common/utils/tw-merge';
import { Checkbox } from '@mantine/core';
import { useEffect, useRef } from 'react';
import { PartsDisplayDescription } from '../../../components/parts-display';
import { FallbackAssembly } from '../../types';

export type FallbackPartsDisplayProps = InheritableElementProps<
	'div',
	{
		assembly: FallbackAssembly;
		searched?: boolean;
		checked: boolean;
		categoryDescription: string;
		onChange: () => void;
	}
>;

export const FallbackPartsDisplay = ({
	className,
	assembly,
	searched,
	checked,
	categoryDescription,
	onChange,
	...rest
}: FallbackPartsDisplayProps) => {
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (searched) {
			ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [searched]);

	return (
		<div
			ref={ref}
			onClick={() => {
				onChange();
			}}
			className={tlsx(
				'flex flex-col w-full gap-0.5 px-6 py-3 border-b last:border-0 hover:bg-gray-100 cursor-pointer',
				{ 'bg-blue-700/10': searched },
				className
			)}
			{...rest}
		>
			<div className="flex items-center w-full gap-3">
				<Checkbox size="lg" radius="100%" checked={checked} readOnly />
				<PartsDisplayDescription
					className="text-base"
					assembly={assembly}
					showHint
					trimCategory={categoryDescription}
				/>
			</div>
		</div>
	);
};
