import { match } from '@/types/match';
import { InheritableElementProps } from '@/types/utilties';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ActionIcon, RingProgress } from '@mantine/core';
import { FileUploadError } from '@partly/frontend-utils';
import { memo, useMemo } from 'react';
import { UseFileUploader } from '../../hooks/use-file-uploader';
import { tlsx } from '@common/utils/tw-merge';

// This is a bit sneaky, but rendering an empty image causes
// the browser to have a border which I don't want.
// This is a transparent base64 image.
const EMPTY_IMAGE =
	'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

type FileUploadListProps = InheritableElementProps<
	'div',
	{
		manager: UseFileUploader;
	}
>;

export const FileUploadList = ({ manager, className, ...rest }: FileUploadListProps) => {
	const uploads = useMemo(() => {
		return Object.values(manager.state.uploads);
	}, [manager.state]);

	if (uploads.length === 0) {
		return null;
	}

	return (
		<div className={tlsx('border divide-y rounded-md', className)} {...rest}>
			{uploads.map(upload => {
				const { publicUrl: _, ...rest } = upload;
				return <UploadItem key={upload.id} {...rest} onRemove={manager.removeFile} />;
			})}
		</div>
	);
};

type UploadItemProps = InheritableElementProps<
	'div',
	{
		// We are enumerating all the props so that we can memoise the
		// primatives even when object references change.
		//
		// Usually not that much of a concern, but lots of items
		// all doing progress updates can cause a lot of re-renders.
		id: string;
		localUrl: string | null;
		file: File;
		isComplete: boolean;
		error: FileUploadError | null;
		percentComplete: number;
		onRemove: (id: string) => void;
	}
>;

const UploadItem = memo(
	({
		id,
		localUrl,
		file,
		isComplete,
		error,
		percentComplete,
		onRemove,
		className,
		...rest
	}: UploadItemProps) => {
		const info = getUploadInfo(isComplete, error, percentComplete);

		return (
			<div key={id} className="flex items-center gap-3 p-4" {...rest}>
				<img
					src={localUrl ?? EMPTY_IMAGE}
					alt={file.name}
					className="object-cover overflow-hidden border rounded-md size-10 bg-gray-50"
				/>
				<div className="flex-1 min-w-0 overflow-hidden">
					<div className="w-full text-sm text-gray-700 truncate">{file.name}</div>
					<div className={tlsx('w-full text-xs truncate', info.className)}>{info.message}</div>
				</div>
				<div className="grid place-content-center size-9">
					{isComplete || error ? (
						<ActionIcon color={error ? 'red' : 'gray'} onClick={() => onRemove(id)}>
							<TrashIcon className="size-5" />
						</ActionIcon>
					) : (
						<RingProgress
							size={36}
							thickness={4}
							roundCaps
							sections={[{ value: percentComplete, color: 'blue' }]}
						/>
					)}
				</div>
			</div>
		);
	}
);

const getUploadInfo = (
	isComplete: boolean,
	error: FileUploadError | null,
	percentComplete: number
) => {
	if (error) {
		return { message: getErrorMessage(error), className: 'text-red-600' };
	}
	if (isComplete) {
		return { message: 'Complete', className: 'text-green-700' };
	}
	if (percentComplete === 0) {
		return { message: 'Preparing...', className: 'text-gray-500' };
	}
	return { message: 'Uploading...', className: 'text-gray-500' };
};

const getErrorMessage = (error: FileUploadError): string => {
	return match(
		error,
		{
			auth: auth => auth,
			internal: internal => internal,
			invalid_file_extension: invalid_file_extension => invalid_file_extension,
			network: ({ status, message }) => `Network error: ${status} - ${message}`
		},
		() => 'Unknown error'
	);
};
