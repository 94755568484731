import { tlsx } from '@common/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { UseFileUploader } from '../../hooks/use-file-uploader';

type FileUploadDropzoneProps = InheritableElementProps<
	'div',
	{
		manager: UseFileUploader;
	}
>;

export const FileUploadDropzone = ({ manager, className, ...rest }: FileUploadDropzoneProps) => {
	const onDrop = useCallback((files: File[]) => {
		for (const file of files) {
			manager.addFile(file);
		}
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			// todo: for now only images, need to expand items list
			// to support others.
			'image/jpeg': ['.jpg', '.jpeg'],
			'image/png': ['.png']
		}
	});

	return (
		<div
			{...getRootProps()}
			className={tlsx(
				'w-full p-4 border border-dashed rounded-lg grid place-content-center min-h-28',
				className,
				{
					'border-blue-600 bg-blue-50 ': isDragActive
				}
			)}
			{...rest}
		>
			<input {...getInputProps()} />
			<div className="text-sm text-center text-gray-700">
				{isDragActive ? (
					<p>Drop the files here ...</p>
				) : (
					<p>Drag the files here, or click to select files</p>
				)}
			</div>
		</div>
	);
};
