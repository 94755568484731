import { lookup } from '@partly/core-server-client';

export type ConditionLocalisation = {
	grade: string;
	mfrType: string;
};

export const localiseCondition = ({
	condition,
	isOem,
	isOes
}: {
	condition: lookup.SellableCondition | null;
	isOem?: boolean;
	isOes?: boolean;
}): ConditionLocalisation => {
	return {
		grade: formatGrade(condition),
		mfrType: formatMfrType(isOem, isOes)
	};
};

export const formatGrade = (condition: lookup.SellableCondition | null) => {
	if (!condition) {
		return '';
	}

	if (condition === 'new') {
		return 'New';
	}
	if (condition.used && 'panel' in condition.used) {
		switch (condition.used.panel) {
			case 'grade0':
				return 'Used, like new';
			case 'grade_a':
				return 'Used A';
			case 'grade_b':
				return 'Used B';
			case 'grade_c':
				return 'Used C';
			default:
				return '--';
		}
	}
	return '';
};

export const formatMfrType = (isOem?: boolean, isOes?: boolean) => {
	return isOem ? 'OEM' : isOes ? 'OES' : 'Aftermarket';
};
