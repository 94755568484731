import { useUnsavedChanges } from '@common/hooks/use-unsaved-changes';
import { jobsMutations, jobsQueries } from '@/sdk/react';
import { bmsMutations } from '@/sdk/react/mutations/bms';
import { queryOptions, useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { getApi, unwrap } from '@/sdk/react/utils/helpers';
import { JobVehicleDetails } from '@features/job/components/job-vehicle-detials';
import { createJobPartName, onError } from '@features/supply/utils';
import { Button, Checkbox, Input, Skeleton, Table, Textarea } from '@mantine/core';
import ImageWithSkeleton from '@common/components/image-with-skeleton';
import { tlsx } from '@common/utils/tw-merge';
import { DatePickerInput } from '@mantine/dates';
import * as yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { JobImageUploadDialog } from '@features/job/components/job-image-upload-dialog';

type PageParams = {
	jobId: string;
};

interface JobConfirmationForm {
	images: string[];
	note?: string;
	delivery_date: Date;
}

export const JobConfirmationPage = () => {
	const { jobId } = useParams<PageParams>();

	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	// Queries
	const { data: jobData, refetch } = useSuspenseQuery({
		...jobsQueries.get({ jobId }),
		refetchOnMount: false
	});

	const { data: jobConfirimationData } = useSuspenseQuery({
		...jobsQueries.getJobConfirmation({ job_id: jobId })
	});

	const schema = useMemo(
		() =>
			yup.object({
				images: yup
					.array()
					.test('at-least-one-selected', 'Please select at least one image', value => {
						if (jobData.job.images.length > 0) {
							return value && value.length > 0;
						}
						return true;
					}),
				delivery_date: yup.date().required('Please pick delivery date')
			}),
		[jobData]
	);

	const [isConfirming, setIsConfirming] = useState(false);

	const { data: jobPartsData } = useSuspenseQuery(
		queryOptions({
			queryKey: ['jobparts', { jobId }],
			queryFn: () =>
				unwrap(async () => {
					const sdk = await getApi();
					return sdk.jobs.listParts({ jobId: jobId });
				}),
			refetchOnWindowFocus: false,
			staleTime: 60 * 1000
		})
	);

	// Mutations
	const { mutateAsync: insertJobConfirmation } = useMutation({
		...jobsMutations.insertConfirmation,
		onError
	});

	const { mutateAsync: syncImages, isPending } = useMutation({
		...bmsMutations.syncJobImages,
		onSuccess: () => refetch()
	});

	const navigate = useNavigate();

	const getDeliveryWithHours = (hour: number, date: Date) => {
		const currentDelivery = new Date(date);
		const newDelivery = currentDelivery.setHours(hour, 0, 0, 0);

		return new Date(newDelivery);
	};

	const form = useForm<JobConfirmationForm>({
		defaultValues: {
			images: jobConfirimationData?.images || jobData.job.images.map(img => img.original) || [],
			note: jobConfirimationData?.note || '',
			delivery_date: jobConfirimationData
				? new Date(jobConfirimationData.delivery_date)
				: getDeliveryWithHours(9, new Date())
		},
		resolver: yupResolver(schema)
	});

	const values = form.watch();

	useUnsavedChanges(({ nextLocation }) => {
		const isConfirmation = nextLocation.pathname.startsWith(`/job/${jobId}/orders`);
		const isBlocking = form.formState.isDirty && !form.formState.isSubmitting && !isConfirmation;
		return isBlocking;
	});

	const confirmJob = async (data: JobConfirmationForm) => {
		setIsConfirming(true);

		const response = await insertJobConfirmation({
			delivery_date: new Date(data.delivery_date).getTime(),
			images: data.images,
			job_id: jobId,
			note: data.note || undefined
		});

		setIsConfirming(false);

		if (response) {
			// Prevent racing condition with supply page redirect
			await refetch();
			navigate(`/job/${jobId}/supply`);
		}
	};

	useEffect(() => {
		syncImages({
			jobId: jobId
		});
	}, []);

	useEffect(() => {
		if (!jobData.job.needsConfirmation) {
			navigate(`/job/${jobId}/supply`);
		}
	}, [jobData]);

	const [uploadImage, setUploadImage] = useState(false);

	return (
		<div className="flex flex-col-reverse grid-cols-12 gap-6 px-8 pb-12 mx-auto mt-8 lg:grid">
			<div className="col-span-8">
				<form className="flex flex-col gap-8" onSubmit={form.handleSubmit(confirmJob)}>
					<h1 className="mt-8 text-2xl font-semibold">Job summary</h1>
					<p className="w-[60rem] text-sm -mt-2 text-gray-700">
						This page is a summary of the vehicle details, parts required, photos of the damage and
						any notes to share with suppliers. When selecting your delivery date, choose the ideal
						date. This can be changed once suppliers confirm parts availability.
					</p>
					<div className="flex items-end gap-12">
						<Controller
							control={form.control}
							name="delivery_date"
							render={({ field, fieldState }) => {
								return (
									<DatePickerInput
										required
										classNames={{ input: 'w-48', root: 'flex-col !items-start' }}
										minDate={new Date()}
										valueFormat="ddd D MMM"
										className="flex gap-2"
										error={fieldState?.error?.message}
										label={<b className="text-base font-semibold">Delivery date</b>}
										{...field}
										onChange={d => {
											if (d) {
												form.setValue(
													'delivery_date',
													getDeliveryWithHours(values.delivery_date.getHours(), d)
												);
											}
										}}
									/>
								);
							}}
						/>
						<div>
							<Input.Label required>
								<b className="text-base font-semibold">Delivery time</b>
							</Input.Label>
							<div className="flex gap-4 text-sm  mb-0.5 mt-2.5">
								<button
									onClick={() =>
										form.setValue('delivery_date', getDeliveryWithHours(9, values.delivery_date))
									}
									type="button"
									className={clsx(
										'px-4 h-8 items-center justify-center ring-1 rounded-md hover:bg-gray-50',
										values.delivery_date.getHours() === 9
											? 'ring-blue-500 bg-blue-100 hover:bg-blue-200'
											: 'ring-gray-200'
									)}
									role="radio"
								>
									9 am
								</button>
								<button
									onClick={() =>
										form.setValue('delivery_date', getDeliveryWithHours(14, values.delivery_date))
									}
									type="button"
									role="radio"
									className={clsx(
										'px-4 h-8 flex items-center justify-center ring-1 rounded-md',
										values.delivery_date.getHours() === 14
											? 'ring-blue-500 bg-blue-100 hover:bg-blue-200'
											: 'ring-gray-200'
									)}
								>
									2 pm
								</button>
							</div>
						</div>
					</div>
					<div>
						<b>Parts list</b>
						<div className="p-4 mt-2 border border-gray-300 rounded-md">
							<Table>
								<thead>
									<tr>
										<th>Qty.</th>
										<th>Items</th>
									</tr>
								</thead>
								<tbody>
									{jobPartsData.parts.map(part => {
										const jobPartName = createJobPartName(part);

										return (
											<tr key={part.id}>
												<td className="border-gray-300">{part.quantity}</td>
												<td className="border-gray-300">
													<div className="flex flex-col text-left">
														<span className=" whitespace-break-spaces">{jobPartName}</span>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</div>
					</div>
					<div>
						{isPending ? (
							<Skeleton height={120} />
						) : (
							<>
								<JobImageUploadDialog
									jobId={jobData.job.id}
									repairerSiteId={jobData.job.repairerSiteId}
									existingImages={jobData.job.images.map(img => img.original)}
									open={uploadImage}
									// Auto select uploaded images
									onUpload={img =>
										form.setValue('images', [...new Set([...values.images, ...img])])
									}
									onClose={() => setUploadImage(false)}
								/>
								<div className="flex items-center justify-between">
									<div className="flex items-center gap-1">
										<b>{`Photo for suppliers (${values.images.length} selected) `}</b>
										<span className="text-sm">- Minimum 1</span>
									</div>
									<Button variant="default" size="xs" onClick={() => setUploadImage(true)}>
										Upload photos
									</Button>
								</div>
								<div className="mt-1 mb-8">
									<p className="text-sm text-gray-700">
										Upload clear photos of the damaged areas and parts to help suppliers find
										accurate replacements.
									</p>
									{form.formState.errors.images?.message ? (
										<p className="mt-2 text-sm text-red-600">
											{form.formState.errors.images?.message}
										</p>
									) : null}
								</div>
								{(jobData.job.images.length > 0 || values.images.length > 0) && (
									<Controller
										control={form.control}
										name="images"
										render={({ field }) => {
											return (
												<Checkbox.Group
													className="grid w-full max-w-3xl grid-cols-4 gap-2 p-4 -mt-3 overflow-auto border border-gray-300 rounded-md shadow-xs s h-96"
													{...field}
												>
													{jobData.job.images.map(img => (
														<Checkbox
															key={img.original}
															label={
																<ImageWithSkeleton
																	className={tlsx('aspect-square rounded-sm object-cover', {
																		'ring-2 ring-theme-600 ring-offset-2': field.value?.includes(
																			img.original
																		)
																	})}
																	src={img.original}
																	alt=""
																/>
															}
															value={img.original}
															className="cursor-pointer"
														></Checkbox>
													))}
												</Checkbox.Group>
											);
										}}
									/>
								)}
							</>
						)}
					</div>
					<Controller
						control={form.control}
						name="note"
						render={({ field }) => (
							<Textarea
								className="w-full"
								minRows={3}
								label={
									<div className="flex items-center gap-2">
										<b className="text-base">Notes for suppliers</b>
										<span className="text-sm">(Recommended)</span>
									</div>
								}
								description={
									<p className="mb-4 text-gray-700 text-sm">
										Add any details that can help suppliers respond faster with the right parts.
										These might include trim info, preferred condition, or delivery requirements.
									</p>
								}
								placeholder="Type a message for suppliers"
								{...field}
							/>
						)}
					/>
					<Button type="submit" disabled={isConfirming}>
						{isConfirming ? 'Confirming' : 'Confirm'} job {isConfirming ? '...' : ''}
					</Button>
				</form>
			</div>
			<div className="col-span-4">
				<JobVehicleDetails job={jobData.job} />
			</div>
		</div>
	);
};
