import {
	GapcBrandSuidRaw,
	PartAssemblyRaw,
	WithI18n_for_WorldTreeAssemblyRaw
} from '@/sdk/generated';
import { WorldTreeAssemblyLink } from '@/sdk/generated/models/WorldTreeAssemblyLink';
import { isDefined } from '@partly/js-ex';
import { PartAssemblyLink } from '@sdk/generated/models/PartAssemblyLink';
import {
	PartAssembliesTree,
	PartAssembly,
	PartAssemblyInfo,
	PartSlot,
	WorldTreeAssembliesTree,
	WorldTreeAssembly
} from '../types';
import { recursiveToCamel } from '../utils/casing';
import { Context } from '../utils/context';
import { decodeGapcPartIdentityKey, ResourcePath } from '../utils/resources';
import { createGapcBrand, createGapcDiagram, createGapcDiagrams, createPartSlot } from './gapc';

type PartAssembliesTreeRaw = {
	assemblies: PartAssemblyLink[];
};

type WorldTreeAssembliesTreeRaw = {
	assemblies: WorldTreeAssemblyLink[];
	gapc_brand: GapcBrandSuidRaw;
};

export const createPartAssembly = (assembly: PartAssemblyRaw, ctx: Context): PartAssembly => {
	const partSlot = assembly.part_slot ? createPartSlot(assembly.part_slot, ctx) : null;
	const part = assembly.part_identity ? createPartAssemblyInfo(assembly.part_identity, ctx) : null;
	const worldTree = assembly.hca_id ? createPartAssemblyWorldTree(assembly.hca_id, ctx) : null;

	const subAssemblies = createPartSubAssemblies(assembly.sub_assemblies, ctx);

	const description = createPartAssemblyDescription(
		assembly.description ?? null,
		worldTree?.name ?? null,
		part,
		partSlot
	);

	const diagrams = assembly.categorized_diagrams
		? assembly.categorized_diagrams
				.map(({ diagram: id, level }) => {
					const diagram = createGapcDiagram(id, ctx);
					if (!diagram) return null;
					return {
						level,
						diagram
					};
				})
				.filter(isDefined)
		: assembly.diagrams
			? createGapcDiagrams(assembly.diagrams, ctx).map(diagram => ({ diagram, level: 1 }))
			: null;

	return {
		id: assembly.id,
		description,
		partSlot,
		hca: assembly.hca_id,
		displayName: worldTree?.name,
		part,
		diagrams,
		assemblyType: assembly.assembly_type,
		subAssemblies,
		confidence: assembly.confidence,
		note: assembly.std_note,
		worldTree
	};
};

export const createPartAssemblies = (items: string[], ctx: Context): PartAssembly[] => {
	return items
		.map(key => {
			const path = ResourcePath.create<'part_assemblies'>(key);
			if (!path) {
				return null;
			}

			const value = ctx.resources.get(path);
			if (!value) {
				return null;
			}

			return createPartAssembly(value, ctx);
		})
		.filter(isDefined);
};

export const createPartAssembliesTree = (
	tree: PartAssembliesTreeRaw,
	ctx: Context
): PartAssembliesTree => {
	return {
		assemblies: createPartAssemblies(tree.assemblies, ctx)
	};
};

export const createPartSubAssemblies = (subAssemblies: string[], ctx: Context) =>
	subAssemblies
		.map(sub_assembly => {
			const path = ResourcePath.create<'part_assemblies'>(sub_assembly);
			if (!path) {
				return null;
			}

			const value = ctx.resources.get(path);
			if (!value) {
				return null;
			}
			const assembly = createPartAssembly(value, ctx);

			return assembly;
		})
		.filter(isDefined);

export const createWorldTreeAssembly = (
	assembly: WithI18n_for_WorldTreeAssemblyRaw,
	ctx: Context
): WorldTreeAssembly => {
	const partSlot = createPartSlot(assembly.part_slot, ctx);
	const description = createPartAssemblyDescription(
		assembly.description ?? null,
		assembly.name ?? null,
		null,
		partSlot
	);

	return {
		id: assembly.id,
		description,
		name: assembly.name,
		partSlot,
		information: assembly.information ? recursiveToCamel(assembly.information) : null,
		// todo (vincent) reconsider only getting same lang aliases
		aliases: assembly.aliases.map(({ name }) => name),
		subAssemblies: createWorldTreeAssemblies(assembly.sub_assemblies, ctx)
	};
};

export const createWorldTreeAssemblies = (items: string[], ctx: Context): WorldTreeAssembly[] => {
	return items
		.map(key => {
			const path = ResourcePath.create<'world_tree_assemblies'>(key) ?? {
				id: key,
				path: 'world_tree_assemblies'
			};
			const value = ctx.resources.get(path);
			if (!value) {
				return null;
			}

			return createWorldTreeAssembly(value, ctx);
		})
		.filter(isDefined);
};

export const createWorldTreeAssembliesTree = (
	tree: WorldTreeAssembliesTreeRaw,
	ctx: Context
): WorldTreeAssembliesTree => {
	return {
		assemblies: createWorldTreeAssemblies(tree.assemblies, ctx),
		gapcBrandId: tree.gapc_brand
	};
};

export const createPartAssemblyInfo = (partIdentity: string, ctx: Context): PartAssemblyInfo => {
	const { gapcBrandId, mpn } = decodeGapcPartIdentityKey(partIdentity);
	const brand = createGapcBrand(gapcBrandId, ctx);
	return {
		gapcBrandId,
		mpn,
		partIdentity,
		brand
	};
};

export const createPartAssemblyWorldTree = (hcaId: string, ctx: Context) => {
	const worldTreeRaw = ctx.resources.get({ path: 'world_tree_assemblies', id: hcaId });
	if (!worldTreeRaw) {
		return null;
	}

	return createWorldTreeAssembly(worldTreeRaw, ctx);
};

export const createPartAssemblyDescription = (
	description: string | null,
	worldTreeName: string | null,
	part: PartAssemblyInfo | null,
	partSlot: PartSlot | null
) => {
	if (worldTreeName) {
		return worldTreeName.split(' > ').at(-1) ?? worldTreeName;
	}

	const partSlotDescription = [partSlot?.gapcPartType?.name, partSlot?.gapcPosition?.name]
		.filter(isNotNA)
		.join(', ');

	if (isNotNA(partSlot?.gapcPartType?.name) && partSlotDescription) {
		return partSlotDescription;
	}

	if (description) {
		return description;
	}

	return `Select part${part?.mpn ? ` (${part.mpn})` : ''}`;
};

const isNotNA = (str?: string | null) => isDefined(str) && str !== 'N/A';
