import { createPartSlotIds } from '@common/utils/part';
import { WorldTreeAssembly } from '@/sdk/lib';
import { compact, partition, sortBy } from 'lodash-es';
import { HCA_FRONT_CUT_ID, HCA_NOSE_CUT_ID, HCA_TOP_LAYERS_ID } from '../../constants';
import {
	FallbackAssembly,
	FallbackAssemblyInfo,
	FallbackCategoryTree,
	FallbackCategoryTreeLeaf,
	FallbackCategoryTreeNode
} from '../types';

export const categoriesAssemblies = (assemblies: WorldTreeAssembly[]) => {
	const frontCut = assemblies.find(asm => asm.id === HCA_FRONT_CUT_ID);
	const noseCut = frontCut?.subAssemblies.find(asm => asm.id === HCA_NOSE_CUT_ID);
	const frontCutFiltered = frontCut
		? {
				...frontCut,
				subAssemblies: frontCut.subAssemblies.filter(sub => sub.id !== HCA_NOSE_CUT_ID)
			}
		: undefined;
	const topLevelAssemblies = compact([
		noseCut,
		frontCutFiltered,
		...assemblies.filter(asm => asm.id !== HCA_FRONT_CUT_ID)
	]);

	const [cuts, others] = partition(topLevelAssemblies, ({ id }) =>
		HCA_TOP_LAYERS_ID.map(layer => layer.toUpperCase()).includes(id.toUpperCase())
	);

	const categories = sortBy(
		cuts.map(assembly => transformFallbackCategoryTree(assembly, [])),
		({ description }) => description
	);

	const order = HCA_TOP_LAYERS_ID.map(layer => layer.toUpperCase());
	const orderedCategories = categories.sort(
		(a, b) => order.indexOf(a.id.toUpperCase()) - order.indexOf(b.id.toUpperCase())
	);

	orderedCategories.push({
		kind: 'node',
		id: 'other',
		description: 'Other',
		categories: others.map(assembly => transformFallbackCategoryTree(assembly, [])),
		hcas: [],
		searchables: []
	});

	return orderedCategories as FallbackCategoryTreeNode[];
};

const transformFallbackCategoryTree = (
	hca: WorldTreeAssembly,
	hcas: FallbackAssemblyInfo[]
): FallbackCategoryTree => {
	const isCut = HCA_TOP_LAYERS_ID.map(each => each.toUpperCase()).includes(hca.id.toUpperCase());
	const isLeaf = !isCut;

	// Hack, hide duplicate named children, because they aren't useful
	const assemblies = hca.subAssemblies.filter(asm => asm.name !== hca.name);

	if (isLeaf) {
		return {
			kind: 'leaf',
			id: hca.id,
			description: hca.description,
			assemblies: sortBy(assemblies, hca => hca.description).flatMap(sub =>
				transformFallbackAssembly(sub, [
					...hcas,
					{
						id: hca.id,
						description: hca.description,
						partSlot: hca.partSlot
					}
				])
			),
			hcas,
			searchables: [hca.description, ...hca.aliases.map(alias => alias)]
		};
	}

	const categories = sortBy(
		assemblies.map(sub =>
			transformFallbackCategoryTree(sub, [
				...hcas,
				{
					id: hca.id,
					description: hca.description,
					partSlot: hca.partSlot
				}
			])
		),
		category => (category.kind === 'leaf' ? 1 : -1)
	);

	return {
		kind: 'node',
		id: hca.id,
		description: hca.description,
		hcas,
		categories,
		searchables: [hca.description, ...hca.aliases.map(alias => alias)]
	};
};

const transformFallbackAssembly = (
	hca: WorldTreeAssembly,
	hcas: FallbackAssemblyInfo[]
): FallbackAssembly[] => {
	const res = [] as FallbackAssembly[];
	if (hca.partSlot.gapcPartType?.name !== 'N/A') {
		const self: FallbackAssembly = {
			id: hca.id,
			description: hca.description,
			displayName: hca.name,
			partSlot: hca.partSlot,
			partSlotIds: createPartSlotIds(hca.partSlot),
			information: hca.information,
			searchables: [hca.description, ...hca.aliases.map(alias => alias)],
			hcas
		};

		res.push(self);
	}

	// Hack, hide duplicate named children, because they aren't useful
	const assemblies = hca.subAssemblies.filter(asm => asm.name !== hca.name);

	res.push(
		...assemblies.flatMap(sub =>
			transformFallbackAssembly(sub, [
				...hcas,
				{
					description: hca.description,
					id: hca.id,
					partSlot: hca.partSlot
				}
			])
		)
	);

	return res;
};

export const fallbackCategoryLeaves = (
	category: FallbackCategoryTree
): FallbackCategoryTreeLeaf[] => {
	if (category.kind === 'leaf') {
		return [category];
	}
	return category.categories.flatMap(fallbackCategoryLeaves);
};

export const getDeepRecommendations = (
	tree: FallbackCategoryTreeNode[],
	searchNodes: string[]
): FallbackAssembly[] => {
	let foundNodes: FallbackAssembly[] = [];
	tree.forEach(node => {
		if (node.categories.length > 0) {
			node.categories.forEach(category => {
				if (category.kind === 'leaf' && category.assemblies.length > 0) {
					category.assemblies.forEach(asm => {
						if (searchNodes.includes(asm.id)) {
							foundNodes.push(asm);
						}
					});
				}
			});
		}
	});
	return foundNodes;
};
