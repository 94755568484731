import {
	CloseJobRequest,
	JobsConfirmationInsertRequest,
	JobsConfirmationInsertResult,
	RemoveJobPartAssembliesTreeRequest,
	RemoveJobPartAssembliesTreeResult,
	RemoveJobRequest,
	RemoveJobResult,
	StorefrontError,
	UpsertJobCollisionsRequest,
	UpsertJobCollisionsResult,
	UpsertJobPartsRequest,
	UpsertJobPartsResult,
	UpsertJobRequest,
	UpsertJobResult,
	UpsertJobVehicleRequest,
	UpsertJobVehicleResult
} from '@sdk/lib';
import { getApi, unwrap } from '../utils/helpers';
import { mutationOptions } from '../utils/mutation-options';
import { api_error } from '@/sdk/reflect/reflect';

export const jobsMutations = {
	remove: mutationOptions<RemoveJobResult, StorefrontError, RemoveJobRequest>({
		mutationKey: ['jobs', 'remove'],
		mutationFn: payload =>
			unwrap(async () => {
				const sdk = await getApi();
				return sdk.jobs.remove(payload);
			})
	}),
	close: mutationOptions<RemoveJobResult, StorefrontError, CloseJobRequest>({
		mutationKey: ['jobs', 'close'],
		mutationFn: payload =>
			unwrap(async () => {
				const sdk = await getApi();
				return sdk.jobs.close(payload);
			})
	}),
	upsert: mutationOptions<UpsertJobResult, StorefrontError, UpsertJobRequest>({
		mutationKey: ['jobs', 'upsert'],
		mutationFn: payload =>
			unwrap(async () => {
				const sdk = await getApi();
				return sdk.jobs.upsert(payload);
			})
	}),
	insertConfirmation: mutationOptions<
		JobsConfirmationInsertResult,
		api_error.RepairerServerError,
		JobsConfirmationInsertRequest
	>({
		mutationKey: ['jobs', 'insertConfirmation'],
		mutationFn: payload =>
			unwrap(async () => {
				const sdk = await getApi();
				return sdk.jobs.insertConfirmation(payload);
			})
	}),
	upsertCollisions: mutationOptions<
		UpsertJobCollisionsResult,
		StorefrontError,
		UpsertJobCollisionsRequest
	>({
		mutationKey: ['jobs', 'upsertCollisions'],
		mutationFn: payload =>
			unwrap(async () => {
				const sdk = await getApi();
				return sdk.jobs.upsertCollisions(payload);
			})
	}),
	upsertParts: mutationOptions<UpsertJobPartsResult, StorefrontError, UpsertJobPartsRequest>({
		mutationKey: ['jobs', 'upsertParts'],
		mutationFn: payload =>
			unwrap(async () => {
				const sdk = await getApi();
				return sdk.jobs.upsertParts(payload);
			})
	}),
	upsertVehicle: mutationOptions<UpsertJobVehicleResult, StorefrontError, UpsertJobVehicleRequest>({
		mutationKey: ['jobs', 'upsertVehicle'],
		mutationFn: payload =>
			unwrap(async () => {
				const sdk = await getApi();
				return sdk.jobs.upsertVehicle(payload);
			})
	}),
	removePartAssembliesTree: mutationOptions<
		RemoveJobPartAssembliesTreeResult,
		StorefrontError,
		RemoveJobPartAssembliesTreeRequest
	>({
		mutationKey: ['jobs', 'removePartAssembliesTree'],
		mutationFn: payload =>
			unwrap(async () => {
				const sdk = await getApi();
				return sdk.jobs.removePartAssembliesTree(payload);
			})
	})
};
