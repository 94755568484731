import { linkWithSearchParams } from '@/app/common/utils/url';
import { withSignedIn } from '@common/hoc/with-access';
import { GetJobResult } from '@sdk/lib';
import { jobsQueries } from '@sdk/react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Navigate, useParams } from 'react-router-dom';

type JobExternalPageProps = {
	jobExternalId: string;
	page?: string;
};

const JobExternalPage = () => {
	const { jobExternalId, page } = useParams<JobExternalPageProps>();
	if (!jobExternalId) {
		throw new Error('Missing required jobExternalId parameter');
	}

	const { data } = useSuspenseQuery(jobsQueries.get({ jobId: { external_id: jobExternalId } }));

	const getPage = (data: GetJobResult) => {
		// if a vehicle is missing, always sent to the job creation page
		if (!data.job.vehicle || (!data.job.vehicle.plateNumber && !data.job.vehicle.chassisNumber)) {
			return linkWithSearchParams('/jobs/create', {
				siteId: data.job.repairerSiteId,
				jobId: data.job.id,
				jobNumber: data.job.jobNumber ?? undefined,
				claimNumber: data.job.claimNumber ?? undefined
			});
		}

		if (!data.job.vehicle.variant) {
			return linkWithSearchParams('/jobs/create/vehicles', {
				jobId: data.job.id,
				jobNumber: data.job.jobNumber ?? undefined,
				claimNumber: data.job.claimNumber ?? undefined,
				chassisNumber: data.job.vehicle.chassisNumber ?? undefined,
				plateNumber: data.job.vehicle.plateNumber ?? undefined,
				plateState: data.job.vehicle.plateState ?? undefined
			});
		}

		const jobPage = `/job/${data.job.id}`;

		if (!page) {
			return jobPage;
		}

		switch (page) {
			case 'parts': {
				if (!data.job.vehicle.variant.token) {
					return `${jobPage}/parts/fallback`;
				}
				return `${jobPage}/parts`;
			}
			case 'supply':
				return `${jobPage}/supply`;
			default:
				return jobPage;
		}
	};

	return <div>{data && <Navigate to={getPage(data)} replace />}</div>;
};

export default withSignedIn(JobExternalPage);
