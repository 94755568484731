import { offers_search } from '@partly/core-server-client';
import { Offer, PartOfferAggregation, TransientPartId } from '../../models';
import { useMemo } from 'react';
import { createJobPartName, findMatchingOffer, formatSellableOffers } from '../../utils';
import { JobPart } from '@/sdk/lib';
import { offer_request_handler } from '@/sdk/reflect/reflect';
import { EXTRA_JOB_PART } from '../../constants';

/**
 * A hook that takes parts/offers and converts them into a part offer
 * aggregation based on matches.
 */
export const usePartOfferAgregations = (
	jobParts: JobPart[],
	matches: offers_search.JobPartMatch[],
	offers: Offer[],
	// isReady: boolean,
	offerRequestsData: offer_request_handler.exp.OfferRequestGetResponse
): PartOfferAggregation[] => {
	const aggregations = useMemo(() => {
		// Format offers to flatten groups
		const flattenOffers = formatSellableOffers(offers, offerRequestsData);

		const aggregationMap = jobParts.reduce((acc, jobPart) => {
			acc.set(jobPart.id, {
				jobPart,
				offers: []
			});

			return acc;
		}, new Map<TransientPartId, PartOfferAggregation>());

		const newJobPartsAggregations: PartOfferAggregation[] = [];

		for (const match of matches) {
			const aggregation = aggregationMap.get(match.job_part_id);

			// We need to match either by offer_part_id or offer_id depending if its group or single (and product or assembly)
			const offer = findMatchingOffer(flattenOffers, match);

			if (!offer) {
				// This shouldn't happen until we add support for group
				// supersessions which require additional UI logic, so we should
				// filter these out for now.
				continue;
			}

			if (aggregation) {
				aggregation.offers.push({
					...offer,
					matchedOfferPartId: match.offer_part_id
				});
			} else {
				const existing = newJobPartsAggregations.find(
					aggr => aggr.jobPart.id === match.job_part_id
				);
				if (existing) {
					existing.offers.push({ ...offer, matchedOfferPartId: match.offer_part_id });
				} else {
					newJobPartsAggregations.push({
						jobPart: { id: match.job_part_id, quantity: 1, description: EXTRA_JOB_PART },
						offers: [{ ...offer, matchedOfferPartId: match.offer_part_id }]
					});
				}
			}
		}

		const aggregationsList = Array.from(aggregationMap.values())
			.sort((a, b) => {
				const aName = createJobPartName(a.jobPart);
				const bName = createJobPartName(b.jobPart);
				const alphaCompare = aName.localeCompare(bName);

				const lengthCompare = aName.length - bName.length;

				// If they are the same alphabetically, compare by length
				if (alphaCompare === 0) {
					return lengthCompare;
				}

				return alphaCompare;
			})
			.sort((a, b) => {
				if (a.offers.length > 0 && b.offers.length === 0) {
					return -1;
				}
				// If offers.length > 0 for 'b' and 0 for 'a', 'b' comes after 'a'
				if (a.offers.length === 0 && b.offers.length > 0) {
					return 1;
				}
				// If both have the same offer length (either both > 0 or both 0), no change
				return 0;
			});

		if (newJobPartsAggregations.length > 0) {
			aggregationsList.push(...newJobPartsAggregations);
		}

		return aggregationsList;
	}, [jobParts, matches, offers]);

	return aggregations;
};
